import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { D365CustomerSegment } from '../../../../common/enums/d365/d365-customer-segment';
import { containsCommodity, flowTypeUtil } from '../../../../common/functions/verifications.functions';
import { BaseApiResponse, StatusResponse } from '../../../../common/interfaces/base-api-response';
import { ServiceError } from '../../../../common/models/app/service-error';
import { ApiMngApi, BaseProvider } from '../../../../common/providers/base-provider';
import { CommonProvider } from '../../../../common/providers/common-provider';
import { ApiService } from '../../../../common/services/shared/api.service';
import { LoadingService } from '../../../../common/services/shared/loading.service';
import { LoggerService } from '../../../../common/services/shared/logger.service';
import { PrivateConfigurationService } from '../../../../common/services/shared/private-configuration.service';
import { setV2TransferModel } from '../../../../store/actions/order-entry-v2.actions';
import { FlowType } from '../../../../store/models/flow-type';
import { Product, ProductConfigurations } from '../../../../store/models/order-entry-state_v2';
import { EglState } from '../../../../store/reducers';
import {
    v2SelectAllProducts,
    v2SelectCurrentProduct,
    v2SelectFlowType,
} from '../../../../store/selectors/order-entry-v2.selectors';
import { selectCreditCheckStatus } from '../../../../store/selectors/order-entry.selectors';
import { selectCurrentProfile, selectUserState } from '../../../../store/selectors/user.selectors';
import { Supply, TransferModelReq } from '../models/transfer-model-request';
import { TrafficLightOutcome, TransferModelResponse } from '../models/transfer-model-response';

@Injectable({ providedIn: 'root' })
export class TransferModelService extends BaseProvider {
    constructor(
        protected store: Store<EglState>,
        protected configSrv: PrivateConfigurationService,
        translateSrv: TranslateService,
        private api: ApiService,
        private commonProvider: CommonProvider,
    ) {
        super(configSrv, translateSrv);
    }
    loggerService: LoggerService;

    /**
     * @description La chiamata "modello voltura" ha la funzione di intercettare l’intento fraudolento di eludere azioni di recupero credito senza procedere al saldo degli importi dovuti.
     * @returns Observable<TransferModelResponse>
     */
    public transferModel(): Observable<TransferModelResponse> {
        return combineLatest([
            this.store.select(selectUserState),
            this.store.select(v2SelectCurrentProduct()),
            this.store.select(selectCurrentProfile),
            this.store.select(v2SelectFlowType),
            this.store.select(v2SelectAllProducts),
            this.store.select(selectCreditCheckStatus),
        ]).pipe(
            map(([user, product, profile, flowType, allProducts, creditCheckStatus]) => {
                const transferodelJSON: TransferModelReq = {
                    SegmentoVolturante: user?.cartSegment,
                    CodiceFiscale:
                        user?.cartSegment === D365CustomerSegment.Residential ? user?.contact?.egl_taxcode : '',
                    PartitaIva:
                        user?.cartSegment === D365CustomerSegment.Microbusiness ? user?.contact?.egl_vatcode : '',
                    NomeCognome:
                        user?.cartSegment === D365CustomerSegment.Residential
                            ? `${user?.contact?.firstname} | ${user?.contact?.lastname} `
                            : '',
                    RagioneSociale: user?.cartSegment === D365CustomerSegment.Microbusiness ? user?.contact?.name : '',
                    FormaGiuridica: '',
                    TipoControlloCreditizio: 'modello voltura',
                    CanaleAcquisizione: profile?.selectedVirtualAg?.VirtualAgency?.Channel?.Code,
                    CodiceAgenzia: profile?.selectedVirtualAg?.CurrentCode,
                    ModalitaPagamentoVolturante: product?.paymentInfo?.paymentInstrument,
                    MailVolturante:
                        user?.cartSegment === D365CustomerSegment.Residential ? user?.contact?.emailaddress1 : '',
                    PECVolturante:
                        user?.cartSegment === D365CustomerSegment.Microbusiness ? user?.contact?.emailaddress1 : '',
                    CellulareVolturante: user?.contact?.mobilephone,
                    ClienteVolturante: user?.customerMastership,
                    ModalitaOperativa: flowType,
                    Forniture: this.getSupplyInfo(allProducts),
                    FlagVulnerabilityVolturante: this.getVulnerability(product?.configurations),
                    ResidenteNonResidente: product?.configurations?.supplyUse,
                    FasciaOraria: product?.configurations?.hoursBundle,
                };

                if (
                    !flowTypeUtil(flowType).equalTo(FlowType.Voltura) ||
                    product?.isMortisCausa ||
                    creditCheckStatus.ccDetails?.whitelist === 'OK'
                ) {
                    //Salto il controllo
                    return null;
                } else {
                    return {
                        input_load: {
                            dataframe_records: [
                                {
                                    INPUT_JSON: JSON.stringify(transferodelJSON).replace(/[']/g, "\\'"),
                                },
                            ],
                        },
                    };
                }
            }),
            mergeMap((req) =>
                this.commonProvider.getBusinessTransactionId().pipe(map((businessTrxId) => ({ req, businessTrxId }))),
            ),
            switchMap(({ req, businessTrxId }) => {
                if (req == null) return of(null);
                return this.api
                    .postAsync<BaseApiResponse<TransferModelResponse>>(
                        this.getApiMngApiUrl(ApiMngApi.TransferModel),
                        req,
                        0,
                        {
                            headers: {
                                businessTrxId,
                            },
                        },
                    )
                    .pipe(
                        map((response) => {
                            if (
                                (response?.result !== '001' || response?.status !== StatusResponse.Success) &&
                                response instanceof BaseApiResponse
                            ) {
                                throw new ServiceError(
                                    response?.errorManagement?.errorCode,
                                    response.errorManagement?.errorDescription,
                                );
                            }
                            return response?.response;
                        }),
                        map((res) => res as TransferModelResponse),
                        catchError((error: Error) => {
                            this.loggerService.error('Errore chiamata gestione case', error?.message, error, true);
                            this.store.dispatch(
                                setV2TransferModel({
                                    transferModel: {
                                        predictions: { esitoSemaforico: TrafficLightOutcome.error, score: null },
                                    },
                                }),
                            );
                            return of(null);
                        }),
                    );
            }),
            tap((res) => {
                if (res === null) return;
                this.store.dispatch(
                    setV2TransferModel({
                        transferModel: {
                            predictions: {
                                esitoSemaforico: res?.predictions?.esitoSemaforico,
                                score: res?.predictions?.score,
                            },
                        },
                    }),
                );
            }),
            map(() => null),
            LoadingService.loaderOperator('Attendere...'),
        );
    }

    private getVulnerability(config: ProductConfigurations): string[] {
        const vulnerabilities: string[] = [];
        if (config.vulnerabilityOver75) vulnerabilities.push('OVER_75');
        if (config.vulnerabilitySocialBonus) vulnerabilities.push('SOCIAL_BONUS');
        if (config.vulnerabilitySaeMapre) vulnerabilities.push('SAE_MAPRE');
        if (config.vulnerabilityDisabled) vulnerabilities.push('DISABILITY');
        return vulnerabilities;
    }
    private getSupplyInfo(products: Product[]): Supply[] {
        return products
            .filter((p) => containsCommodity(p?.productType))
            .map(
                (p) =>
                    ({
                        Commodity: p?.powerOrGas,
                        'POD/PDR': p?.podPdr,
                        ProductType: p?.productType,
                        ProductFamily: p?.family,
                    }) as Supply,
            );
    }
}
