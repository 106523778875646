export class KnowledgeSearchCategory {
    CategoryId: string;
    Description?: string;
    IsLastChild: boolean;
    SequenceNumber: number;
    Title: string;
    ParentCategoryId?: string;
    KnowledgeArticleCategory: KnowledgeArticle[];
    children?: KnowledgeSearchCategory[];
    hasArticleChildren?: number;

    constructor(
        CategoryId: string,
        Description: string,
        IsLastChild: boolean,
        SequenceNumber: number,
        Title: string,
        ParentCategoryId: string,
        KnowledgeArticleCategory: KnowledgeArticle[]
    ) {
        this.CategoryId = CategoryId;
        this.Description = Description;
        this.IsLastChild = IsLastChild;
        this.SequenceNumber = SequenceNumber;
        this.Title = Title;
        this.ParentCategoryId = ParentCategoryId;
        this.KnowledgeArticleCategory = KnowledgeArticleCategory;
        this.hasArticleChildren = 0;
    }
}

export class KnowledgeArticle {
    majorversionnumber: number;
    minorversionnumber: number;
    modifiedon: string;
    title: string;
    description: string;
    knowledgearticleid: string;
}
