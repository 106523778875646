import { AField, AObject, ATable } from '@congacommerce/core';
import { Type } from 'class-transformer';

@ATable({
    sobjectName: 'Product2',
})
export class EglOptionId extends AObject {
    @AField({
        soql: 'RecordType',
        expand: 'shallow',
    })
    @Type(() => RecordType)
    RecordTypeId: RecordType;
}

@ATable({
    sobjectName: 'RecordType',
})
export class RecordType extends AObject {
    @AField({ soql: 'Name' })
    Name: string;
}
