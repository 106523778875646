<dialog open [ngClass]="txtBtnCn ? 'egl-modal__wrapper--big' : 'egl-modal__wrapper--small'">
    <div class="egl-modal__header">
        <h3 class="egl-modal__title">{{ title | translate }}</h3>
        <egl-icon
            class="egl-modal__header-icon"
            path="x_1"
            [width]="20"
            [height]="20"
            (click)="onCloseBtn()"
        ></egl-icon>
    </div>

    <div class="egl-modal__body" [ngClass]="alignLeft ? 'egl-modal__body--text-left' : 'egl-modal__body--text-center'">
        <p class="egl-modal__paragraph" [innerHtml]="description" *ngIf="description"></p>
        <ng-content></ng-content>
    </div>
    <div [ngClass]="txtBtnSx && (txtBtnCn || txtBtnDx) ? 'egl-modal__footer--complex' : 'egl-modal__footer'">
        <button
            *ngIf="txtBtnSx"
            type="button"
            class="cta-secondary"
            data-dismiss="modal"
            (click)="onBtnSxClick()"
            [innerHtml]="txtBtnSx | translate"
        ></button>
        <div class="egl-modal__footer--right-part">
            <button
                *ngIf="txtBtnCn"
                type="button"
                class="cta-secondary"
                data-dismiss="modal"
                (click)="onBtnMdClick()"
                [innerHtml]="txtBtnCn | translate"
            ></button>
            <button
                *ngIf="txtBtnDx"
                type="button"
                class="cta-primary"
                (click)="onBtnDxClick()"
                [innerHtml]="txtBtnDx | translate"
            ></button>
        </div>
    </div>
</dialog>
