import { ErrorManagement, StatusDDLResponse } from '../../../../common/interfaces/base-api-response';

export interface CheckBalanceResponse {
    status: StatusDDLResponse;
    errorManagement?: ErrorManagement;
    /** Il valore "NON DISPONIBILE" non arriva da API ma viene impostato a runtime da SUP per indicare che si può andare avanti e il controllo verrà rieseguito da CPQ successivamente */
    esito: CheckBalanceResult;
}

export enum CheckBalanceResult {
    OK = 'SUPERATO',
    KO = 'NON SUPERATO',
    Retry = 'NON DISPONIBILE',
    NotNeeded = 'NON NECESSARIO',
}
