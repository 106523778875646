import { Expose } from 'class-transformer';

export class D365WorkOrderCaseData {
    /**
     * @description Partner tecnico di riferimento
     */
    @Expose({ name: 'NomeAgenzia' })
    agencyName: string;

    @Expose({ name: 'DataInizioValidita' })
    startExpirationDate: string;

    @Expose({ name: 'DataFineValidita' })
    endExpirationDate: string;

    @Expose({ name: 'DataUltimoAggiornamento' })
    lastUpdateDate: string;

    @Expose({ name: 'NumeroCase' })
    numberCase: string;

    @Expose({ name: 'Livello1Case.egl_name' })
    livello1CaseName: string;

    @Expose({ name: 'Livello2Case.egl_name' })
    livello2CaseName: string;

    @Expose({ name: 'Livello3Case.egl_name' })
    livello3CaseName: string;

    @Expose({ name: 'DataOraCreazioneCase' })
    creationCaseDate: string;

    @Expose({ name: 'StatoCase@OData.Community.Display.V1.FormattedValue' })
    caseStatus: string;

    @Expose({ name: 'incidentid' })
    caseId: string;

    assetExternalId?: string;
}
