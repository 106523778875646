import { TypeHelpOptions, TypeOptions } from 'class-transformer';
import { TypeMetadata } from 'class-transformer/metadata/TypeMetadata';
import { defaultMetadataStorage } from 'class-transformer/storage';

/**
 * Implementazione custom del decoratore "@Type" usato nei modelli Conga/Apttus.
 * Imposta il "reflectedType" a "Array" in modo che possa essere letto dal SDK di Conga per trasformarlo in un "child"
 * dell'array "children" nelle chiamate al metodo "query".
 * Per un bug della libreria "reflect-data", dopo l'aggiornamento alla v12 di Angular e Conga Winter 21, usare questo decoratore per le relazioni ("__r") di tipo array
 * In caso di relazione singola si può continuare ad usare il decoratore originale "@Type" in modo che l'SDK lo interpreti come lookup
 * @example
 *  '@AField({
 *       soql: 'Apttus_Config2__LineItems__r',
 *       expand: 'shallow',
 *   })
 *   '@ArrayType(() => EglCartItemLight)
 *   LineItems: Array<EglCartItemLight>;
 *
 */
export const ArrayType = (typeFunction: (options?: TypeHelpOptions) => Function, options: TypeOptions = {}) => {
    return function (target, key) {
        const type = Array;
        const metadata = new TypeMetadata(target.constructor, key, type, typeFunction, options);
        defaultMetadataStorage.addTypeMetadata(metadata);
    };
};
