import { GasConsumptionUseVariationMB } from '../enums/shared/type-of-usage.type';

export const APT_SUP_TYPEUSAGE_TYPE_MAP: {
    [key in GasConsumptionUseVariationMB]?: string;
} = {
    [GasConsumptionUseVariationMB.c1]: 'C1 - Riscaldamento',
    [GasConsumptionUseVariationMB.c2]: 'C2 - Uso cottura cibi e/o produzione di acqua calda sanitaria',
    [GasConsumptionUseVariationMB.c3]: 'C3 - Riscaldamento + uso cottura cibi e/o produzione di acqua calda sanitaria',
    [GasConsumptionUseVariationMB.c4]: 'C4 - Uso condizionamento',
    [GasConsumptionUseVariationMB.c5]: 'C5 - Uso condizionamento + riscaldamento',
    [GasConsumptionUseVariationMB.t1]: 'T1 - Uso tecnologico (artigianale - industriale)',
    [GasConsumptionUseVariationMB.t2]: 'T2 - Uso tecnologico + riscaldamento',
};
