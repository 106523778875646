import { FieldMap } from '../../../utility/functions/map-fields';
import { FetchXmlQuery } from './fetch-xml-manager';

export function getConsumerCreditWorkOrderXmlQuery(assetExternalId: string): FetchXmlQuery {
    // Recupera i dati legati ai work order di credito al consumo
    const entity = 'egl_workorderconsumercredit';
    return {
        entity,
        query: `
            <?xml version="1.0" encoding="UTF-8"?>
            <fetch top="100">
                <entity name="${entity}">
                    <attribute name="createdon" />
                    <attribute name="egl_envelopecode" />
                    <attribute name="egl_lastchangesubstatusdays_calculated" />
                    <attribute name="egl_name" />
                    <attribute name="egl_practiceid" />
                    <attribute name="egl_providerid" />
                    <attribute name="egl_quoteexternalid" />
                    <attribute name="egl_workorderconsumercreditsubstatusid" />
                    <attribute name="modifiedon" />
                    <attribute name="statecode" />
                    <attribute name="egl_assetexternalid" />
                    <attribute name="egl_parentassetexternalid" />
                    <filter>
                        <condition attribute="egl_assetexternalid" operator="eq" value="${assetExternalId}" />
                    </filter>
                    <link-entity name="account" from="accountid" to="egl_accountid" alias="Account">
                        <attribute name="egl_taxcode" />
                    </link-entity>
                </entity>
            </fetch>
        `,
    };
}

export interface D365ConsumerCreditWorkOrder {
    egl_name: string; // Numero WO
    ['statecode@OData.Community.Display.V1.FormattedValue']: string; // Stato WO
    ['_egl_workorderconsumercreditsubstatusid_value@OData.Community.Display.V1.FormattedValue']: string; // Sottostato WO
    egl_envelopecode: string; // Codice plico
    ['Account.egl_taxcode']: string; // Codice fiscale
    ['_egl_providerid_value@OData.Community.Display.V1.FormattedValue']: string; // Provider
    egl_practiceid: number; // ID Pratica
    createdon: string; // Data creazione
    modifiedon: string; // Data ultimo aggiornamento
    egl_lastchangesubstatusdays_calculated: string; // Aging
    egl_quoteexternalid: string; // ID Quote
    egl_workorderconsumercreditid: string; // WO ID
}

export interface SupConsumerCreditWorkOrder {
    workOrderNumber: string; // Original: egl_name - Numero WO
    workOrderStatus: string; // Original: statecode - Stato WO
    workOrderSubStatus: string; // Original: statuscode - Sottostato WO
    envelopeCode: string; // Original: egl_envelopecode - Codice plico
    taxCode: string; // Original: account.egl_taxcode - Codice fiscale
    providerID: string; // Original: egl_providerid - Provider
    practiceID: string; // Original: egl_practiceid - ID Pratica
    creationDate: string; // Original: createdon - Data creazione
    lastUpdateDate: string; // Original: modifiedon - Data ultimo aggiornamento
    aging: string; // Original: egl_aging - Aging
    quoteExternalID: string; // Original: egl_quoteexternalid - ID Quote
    workorderConsumerCreditId: string; // Original: egl_workorderconsumercreditid - WO ID
}

export const D365ToSupConsumerCreditWorkOrderFieldMap: FieldMap<
    D365ConsumerCreditWorkOrder,
    SupConsumerCreditWorkOrder
>[] = [
    { sourceField: 'egl_name', resultField: 'workOrderNumber' },
    { sourceField: 'statecode@OData.Community.Display.V1.FormattedValue', resultField: 'workOrderStatus' },
    {
        sourceField: '_egl_workorderconsumercreditsubstatusid_value@OData.Community.Display.V1.FormattedValue',
        resultField: 'workOrderSubStatus',
    },
    { sourceField: 'egl_envelopecode', resultField: 'envelopeCode' },
    { sourceField: 'Account.egl_taxcode', resultField: 'taxCode' },
    { sourceField: '_egl_providerid_value@OData.Community.Display.V1.FormattedValue', resultField: 'providerID' },
    { sourceField: 'egl_practiceid', resultField: 'practiceID' },
    { sourceField: 'createdon', resultField: 'creationDate' },
    { sourceField: 'modifiedon', resultField: 'lastUpdateDate' },
    { sourceField: 'egl_lastchangesubstatusdays_calculated', resultField: 'aging' },
    { sourceField: 'egl_quoteexternalid', resultField: 'quoteExternalID' },
    { sourceField: 'egl_workorderconsumercreditid', resultField: 'workorderConsumerCreditId' },
];
