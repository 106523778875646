import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PrivateConfigurationService } from '../../../../common/services/shared/private-configuration.service';
import { EglState } from '../../../../store/reducers';
import { Store } from '@ngrx/store';
import {
    setSelectedMacroOpMode,
    setSelectedMostUsedActions,
    setSelectedOpMode,
} from '../../../../store/actions/bo.actions';
import { AptSalesProcess } from '../../../../common/enums/apttus/apt-sales-process';
import { Subscription } from 'rxjs';
import { selectCurrentTab, selectMostUsedAction } from '../../../../store/selectors/bo.selectors';
import { AptQuoteStatus } from '../../../../common/enums/apttus/apt-quote-status';
import { AptQuoteSubStatus } from '../../../../common/enums/apttus/apt-quote-sub-status';

@Component({
    selector: 'egl-most-used-actions',
    templateUrl: './most-used-actions.component.html',
    styleUrls: ['./most-used-actions.component.scss'],
})
export class MostUsedActionsComponent implements OnInit, OnDestroy {
    enabled: boolean = true;
    actionDefinitions: Array<ActionDefinition>;
    selectedAction: ActionDefinition = new ActionDefinition();
    subscriptions: Subscription[] = [];

    @Input() disabled = false;
    @Output() onSelectedActionChanged: EventEmitter<ActionDefinition> = new EventEmitter<ActionDefinition>();

    constructor(private store: Store<EglState>, private configSrv: PrivateConfigurationService) {
        this.store.select(selectCurrentTab).subscribe((currentTab) => {
            const mostUsedActionByConfig = this.configSrv.get<ActionDefinition[]>('mostUsedActionsConfig', '[]');
            this.actionDefinitions =
                currentTab !== 'MON'
                    ? mostUsedActionByConfig.filter((action) => action?.label !== 'Switch in in attesa di winback')
                    : mostUsedActionByConfig;
        });
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.store.select(selectMostUsedAction).subscribe((selectedMostUsedAction) => {
                this.selectedAction = selectedMostUsedAction;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    onSelectAction(event: ActionDefinition): void {
        this.selectedAction = event;
        const opMode = this.selectedAction.process as AptSalesProcess;
        const newStatus =
            this.selectedAction?.subStatus === AptQuoteSubStatus.AttesaCompletamentoSwitchOut
                ? AptQuoteStatus.Confermato
                : this.selectedAction?.status;
        this.store.dispatch(
            setSelectedMostUsedActions({
                p: { ...this.selectedAction, status: newStatus, subStatus: this.selectedAction?.subStatus ?? null },
            })
        );
        this.store.dispatch(setSelectedOpMode({ p: opMode }));
        this.store.dispatch(setSelectedMacroOpMode({ p: this.selectedAction.macroProcess }));
        this.onSelectedActionChanged.emit(this.selectedAction);
    }

    reset(): void {
        this.selectedAction = new ActionDefinition();
        this.store.dispatch(setSelectedMostUsedActions({ p: this.selectedAction }));
    }

    isSelected(action: ActionDefinition): boolean {
        return (
            this.selectedAction?.label === action?.label &&
            this.selectedAction?.macroProcess === action?.macroProcess &&
            this.selectedAction?.process === action?.process
        );
    }
}

export class ActionDefinition {
    label: string = null;
    macroProcess: string = null;
    process: string = null;
    status?: AptQuoteStatus;
    subStatus?: AptQuoteSubStatus;
}
