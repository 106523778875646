import { D365DataOwnershipChangeStatuscodes } from '../../enums/d365/d365-data-ownership.change-statuscodes';

export class DataOwnershipChangeRecord {
    egl_code: string = null;
    statuscode: D365DataOwnershipChangeStatuscodes = null;
    egl_dataownershipchangeid: string = null;

    static recordFields(): string[] {
        return Object.keys(new DataOwnershipChangeRecord());
    }

    static entityName: string = 'egl_dataownershipchange';
}
