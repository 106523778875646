export class WidgetListItem {
    id: string;
    title: string;
    icon?: string;
    actionIcon: string;
    action: string;
    description?: string;

    constructor(
        id: string,
        title: string,
        action: string,
        actionIcon: string = 'single-light-right',
        icon?: string,
        description?: string
    ) {
        this.id = id;
        this.title = title;
        this.icon = icon;
        this.actionIcon = actionIcon;
        this.action = action;
        this.description = description;
    }
}
