import { Injectable, Injector } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, from, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { EglState } from '../../../../store/reducers';
import { RoutesPaths } from '../../../config/routes-paths';
import { LoggerService } from '../logger.service';
import { PrivateConfigurationService } from '../private-configuration.service';
import { GLOBAL_SKIPPING_RULES } from './dragon-router-skipping-rules';
import {
    DragonRouterPageConfig,
    DragonRouterSkippingFunction,
    DragonRouteSubConfiguration,
    OrderEntrySubPathConfiguration,
    RailwayEntry,
    RailwayStop,
    SkippingFn,
} from './dragon-router.type';
import { CartService } from '@congacommerce/ecommerce';

@Injectable({
    providedIn: 'root',
})
export class DragonUtilsService {
    public readonly ORDER_ENTRY_ROOTS: RoutesPaths[] = [
        RoutesPaths.OrderEntry,
        RoutesPaths.CP,
        RoutesPaths.SuspensionBasePath,
        RoutesPaths.InterruptionBasePath,
        RoutesPaths.ResumingBasePath,
        RoutesPaths.AdminTerminationBasePath,
    ];

    constructor(
        private eglState: Store<EglState>,
        private configSrv: PrivateConfigurationService,
        private cartSrv: CartService,
        private logger: LoggerService,
        private injector: Injector
    ) {}

    private callbackObservableAdapter<R>(value: R | Promise<R> | Observable<R>): Observable<R> {
        if (value instanceof Promise) {
            return from(value);
        }
        if (value instanceof Observable) {
            return value.pipe(take(1));
        }

        return of(value);
    }

    public railwaySkipAdapter(skipFn: DragonRouterSkippingFunction): SkippingFn {
        return (params: Params) => {
            const skipResult = skipFn
                ? skipFn({
                      eglState: this.eglState,
                      configSrv: this.configSrv,
                      cartService: this.cartSrv,
                      params,
                      injector: this.injector,
                  })
                : false; // Nothing to skip;

            // turning skipping function result into an observable
            return (
                this.callbackObservableAdapter(skipResult)
                    // Limiting the obtained observable to only 1 result to prevent to trigger the navigation flow when not desired
                    .pipe(
                        take(1),
                        catchError((err) => {
                            this.logger.error('[DragonRouter]', 'Skipping rule error', err, false);
                            // On error the page will be skipped
                            return of(true);
                        })
                    )
            );
        };
    }

    public findRailwayStopIndex(railway: RailwayStop[], routeFragment: string): number {
        return railway.findIndex((railwayStop) => this.checkCurrentRoute(railwayStop, routeFragment));
    }

    public findRailwayStop(
        railway: (DragonRouterPageConfig | string | RoutesPaths)[],
        routeFragment: string
    ): RoutesPaths {
        const foundStop = railway.find(
            (railwayStop) =>
                this.checkCurrentRoute(railwayStop, routeFragment) && ['string', 'object'].includes(typeof railwayStop)
        );
        return ((foundStop as DragonRouterPageConfig)?.path || foundStop) as RoutesPaths;
    }

    private routeMatcher(route: string, path: string): boolean {
        return new RegExp(route.replace(/:[^/|:]+/gm, '[a-zA-Z\\d_]+') + '(?:[^/]+|$)').test(path);
    }

    public checkCurrentRoute(railwayStop: RailwayEntry | DragonRouteSubConfiguration, routeFragment: string): boolean {
        switch (railwayStop instanceof RegExp ? 'regexp' : typeof railwayStop) {
            case 'string': {
                return this.routeMatcher(railwayStop as string, routeFragment);
            }
            case 'regexp': {
                return (railwayStop as RegExp).test(routeFragment);
            }
            case 'object': {
                return this.routeMatcher((railwayStop as DragonRouterPageConfig).path, routeFragment);
            }
        }
        return false;
    }

    public isOrderEntrySubPathConfiguration(value: any): value is OrderEntrySubPathConfiguration {
        return (
            typeof value === 'object' &&
            ['requiredParams', 'railway'].every((propName) => Array.isArray(value[propName]))
        );
    }

    public mapRouteConfigPath(railwayStop: RailwayEntry | DragonRouteSubConfiguration): RailwayStop[] {
        if (typeof railwayStop === 'string') {
            return [
                {
                    component: null,
                    path: railwayStop,
                    skip: this.railwaySkipAdapter(GLOBAL_SKIPPING_RULES[railwayStop]?.skip),
                },
            ];
        }

        if (this.isDragonRouteSubConfiguration(railwayStop)) {
            // funzione che verifica i parmetri necessari: true se la pagina deve essere saltata per mancanza di uno o piu paramentri required
            const paramsCheckerFn = (params?: Params) =>
                Array.from(railwayStop.requiredParams).some(
                    (paramName) => !params || params[paramName] === null || typeof params[paramName] === 'undefined'
                );

            return Array.from(railwayStop.railway)
                .reduce((aggr, subStop) => [...aggr, ...this.mapRouteConfigPath(subStop)], [])
                .map((subStop) =>
                    this.isDragonRouterPageConfig(subStop)
                        ? {
                              ...subStop,
                              skip: (params?: Params) => (paramsCheckerFn(params) ? of(true) : subStop.skip(params)),
                          }
                        : subStop
                );
        }

        // verifico che railwaystop sia una destinazione(path) e non regex o function
        if (this.isDragonRouterPageConfig(railwayStop)) {
            return [
                {
                    ...railwayStop,
                    path: railwayStop.path,
                    skip: railwayStop.skip || (() => of(false)),
                },
            ];
        }
        // regex o function
        return [railwayStop];
    }

    public isDragonRouteSubConfiguration(value: any): value is DragonRouteSubConfiguration {
        return (
            typeof value === 'object' &&
            ['requiredParams', 'railway'].every((propName) => value[propName] instanceof Set)
        );
    }

    public isDragonRouterPageConfig(
        railwayStop: RailwayStop | DragonRouteSubConfiguration
    ): railwayStop is DragonRouterPageConfig {
        return typeof railwayStop === 'object' && ['component', 'path'].every((propName) => propName in railwayStop);
    }
}
