export enum D365AgentType {
    Agent = 100000000,
    Backoffice = 100000001,
    //Internal
    TeamLeader = 100000002,
}

export enum AuthorityCodes {
    AcquirenteUnico = '100000003',
    ARERA = '100000000',
    AGCOM = '100000001',
    GarantePrivacy = '100000002',
    AGCM = '100000005',
    Altro = '100000004',
}
