import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductService, Product, ConstraintRuleService } from '@congacommerce/ecommerce';
import { ACondition, ConfigurationService } from '@congacommerce/core';
import { map, filter, distinctUntilKeyChanged, flatMap } from 'rxjs/operators';
import { ProductDetailComponent } from '../../../../modules/switch-in/products/detail/product-detail.component';
import { EglApttusFieldValidationProvider } from '../utility/egl-apttus-field-validation-provider.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ConfigureGuard implements CanActivate, CanDeactivate<ProductDetailComponent> {
    constructor(
        private router: Router,
        private productService: ProductService,
        private constraintRuleService: ConstraintRuleService,
        private config: ConfigurationService,
        private eglApttusFieldValidationProvider: EglApttusFieldValidationProvider,
        private translateSrv: TranslateService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.productService
            .where([new ACondition(Product, this.config.get('productIdentifier'), 'Equal', route.params.productCode)])
            .pipe(
                map((res) => res[0]),
                filter((product) => product !== null),
                distinctUntilKeyChanged('Id'),
                flatMap((product) =>
                    this.constraintRuleService.getConstraintRulesForProducts([product]).pipe(
                        map((rules) => {
                            const activate =
                                ((product?.Apttus_Config2__HasAttributes__c &&
                                    (product?.Apttus_Config2__AttributeGroups__r || []).totalSize > 0) ||
                                    (product?.Apttus_Config2__HasOptions__c &&
                                        (product?.Apttus_Config2__OptionGroups__r || []).totalSize > 0)) &&
                                rules.filter(
                                    (rule) =>
                                        rule.ConstraintRuleActions.filter(
                                            (action) => action.ActionType === 'Replacement'
                                        ).length > 0
                                ).length === 0;
                            if (!activate) {
                                this.router.navigate(['/products', product[this.config.get('productIdentifier')]]);
                            }
                            return activate;
                        })
                    )
                )
            );
    }

    canDeactivate(
        component: ProductDetailComponent,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (component.configurationChanged) {
            if (confirm(this.translateSrv.instant('PRODUCT_DETAILS.LEAVE_PAGE_MSG'))) {
                route?.routeConfig?.data?.pageId === 'ORDER_ENTRY.PRODUCT_CONFIGURATION_STEP' &&
                    this.eglApttusFieldValidationProvider.clear();
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
}
