import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationLocationType, AppState } from '../../../store/models/app-state';
import { selectApplicationLocation, selectD365AppUrl } from '../../../store/selectors/app.selectors';
import { D365Service } from '../d365/d365.service';
import { combineLatest, Subscription } from 'rxjs';
import { UtilityService } from '../shared/utility.service';

@Injectable({
    providedIn: 'root',
})
export class LoginRedirectService {
    private subscriptions: Subscription[] = [];

    constructor(
        private d365srv: D365Service,
        private store: Store<{ app: AppState }>,
        private utilitySrv: UtilityService
    ) {
        this.loginRedirectLogic();
    }

    private loginRedirectLogic(): void {
        let loginUrlFromLocalStorage = '';
        //Leggo il valore login_app_url da local storage
        loginUrlFromLocalStorage = localStorage.getItem('login_app_url');

        //Se non è presente il valore esco, non è necessario proseguire oltre
        if (loginUrlFromLocalStorage === null) {
            return;
        }

        if (!loginUrlFromLocalStorage.includes('appid')) {
            localStorage.removeItem('login_app_url');
            return;
        }

        this.subscriptions.push(
            combineLatest([this.store.select(selectD365AppUrl), this.store.select(selectApplicationLocation)])
                .pipe()
                .subscribe(([d365AppUrl, appLocation]) => {
                    if (!d365AppUrl || !appLocation) {
                        return;
                    }

                    const currentAppId = this.utilitySrv.getValueFromUrlParameters(d365AppUrl, 'appid');
                    const localStorageAppId = this.utilitySrv.getValueFromUrlParameters(
                        loginUrlFromLocalStorage,
                        'appid'
                    );

                    //Se appId dell'app di d365è la medesima di quella salvata nello storage non è necessario il redirect
                    if (currentAppId === localStorageAppId) {
                        localStorage.removeItem('login_app_url');
                        this.usubscribe();
                        return;
                    }

                    //Se appLocation è Mobile non è necessario eseguire la logica di redirect
                    if (appLocation === ApplicationLocationType.Mobile) {
                        localStorage.removeItem('login_app_url');
                        this.usubscribe();
                        return;
                    }

                    localStorage.removeItem('login_app_url');
                    this.d365srv.topNavigationRequest(d365AppUrl);
                })
        );
    }

    private usubscribe(): void {
        (this.subscriptions || []).forEach((s: Subscription) => s.unsubscribe());
    }
}
