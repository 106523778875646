import { AObjectService } from '@congacommerce/core';
import { Injectable } from '@angular/core';
import { EglQuotePAVLight } from '../../../models/apttus/tables/pav/egl-quote-p-a-v-light';

@Injectable({
    providedIn: 'root',
})
export class EglPAVLightService extends AObjectService {
    type = EglQuotePAVLight;
}
