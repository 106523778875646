export class Ateco {
    code: string;
    name: string;
    activity?: string;
    sector?: string;

    constructor(code?: string, name?: string, activity?: string, sector?: string) {
        this.code = code;
        this.name = name;
        this.activity = activity;
        this.sector = sector;
    }
}
