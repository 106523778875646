import { from, MonoTypeOperatorFunction, Observable, of, OperatorFunction } from 'rxjs';
import { mergeMap, tap, isEmpty, map, retryWhen, scan, delay } from 'rxjs/operators';

export type ClbkValue<R> = Exclude<R, Function | Promise<any> | Observable<any>>;
export type ClbkValueTypes<R> = ClbkValue<R> | Promise<ClbkValue<R>> | Observable<ClbkValue<R>>;
export type ClbkFnValue<R, I> = (input?: I) => ClbkValueTypes<R>;

export function callbackToObservable<R>(value: ClbkValueTypes<R>): Observable<R>;
export function callbackToObservable<R, I>(callbackFn: (input?: I) => ClbkValueTypes<R>): (input: I) => Observable<R>;
export function callbackToObservable<R, I>(
    value: ClbkValueTypes<R> | ClbkFnValue<R, I>
): Observable<R> | ((input: I) => Observable<R>) {
    //Callback / Function
    if (typeof value === 'function') {
        return (input: I) =>
            new Observable<R>((subscriber) => {
                const clbkValue = (value as ClbkFnValue<R, I>)(input);
                callbackToObservable(clbkValue).subscribe(
                    (result) => subscriber.next(result),
                    (err) => subscriber.error(err),
                    () => subscriber.complete
                );
            });
    }

    // Promise
    if (value instanceof Promise) {
        return from(value);
    }
    // Observable
    if (value instanceof Observable) {
        return value;
    }
    // Plain value
    return of(value);
}

type MapOrEmptyClbk<S, R> = (value: S, index: number) => R;

export const mapOrEmpty =
    <S, R>(eventHandler: MapOrEmptyClbk<S, R>, emptyHandler: MapOrEmptyClbk<S, R>): OperatorFunction<S, R> =>
    (source) => {
        let srcParams;
        return source.pipe(
            tap((params) => {
                srcParams = params;
            }),
            isEmpty(),
            map((useEmptyHandler) => (useEmptyHandler ? emptyHandler : eventHandler)),
            map((handler, index) => handler(srcParams, index))
        );
    };

export const mergeMapOrEmpty =
    <S, R>(
        eventHandler: MapOrEmptyClbk<S, Observable<R> | Promise<R>>,
        emptyHandler: MapOrEmptyClbk<S, Observable<R> | Promise<R>>
    ): OperatorFunction<S, R> =>
    ($source) =>
        $source.pipe(
            mapOrEmpty(eventHandler, emptyHandler),
            mergeMap((handlerObs) => handlerObs)
        );

export function ifOp<S>(condition: boolean, operator: OperatorFunction<S, S>): OperatorFunction<S, S> {
    return condition ? ($source) => $source.pipe(operator) : ($source) => $source;
}

export function retryWithDelay<T>(
    maxAttempts: number,
    delayMs: number,
    logMessage?: string
): MonoTypeOperatorFunction<T> {
    return ($source: Observable<T>): Observable<T> =>
        $source.pipe(
            retryWhen(($errors: Observable<Error>) =>
                $errors.pipe(
                    scan((acc, err) => ({ attempt: acc?.attempt + 1, err }), { attempt: 1, err: null }),
                    tap(({ attempt, err }) => {
                        if (logMessage) {
                            console.warn(`${logMessage} ${attempt}/${maxAttempts}`);
                        }
                        if (attempt >= maxAttempts) {
                            throw err;
                        }
                    }),
                    delay(delayMs)
                )
            )
        );
}
