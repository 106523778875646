import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { cleanObj } from '../../../common/functions/misc.functions';
import { BaseApiResponse, StatusResponse } from '../../../common/interfaces/base-api-response';
import { ApiMngApi, BaseProvider } from '../../../common/providers/base-provider';
import { ApiService } from '../../../common/services/shared/api.service';
import { PrivateConfigurationService } from '../../../common/services/shared/private-configuration.service';
import { Record } from '../models/morosity.response';
import { MorosityCancelRequest } from '../models/morosity-cancel.request';
import { MorosityRequest, MorosityResumingRequest } from '../models/morosity.request';
import { MorosityResponse } from '../models/morosity.response';
import { MorosityErrorCode } from '../models/morosity.error';

@Injectable({ providedIn: 'root' })
export class MorosityProvider extends BaseProvider {
    constructor(
        private api: ApiService,
        translateSrv: TranslateService,
        protected configSrv: PrivateConfigurationService
    ) {
        super(configSrv, translateSrv);
    }

    /**
     * @description: Crea una sospensione di un ordine
     * @param request: request di tipo SuspensionFormRequest
     * @return: Observable<SuspensionFormResponse>
     */
    createSuspendOrder(request: MorosityRequest): Observable<BaseApiResponse<MorosityResponse>> {
        return this.api.postAsync<BaseApiResponse<MorosityResponse>>(
            this.getApiMngApiUrl(ApiMngApi.CreateSuspendOrder),
            request
        );
    }

    createInterruptionOrder(request: MorosityRequest): Observable<BaseApiResponse<MorosityResponse>> {
        return this.api.postAsync<BaseApiResponse<MorosityResponse>>(
            this.getApiMngApiUrl(ApiMngApi.CreateInterruptionOrder),
            request
        );
    }

    createResumeOrder(request: MorosityResumingRequest): Observable<BaseApiResponse<MorosityResponse>> {
        return this.api.postAsync<BaseApiResponse<MorosityResponse>>(
            this.getApiMngApiUrl(ApiMngApi.CreateResumeOrder),
            request
        );
    }

    /**
     * @description: Crea una revoca di sospensione di un ordine
     * @param request: request di tipo SuspensionFormRequest
     * @return: Observable<SuspensionFormResponse>
     */
    cancelOrder(request: MorosityCancelRequest): Observable<BaseApiResponse<MorosityResponse>> {
        return this.api.postAsync<BaseApiResponse<MorosityResponse>>(
            this.getApiMngApiUrl(ApiMngApi.CancelOrder),
            request
        );
    }

    /**
     * @description: Crea una cessazione amministrativa di un ordine
     * @param request: request di tipo MorosityRequest
     * @return: Observable<MorosityResponse>
     */
    createTerminationOrder(request: MorosityRequest): Observable<BaseApiResponse<MorosityResponse>> {
        return this.api.postAsync<BaseApiResponse<MorosityResponse>>(
            this.getApiMngApiUrl(ApiMngApi.CreateTerminateOrder),
            request
        );
    }

    /**
     * @description: Crea una cessazione amministrativa di un ordine in forma differita
     */
    createTerminationOrderDefered(request: MorosityRequest): Observable<Record[]> {
        const options = {
            headers: new HttpHeaders({
                SourceSystem: 'Sup - Volt',
                Deferred: 'true',
            }),
        };
        return this.api
            .postAsync<BaseApiResponse<MorosityResponse>>(
                this.getApiMngApiUrl(ApiMngApi.CreateTerminateOrder),
                cleanObj(request),
                0,
                options
            )
            .pipe(
                tap((response) => {
                    if (
                        response?.status !== StatusResponse.Success ||
                        !(response?.response?.Orders?.records || []).every(
                            (record) => record?.CodiceEsito === MorosityErrorCode.Ok
                        )
                    ) {
                        throw new Error(
                            response?.errorManagement?.errorDescription ||
                                (response?.response?.Orders?.records || []).find(
                                    (record) => record?.CodiceEsito !== MorosityErrorCode.Ok
                                )?.DescrizioneEsito
                        );
                    }
                }),
                map((response) => response?.response?.Orders?.records)
            );
    }
}
