import moment from 'moment';
import { BoDateRangeFilterVal, BoDateTypeFilterVal } from '../../../common/enums/shared/bo-date-filter-val';

export class BoFilterByDate {
    type: BoDateTypeFilterVal;
    range: BoDateRangeFilterVal;
    dateStart: Date;
    dateEnd: Date;

    constructor(setDefault: boolean = false) {
        if (!setDefault) {
            return;
        }

        this.dateStart = moment().subtract(6, 'days').startOf('day').toDate();
        this.dateEnd = new Date();
        this.range = BoDateRangeFilterVal.UnaSett;
        this.type = BoDateTypeFilterVal.DataCreazione;
    }
}
