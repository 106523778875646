<div class="egl-textfield__container">
    <div class="egl-modal-with-footer__container egl-modal-with-footer--open" *ngIf="isOpen">
        <div class="egl-modal-with-footer__overlay" (click)="!closeOnlyOnConfirm && dismiss()"></div>
        <div class="egl-modal-with-footer">
            <div class="egl-modal-with-footer__header">
                <egl-icon
                    class="egl-modal-with-footer__icon"
                    *ngIf="path"
                    [path]="path"
                    [withCircle]="withCircle"
                    [width]="32"
                    [height]="32"
                ></egl-icon>
                <h3 class="egl-modal-with-footer__title">{{ title }}</h3>
                <button
                    class="egl-modal-with-footer__close"
                    [hidden]="hideDismissButton"
                    (click)="dismiss()"
                    type="button"
                >
                    <span [innerHTML]="icons.Cancel"></span>
                </button>
            </div>
            <p [innerHTML]="description"></p>
            <ng-container [ngSwitch]="!!message">
                <p *ngSwitchCase="true">{{ message }}</p>
                <ng-container *ngSwitchDefault><ng-content></ng-content></ng-container>
            </ng-container>
            <p [innerHTML]="secondMessage"></p>
            <div class="egl-modal-with-footer__footer">
                <button
                    class="egl-modal-with-footer__cta-secondary cta-secondary"
                    *ngIf="secondButtonLabel"
                    [disabled]="backDisabled"
                    (click)="back()"
                    type="button"
                >
                    {{ secondButtonLabel }}
                </button>
                <div class="egl-modal-with-footer__btn-container">
                    <button
                        class="egl-modal-with-footer__btn-third cta-secondary"
                        *ngIf="thirdButtonLabel"
                        [disabled]="backDisabled"
                        (click)="genericAction()"
                        type="button"
                    >
                        {{ thirdButtonLabel }}
                    </button>
                    <button
                        class="cta-primary"
                        *ngIf="!hideConfirm"
                        [disabled]="confirmDisabled"
                        (click)="confirm()"
                        type="button"
                    >
                        {{ buttonLabel }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
