import { TextareaAutoresizeDirective } from './textarea-autoresize.directive';
import { NgModule } from '@angular/core';
import { DragDropDirective } from './drag-drop.directive';
import { PreventCopyPasteDirective } from './prevent-copy-paste.directive';
import { LowercaseControlDirective } from './lowercase-control.directive';
import { UppercaseControlDirective } from './uppercase-control.directive';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { SafeHtml } from '../pipes/safehtml.pipe';
import { CnfInputDirective } from './cnf-input/cnf-input.directive';
import { TigerEgonDirective } from './tiger-egon/tiger-egon.directive';
import { TypeAheadDirective } from './type-ahead.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TigerEgonAutocompleteDirective } from './tiger-egon/tiger-egon-autocomplete.directive';
import { ClearSpacesDirective } from './clear-spaces.directive';
import { SublimeDirective } from './sublime.directive';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule],
    exports: [
        UppercaseControlDirective,
        DragDropDirective,
        LowercaseControlDirective,
        PreventCopyPasteDirective,
        TextareaAutoresizeDirective,
        SafeHtml,
        NumbersOnlyDirective,
        CnfInputDirective,
        TigerEgonDirective,
        TypeAheadDirective,
        TigerEgonAutocompleteDirective,
        ClearSpacesDirective,
        SublimeDirective,
    ],
    declarations: [
        UppercaseControlDirective,
        DragDropDirective,
        LowercaseControlDirective,
        PreventCopyPasteDirective,
        TextareaAutoresizeDirective,
        SafeHtml,
        NumbersOnlyDirective,
        CnfInputDirective,
        TigerEgonDirective,
        TypeAheadDirective,
        TigerEgonAutocompleteDirective,
        ClearSpacesDirective,
        SublimeDirective,
    ],
})
export class DirectiveModule {}
