import { ApiService } from '../services/shared/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiMngApi, BaseProvider } from './base-provider';
import { PrivateConfigurationService } from '../services/shared/private-configuration.service';
import { EglQuoteLight } from '../models/apttus/tables/quote/egl-quote-light';
import { catchError, map, tap } from 'rxjs/operators';
import { BaseApiResponse, StatusResponse } from '../interfaces/base-api-response';
import { TelemetryMetricService } from '../services/app/telemetry-metric.service';
import { CartToQuoteRequest } from '../models/apttus/request-response/cart-to-quote-request';
import { LoggerService } from '../services/shared/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { ApimError } from '../../modules/back-office/providers/back-office.provider';

@Injectable({ providedIn: 'root' })
export class SaveQuoteProvider extends BaseProvider {
    constructor(
        private api: ApiService,
        protected configSrv: PrivateConfigurationService,
        private telemetrySrv: TelemetryMetricService,
        private logger: LoggerService,
        translateSrv: TranslateService
    ) {
        super(configSrv, translateSrv);
    }

    createQuoteFromCart(req: Partial<CartToQuoteRequest>): Observable<CartToQuoteResponse> {
        return this.api
            .postAsync<BaseApiResponse<CartToQuoteResponse>>(this.getApiMngApiUrl(ApiMngApi.CreateQuoteFromCart), req)
            .pipe(
                tap((res) => {
                    if (res?.status !== StatusResponse.Success) {
                        if (res?.errorManagement?.errorCode === 'E03_1') {
                            throw new ApimError(
                                res?.errorManagement?.errorDescription,
                                res?.errorManagement?.errorCode
                            );
                        } else {
                            throw new Error(res?.errorManagement?.errorDescription || (res as any));
                        }
                    }
                    const isWrongResponse = !Object.keys(res?.response || {}).some((v) => (v || '').startsWith('egl_'));
                    if (isWrongResponse) {
                        this.logger.error('createQuoteFromCart', 'wrong response', null, true, res?.response || res);
                        throw new Error(
                            'Wrong response from createQuoteFromCart. Returned Quote object not contains all required properties'
                        );
                    }
                }),
                this.telemetrySrv.rxTelemetry('from-cart-api'),
                map(({ response }) => Object.assign(new EglQuoteLight(), response)),
                catchError((err) => {
                    this.logger.error(null, 'createQuoteFromCart have an error', err, true);
                    throw err;
                })
            );
    }
}

export type CartToQuoteResponse = EglQuoteLight & {
    /**
     * @description id account cliente D365 (esistente o appena creato)
     */
    accountId: string;
};
