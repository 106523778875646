<div *ngIf="dataTable?.filterByFields" class="egl-dynamics-table__filter">
    <button
        type="button"
        #ddBtn
        class="egl-dynamics-table__filter-button"
        (click)="ddSelectionDataOpen = !ddSelectionDataOpen"
    >
        <egl-icon class="egl-dynamics-table__filter-dropdown-button-icon" [path]="'view-see'"> </egl-icon>
        {{ 'COMMON.BUTTON.FILTER' | translate }}
        <egl-icon
            class="egl-dynamics-table__filter-dropdown-icon"
            [ngClass]="ddSelectionDataOpen ? 'egl-dynamics-table__filter-dropdown-icon--rotate' : ''"
            path="single-light-down"
        ></egl-icon>
    </button>
    <ul
        class="egl-dynamics-table__filter-dropdown"
        [ngClass]="ddSelectionDataOpen ? 'egl-dynamics-table__filter-dropdown--open' : ''"
    >
        <li *ngFor="let column of allColumns" class="egl-dynamics-table__filter-dropdown-list-item">
            <div class="egl-dynamics-table__filter-dropdown-list-item-input-container">
                <input
                    type="checkbox"
                    class="egl-dynamics-table__filter-dropdown-list-item-checkbox"
                    [checked]="!column.hidden"
                    (change)="toggleFilter(column)"
                    eglCnfInput
                />
                {{ column.title }}
            </div>
        </li>
    </ul>
</div>
<div class="egl-dynamics-table__wrapper" [ngClass]="'egl-dynamics-table__wrapper--' + templateType">
    <table class="egl-dynamics-table" [ngClass]="{ 'table-hover': hover }">
        <thead class="egl-dynamics-table__header">
            <tr class="egl-dynamics-table__titles">
                <th class="egl-dynamics-table__title" title="Seleziona tutti" *ngIf="check">Check</th>
                <th
                    class="egl-dynamics-table__title"
                    *ngFor="let column of validColumns"
                    [ngClass]="{
                        sortable: column.sortable,
                        'sort-asc': currentSortField === column.field && ascending,
                        'sort-desc': currentSortField === column.field && !ascending
                    }"
                >
                    <span
                        *ngIf="!column.sortable"
                        [tooltip]="column.tooltip | translate"
                        [tooltipEnable]="column.tooltip != null"
                    >
                        {{ column.title | translate }}</span
                    >
                    <a
                        [tooltip]="column.tooltip | translate"
                        [tooltipEnable]="column.tooltip != null"
                        href="javascript:void(0)"
                        class="text-muted"
                        *ngIf="column.sortable"
                        (click)="onSorting(column)"
                        >{{ column.title | translate }}</a
                    >
                </th>
            </tr>
        </thead>
        <tbody class="egl-dynamics-table__body" *ngIf="!isTableLoading; else spinner">
            <ng-container *ngFor="let item of dataTable?.options">
                <tr [ngClass]="item?.isTableItemChecked ? 'egl-dynamics-table__selected-color' : ''">
                    <td *ngIf="check">
                        <div class="egl-dynamics-table__check-container" [ngSwitch]="checkType">
                            <ng-container *ngSwitchCase="'checkbox'">
                                <input
                                    type="checkbox"
                                    id="{{ item.uniqueKey }}"
                                    name="dataTableCheck"
                                    (click)="onCheckClicked(item, checkbox.checked)"
                                    class="egl-dynamics-table__checkbox"
                                    [checked]="item.checked"
                                    value="{{ item.uniqueKey }}"
                                    #checkbox
                                />
                            </ng-container>
                            <ng-container *ngSwitchCase="'radio'">
                                <input
                                    type="radio"
                                    id="{{ item.uniqueKey }}"
                                    name="dataTableCheck"
                                    (click)="onCheckClicked(item, checkbox.checked)"
                                    class="egl-dynamics-table__checkbox"
                                    [checked]="item.checked"
                                    value="{{ item.uniqueKey }}"
                                    #checkbox
                                />
                            </ng-container>
                            <label for="{{ item.code }}"></label>
                        </div>
                    </td>

                    <td *ngFor="let column of validColumns">
                        <ng-container
                            *ngTemplateOutlet="cellValue; context: { column: column, item: item, field: column.field }"
                        >
                        </ng-container>
                    </td>
                </tr>
            </ng-container>
            <tr *ngIf="dataTable?.noDataLabel && !dataTable?.options?.length">
                <td [colSpan]="columnsLength" class="egl-dynamics-table__no-results">
                    {{ dataTable?.noDataLabel | translate }}
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr class="egl-dynamics-table__footer" *ngIf="dataTable?.footerLabel">
                <th [colSpan]="validColumns.length - 1">
                    {{ dataTable?.footerLabel }}
                </th>
                <td>{{ dataTable?.footerValue }}</td>
            </tr>
        </tfoot>
    </table>
</div>

<ng-template #cellValue let-column="column" let-field="field" let-item="item">
    <ng-container *ngIf="column.type !== 'icon'">
        <a href="javascript:void(0)" *ngIf="isclickable(column, item) | async" (click)="onCellClicked(item, column)">
            <span
                *ngIf="column.truncateCharacters > 0; else simpleValue"
                [tooltip]="item[field]"
                [tooltipEnable]="item[field]?.length > column.truncateCharacters"
                >{{
                    item[field] || (dataTable.showDefaultValue ? '---' : '') | truncate : column.truncateCharacters
                }}</span
            >
        </a>
        <span *ngIf="!(isclickable(column, item) | async)">
            <ng-container
                *ngTemplateOutlet="
                    colField;
                    context: { column: column, item: item, field: column.field, simpleValue: simpleValue }
                "
            ></ng-container>
        </span>
    </ng-container>
    <egl-icon *ngIf="column.type === 'icon' && item[field]" [path]="item[field]"></egl-icon>
    <ng-template #simpleValue>
        {{ item[field] || (dataTable.showDefaultValue ? '---' : '') }}
    </ng-template>
</ng-template>

<ng-template #colField let-column="column" let-item="item" let-field="field" let-simpleValue="simpleValue">
    <span
        *ngIf="column.truncateCharacters > 0; else simpleValue"
        [tooltip]="item[field]"
        [tooltipEnable]="item[field]?.length > column.truncateCharacters"
        >{{ item[field] || (dataTable.showDefaultValue ? '---' : '') | truncate : column.truncateCharacters }}</span
    >
</ng-template>

<ng-template #spinner>
    <egl-spinner [config]="{ paddingTop: '0.625rem', paddingBottom: '0.4375rem', position: 'sticky', left: '50%' }">
    </egl-spinner>
</ng-template>
