import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { CartService } from '@congacommerce/ecommerce';
import { Store } from '@ngrx/store';
import { merge as _merge, mergeWith } from 'lodash';
import { combineLatest, EMPTY, forkJoin, merge, Observable, of, Subscription } from 'rxjs';
import {
    catchError,
    debounceTime,
    filter,
    isEmpty,
    map,
    mergeMap,
    shareReplay,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';
import { Regex } from '../../../common/config/regex';
import { ERROR_ROUTES, RoutesPaths } from '../../../common/config/routes-paths';
import { AptLineStatus } from '../../../common/enums/apttus/apt-line-status';
import { AptSalesProcess } from '../../../common/enums/apttus/apt-sales-process';
import { Phase } from '../../../common/enums/shared/process-name-enum';
import {
    activationCartFromUrl,
    cleanObj,
    isVisibleNotTechProduct,
    parseToDate,
} from '../../../common/functions/misc.functions';
import { mapOrEmpty } from '../../../common/functions/observable-operators';
import {
    aptSalesProcessToFlowType,
    flowTypeToMacroFlowType,
    salesProcessOrOperationTypeToFlowType,
} from '../../../common/functions/remap.functions';
import { eglCart2Products, getProductConfigByProductGroups } from '../../../common/functions/transformation.functions';
import {
    containsProductComplex,
    containsProductGas,
    containsProductInsurance,
    containsProductMaintenance,
} from '../../../common/functions/verifications.functions';
import { DeepPartial } from '../../../common/interfaces/deep-partial';
import { EglCartExtended } from '../../../common/models/apttus/tables/cart/egl-cart-extended';
import { EglProductExtended } from '../../../common/models/apttus/tables/product/egl-product-extended';
import { ApttusService } from '../../../common/services/apttus/apttus.service';
import { EglCartLightService } from '../../../common/services/apttus/tables/cart/egl-cart-light.service';
import { EglSalesupStateService } from '../../../common/services/apttus/tables/egl-salesup-state.service';
import { EglProductService } from '../../../common/services/apttus/tables/product/egl-product.service';
import { ChimeraService } from '../../../common/services/shared/chimera.service';
import { FeatureToggleService } from '../../../common/services/shared/feature-toggle.service';
import { LoadingService } from '../../../common/services/shared/loading.service';
import { LocalStorageGenericService } from '../../../common/services/shared/local-storage-generic.service';
import { LoggerService } from '../../../common/services/shared/logger.service';
import { setV2OrderEntryState, setV2Products } from '../../../store/actions/order-entry-v2.actions';
import { setFlowType } from '../../../store/actions/order-entry.actions';
import { FlowType, MacroFlowType } from '../../../store/models/flow-type';
import { Firma } from '../../../store/models/order-entry-state';
import { OrderEntryState_v2, Product, TechnicalDetails } from '../../../store/models/order-entry-state_v2';
import { EglState } from '../../../store/reducers';
import { v2OrderEntryState, v2SelectAllProducts } from '../../../store/selectors/order-entry-v2.selectors';
import { selectSalesProcess } from '../../../store/selectors/order-entry.selectors';
import { isVisibleProduct } from '../../../store/selectors/selector-utility.functions';
import { ESEProvider } from '../../common/order-entry/steps/appointment/providers/ese-provider';

/**
 * @author Marco Ricupero & Felice Lombardi ft. Ajay Kumar, Giovanni Bazan, Lorenzo Corbella
 * @version 1.2.1
 * @description Resolver to handle cart and state sync for products, packaged cart unload (acart) & salesProces updater
 *        /\  .-"""-.  /\
 *       //\\/  ,,,  \//\\
 *       |/\| ,;;;;;, |/\|
 *       //\\\;-"""-;///\\
 *      //  \/   .   \/  \\
 *     (| ,-_| \ | / |_-, |)
 *       //`__\.-.-./__`\\
 *      // /.-(() ())-.\ \\
 *     (\ |)   '---'   (| /)
 *      ` (|           |) `
 *        \)           (/
 */
type ProductEnricher = (
    state: OrderEntryState_v2,
    cart: EglCartExtended,
    previousState: OrderEntryState_v2,
) => OrderEntryState_v2;

type FlowTypeStateStrategyMap = {
    [key in FlowType | MacroFlowType]?: ProductEnricher;
} & {
    DEFAULT: ProductEnricher;
};

@Injectable()
export class TarantulaResolver implements Resolve<Observable<void>> {
    constructor(
        private eglState: Store<EglState>,
        private cartSrv: CartService,
        private cartLightSrv: EglCartLightService,
        private logger: LoggerService,
        private supStateSrv: EglSalesupStateService,
        private router: Router,
        private apttusSrv: ApttusService,
        private localStorageSrv: LocalStorageGenericService,
        private chimeraSrv: ChimeraService,
        private eseProvider: ESEProvider,
        private featureToggleSrv: FeatureToggleService,
        private productSrv: EglProductService,
        private store: Store<EglState>,
    ) {}

    productCodes: { codeOrSku: string; isComplex: boolean }[] = [];

    //mappature specifiche o ribaltamento proprietà specifiche per flusso
    private FLOW_TYPE_STATE_STRATEGY_MAP: FlowTypeStateStrategyMap = {
        // Il CP Retroattivo su Asset Cessato cambia il prodotto già cessato, il nuovo sarà anch'esso cessato. Serve come rettifica del prodotto che è stato usato.
        // Il CP Retroattivo su Asset Cessato è particolare in quanto non fa la Swap, ma si comporta come uno Switch-In (quindi c'è un solo prodotto a carrello).
        // Le logiche di aggiunta a carrello le fa sempre Apim, ma l'api di Travaso non travasa tutti i dati che ci siamo recuperati dall'asset precedente, quindi tocca a noi fare la merge per podPdr.
        [FlowType.CPRetroattivoAssetCessato]: ({ products, ...state }) => {
            return {
                ...state,
                products: [
                    // Prodotti Visibili
                    ...Array.from(
                        (products || [])
                            // filtro prendendo solo i prodotti visibili
                            .filter((product: Product) => !!isVisibleProduct(product))
                            // creo una mappa per raggruppare tutti i prodotti con stesso pod/pdr, in quanto vanno mergiati. Tutti gli altri vengono tenuti come tali
                            .reduce((aggregatorMap, currentProduct, idx) => {
                                const productsArray: Product[] = aggregatorMap.get(currentProduct.podPdr) || [];
                                // siccome il primo prodotto (quello inserito a carrello inizialmente) non ha lineItemId ma ha tutti i dati dell'utente,
                                // lo inserisco in testa in quanto la merge di lodash scrive sul primo oggetto tutti i successivi
                                !currentProduct.lineItemId
                                    ? productsArray.unshift(currentProduct)
                                    : productsArray.push(currentProduct);
                                // se il prodotto non ha podPdr, inserisco l'indice come chiave, in modo da non mergiarlo con gli altri prodotti
                                return aggregatorMap.set(currentProduct.podPdr || `${idx}`, productsArray);
                            }, new Map<string, Product[]>())
                            .values(),
                        // mergio fra di loro i prodotti che hanno lo stesso podPdr. Per ottimizzare, faccio la merge solo se ho più di un oggetto nell'array
                    ).map((productsArray: Product[]) =>
                        productsArray.length > 1
                            ? _merge(productsArray[0], ...productsArray.slice(1))
                            : productsArray[0],
                    ),

                    // Prodotti Non Visibili (es: tecnici, sconti, etc...)
                    ...(products || []).filter((product) => !isVisibleProduct(product)),
                ],
            };
        },

        [FlowType.CPRetroattivoExNovo]: ({ products, ...state }) => {
            const effectiveDate = (products || []).find(
                (product) => product.lineItemStatus === AptLineStatus.Cancelled,
            )?.effectiveDate;
            return {
                ...state,
                products: (products || []).map((prod) =>
                    prod.lineItemStatus === AptLineStatus.Upgraded ? { ...prod, effectiveDate } : prod,
                ),
            };
        },

        [FlowType.CPCommerciale]: ({ products, ...state }) => {
            const prodWithPaymentTool = products.find((p) => p.paymentInfo?.paymentTool);
            const sourceProduct = (products || []).find(
                (product) => product.lineItemStatus === AptLineStatus.Cancelled,
            );

            return {
                ...state,
                products: products.map((prod) => ({
                    ...prod,
                    paymentInfo: { ...prod.paymentInfo, paymentTool: prodWithPaymentTool?.paymentInfo?.paymentTool },
                    deliveryAddress: { ...prod?.deliveryAddress, ...sourceProduct?.deliveryAddress },
                    effectiveDate: prod?.effectiveDate || prod?.startDate,
                })),
            };
        },

        [FlowType.CPDelibera]: ({ products, ...state }) => {
            const prodWithPaymentTool = products.find((p) => p.paymentInfo?.paymentTool);
            const sourceProduct = (products || []).find(
                (product) => product.lineItemStatus === AptLineStatus.Cancelled,
            );

            return {
                ...state,
                products: products.map((prod) => ({
                    ...prod,
                    paymentInfo: {
                        ...prod.paymentInfo,
                        paymentInstrument: sourceProduct?.paymentInfo?.paymentInstrument,
                        paymentTool: prodWithPaymentTool?.paymentInfo?.paymentTool,
                    },
                    deliveryAddress: { ...prod?.deliveryAddress, ...sourceProduct?.deliveryAddress },
                    effectiveDate: prod?.effectiveDate || prod?.startDate,
                })),
            };
        },

        [FlowType.CPAmministrativo]: ({ products, ...state }) => {
            const prodWithPaymentTool = products.find((p) => p.paymentInfo?.paymentTool);
            const sourceProduct = (products || []).find(
                (product) => product.lineItemStatus === AptLineStatus.Cancelled,
            );

            return {
                ...state,
                products: products.map((prod) => ({
                    ...prod,
                    paymentInfo: {
                        ...prod.paymentInfo,
                        paymentInstrument: sourceProduct?.paymentInfo?.paymentInstrument,
                        paymentTool: prodWithPaymentTool?.paymentInfo?.paymentTool,
                    },
                    deliveryAddress: { ...prod?.deliveryAddress, ...sourceProduct?.deliveryAddress },
                    effectiveDate: prod?.effectiveDate || prod?.startDate,
                })),
            };
        },

        [FlowType.CambioTipologiaFornitura]: ({ products, ...state }, _, previousState) => {
            return {
                ...state,
                products: products.map((currentProduct) => {
                    if (currentProduct?.lineItemStatus === AptLineStatus.Upgraded) {
                        const previousProduct = previousState?.products.find(
                            (product) =>
                                (product?.lineItemStatus === AptLineStatus.Upgraded || !product?.lineItemStatus) &&
                                product.podPdr === currentProduct.podPdr,
                        );
                        return {
                            ...currentProduct,
                            configurations: {
                                ...currentProduct?.configurations,
                                supplyUse:
                                    previousProduct?.configurations?.supplyUse ||
                                    currentProduct?.configurations?.supplyUse,
                                destinationUse:
                                    previousProduct?.configurations?.destinationUse ||
                                    currentProduct?.configurations?.destinationUse,
                            },
                            effectiveDate: previousProduct?.effectiveDate || currentProduct?.effectiveDate,
                        };
                    }
                    return currentProduct;
                }),
            };
        },

        [MacroFlowType.Voltura]: ({ products, ...state }, _, previousState) => {
            return {
                ...state,
                products: products.map((currentProduct) => {
                    const previousTechDetails = previousState?.products.find(
                        (product) => product.podPdr === currentProduct.podPdr,
                    )?.technicalDetails;
                    if (previousTechDetails) {
                        return {
                            ...currentProduct,
                            technicalDetails: {
                                ...currentProduct?.technicalDetails,
                                pwrVoltage:
                                    previousTechDetails.pwrVoltage || currentProduct?.technicalDetails?.pwrVoltage,
                                pwrAvailablePower:
                                    previousTechDetails.pwrAvailablePower ||
                                    currentProduct?.technicalDetails?.pwrAvailablePower,
                                pwrInstantaneousPower:
                                    previousTechDetails.pwrInstantaneousPower ||
                                    currentProduct?.technicalDetails?.pwrInstantaneousPower,
                            },
                        };
                    }
                    return currentProduct;
                }),
            };
        },

        DEFAULT: (state, cart) => ({
            ...state,
            products: (state?.products || []).map((product) => ({
                ...product,
                prices: Object.entries(product.prices || {}).reduce(
                    (aggr, [key, value]) => (typeof value === 'number' ? aggr : { ...aggr, [key]: value }),
                    {},
                ),
            })),
            firma: {
                ...(state?.firma || ({} as Firma)),
                signedDate: parseToDate(cart?.egl_signed_date || state?.firma?.signedDate),
            },
        }),
    };

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        // Allineamento automatico carrello -> statev2.products
        // https://localhost:4200/cp/productdetails/a1k7a000001mbQwAAI
        // https://localhost:4200/cp/productconfig/01t7a000009l3Z6AAI/a3K7a000000fX8HEAU?v=1652630940446
        // https://localhost:4200/carts/active?v=1652630940446

        // Aggiorno l'id prodotto corrente
        /*
        if (/(?:^|\/)order-entry(?:$|[\/?])/.test(state.url)) {
            const params = {
                ...route.params,
                ...route.queryParams,
            };
            this.eglState.dispatch(setProductIndex({ currentProductV2Id: getNumberOrNull(params.itemId) }));
        }
        */

        return of(state).pipe(
            filter(
                ({ url }) =>
                    !new RegExp(
                        [RoutesPaths.BackOffice, ...ERROR_ROUTES].map((route) => `(?:${route})`).join('|'),
                    ).test(url),
            ),
            // Caricamento iniziale dell'operationMode (salesProcess D365) se presente e se appena atterrato in SUP
            switchMap(() => this.d365ParamsObs(route)),
            // Caricamento iniziale del Cart se presente e se appena atterrato in SUP
            switchMap(() => this.activationCartObs(route)),
            debounceTime(300),
            switchMap(() => {
                if (Regex.CATALOG_ROUTE.test(state.url) || Regex.ORDER_ENTRY_PRODUCT_CONFIG_ROUTE.test(state.url)) {
                    return this.initCart2ProductSub();
                } else if (this.cart2ProductsSub) {
                    this.cart2ProductsSub.unsubscribe();
                    this.cart2ProductsSub = null;
                }
                return this.cartSrv.getMyCart().pipe(
                    take(1),
                    map((cart) => cart as EglCartExtended),
                );
            }),
            // Arricchisco i prodotti nello state usando le informazioni presenti nei queryParams
            switchMap((cart) => combineLatest([of(cart), this.enrichProductsFromQueryString(route.queryParams)])),
            filter(
                () =>
                    // In caso di ricaricamento SUP (click su sidebar D365) non devo aggiornare il sales_process del carrello
                    // perchè nell'app.component potrebbe esser stata triggarata la creazione di un nuovo carrello.
                    // Senza questa filter venieva aggiornato il sales process del carrello precedente alla creazione del nuovo.
                    !new RegExp(RoutesPaths.Dashboard).test(state.url) ||
                    // In seguito al bug #199687 si skippa l'aggiornamento del sales_process nel premere "Catalog" in quanto
                    // ciò andrebbe a creare poi degli errori sulla decomposizione OTI
                    !new RegExp(RoutesPaths.AllProducts).test(state.url),
            ),
            switchMap(([cart]) =>
                this.eglState
                    .select(selectSalesProcess)
                    .pipe(
                        map((stateSalesProcess) => ({
                            stateSalesProcess,
                            cartSalesProcess: cart?.egl_sales_process,
                            cart,
                        })),
                    )
                    .pipe(take(1)),
            ),
            map(({ stateSalesProcess, cartSalesProcess, ...data }) => ({
                ...data,
                stateSalesProcess,
                cartSalesProcess,
            })),
            mergeMap(({ stateSalesProcess }) =>
                // I'm a pezzotto
                new RegExp(RoutesPaths.Dashboard).test(state.url) || state.url === `/${RoutesPaths.AllProducts}`
                    ? of(null)
                    : this.cartLightSrv.updateCartSalesProcess(stateSalesProcess),
            ),
            take(1),
            isEmpty(),
            map(() => null),
            catchError((err) => {
                this.logger.error(null, err?.message || 'Generic Tarantula.Resolver error');
                return of(null);
            }),
        );
    }

    private mergeProducts(targetProduct: DeepPartial<Product>, additionalData: DeepPartial<Product>): Product {
        return mergeWith(targetProduct || {}, additionalData, (objValue, srcValue) => {
            // Evito di unire le informazioni delle promo, utilizzo i dati esistenti solo in assenza dei discountPromo nel nuovo nodo
            if (Array.isArray(objValue) || Array.isArray(srcValue)) {
                return srcValue || objValue;
            }
        }) as Product;
    }

    private mergeProductLists(
        srcProductLists: DeepPartial<Product>[],
        ...productLists: DeepPartial<Product>[][]
    ): Product[] {
        // Mappo i prodotti dallo state in base a degli id univoci con priorità lineItemId > PodPdt > CodeSku
        const lineMapReducer = (aggr: { [key: string]: Product }, product: Product) => {
            const destProduct = cleanObj(product) as Product;
            const itemId = product?.lineItemId || product?.podPdr || product?.codeOrSku;
            return {
                ...aggr,
                ...(itemId
                    ? {
                          [itemId]: this.mergeProducts(aggr[itemId], destProduct),
                      }
                    : {}),
            };
        };

        const destProductsMap = (srcProductLists || []).reduce(lineMapReducer, {} as { [key: string]: Product });
        const cartLineItemIds = new Set<string>();

        (productLists || [])
            .reduce((aggr, productList) => aggr.concat(productList), [] as DeepPartial<Product>[])
            .forEach((product) => {
                cartLineItemIds.add(product?.lineItemId);
                //Recupero il prodotto dallo state con lo stesso lineItemId del prodotto nel carrello che sto scorrendo
                const targetProductByLineItemId = destProductsMap[product?.lineItemId];
                //Recupero il prodotto dallo state con lo stesso podPdr/codeSku del prodotto nel carrello che sto scorrendo
                const targetProductByPodPdrSku = destProductsMap[product?.podPdr || product?.codeOrSku];

                // Il targetProduct (state) è il prodotto con lo stesso lineItemId o lo stesso podPdr/codeSku ma privo di lineItemId
                const targetProduct =
                    targetProductByLineItemId ||
                    (!targetProductByPodPdrSku?.lineItemId ? targetProductByPodPdrSku : null);

                const cleanedProduct = cleanObj(product) as Product;
                if (targetProduct) {
                    this.mergeProducts(targetProduct, cleanedProduct);
                } else if (product?.lineItemId || product?.podPdr || product?.codeOrSku) {
                    destProductsMap[product?.lineItemId || product?.podPdr || product?.codeOrSku] = cleanedProduct;
                }
            });

        return Array.from(new Set(Object.values(destProductsMap))).filter(
            (product) => !product?.lineItemId || cartLineItemIds.has(product?.lineItemId),
        );
    }

    private cart2Products$: Observable<EglCartExtended>;
    private cart2ProductsSub: Subscription;
    private ENABLE_CART_SYNC_LOADER = true;

    /**
     * arricchisce lo state con il nodo 'products' generato in base al carrello
     * @param cart
     * @returns
     */
    private initCart2ProductSub(): Observable<EglCartExtended> {
        this.cart2Products$ =
            this.cart2Products$ ||
            (this.cartSrv.getMyCart().pipe(
                switchMap((cart: EglCartExtended) =>
                    this.eglState.select(v2OrderEntryState).pipe(
                        take(1),
                        map((state) => ({ state, cart })),
                    ),
                ),
                tap(() => {
                    this.ENABLE_CART_SYNC_LOADER && LoadingService.show();
                    this.logger.info('TarantulaResolver: creating  state to start order entry');
                }),
                map(({ state, cart }) => ({
                    cart,
                    previousState: state,
                    newState: {
                        ...state,
                        cartId: cart.Id,
                        products: this.mergeProductLists(state?.products, eglCart2Products(cart)),
                    },
                })),
                mergeMap(({ newState, cart, previousState }) => {
                    // Recupero le characteristics per i prodotti manutenzione
                    this.productCodes = newState.products
                        .filter(
                            (product) =>
                                (containsProductMaintenance(product.productType) ||
                                    containsProductComplex(product.productType)) &&
                                product.codeOrSku &&
                                !product.technicalDetails?.characteristics?.length,
                        )
                        .map((product) => {
                            return {
                                codeOrSku: product.codeOrSku,
                                isComplex: containsProductComplex(product?.productType),
                            };
                        });
                    if (this.featureToggleSrv.isExtraCommodityFlowEnabled && this.productCodes.length > 0) {
                        return this._getCharacteristics(
                            this.productCodes.map((prdCodes) => prdCodes.codeOrSku),
                            newState,
                            previousState,
                            cart,
                            Phase.SALES,
                        );
                    } else {
                        return of({ newState, cart, previousState });
                    }
                }),
                switchMap(({ newState, cart, previousState }) => {
                    // Chiamo il turbo per recuperare la struttura dei prodotti per analizzare i ProductGroup.
                    // Al momento lo faccio solo per i prodotti assicurativi (Nuovo prodotto polizza 2023) e per i prodotti GAS (Delibera 100/102)
                    const ob$ = newState.products
                        .filter(
                            (p) =>
                                isVisibleNotTechProduct(p) &&
                                (containsProductInsurance(p.productType) || containsProductGas(p.productType)),
                        )
                        .map((product) =>
                            product?.productId ? this.productSrv.fetch(product?.productId).pipe(take(1)) : [],
                        );

                    return forkJoin(ob$.length ? ob$ : [EMPTY]).pipe(
                        map((productsEXT) =>
                            (productsEXT || [])?.map((p: EglProductExtended) => getProductConfigByProductGroups(p)),
                        ),
                        map((partialProducts) =>
                            newState.products.map((product) => ({
                                ...product,
                                ...(partialProducts?.find(
                                    (partialProduct) => partialProduct.productId === product.productId,
                                ) || {}),
                            })),
                        ),
                        catchError((error) => {
                            this.logger.error('TarantulaResolver', error?.message, error, true);
                            this.ENABLE_CART_SYNC_LOADER && LoadingService.hide();
                            throw new Error('Error in retrieving product details');
                        }),
                        mapOrEmpty(
                            (products) => ({
                                newState: {
                                    ...newState,
                                    products,
                                },
                                cart,
                                previousState,
                            }),
                            () => ({
                                newState,
                                cart,
                                previousState,
                            }),
                        ),
                    );
                }),
                map(({ newState, cart, previousState }) => ({
                    cart,
                    newState: [
                        this.FLOW_TYPE_STATE_STRATEGY_MAP.DEFAULT,
                        // SI APPLICA la strategia sul FLOWTYPE
                        this.FLOW_TYPE_STATE_STRATEGY_MAP[newState?.flowType],
                        // si applica la strategia sul MACRO FLOWTYPE
                        this.FLOW_TYPE_STATE_STRATEGY_MAP[flowTypeToMacroFlowType(newState?.flowType)],
                    ]
                        .filter(Boolean)
                        .reduce((aggState, strategy) => strategy(aggState, cart, previousState), newState),
                })),
                tap(({ newState: state }) => {
                    this.eglState.dispatch(setV2OrderEntryState({ state }));
                    this.logger.info('TarantulaResolver: state dispatched using getMyCart()', state);
                    this.ENABLE_CART_SYNC_LOADER && LoadingService.hide();
                }),
                catchError((error) => {
                    this.logger.error('TarantulaResolver', error?.message, error, true);
                    this.ENABLE_CART_SYNC_LOADER && LoadingService.hide();
                    throw new Error('Error Dispatching enriched state with products');
                }),
                map(({ cart }) => cart),
            ) as Observable<EglCartExtended>);
        this.cart2ProductsSub = this.cart2ProductsSub || this.cart2Products$.subscribe();
        return this.cart2Products$;
    }

    private _getCharacteristics(
        productCodes: string[],
        newState: OrderEntryState_v2,
        previousState: OrderEntryState_v2,
        cart: EglCartExtended,
        phase: Phase,
    ): Observable<any> {
        return this.eseProvider.getProductCharacteristics(productCodes, phase).pipe(
            map((productCharacteristics) => {
                newState.products = newState.products.map((product) => {
                    // Rimappo le caratteristiche in base alla struttura desiderata
                    const duplicatedCharacteristics = productCharacteristics
                        .filter((pc) => pc?.SKU === product.codeOrSku)
                        .map((pc) => pc?.Characteristics)
                        .reduce((aggr, characteristics) => [...aggr, ...characteristics], []);
                    // Tolgo le caratteristiche duplicate in base all'Id
                    const uniqueCharacteristics = [];
                    const existingIds = new Set();
                    duplicatedCharacteristics.forEach((characteristic) => {
                        if (!existingIds.has(characteristic.Id)) {
                            existingIds.add(characteristic.Id);
                            uniqueCharacteristics.push(characteristic);
                        }
                    });
                    return {
                        ...product,
                        configurations: {
                            ...product.configurations,
                            saleabilityZip:
                                product.configurations.saleabilityZip ||
                                this.localStorageSrv.productsZip?.find((p) => p.productId === product.productId)?.zip ||
                                this.localStorageSrv.productsZip?.find((p) => p.zip)?.zip,
                        },
                        technicalDetails: {
                            ...product.technicalDetails,
                            characteristics: product?.technicalDetails?.characteristics?.length
                                ? product?.technicalDetails?.characteristics
                                : uniqueCharacteristics,
                        } as TechnicalDetails,
                    };
                });

                return { newState, cart, previousState };
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }

    /**
     * Se presente il parametro 'acart' nell'url, allora prima di fare qualsiasi altra cosa recupero eventuale state reducx salvato su salesforce
     * @param route
     * @returns EglState che è stato dispatchato in memoria
     */
    private activationCartObs(route: ActivatedRouteSnapshot): Observable<void> {
        const aCartId = this.getActivationCart(route);
        if (aCartId) {
            this.logger.info('TarantulaResolver - activating cartId: ', aCartId, route?.url);
            return this.cartSrv.getCartWithId(aCartId).pipe(
                tap((cart) => {
                    if (!cart) {
                        throw new Error(`Carrello ${aCartId} inesistente o fuori matrice di visibilità `);
                    }
                }),
                mergeMap((cart: EglCartExtended) =>
                    this.supStateSrv
                        .restoreSupStateByCartId(aCartId, {
                            cartSegment: cart.egl_customer_type,
                            orderEntry: {
                                flowType: aptSalesProcessToFlowType(cart?.egl_sales_process),
                            },
                        })
                        .pipe(map(() => cart)),
                ),
                LoadingService.loaderOperator('Attivazione carrello in corso'),
                tap((cart) => {
                    CartService.setCurrentCartId(cart.Id);
                    this.localStorageSrv.latestACartLoaded = cart.Id;
                    this.apttusSrv.setCartId(cart.Id);
                    this.cartSrv.publish(cart);
                    if (cart?.egl_sales_process !== AptSalesProcess.VariazioneModificaParametriFornitura) {
                        //Aggiunto per il bug #290985, per un avvio corretto del flowtype in caso di carrello recuperato, se il carrello non ha il salesprocess lo importo in automatico a swin come da as-is
                        this.store.dispatch(
                            setFlowType({
                                flowType: salesProcessOrOperationTypeToFlowType(
                                    cart?.egl_sales_process || AptSalesProcess.SwitchIn,
                                ),
                            }),
                        );
                    }
                }),
                map(() => null),
                catchError((err) => {
                    this.router.navigate([RoutesPaths.Error500]);
                    this.logger.error(null, err?.message || `Generic error activating cart ${aCartId}`);
                    return of(null);
                }),
            );
        }
        return of(null);
    }

    private getActivationCart(route: ActivatedRouteSnapshot): string {
        const aCart = route.queryParams?.acart || activationCartFromUrl();
        return !this.aCartLoaded(aCart) &&
            !!aCart &&
            (CartService.getCurrentCartId() !== aCart || this.isFirstNavigation())
            ? aCart
            : null;
    }

    private aCartLoaded(cartId: string): boolean {
        return this.localStorageSrv.latestACartLoaded === cartId;
    }

    private readonly D365_PARAMS_UPDATER_MAP: {
        [key in 'operationMode' | 'customerSegment']: (value: string) => Observable<void>;
    } = {
        operationMode: (value) => this.chimeraSrv.updateOperationMode(value),
        customerSegment: (value) => this.chimeraSrv.updateSegment(value),
    };

    /**
     * @description imposto i dati in base ai d365 params
     */
    private d365ParamsObs(route: ActivatedRouteSnapshot): Observable<void> {
        return merge(
            ...Object.entries(this.D365_PARAMS_UPDATER_MAP).map(([fieldName, updater]) => {
                if (route.queryParams[fieldName] && this.isFirstNavigation()) {
                    return updater(route.queryParams[fieldName]);
                }
                return of(null);
            }),
        );
    }

    private isFirstNavigation(): boolean {
        return !this.router.getCurrentNavigation()?.previousNavigation;
    }

    private enrichProductsFromQueryString(queryParams: Params): Observable<void> {
        const assetIntegrationId = <string>queryParams?.assetId;
        const partNumber = <string>queryParams?.serviceCode;
        return this.eglState.select(v2SelectAllProducts).pipe(
            take(1),
            map((products) =>
                products.map((product) => ({
                    ...product,
                    ...(assetIntegrationId && { assetIntegrationId }),
                    ...(partNumber && { partNumber }),
                })),
            ),
            tap((products) => this.eglState.dispatch(setV2Products({ products }))),
            map(() => null),
        );
    }
}
