export enum AptCommodityTypeOfSale {
    Gas = 'GAS',
    Power = 'POWER',
    Dual = 'DUAL',
}

export enum AptCommodityType {
    Gas = 'GAS',
    Power = 'POWER',
}

export enum AptCodeType {
    pod = 'POD',
    pdr = 'PDR',
}

export enum FilterCommodityType {
    Gas = 'GAS',
    Power = 'POWER',
    All = 'ALL',
}

export const APT_COMMODITY_TYPE_MAP: { [key in string]: AptCommodityType } = {
    GAS: AptCommodityType.Gas,
    POWER: AptCommodityType.Power,
};
