import { Pipe, PipeTransform } from '@angular/core';
import { D365CustomerSegment } from '../../enums/d365/d365-customer-segment';
import {
    TroubleshootingFormValues,
    TroubleshootingQuestion,
} from '../../services/shared/troubleshooting-questions.service';

@Pipe({
    name: 'troubleshooter',
})
export class TroubleshooterPipe implements PipeTransform {
    transform(
        questions: TroubleshootingQuestion[],
        questionResult: number,
        values: TroubleshootingFormValues,
        params: TroubleshootingExternalParameters
    ): TroubleshootingQuestion[] {
        return questions.filter((question) => this.isVisible(question, questionResult, values, params));
    }

    private isVisible(
        question: TroubleshootingQuestion,
        questionResult: number,
        values: TroubleshootingFormValues,
        params: TroubleshootingExternalParameters
    ): boolean {
        return typeof question.visible === 'function'
            ? question.visible(questionResult, values || {}, params)
            : (question.visible & questionResult) === question.visible;
    }
}

export interface TroubleshootingExternalParameters {
    customerSegment: D365CustomerSegment;
}
