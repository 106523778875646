import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, finalize, map, take, tap } from 'rxjs/operators';
import { setFlowType } from '../../../../../store/actions/order-entry.actions';
import { selectAgentInfo, selectContact, selectIsSiebelCustomer } from '../../../../../store/selectors/user.selectors';
import { AppState } from '../../../../../store/models/app-state';
import { RoutesPaths } from '../../../../config/routes-paths';
import { DdlElement } from '../../../../interfaces/ddl-element';
import { CartUtilityService } from '../../../../services/apttus/tables/cart/cart-utility.service';
import { LoadingService } from '../../../../services/shared/loading.service';
import { PrivateConfigurationService } from '../../../../services/shared/private-configuration.service';
import { DragonRouterService } from '../../../../services/shared/router/dragon-router.service';
import { FlowType } from '../../../../../store/models/flow-type';
import { AgentAuthorizationService } from '../../../../services/app/agent-authorization.service';
import { LoggerService } from '../../../../services/shared/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, of } from 'rxjs';
import { D365CustomerSegment } from '../../../../enums/d365/d365-customer-segment';

@Injectable()
export class MainHeaderService {
    private _lastSelectionDdlValueId: string;
    headersElements: HeaderDropdownlist[];
    isResidential: boolean;

    constructor(
        private store: Store<AppState>,
        private routerSrv: DragonRouterService,
        private cartUtilitySrv: CartUtilityService,
        private configSrv: PrivateConfigurationService,
        private agentAuthorizationSrv: AgentAuthorizationService,
        private logger: LoggerService,
        private translateSrv: TranslateService
    ) {
        this.calculateElements();
    }

    public calculateElements(): void {
        this.headersElements = this.HEADER_MENU_CONFIG.filter((x) =>
            this._lastSelectionDdlValueId
                ? x.elements.find((e) => e.value.id === this._lastSelectionDdlValueId) !== undefined
                : x.visible
        ).map((j) =>
            Object.assign(new HeaderDropdownlist(), {
                ...j,
                elements: j.elements.filter((e) => e.visible()),
            })
        );
    }

    public performActionDdl(selectedValue: HeaderDdlElement): Observable<void> {
        if (typeof selectedValue.value.f === 'function') {
            const fnResult = selectedValue.value.f();

            return from(fnResult).pipe(
                tap(() => {
                    this._lastSelectionDdlValueId = selectedValue.value.id;
                    selectedValue.selected = true;
                    this.calculateElements();
                })
            );
        }
        return of(null);
    }

    public discardActionDdl(selectedValue: HeaderDdlElement): void {
        this._lastSelectionDdlValueId = null;
        selectedValue.selected = false;
        this.store.dispatch(setFlowType({ flowType: FlowType.SwitchIn }));
        this.cartUtilitySrv
            .updateOrCreateNewCart()
            .pipe(LoadingService.loaderOperator('Annullamento operazione'))
            .subscribe(() => this.routerSrv.goToDashboard());
        this.calculateElements();
    }

    private updateOrCreateNewCart(flowType: FlowType, category?: string): void {
        this.store.dispatch(setFlowType({ flowType: flowType }));
        this.cartUtilitySrv
            .updateOrCreateNewCart()
            .pipe(LoadingService.loaderOperator('Inizializzazione processo'))
            .subscribe(() => {
                this.routerSrv.goToCatalog(category);
            });
    }

    private handleAdministrativeCallback(flowType: FlowType): Promise<void> {
        return this.store
            .select(selectIsSiebelCustomer)
            .pipe(
                take(1),
                tap((isSiebelCustomer) => {
                    if (isSiebelCustomer) {
                        throw new Error(this.translateSrv.instant('ERROR.ADMIN_PROC_NOT_ALLOWED_WEBAPP_REDIRECT'));
                    }
                }),
                tap(() => this.updateOrCreateNewCart(flowType, this.configSrv.config.commodityCategoryName)),
                catchError((err) => {
                    this.logger.warn(err?.message, true);
                    throw err;
                }),
                map(() => null)
            )
            .toPromise();
    }

    private readonly HEADER_MENU_CONFIG: HeaderDropdownlist[] = [
        Object.assign(new HeaderDropdownlist(), {
            placeholder: 'Recupero Amministrativo',
            elements: [
                {
                    name: 'Switch-in Amministrativo',
                    visible: () => this.agentAuthorizationSrv.canRecoverSwitchIn,
                    value: {
                        id: '#001',
                        flowType: FlowType.SwitchInAmmRecupero2C,
                        f: () => this.handleAdministrativeCallback(FlowType.SwitchInAmmRecupero2C),
                    },
                },
                {
                    name: 'Attivazione Amministrativo',
                    visible: () => this.agentAuthorizationSrv.canDoRecoverActivation,
                    value: {
                        id: '#002',
                        flowType: FlowType.AttivazioneAmmRecupero,
                        f: () => this.handleAdministrativeCallback(FlowType.AttivazioneAmmRecupero),
                    },
                },
                {
                    name: 'Voltura con cambio fornitore Amministrativo',
                    visible: () => this.agentAuthorizationSrv.canTransferWithSwitchRecovery,
                    value: {
                        id: '#003',
                        flowType: FlowType.VolturaCambioFornitoreAmmRecupero2C,
                        f: () => this.handleAdministrativeCallback(FlowType.VolturaCambioFornitoreAmmRecupero2C),
                    },
                },
            ],
        }),
        Object.assign(new HeaderDropdownlist(), {
            placeholder: 'Acquisizione VIP',
            elements: [
                {
                    name: 'Switch-in VIP',
                    visible: () => this.agentAuthorizationSrv.canSwitchInVip,
                    value: {
                        id: '#003',
                        flowType: FlowType.SwitchInAmmVip,
                        f: () => this.handleAdministrativeCallback(FlowType.SwitchInAmmVip),
                    },
                },
                {
                    name: 'Attivazione VIP',
                    visible: () => this.agentAuthorizationSrv.canDoVIPActivation,
                    value: {
                        id: '#004',
                        flowType: FlowType.AttivazioneAmmVip,
                        f: () =>
                            this.updateOrCreateNewCart(
                                FlowType.AttivazioneAmmVip,
                                this.configSrv.config.commodityCategoryName
                            ),
                    },
                },
                {
                    name: 'Voltura con cambio fornitore VIP',
                    visible: () => this.agentAuthorizationSrv.canTransferWithSwitchVip && this.isResidential,
                    value: {
                        id: '#005',
                        flowType: FlowType.VolturaCambioFornitoreVip,
                        f: () =>
                            this.updateOrCreateNewCart(
                                FlowType.VolturaCambioFornitoreVip,
                                this.configSrv.config.commodityCategoryName
                            ),
                    },
                },
            ],
        }),
        Object.assign(new HeaderDropdownlist(), {
            placeholder: 'Provisioning Manuale',
            elements: [
                {
                    name: 'Attivazione',
                    visible: () => this.agentAuthorizationSrv.canDoManualProvisioningActivation,
                    value: {
                        id: '#001',
                        flowType: FlowType.AttivazioneAmministrativa,
                        f: () => this.handleAdministrativeCallback(FlowType.AttivazioneAmministrativa),
                    },
                },
            ],
        }),
    ];

    public setIsResidential(segment: D365CustomerSegment) {
        this.isResidential = segment === D365CustomerSegment.Residential;
    }

    public get selectedHeaderConfig(): HeaderDdlElement {
        return this.HEADER_MENU_CONFIG.map((config) => config.elements)
            .reduce((aggr, curr) => [...aggr, ...curr], [])
            .find((config) => config.selected);
    }
}

export class HeaderDropdownlist {
    placeholder: string;
    elements: HeaderDdlElement[];
    get visible(): boolean {
        return (this.elements || []).filter((e) => e.visible()).length > 0;
    }
}

export interface HeaderDdlElement extends DdlElement<HeaderDdlValue> {
    visible?: () => boolean;
    value: HeaderDdlValue;
}

export interface HeaderDdlValue {
    id: string; // primary kay
    flowType?: FlowType;
    f: () => Promise<void> | Observable<void>;
}
