import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MD5 } from 'crypto-js';
import { cloneDeep, merge, set } from 'lodash';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import {
    bufferWhen,
    catchError,
    concatMap,
    debounceTime,
    filter,
    isEmpty,
    map,
    mergeMap,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';
import { DataLoaderService } from '../../../../modules/data-loader/services/data-loader.service';
import { setV2OrderEntryState } from '../../../../store/actions/order-entry-v2.actions';
import { setUserState } from '../../../../store/actions/user.actions';
import { FlowType } from '../../../../store/models/flow-type';
import { OrderEntryState } from '../../../../store/models/order-entry-state';
import { ContactV2, OrderEntryState_v2, Product } from '../../../../store/models/order-entry-state_v2';
import { UserState } from '../../../../store/models/user-state';
import { EglState } from '../../../../store/reducers';
import { selectSaveSupState } from '../../../../store/selectors/common.selectors';
import { AptCustomerType } from '../../../enums/apttus/apt-customer-type';
import { AptQuoteStatus } from '../../../enums/apttus/apt-quote-status';
import { AptQuoteSubStatus } from '../../../enums/apttus/apt-quote-sub-status';
import { D365CustomerSegment } from '../../../enums/d365/d365-customer-segment';
import { cleanObj, jsonTryParse } from '../../../functions/misc.functions';
import { convertSegmentAptToD365, normalizeContactPhones } from '../../../functions/remap.functions';
import {
    anyOrderEntryStateToV1,
    anyOrderEntryStateToV2,
    d365AccountContactDataToCustomerContact,
    d365AccountContactDataToDatiAnagraficiMBV2,
} from '../../../functions/transformation.functions';
import { flowTypeUtil, isOrderEntryStateV1 } from '../../../functions/verifications.functions';
import { AgentInfo } from '../../../models/user/agent';
import { Contact } from '../../../models/user/contact';
import { Lead } from '../../../models/user/lead';
import { CommonProvider } from '../../../providers/common-provider';
import { FeatureToggleService } from '../../shared/feature-toggle.service';
import { LoggerService } from '../../shared/logger.service';
@Injectable({
    providedIn: 'root',
})
export class EglSalesupStateService {
    constructor(
        private store: Store<any>,
        private logger: LoggerService,
        private commonPrv: CommonProvider,
        private dataLoaderSrv: DataLoaderService,
        private toggleSrv: FeatureToggleService,
    ) {}
    private latestSavedHashState: string;
    private _isSavingInProgress: boolean;
    private set isSavingInProgress(value: boolean) {
        this._isSavingInProgress = value;
        setTimeout(() => {
            !value && this.passNextRequest.emit();
        }, 300);
    }
    private passNextRequest = new EventEmitter<void>();
    private saveStateRequestBuffer = new BehaviorSubject<SaveStateData>(null);
    private readonly saveState = this.saveStateRequestBuffer
        .pipe(
            filter((request) => !!request),
            tap(() => !this._isSavingInProgress && (this.isSavingInProgress = false)),
            bufferWhen(() => this.passNextRequest),
            filter((bufferedRequests) => !!bufferedRequests.length),
            map((bufferedRequests) => bufferedRequests.pop()),
            tap(() => (this.isSavingInProgress = true)),
            mergeMap(({ serializedState, cartId, callingSource, hash, stateVersion }) => {
                return this.commonPrv.saveSalesUpState(cartId, serializedState, stateVersion, callingSource).pipe(
                    tap(() => {
                        this.latestSavedHashState = hash;
                        this.isSavingInProgress = false;
                    }),
                    catchError((e) => {
                        throw new Error(e?.message || 'Unmanaged error during saving SalesUP state');
                    }),
                );
            }),
        )
        .subscribe();
    /**
     * @description: funzione che salva lo state della SalesUP nella tabella su SalesForce usando APEX API.
     * è fondamentale che lo state contenga sempere la prop state.orderEntry.cartId. In caso contrario non sarà possibile salvare lo state.
     * @param callingSource: La sorgente che sta invocando il salvataggio, serve solo per i log
     * @param stateVersion: in che formato voglio salvare lo state
     */
    saveSupState(
        callingSource: string,
        stateVersion: number = this.toggleSrv.orderEntryStateV2Enabled ? 2 : 1,
    ): Observable<boolean> {
        // in caso di SwitchIn E2E spento la gestione lo state deve essere salvato sempre a vecchio.
        // tutti i processi con post MVP6 (Voltura, domiciliazione, ecc...) non possono funzionare senza state v2
        return this.getStateToSave(callingSource, stateVersion).pipe(
            switchMap(({ serializedState, cartId, callingSource }) => {
                const currentHashState = MD5(serializedState).toString();
                if (currentHashState === this.latestSavedHashState) {
                    return of(true);
                }
                // Salvo lo state solo se questo è diverso dall'ultimo salvato
                return this.commonPrv.saveSalesUpState(cartId, serializedState, stateVersion, callingSource).pipe(
                    tap(() => (this.latestSavedHashState = currentHashState)),
                    map(() => true),
                    catchError((e) => {
                        throw new Error(e?.message || 'Unmanaged error during saving SalesUP state');
                    }),
                );
            }),
            catchError((e) => {
                this.logger.error('Error during saveSupState', e, false, null, e);
                return of(false);
            }),
        );
    }

    /**
     * @description: recupera lo state di SUP partendo dal cartID e lo dispatcha nello store e lo restituisce al chiamante
     * @param cartId: id del carrello
     */
    getSupStateByCartId(cartId: string): Observable<
        Omit<EglState, 'orderEntry'> & {
            orderEntry: OrderEntryState_v2 | OrderEntryState;
        }
    > {
        return this.commonPrv.getSalesUpState(cartId).pipe(
            map((res) => jsonTryParse<EglState>(res?.State)),
            tap((state) => state && this.logger.info(`Raw state for cart ${cartId}`, state)),
        );
    }

    /**
     * @description: recupera lo state di SUP partendo dal cartID e lo dispatcha nello store
     * @param cartId: id del carrello
     * @param defaultVals: valori usati come default in caso in cui il dato recuperato non contiene l'informazione
     */
    restoreSupStateByCartId(
        cartId: string,
        defaultVals?: {
            orderEntry?: Partial<OrderEntryState>;
            contact?: Contact;
            cartSegment?: AptCustomerType;
            lead?: Lead;
        },
    ): Observable<EglState> {
        return this.getSupStateByCartId(cartId)
            .pipe(
                tap((res) => {
                    if (!res) {
                        this.logger.info(`State for cart ${cartId} not found`);
                        throw new Error();
                    } else {
                        this.logger.info(`State found for cart ${cartId}. Dispatcing...`);
                    }
                }),
                // PEZZA per errato mapping BE su state prefabbricato nei processi di Cessazione + CP (per ora...)
                map((state) =>
                    isOrderEntryStateV1(state?.orderEntry)
                        ? {
                              ...state,
                              orderEntry: {
                                  ...state?.orderEntry,
                                  infoProdotti: (state?.orderEntry?.infoProdotti || []).map(
                                      ({ id, ...productInfo }) => ({
                                          ...productInfo,
                                          productId: null,
                                          lineItemId: id,
                                      }),
                                  ),
                              },
                              isGeneratedState: true,
                          }
                        : (state as typeof state & { isGeneratedState?: boolean }),
                ),
                // Conversione state V1 -> V2
                map((state) => ({
                    ...state,
                    orderEntry: anyOrderEntryStateToV2(state?.orderEntry),
                })),
                // Se ho recuperato uno state e questo non contiene il cartId lo valorizzo
                map((state) => set(state, 'orderEntry.cartId', cartId)),
                // Getting account/contact informations from D365 CRM
                mergeMap(({ isGeneratedState, ...state }) => {
                    // Nel caso in cui lo state sia nativamente v2, e quindi generato da SUP, NON sovrascrivo i dati clienti con quelli D365
                    if (!isGeneratedState) {
                        return of(state);
                    }
                    // Nel caso in lo state sia stato convertito da V1, e quindi generato da BackEnd, sovrascrivo i dati cliente con quelli del CRM (D365)
                    const customerCode = state?.user?.contact?.egl_customercode || state?.user?.contact?.accountid;
                    return !customerCode
                        ? this.normalizeContactAddresses(state?.orderEntry?.contact).pipe(
                              map((contact) => merge({ ...state }, { orderEntry: { contact } })),
                          )
                        : this.dataLoaderSrv.getAccountContact(customerCode).pipe(
                              map((account) => ({
                                  user: d365AccountContactDataToCustomerContact(account),
                                  orderEntry: {
                                      contact: {
                                          mainAddress: account?.addresses?.['SEDE LEGALE'],
                                      },
                                      anagraficaMb:
                                          account.egl_customersegmentcode === D365CustomerSegment.Microbusiness
                                              ? merge(
                                                    d365AccountContactDataToDatiAnagraficiMBV2(account),
                                                    cleanObj(state?.orderEntry.anagraficaMb),
                                                )
                                              : null,
                                  },
                              })),
                              map((normState) => ({
                                  normState: cleanObj(normState),
                                  state: cleanObj(state),
                              })),
                              map(({ normState, state }) => merge(state, normState)),
                          );
                }),
                // Addresses & phone normalization
                mergeMap((res: EglState) =>
                    forkJoin({
                        user: this.normalizeUserState(res?.user),
                        // Normalizzazione indirizzi
                        orderEntry: this.normalizeProductsAddresses(res?.orderEntry?.products).pipe(
                            map((products) => ({
                                ...res?.orderEntry,
                                products,
                            })),
                        ),
                    }).pipe(
                        map((normalizedState) => ({
                            ...res,
                            ...normalizedState,
                        })),
                    ),
                ),
                map((state: EglState) => this.mergeMicrobusinessDataToCustomer(state, defaultVals)),
                map((state) => this.overrideQuoteStatusAndSign(state)),
                tap((state) => this.dispatchState(state, defaultVals, cartId)),
            )
            .pipe(
                concatMap((state) => this.saveSupState('state-refurbished', 2).pipe(map(() => state))),
                catchError((e) => {
                    if (e?.message) {
                        this.logger.error(`Error to retrieve salesUpState ${cartId}`, null, e);
                    }
                    return of(null);
                }),
            );
    }

    private overrideQuoteStatusAndSign(state: EglState): EglState {
        return ![FlowType.VariazioneCommerciale, FlowType.ScontoStandalone].includes(state?.orderEntry?.flowType)
            ? state
            : {
                  ...state,
                  orderEntry: {
                      ...state?.orderEntry,
                      quoteStateModel: {
                          status: AptQuoteStatus.Confermato,
                          subStatus: AptQuoteSubStatus.AttesaSottomissioneOrdine,
                      },
                      firma: {
                          signedDate: new Date(),
                      },
                  },
              };
    }

    /**
     * @description Fix temporanea per ribaltare i campi del customer dentro il cliente
     * @deprecated
     */
    private mergeMicrobusinessDataToCustomer(
        state: EglState,
        defaultVals?: {
            orderEntry?: Partial<OrderEntryState>;
            contact?: Contact;
            cartSegment?: AptCustomerType;
            lead?: Lead;
        },
    ): EglState {
        // in caso di lead non valorizzo il nodo contact
        const stateAnagraficaMbData = cleanObj({
            ...state?.user?.contact,
            egl_customersegmentcode: D365CustomerSegment.Microbusiness,
            egl_vatcode: state?.orderEntry?.anagraficaMb?.piva,
            egl_taxcode: state?.orderEntry?.anagraficaMb?.cf || state?.orderEntry?.anagraficaMb?.cfLegal,
            name: state?.orderEntry?.anagraficaMb?.companyName,
        });

        return state?.user?.cartSegment !== D365CustomerSegment.Microbusiness || defaultVals?.lead
            ? state
            : {
                  ...state,
                  user: {
                      ...state?.user,
                      contact: {
                          ...state?.user?.contact,
                          ...stateAnagraficaMbData,
                      },
                  },
              };
    }

    private normalizeUserState(user: UserState): Observable<UserState> {
        return of(
            user && {
                ...(user || ({} as UserState)),
                contact: normalizeContactPhones(user?.contact),
            },
        );
    }

    private normalizeProductsAddresses(products: Product[]): Observable<Product[]> {
        return (products || []).length
            ? forkJoin(
                  (products || []).map((product) =>
                      combineLatest([
                          this.dataLoaderSrv.normalizeAddress(product?.deliveryAddress),
                          this.dataLoaderSrv.normalizeAddress(product?.communicationAddress),
                      ]).pipe(
                          map(([deliveryAddress, communicationAddress]) => ({
                              ...product,
                              deliveryAddress,
                              communicationAddress,
                          })),
                      ),
                  ),
              )
            : of([]);
    }

    private normalizeContactAddresses(contact: ContactV2): Observable<ContactV2> {
        return !!contact?.mainAddress
            ? this.dataLoaderSrv.normalizeAddress(contact?.mainAddress).pipe(
                  map((mainAddress) => ({
                      ...contact,
                      mainAddress,
                  })),
              )
            : of(contact);
    }

    private getStateToSave(callingSource: string, stateVersion: number): Observable<SaveStateData> {
        return this.store
            .select(selectSaveSupState)
            .pipe(
                take(1),
                tap((state) => {
                    if (!state?.orderEntry?.cartId) {
                        throw new Error("Error saving sup state: 'state.orderEntry.cartId' was null.");
                    }
                }),
                map((state: EglState) => {
                    const copyState = cloneDeep(state);
                    //#179184 - Rimuovo l'array fornitureAttive che potenzialmente può contenere molti dati che non sono necessari lato backend e che possono quindi far superare il limite di spazio del campo "egl_state__c" su SalesForce
                    delete copyState?.orderEntry?.fornitureEsistenti;
                    if (state?.user?.agentInfo) {
                        copyState.user.agentInfo = Object.assign(new AgentInfo(), {
                            Agency: state.user.agentInfo.Agency,
                            Agent: state.user.agentInfo.Agent,
                        });
                    }
                    return {
                        state: copyState,
                        cartId: state.orderEntry.cartId,
                        callingSource,
                    };
                }),
            )
            .pipe(
                // Trasformo l'order entry state in V1 in base alla versione indicata
                map(({ state, ...data }) => ({
                    ...data,
                    stateVersion,
                    state: {
                        ...state,
                        orderEntry:
                            stateVersion === 1
                                ? anyOrderEntryStateToV1(state?.orderEntry)
                                : anyOrderEntryStateToV2(state?.orderEntry),
                    },
                })),
                // Serializzo lo state per inviarlo alla request
                map(({ state, ...data }) => ({
                    ...data,
                    serializedState: JSON.stringify(state),
                })),
                map((data) => ({ ...data, hash: MD5(data.serializedState).toString() })),
                debounceTime(50),
            );
    }

    private dispatchState(
        state: EglState,
        defaultVals?: {
            orderEntry?: Partial<OrderEntryState | OrderEntryState_v2>;
            contact?: Contact;
            cartSegment?: AptCustomerType;
            lead?: Lead;
        },
        cartId?: string,
    ): EglState {
        try {
            const orderEntryV2 = merge(
                anyOrderEntryStateToV2(state?.orderEntry),
                cleanObj(anyOrderEntryStateToV2(defaultVals?.orderEntry)),
                cleanObj(anyOrderEntryStateToV2(state?.orderEntry)),
                {
                    flowType:
                        (!flowTypeUtil(state?.orderEntry?.flowType).equalTo(FlowType.SwitchIn)
                            ? state?.orderEntry?.flowType
                            : null) || defaultVals?.orderEntry?.flowType,
                },
            );

            const userState = {
                ...state.user,
                contact: state?.user?.contact || defaultVals?.contact,
                lead: state?.user?.lead || defaultVals?.lead,
                cartSegment: state?.user?.cartSegment || convertSegmentAptToD365(defaultVals?.cartSegment),
            };

            this.store.dispatch(setV2OrderEntryState({ state: orderEntryV2 }));
            this.store.dispatch(setUserState({ s: userState }));

            this.logger.info(`State for cart ${cartId} dispatched after normalizations`, {
                ordeEntry: orderEntryV2,
                user: userState,
            });
            return state;
        } catch (error) {
            this.logger.error(`error during dispatching state`, '', error);
            return null;
        }
    }

    /**
     * @description: Questo metodo inserisce dentro un buffer le chiamate di salvataggio dello state su CPQ
     * @param callingSource: La sorgente che sta invocando il salvataggio, serve solo per i log
     * @param stateVersion: in che formato voglio salvare lo state
     */
    bufferizeSaveSupState(
        callingSource: string,
        stateVersion: number = this.toggleSrv.orderEntryStateV2Enabled ? 2 : 1,
    ): Observable<void> {
        return this.getStateToSave(callingSource, stateVersion).pipe(
            filter(({ hash }) => hash !== this.latestSavedHashState),
            tap((data) => this.saveStateRequestBuffer.next(data)),
            catchError((e) => {
                this.logger.error('Error during bufferizeSaveSupState', e, false, null, e);
                return EMPTY;
            }),
            isEmpty(),
            map(() => null),
        );
    }
}

interface SaveStateData {
    serializedState: string;
    cartId: string;
    callingSource: string;
    stateVersion?: number;
    hash?: string;
}
