import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { delay, mergeMap } from 'rxjs/operators';
import { setV2OrderEntryState } from '../../../../../store/actions/order-entry-v2.actions';
import { setUserState } from '../../../../../store/actions/user.actions';
import { EglState } from '../../../../../store/reducers';
import { jsonTryParse } from '../../../../functions/misc.functions';
import { CartToQuoteRequestService } from '../../../../services/apttus/cart-to-quote/cart-to-quote-request.service';
import { CartToQuoteService } from '../../../../services/apttus/cart-to-quote/cart-to-quote.service';
import { EglSalesupStateService } from '../../../../services/apttus/tables/egl-salesup-state.service';

@Component({
    selector: 'egl-new-convert-cart-to-quote-test-page',
    templateUrl: './new-convert-cart-to-quote-test-page.component.html',
    styleUrls: ['./new-convert-cart-to-quote-test-page.component.scss'],
})
export class NewConvertCartToQuoteTestPageComponent implements OnInit {
    textareaState: string;
    constructor(
        private store: Store<EglState>,
        private quoteSrv: CartToQuoteService,
        private quoteRequestSrv: CartToQuoteRequestService,
        private salesUpState: EglSalesupStateService
    ) {}

    ngOnInit(): void {}

    initState(): Observable<boolean> {
        let obs$: Observable<any>;
        this.textareaState = this.textareaState.trim();
        console.log('textareaState: ', this.textareaState);
        if (this.textareaState.startsWith('#')) {
            obs$ = this.salesUpState.getSupStateByCartId(this.textareaState.replace('#', ''));
        } else {
            obs$ = of(jsonTryParse<EglState>(this.textareaState));
        }
        return obs$.pipe(mergeMap((state) => this.dispatchState(state)));
    }

    dispatchState(state: EglState): Observable<boolean> {
        this.store.dispatch(setV2OrderEntryState({ state: state?.orderEntry }));
        this.store.dispatch(setUserState({ s: state.user }));
        return of(true).pipe(delay(1000));
    }

    mapRequest() {
        this.initState()
            .pipe(mergeMap(() => this.quoteRequestSrv.getCartToQuoteReq()))
            .subscribe((request) => {
                console.log('getConvertCartToNewQuoteReq - SUCCESS');
                console.log(request);
                debugger;
            });
    }

    createQuote() {
        this.initState()
            .pipe(mergeMap(() => this.quoteSrv.saveQuoteV2()))
            .subscribe((x) => {
                console.log('end create quote');
                debugger;
            });
    }
}
