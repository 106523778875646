import { AsyncPipe, CurrencyPipe, DatePipe, DecimalPipe, JsonPipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AutoFormatPipe } from './auto-format.pipe';
import { FilterTechProductsPipe } from './filter-tech-products.pipe';
import { FilterDiscountProdutsPipe } from './filterdiscountproduct.pipe';
import { FlowTranslatePipe } from './flow-translate.pipe';
import { TruncatePipe } from './truncate.pipe';
@NgModule({
    imports: [TranslateModule.forChild()],
    exports: [AutoFormatPipe, TruncatePipe, FlowTranslatePipe, FilterDiscountProdutsPipe, FilterTechProductsPipe],
    declarations: [AutoFormatPipe, TruncatePipe, FlowTranslatePipe, FilterDiscountProdutsPipe, FilterTechProductsPipe],
    providers: [
        DecimalPipe,
        CurrencyPipe,
        DatePipe,
        JsonPipe,
        AsyncPipe,
        AutoFormatPipe,
        TruncatePipe,
        FilterTechProductsPipe,
        FilterDiscountProdutsPipe,
        FlowTranslatePipe,
        PercentPipe,
    ],
})
export class CommonPipesModule {}
