export class Alert {
    name: string;
    description: string;
    id: string;

    constructor(name: string, description: string, id: string) {
        this.name = name;
        this.description = description?.replace(/<br.*>[\s]*<br.*>/g, '<br>');
        this.id = id;
    }
}
