export class ServiceError extends Error {
    public code: string;
    public level: 'HIGH' | 'LOW';
    public additionalData?: { [key in string]: unknown };
    constructor(
        code: string,
        message: string,
        level: 'HIGH' | 'LOW' = 'LOW',
        additionalData?: { [key in string]: unknown }
    ) {
        super(message);
        this.code = code;
        this.level = level;
        this.additionalData = additionalData;
    }

    public static isServiceError(err: Error): err is ServiceError {
        return err instanceof ServiceError || !!(err as ServiceError)?.level;
    }
}
