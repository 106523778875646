export enum SegoeMDL2Font {
    Accept = '&#xE10B;',
    Account = '&#xE168;', // chiocciola
    Add = '&#xE109;',
    AddFriend = '&#xE1E2;',
    Admin = '&#xE1A7;',
    AlignCenter = '&#xE1A1;',
    AlignLeft = '&#xE1A2;',
    AlignRight = '&#xE1A0;',
    AllApps = '&#xE179;',
    Attach = '&#xE16C;',
    AttachCamera = '&#xE12D;',
    Audio = '&#xE189;',
    Back = '&#xE112;',
    BackToWindow = '&#xE1D8;',
    BlockContact = '&#xE1E0;',
    Bold = '&#xE19B;',
    Bookmarks = '&#xE12F;',
    BrowsePhotos = '&#xE155;',
    Bullets = '&#xE133;',
    CalculatorAddition = '&#xE948',
    Calculator = '&#xE1D0;',
    Calendar = '&#xE163;',
    CalendarDay = '&#xE161;',
    CalendarReply = '&#xE1DB;',
    CalendarWeek = '&#xE162;',
    Camera = '&#xE722;',
    Cancel = '&#xE10A;',
    Caption = '&#xE15A;',
    CellPhone = '&#xE1C9;',
    Character = '&#xE164;',
    ChevronDown = '&#xE70D;',
    ChevronUp = '&#xE70E;',
    ChevronRight = '&#xE76C;',
    Clear = '&#xE106;',
    ClearSelection = '&#xE1C5;',
    Clock = '&#xE121;',
    ClosedCaption = '&#xE190;',
    ClosePane = '&#xE127;',
    Comment = '&#xE134;',
    Contact = '&#xE13D;',
    Contact2 = '&#xE187;',
    ContactInfo = '&#xE136;',
    ContactPresence = '&#xE181;',
    Copy = '&#xE16F;',
    Crop = '&#xE123;',
    Cut = '&#xE16B;',
    Delete = '&#xE107;',
    Directions = '&#xE1D1;',
    DisableUpdates = '&#xE194;',
    DisconnectDrive = '&#xE17A;',
    Dislike = '&#xE19E;',
    DockBottom = '&#xE147;',
    DockLeft = '&#xE145;',
    DockRight = '&#xE146;',
    Document = '&#xE130;',
    Download = '&#xE896;',
    Edit = '&#xE104;',
    Emoji = '&#xE11D;',
    Emoji2 = '&#xE170;',
    Favorite = '&#xE113;',
    Filter = '&#xE16E;',
    Find = '&#xE11A;',
    Flag = '&#xE129;',
    Folder = '&#xE188;',
    Font = '&#xE185;',
    FontColor = '&#xE186;',
    FontDecrease = '&#xE1C6;',
    FontIncrease = '&#xE1C7;',
    FontSize = '&#xE1C8;',
    Forward = '&#xE111;',
    FourBars = '&#xE1E9;',
    FullScreen = '&#xE1D9;',
    GlobalNavigationButton = '&#xE700;',
    Globe = '&#xE12B;',
    Go = '&#xE143;',
    GoToStart = '&#xE1E4;',
    GoToToday = '&#xE184;',
    HangUp = '&#xE137;',
    Help = '&#xE11B;',
    HideBcc = '&#xE16A;',
    Highlight = '&#xE193;',
    Home = '&#xE10F;',
    Import = '&#xE150;',
    ImportAll = '&#xE151;',
    Important = '&#xE171;',
    Italic = '&#xE199;',
    Keyboard = '&#xE144;',
    LeaveChat = '&#xE11F;',
    Library = '&#xE1D3;',
    Like = '&#xE19F;',
    LikeDislike = '&#xE19D;',
    Link = '&#xE167;',
    List = '&#xE14C;',
    Mail = '&#xE119;',
    MailFilled = '&#xE135;',
    MailForward = '&#xE120;',
    MailReply = '&#xE172;',
    MailReplyAll = '&#xE165;',
    MailReplyMirrored = '&#xEA57;',
    Manage = '&#xE178;',
    Map = '&#xE1C4;',
    MapDrive = '&#xE17B;',
    MapPin = '&#xE707;',
    Memo = '&#xE1D5;',
    Message = '&#xE15F;',
    Microphone = '&#xE1D6;',
    More = '&#xE10C;',
    MoveToFolder = '&#xE19C;',
    MusicInfo = '&#xE142;',
    Mute = '&#xE198;',
    NewFolder = '&#xE1DA;',
    NewWindow = '&#xE17C;',
    Next = '&#xE101;',
    OneBar = '&#xE1E6;',
    OpenFile = '&#xE1A5;',
    OpenLocal = '&#xE197;',
    OpenPane = '&#xE126;',
    OpenWith = '&#xE17D;',
    Orientation = '&#xE14F;',
    OtherUser = '&#xE1A6;',
    OutlineStar = '&#xE1CE;',
    Page = '&#xE132;',
    Page2 = '&#xE160;',
    Paste = '&#xE16D;',
    Pause = '&#xE103;',
    People = '&#xE125;',
    Permissions = '&#xE192;',
    Phone = '&#xE13A;',
    PhoneBook = '&#xE1D4;',
    Pictures = '&#xE158;',
    Pin = '&#xE141;',
    Placeholder = '&#xE18A;',
    Play = '&#xE102;',
    PostUpdate = '&#xE1D7;',
    Preview = '&#xE295;',
    PreviewLink = '&#xE12A;',
    Previous = '&#xE100;',
    Print = '&#xE749;',
    Priority = '&#xE182;',
    ProtectedDocument = '&#xE131;',
    Read = '&#xE166;',
    Redo = '&#xE10D;',
    Refresh = '&#xE149;',
    Remote = '&#xE148;',
    Remove = '&#xE108;',
    Rename = '&#xE13E;',
    Repair = '&#xE15E;',
    RepeatAll = '&#xE1CD;',
    RepeatOne = '&#xE1CC;',
    ReportHacked = '&#xE1DE;',
    ResizeTouchNarrowerMirrored = '&#xEA62',
    ReShare = '&#xE1CA;',
    Rotate = '&#xE14A;',
    RotateCamera = '&#xE124;',
    Save = '&#xE105;',
    SaveLocal = '&#xE159;',
    Scan = '&#xE294;',
    Search = '&#xE721',
    SelectAll = '&#xE14E;',
    Send = '&#xE122;',
    SetLockScreen = '&#xE18C;',
    SetTile = '&#xE18D;',
    Setting = '&#xE115;',
    Share = '&#xE72D;',
    Shop = '&#xE14D;',
    ShoppingCart = '&#xE7BF;',
    ShowBcc = '&#xE169;',
    ShowResults = '&#xE15C;',
    Shuffle = '&#xE14B;',
    SlideShow = '&#xE173;',
    SolidStar = '&#xE1CF;',
    Sort = '&#xE174;',
    Stop = '&#xE15B;',
    StopSlideShow = '&#xE191;',
    Street = '&#xE1C3;',
    Switch = '&#xE13C;',
    SwitchApps = '&#xE1E1;',
    Sync = '&#xE117;',
    SyncFolder = '&#xE1DF;',
    Tag = '&#xE1CB;',
    Target = '&#xE1D2;',
    ThreeBars = '&#xE1E8;',
    TouchPointer = '&#xE1E3;',
    Trim = '&#xE12C;',
    TwoBars = '&#xE1E7;',
    TwoPage = '&#xE11E;',
    Underline = '&#xE19A;',
    Undo = '&#xE10E;',
    UnFavorite = '&#xE195;',
    UnPin = '&#xE196;',
    UnSyncFolder = '&#xE1DD;',
    Up = '&#xE110;',
    Upload = '&#xE11C;',
    Video = '&#xE116;',
    VideoChat = '&#xE13B;',
    View = '&#xE18B;',
    ViewAll = '&#xE138;',
    Volume = '&#xE15D;',
    Warning = '&#xE7BA',
    WebCam = '&#xE156;',
    World = '&#xE128;',
    XboxOneConsole = '&#xE990;',
    ZeroBars = '&#xE1E5;',
    Zoom = '&#xE1A3;',
    ZoomIn = '&#xE12E;',
    ZoomOut = '&#xE1A4;',

    Rulers = '&#xEB3C;',
    Connector = '&#xF003;',
    LightningBolt = '&#xE945',
    CalligraphyPen = '&#xEDFB;',
    Label = '&#xE932;',
    Fingerprint = '&#xE928;',
    Unlock = '&#xE785;',
    Completed = '&#xE930;',
    CompletedSolid = '&#xEC61;',
    Info = '&#xE946;',
    InfoSolid = '&#xF167;',
    ErrorBadge = '&#xEA39;',
    Error = '&#xE783',
    MobLocation = '&#xEC43;',
    CloseChrome = '&#xE8BB;',
    ChromeCloseContrast = '&#xEF2C;',
    Close = '&#xE8BB',
    AddTo = '&#xECC8;',
    RemoveFrom = '&#xECC9;',
}
