export interface FieldMap<T, K> {
    sourceField: KeyPaths<T>; // Dot-separated string indicating the path to the source field.
    resultField: KeyPaths<K>; // Dot-separated string indicating the path to the target field.
}

export function mapFields<T, K>(sourceObject: T, fieldsMap: FieldMap<T, K>[]): K {
    // Maps fields from one object to another based on a specified mapping.
    // The user is responsible to gave a correct fieldsMap to generate T from the sourceObject, we cannot type check it :(

    const resObject: K = {} as K;

    fieldsMap.forEach((fieldMap) => {
        const sourceValue = getValue(sourceObject, (fieldMap.sourceField as string).split('/.'));
        setValue(resObject, (fieldMap.resultField as string).split('/.'), sourceValue);
    });

    return resObject;
}

export function reverseMapFields<T, K>(sourceObject: T, fieldsMap: FieldMap<K, T>[]): K {
    // Reverses field mapping directions and uses mapFields to transfer values.
    // This is useful so we don't have to define two FieldMap for swapping object representation back and forth(like A -> B and then B -> A).
    return mapFields<T, K>(
        sourceObject,
        fieldsMap.map((fm) => ({
            sourceField: fm.resultField, // Swap source and result fields for reverse mapping
            resultField: fm.sourceField,
        })),
    );
}

/*
 * Type `KeyPaths<T>` generates a union of string literal types representing the accessible paths to properties within an object type `T`.
 * It avoids traversing properties of specific exclusion types like `Date`, `Array<any>`, and `Function` to prevent excessively deep or irrelevant paths.
 * The paths are dot-separated, representing nested property access in the object.
 *
 * - `KeyPaths<T>`: Entry type alias initiating the recursion.
 * - `KeyPathsRec<T, ExclusionTypes, Prefix>`: Recursive utility type for building paths.
 *   - `T` is the current part of the object being inspected.
 *   - `ExclusionTypes` are types to be excluded from the paths (e.g., functions, dates).
 *   - `Prefix` accumulates the path as a string, adding a dot with each nested property.
 *
 * This type is useful for creating type-safe references to nested properties, for instance in functions that accept object paths as arguments.
 *
 *
 * Example usage:
 *
 * interface Person {
 *     name: string;
 *     age: number;
 *     birthdate: Date;
 *     address: {
 *         street: string;
 *         city: string;
 *     };
 *     hobbies: string[];
 *     updateName: (name: string) => void;
 * }
 *
 * type PersonKeyPaths = KeyPaths<Person>;
 *
 * The type `PersonKeyPaths` would be "name" | "age" | "birthdate" | "address.street" | "address.city" | "hobbies" | "updateName"
 *
 */

type KeyPaths<T> = KeyPathsRec<T, Date | Array<any> | Function, ''>;

type KeyPathsRec<T, ExclusionTypes, Prefix extends string> = T extends object
    ? {
          [K in keyof T]: K extends string
              ? T[K] extends ExclusionTypes
                  ? `${Prefix}${K}`
                  : T[K] extends object
                    ? KeyPathsRec<T[K], ExclusionTypes, `${Prefix}${K}.`>
                    : `${Prefix}${K}`
              : never;
      }[keyof T]
    : never;

function getValue(obj: unknown, keys: string[]): unknown {
    // Retrieves a value from an object based on a list of keys representing nested fields.
    let value = obj;

    for (const k of keys) {
        if (value === null || value === undefined) {
            return undefined; // Returns undefined if any intermediate value is null or undefined
        }
        value = value[k];
    }

    return value;
}

function setValue(obj: unknown, keys: string[], value: unknown): void {
    // Sets a value in an object at a specified path, creating nested objects as necessary.
    if (keys.length === 0) {
        return; // If no keys are provided, exit the function without setting any value
    }

    let current = obj;
    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        if (current[key] === undefined || current[key] === null) {
            current[key] = {}; // Initialize a new object if the current key does not exist
        }
        current = current[key]; // Move to the next level in the path
    }

    // Set the final value at the target key
    const finalKey = keys[keys.length - 1];
    current[finalKey] = value;
}
