export class EgonRequestZip {
    LST = 'LstZip';
    CDXZIP: string;
    MAXLIVLOC = 8;
    TPXEXT = 'L';
    WPXISO = 'ITA';
    constructor(zipCode: string) {
        this.CDXZIP = zipCode;
    }
}

export class EgonRequestCity {
    LST = 'LstCnl';
    DSXCNL: string;
    WPXISO = 'ITA';
    constructor(city: string) {
        this.DSXCNL = city;
    }
}
