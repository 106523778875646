import { Product } from '@congacommerce/ecommerce';
import { Expose, Type } from 'class-transformer';
import { AptCommodityType } from '../../../../enums/apttus/apt-commodity-typeof-sale';
import { AptVatCode } from '../../../../enums/apttus/apt-vat-code';
import { UpperSiNoType } from '../../../app/si-no-type';
import { RecordType } from '../order/option-id';
import { EglAccountLocationLight } from '../user/egl-account-location-light';
import { EglPriceListItem } from './egl-price-list-item';
import { ProductAttributeGroupMemberExtended } from './egl-product-attribute-group-member-extended';
import { EglProductOptionGroup } from './egl-product-option-group';

export class EglProductExtended extends Product {
    constructor() {
        super();
        this.egl_Product_Code = null;
        this.egl_Product_Code = null;
        this.ProductType = null;
        this.UniqueProductCode = null;
        this.egl_shortdescription = null;
        this.egl_subtitle = null;
        this.egl_sellingpoint1_desc = null;
        this.egl_sellingpoint2_desc = null;
        this.egl_sellingpoint3_desc = null;
        this.egl_sellingpoint4_desc = null;
        this.egl_advantage1_title = null;
        this.egl_advantage1_desc = null;
        this.egl_advantage2_title = null;
        this.egl_advantage2_desc = null;
        this.egl_SKU = null;
        this.egl_tax_deduction_id = null;
        this.egl_override_list_price__c = null;
        this.egl_consumer_credit_campaign__c = null;
        this.egl_CheckSaleabilityZip = null;
    }

    @Expose({ name: 'Name' })
    Name: string;

    @Expose({ name: 'Family' })
    Family: string;

    @Expose({ name: 'APTSENI_Commodity' })
    APTSENI_Commodity: AptCommodityType;

    @Expose({ name: 'Apttus_Config2__ExpirationDate__c' })
    ExpirationDate: Date;

    @Expose({ name: 'Apttus_Config2__ProductType__c' })
    ProductType: string;

    @Expose({ name: 'egl_Product_Code__c' })
    egl_Product_Code: string;

    ProductCode: string;

    @Expose({ name: 'UniqueProductCode' })
    UniqueProductCode: string;

    @Expose({ name: 'egl_shortdescription__c' })
    egl_shortdescription: string;

    @Expose({ name: 'egl_subtitle__c' })
    egl_subtitle: string;

    @Expose({ name: 'egl_sellingpoint1_desc__c' })
    egl_sellingpoint1_desc: string;

    @Expose({ name: 'egl_sellingpoint2_desc__c' })
    egl_sellingpoint2_desc: string;

    @Expose({ name: 'egl_sellingpoint3_desc__c' })
    egl_sellingpoint3_desc: string;

    @Expose({ name: 'egl_sellingpoint4_desc__c' })
    egl_sellingpoint4_desc: string;

    @Expose({ name: 'egl_advantage1_title__c' })
    egl_advantage1_title: string;

    @Expose({ name: 'egl_advantage1_desc__c' })
    egl_advantage1_desc: string;

    @Expose({ name: 'egl_advantage2_title__c' })
    egl_advantage2_title: string;

    @Expose({ name: 'egl_advantage2_desc__c' })
    egl_advantage2_desc: string;

    @Expose({ name: 'egl_SKU__c' })
    egl_SKU: string;

    @Expose({ name: 'egl_unique_product_code__c' })
    egl_unique_product_code: string;

    @Expose({ name: 'egl_highlight_desc__c' })
    egl_highlight_desc: string;

    @Expose({ name: 'egl_is_expired__c' })
    egl_is_expired: boolean;

    @Expose({ name: 'Apttus_Config2__OptionGroups__r' })
    @Type(() => EglProductOptionGroup)
    OptionGroups: Array<EglProductOptionGroup>;

    @Expose({ name: 'Apttus_Config2__AttributeGroups__r' })
    @Type(() => ProductAttributeGroupMemberExtended)
    AttributeGroups: Array<ProductAttributeGroupMemberExtended>;

    @Expose({ name: 'egl_IVASS_01__c' })
    egl_IVASS_01: string;

    @Expose({ name: 'egl_IVASS_02__c' })
    egl_IVASS_02: string;

    @Expose({ name: 'egl_IVASS_03__c' })
    egl_IVASS_03: string;

    @Expose({ name: 'egl_IVASS_04__c' })
    egl_IVASS_04: string;

    @Expose({ name: 'egl_IVASS_05__c' })
    egl_IVASS_05: string;

    @Expose({ name: 'egl_floor__c' })
    egl_floor: string;

    @Expose({ name: 'egl_privacy_InsuranceCommunication__c' })
    egl_privacy_InsuranceCommunication: string;

    @Expose({ name: 'egl_privacy_InsuranceCommunication2__c' })
    egl_privacy_InsuranceCommunication2: string;

    @Expose({ name: 'egl_Privacy_Zurich_01__c' })
    egl_Privacy_Zurich_01: string;

    @Expose({ name: 'egl_Privacy_Zurich_02__c' })
    egl_Privacy_Zurich_02: string;

    @Expose({ name: 'egl_Privacy_Zurich_03__c' })
    egl_Privacy_Zurich_03: string;

    @Expose({ name: 'egl_Privacy_Zurich_04__c' })
    egl_Privacy_Zurich_04: string;

    @Expose({ name: 'egl_selling_order_number__c' })
    egl_selling_order_number: string;

    @Expose({ name: 'egl_combined_sale_insurance__c' })
    egl_combined_sale_insurance: string;

    @Expose({ name: 'egl_promotion__c' })
    egl_promotion: string;

    @Expose({ name: 'Apttus_Config2__LocationId__r' })
    @Type(() => EglAccountLocationLight)
    Location: EglAccountLocationLight;

    @Expose({ name: 'RecordTypeId' })
    @Type(() => RecordType)
    RecordType: RecordType;

    @Expose({ name: 'egl_addebitoinbolletta__c' })
    egl_addebitoinbolletta: UpperSiNoType;

    @Expose({ name: 'egl_instore_sales_enablement__c' })
    egl_instore_sales_enablement: UpperSiNoType;

    @Expose({ name: 'egl_codice_iva__c' })
    egl_codice_iva: AptVatCode;

    @Expose({ name: 'egl_product_core__c' })
    egl_product_core: string;

    @Expose({ name: 'egl_billingitem_code__c' })
    egl_billingitem_code: string;

    @Expose({ name: 'Apttus_Config2__PriceLists__r' })
    @Type(() => EglPriceListItem)
    PriceLists: Array<EglPriceListItem>;

    @Expose({ name: 'egl_tax_deduction_id__c' })
    egl_tax_deduction_id: string;

    @Expose({ name: 'egl_override_list_price__c' })
    egl_override_list_price__c: number;

    @Expose({ name: 'egl_ProductFooter_line1' })
    egl_ProductFooter_line1: string;

    @Expose({ name: 'egl_ProductFooter_line2' })
    egl_ProductFooter_line2: string;

    @Expose({ name: 'egl_consumer_credit_campaign__c' })
    egl_consumer_credit_campaign__c: string;

    @Expose({ name: 'egl_credit_assignment__c' })
    egl_credit_assignment: boolean;

    egl_powerKw: string;
    egl_panelsize: string;
    egl_generator_code: string; // Location of the panels
    egl_hrsproduzione_impianto: string;

    @Expose({ name: 'Famiglia_Sconti__c' })
    Famiglia_Sconti: string;

    @Expose({ name: 'egl_CheckSaleabilityZip__c' })
    egl_CheckSaleabilityZip: boolean;
}
