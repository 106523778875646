import {
    AJoin,
    AObjectService,
    CacheService,
    ConfigurationService,
    MetadataService,
    PlatformService,
} from '@congacommerce/core';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EglUser, EglUserQuery } from '../../../../models/apttus/tables/user/egl-user';
import { CommonProvider } from '../../../../providers/common-provider';
import { EglUserLight } from '../../../../models/apttus/tables/user/egl-user-light';
import { EglBulkImportItem } from '../../../../models/apttus/tables/bulk-order/egl-bulk-import-item';
import { DdlElement } from '../../../../interfaces/ddl-element';
import { TranslateService } from '@ngx-translate/core';
import { MemoizeAll } from 'lodash-decorators';

@Injectable({
    providedIn: 'root',
})
export class EglUserService extends AObjectService {
    constructor(
        cacheSrv: CacheService,
        plSrv: PlatformService,
        mtSrv: MetadataService,
        confSrv: ConfigurationService,
        inj: Injector,
        private commonPrv: CommonProvider,
        private translateSrv: TranslateService
    ) {
        super(cacheSrv, plSrv, mtSrv, confSrv, inj);
        this.setType(EglUserLight);
    }

    /**
     * @description Recupera, attraverso query diretta verso apttus,  il federation ID e altri dati necessari per la gestione del login
     * @param userId Id dell'utente
     * @returns Osservabile contenente egl_federationidentifier__c, Name e Id
     */
    @MemoizeAll()
    getFederationId(userId: string): Observable<EglUser> {
        const query = `select Id, egl_federationidentifier__c, Name from User where Id = '${userId}'`;
        const obs = this.commonPrv.query<EglUserQuery>(query.replace(' ', '+'));
        return obs.pipe(
            filter((res: EglUserQuery) => !!res && res.totalSize === 1),
            map((res: EglUserQuery) => res.records[0])
        );
    }

    getFederationIdList(userIds: string): Observable<Map<string, string>> {
        const query = `select Id, egl_federationidentifier__c, Name from User where Id In ${userIds}`;
        const obs = this.commonPrv.query<EglUserQuery>(query.replace(' ', '+'));
        return obs.pipe(
            filter((res: EglUserQuery) => !!res && res.totalSize > 0),
            map((res: EglUserQuery) => {
                let ids = new Map<string, string>();
                res.records.forEach((e) => {
                    ids.set(e.Id, e.egl_federationidentifier__c);
                });
                return ids;
            })
        );
    }

    getBulkOrderUserNames(): Observable<DdlElement[]> {
        return this.query({
            fieldList: ['FirstName', 'LastName'],
            joins: [new AJoin(EglBulkImportItem, 'Id', 'CreatedById')],
        }).pipe(
            map((res: EglUserLight[]) => {
                const arr = res.map((u) => ({
                    name: `${u.FirstName} ${u.LastName}`,
                    value: u.Id,
                }));
                arr.sort((a, b) => a.name.localeCompare(b.name));
                return [
                    {
                        name: this.translateSrv.instant('BULK_ORDER.MAIN.FILTER.ALL'),
                        value: null,
                        selected: true,
                    },
                    ...arr,
                ];
            })
        );
    }
}
