import { AptSalesProcess } from '../../../../common/enums/apttus/apt-sales-process';

export class CheckPdfApttusResponse {
    Result: CheckPdfApttusResponseResult;
    Status: string;
    ErrorMessage?: string;
    ListaProcessi?: CheckPdfApttusResponseListaProcessi[];
}

export class CheckPdfApttusResponseListaProcessi {
    objectType: string;
    id: string;
    salesProcess: AptSalesProcess;
}

export enum CheckPdfApttusResponseResult {
    Success = 'Success',
    Failed = 'Error',
}
