import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'egl-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
    @Input() config: SpinnerConfig;
    constructor() {}

    ngOnInit() {}
}

export interface SpinnerConfig {
    paddingTop?: string;
    paddingBottom?: string;
    paddingRight?: string;
    paddingLeft?: string;
    left?: string;
    position?: string;
}
