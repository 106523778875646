<ng-container *ngIf="cartItems$ | async as cartItems; else loading">
    <div class="dropdown animated fadeIn">
        <button
            class="btn btn-link dropdown-toggle mr-2"
            href="#cartMenu"
            data-toggle="dropdown"
            [innerHTML]="cartIcon"
        ></button>
        <div class="dropdown-menu dropdown-menu-right m-0 p-0" aria-labelledby="dropdownMenuButton" id="cartMenu">
            <ul class="list-group p-0" *ngIf="!!cartItems?.length; else empty">
                <ng-container *ngFor="let item of cartItems; let i = index; trackBy: trackById">
                    <li class="media list-group-item d-flex">
                        <img
                            class="align-self-center"
                            [src]="getImageUrl(item.Product?.IconId)"
                            alt="Generic placeholder image"
                        />
                        <div class="media-body ml-3">
                            <h6 class="m-0">
                                <a
                                    href="javascript:void(0)"
                                    (click)="productDetail(item.Product[identifier], item.Id)"
                                    *ngIf="isItemEditable(getAsAny(item)); else read"
                                >
                                    {{ item.Product?.Name }}
                                </a>
                                <ng-template #read>
                                    {{ item.Product?.Name }}
                                </ng-template>
                            </h6>
                            <span class="d-block">{{ item.Product?.ProductCode }}</span>
                            <div class="d-flex align-items-center">
                                <apt-input-field
                                    *ngIf="isItemQuantity(getAsAny(item))"
                                    [inline]="getAsAny('true')"
                                    [(ngModel)]="item.Quantity"
                                    [entity]="entity"
                                    field="Quantity"
                                    (change)="changeItemQuantity(item)"
                                    [small]="true"
                                    [inline]="true"
                                    [readonly]="item?.LineStatus?.toLowerCase() === 'cancelled'"
                                    class="w-50 mt-2"
                                >
                                </apt-input-field>
                            </div>
                        </div>
                        <div class="ml-auto">
                            <button
                                *ngIf="
                                    item?.IsPrimaryLine &&
                                    !isChangeProduct &&
                                    isNotDiscountItem(getAsAny(item)) &&
                                    !isScontoStandalone
                                "
                                class="btn btn-link p-0 m-0"
                                (click)="removeCartItem(item, $event)"
                                [ladda]="item?._metadata?.deleting"
                                data-style="zoom-in"
                                data-spinner-color="black"
                            >
                                <i class="fas fa-trash"></i>
                            </button>
                        </div>
                    </li>
                </ng-container>

                <li class="footer d-flex align-items-center" *ngIf="!!cartItems?.length && !isScontoStandalone">
                    <button class="cta-secondary" (click)="onClearCartClick()">Svuota</button>

                    <button class="cta-primary" [routerLink]="['/carts', 'active']">
                        {{ 'MINI_CART.VIEW_CART' | translate }}
                    </button>
                </li>
            </ul>

            <ng-template #empty>
                <ul class="list-group p-0">
                    <li class="list-group-item bg-light">
                        {{ 'MINI_CART.YOUR_CART_IS_EMPTY' | translate }}
                    </li>
                </ul>
            </ng-template>
        </div>
    </div>
    <span class="badge badge-primary p-1 animated bounceIn" *ngIf="!!cartItems?.length">{{ cartItems?.length }}</span>
</ng-container>

<ng-template #loading>
    <div class="spinner-border text-secondary" role="status">
        <span class="sr-only"></span>
    </div>
</ng-template>
