export enum D365ProductType {
    Manutenzione = 'TIPO-PROD-MANUTENZIONE',
    Polizza = 'TIPO-PROD-POLIZZA',
    SmartHome = 'TIPO-PROD-SMART-HOME',
    Caldaia = 'TIPO-PROD-CALDAIA',
    ScaldabagnoAGas = 'TIPO-PROD-SCALDACQUA',
    Climatizzatore = 'TIPO-PROD-CLIMATIZZATORE',
    PompeDiCalore = 'TIPO-PROD-POMPE-CALORE',
    Wallbox = 'TIPO-PROD-WALLBOX',
    Sopralluogo = 'TIPO-PROD-SOPRALLUOGO',
}
