import { AField, AObject, ATable } from '@congacommerce/core';
import { Type } from 'class-transformer';
import { EglAddress } from '../address/egl-address';
import { AptProductType } from '../../../../enums/apttus/apt-product-type';
import { EglOptionId } from '../order/option-id';
import { EglAccountLight } from '../user/egl-account-light';
import { EglAssetAttributeValue } from '../pav/egl-asset-pav';
import { AptProductFamily } from '../../../../enums/apttus/apt-product-family';
import { EglProductExtended } from '../product/egl-product-extended';
import { EglAccountLocationLight } from './egl-account-location-light';
import { AptLineStatus } from '../../../../enums/apttus/apt-line-status';

@ATable({
    sobjectName: 'Apttus_Config2__AssetLineItem__c',
    aqlName: null,
})
export class EglAssetLineItem extends AObject {
    constructor() {
        super();
    }

    @AField({ soql: 'egl_Conto_Cliente' })
    egl_Conto_Cliente: string;

    @AField({ soql: 'egl_pdf' })
    egl_pdf: string;

    @AField({ soql: 'egl_POD_PDR' })
    egl_POD_PDR: string;

    @AField({ soql: 'egl_isTerminable' })
    egl_isTerminable: string;

    @AField({
        soql: 'egl_service_address_id',
        expand: 'shallow',
    })
    @Type(() => EglAddress)
    egl_service_address: EglAddress;

    @AField({
        soql: 'Apttus_Config2__AccountId',
        expand: 'shallow',
    })
    @Type(() => EglAccountLight)
    Apttus_Config2__AccountId: EglAccountLight;

    @AField({
        soql: 'Apttus_Config2__OptionId',
        expand: 'shallow',
    })
    @Type(() => EglOptionId)
    Apttus_Config2__OptionId: EglOptionId;

    @AField({ soql: 'ProductType' })
    ProductType: AptProductType;

    @AField({ soql: 'egl_classification_64_09' })
    egl_classification_64_09: string; // Ateco Classificazione 64/09

    @AField({ soql: 'Apttus_Proposal__Account__r', aql: 'Account', expand: 'deep' })
    @Type(() => EglAccountLight)
    Account: EglAccountLight;

    @AField({ soql: 'Apttus_Config2__AttributeValueId__c', aql: 'AttributeValue', expand: 'deep' })
    @Type(() => EglAssetAttributeValue)
    AttributeValue: EglAssetAttributeValue;

    @AField({ soql: 'egl_Product_Family' })
    egl_Product_Family: AptProductFamily;

    @AField({ soql: 'EffectiveDate' })
    EffectiveDate: string;

    @AField({ soql: 'StartDate' })
    StartDate: string;

    @AField({ soql: 'OriginalStartDate' })
    OriginalStartDate: string;

    @AField({ soql: 'egl_signed_date' })
    egl_signed_date: string;

    @AField({ soql: 'ProductId' })
    ProductId: string;

    @AField({ soql: 'egl_supply_code' })
    egl_supply_code: string;

    @AField({ soql: 'egl_supply_asset_id' })
    egl_supply_asset_id: string;

    @AField({
        soql: 'Apttus_Config2__LocationId__r',
        aql: 'Location',
        expand: 'deep',
    })
    @Type(() => EglAccountLocationLight)
    Location: EglAccountLocationLight;

    @AField({ soql: 'Apttus_Config2__ProductId__r', aql: 'Product2', expand: 'deep' })
    @Type(() => EglProductExtended)
    Product: EglProductExtended;

    @AField({ soql: 'Apttus_Config2__AssetStatus__c' })
    AssetStatus: AptLineStatus;

    @AField({ soql: 'Apttus_Config2__ProductId__r', aql: 'Product2', expand: 'deep' })
    @Type(() => EglProductExtended)
    Option: EglProductExtended;

    @AField({ soql: 'egl_customer_code' })
    egl_customer_code: string;
}
