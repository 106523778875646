export class GetAssetReportViewsRequest {
    searchedPdfPodPdr: string;
    searchType: PdfPodPdr;
}

export enum PdfPodPdr {
    Pdf = 'pdf',
    Pod = 'pod',
    Pdr = 'pdr',
}
