import { Injectable, Injector } from '@angular/core';
import {
    AObjectService,
    CacheService,
    ConfigurationService,
    MetadataService,
    PlatformService,
} from '@congacommerce/core';
import { EglAssetLineItem } from '../../../../models/apttus/tables/user/egl-asset-line-item';
@Injectable()
export class EglAssetLineItemService extends AObjectService {
    constructor(
        cacheSrv: CacheService,
        plSrv: PlatformService,
        mtSrv: MetadataService,
        confSrv: ConfigurationService,
        inj: Injector
    ) {
        super(cacheSrv, plSrv, mtSrv, confSrv, inj);
        this.setType(EglAssetLineItem);
    }
}
