import { AField, AObject, ATable } from '@congacommerce/core';
import { Type } from 'class-transformer';

@ATable({
    sobjectName: 'egl_Bulk_Import__c',
    aqlName: null,
})
export class EglBulkImportItem extends AObject {
    constructor() {
        super();
    }

    @AField({ soql: 'Id' })
    Id: string;

    @AField({ soql: 'Name' })
    Name: string;

    @AField({ soql: 'egl_LinkStorage' })
    egl_LinkStorage: string;

    @AField({ soql: 'CreatedDate' })
    CreatedDate: Date;

    @AField({ soql: 'egl_sales_process' })
    egl_sales_process: string;

    @AField({ soql: 'egl_Status' })
    egl_Status: string;

    @AField({ soql: 'egl_user' })
    egl_user: string;
   
    @AField({ soql: 'egl_federationIdentifier' })
    egl_federationIdentifier: string;

    @AField({
        soql: 'CreatedBy',
        expand: 'shallow',
    })
    @Type(() => CreatedBy)
    CreatedBy: CreatedBy;
}

@ATable({
    sobjectName: 'CreatedBy',
    aqlName: null,
})
export class CreatedBy extends AObject {
    constructor() {
        super();
    }

    @AField({ soql: 'FirstName' })
    FirstName: string;

    @AField({ soql: 'LastName' })
    LastName: string;
}
