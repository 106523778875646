<button
    type="button"
    class="egl-radiobutton-highlighted"
    (click)="onClick($event)"
    [class.egl-radiobutton-highlighted--checked]="checked"
    [ngClass]="
        disabled
            ? 'egl-radiobutton-highlighted--disabled'
            : layout === 'Errore'
            ? 'egl-radiobutton-highlighted--error'
            : layout === 'Blu'
            ? 'egl-radiobutton-highlighted--light-blue'
            : ''
    "
>
    {{ label }}
</button>

<!-- 
    [class.egl-radiobutton-highlighted--checked]="checked"

    [ngClass]="
        disabled
            ? 'egl-radiobutton-highlighted--disabled'
            : layout === 'Errore'
            ? 'egl-radiobutton-highlighted--error'
            : layout === 'Blu'
            ? 'egl-radiobutton-highlighted--light-blue'
            : ''
    "
 -->
