import { Injectable } from '@angular/core';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { forkJoin, Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { EglState } from '../../../store/reducers';
import { D365Service } from '../d365/d365.service';
import { D365CustomerSegment } from '../../enums/d365/d365-customer-segment';
import { setCartSegment } from '../../../store/actions/user.actions';
import { EglCartExtendedService } from '../apttus/tables/cart/egl-cart-extended.service';
import { EglCartLightService } from '../apttus/tables/cart/egl-cart-light.service';
import {
    convertSegmentD365toApt,
    d365OperationModeToFlowType,
    flowTypeToAptSalesProcess,
    getNumberOrNull,
} from '../../functions/remap.functions';
import { selectAgentInfo } from '../../../store/selectors/user.selectors';
import { setFlowType } from '../../../store/actions/order-entry.actions';
import { D365OperationMode } from '../../enums/d365/d365-operation-mode';

@Injectable({
    providedIn: 'root',
})
export class ChimeraService {
    constructor(
        private store: Store<EglState>,
        private eglCartExtendedSrv: EglCartExtendedService,
        private eglCartLightSrv: EglCartLightService,
        private d365Service: D365Service
    ) {}

    public updateSegment(segment: D365CustomerSegment, needsNewCart?: boolean): Observable<void>;
    public updateSegment(segment: string, needsNewCart?: boolean): Observable<void>;
    public updateSegment(rawSegment: D365CustomerSegment | string, needsNewCart: boolean = false): Observable<void> {
        const segment: D365CustomerSegment =
            typeof rawSegment === 'string' ? (getNumberOrNull(rawSegment) as D365CustomerSegment) : rawSegment;
        if (!segment) {
            return throwError(() => new Error(`Segmento non gestito`));
        }
        this.store.dispatch(setCartSegment({ payload: segment }));
        return forkJoin([
            needsNewCart
                ? this.eglCartExtendedSrv.checkAndCreateCart(segment)
                : this.eglCartLightSrv.updateCartSegment(convertSegmentD365toApt(segment)),
            this.store.select(selectAgentInfo).pipe(
                take(1),
                map((currentAgentInfo) => currentAgentInfo.SystemUserId),
                mergeMap((sysUserId) => this.d365Service.setUserConfiguration(sysUserId, segment))
            ),
        ]).pipe(map(() => null));
    }

    public updateOperationMode(operationMode: D365OperationMode): Observable<void>;
    public updateOperationMode(operationMode: string): Observable<void>;
    public updateOperationMode(rawOperationMode: D365OperationMode | string): Observable<void> {
        const operationMode: D365OperationMode =
            typeof rawOperationMode === 'string'
                ? (getNumberOrNull(rawOperationMode) as D365OperationMode)
                : rawOperationMode;
        const flowType = d365OperationModeToFlowType(operationMode);
        // Verifico che l'operationMode ricevuto sia censito e convertibile in flowType altrimenti errore
        if (!flowType) {
            return throwError(() => new Error(`OperationMode non gestito`));
        }
        const salesProcess = flowTypeToAptSalesProcess(flowType);
        // Salvo il salesProcess nel Cart e attendo che il Cart sia sincronizzato
        return this.eglCartLightSrv.updateCartSalesProcess(salesProcess).pipe(
            tap(() => {
                // Salvo il flowType nello state
                this.store.dispatch(setFlowType({ flowType: flowType }));
            }),
            map(() => null)
        );
    }
}
