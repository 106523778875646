<ng-container>
    <div class="user-container" (click)="openSettings()">
        <div class="user-badge">
            <span>{{ initials }}</span>
        </div>
        <div class="user-data">
            <div class="user-data_channel">{{ (channel$ | async) || '--' }}</div>
            <div class="user-data_segment">{{ segment || '--' }}</div>
        </div>
    </div>
    <!--<div class="row">
        <p class="user-badge">
            <span>{{ initials }}</span>
        </p>

        <div class="col-8 user-details" *ngIf="fullName" (click)="openSettings()">
            <div class="nome">
                <strong>{{ fullName }}</strong>
            </div>
            <div class="matricola"></div>
            <div class="agent-type">{{ agentType }}</div>
        </div>
    </div>-->
</ng-container>
