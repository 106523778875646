import { ACondition, AFilter, AObjectService } from '@congacommerce/core';
import { EglProductCreditCheckRule } from '../../../../models/apttus/tables/product/egl-product-credit-check-rule';
import { Product } from '../../../../../store/models/order-entry-state_v2';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class EglProductCreditCheckRuleService extends AObjectService {
    type = EglProductCreditCheckRule;

    shouldExecuteCreditCheck(products: Product[]): Observable<boolean> {
        const rulesApiCalls$: Observable<EglProductCreditCheckRule[]>[] = products.map((product) =>
            this.query({
                filters: [
                    new AFilter(
                        EglProductCreditCheckRule,
                        [
                            new ACondition(EglProductCreditCheckRule, 'egl_Product_Type', 'Equal', product.productType),
                            new ACondition(
                                EglProductCreditCheckRule,
                                'egl_Payment_Type',
                                'Equal',
                                product.paymentInfo.paymentType
                            ),
                            new ACondition(
                                EglProductCreditCheckRule,
                                'egl_Payment_Instrument',
                                'Equal',
                                product.paymentInfo.paymentInstrument
                            ),
                        ],
                        null,
                        'AND'
                    ),
                ],
            })
        );
        return forkJoin(rulesApiCalls$).pipe(
            map((responses) => responses.reduce((aggr, currentArray) => [...aggr, ...currentArray], [])),
            map((rules: EglProductCreditCheckRule[]) => (rules || []).some((rule) => rule.egl_Credit_Check_Required))
        );
    }
}
