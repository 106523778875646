import * as fromUser from './user.selectors';
import * as fromOrder from './order-entry.selectors';
import * as fromApp from './app.selectors';
import { createSelector } from '@ngrx/store';
import { D365ChannelCode } from '../../common/enums/d365/d365-channel-code';
import { FullState } from '../models/full-state';
import { v2OrderEntryState } from './order-entry-v2.selectors';
import { getFullAddressString } from '../../common/functions/address.functions';
import { ContactLeadInfo } from '../models/select-contact-lead';

export const selectSaveSupState = createSelector(fromUser.selectUserState, v2OrderEntryState, (user, orderEntry) => ({
    user,
    orderEntry,
}));

export const selectFullState = createSelector(
    fromUser.selectUserState,
    fromOrder.selectOrderEntryState,
    v2OrderEntryState,
    fromUser.selectCurrentVirtualAgent,
    fromOrder.selectIsDigitalCommunications,
    fromOrder.selectSalesProcess,
    fromOrder.selectDeferredSaleStatus,
    (
        user,
        orderEntry,
        orderEntryV2,
        selectedVirtualAgent,
        isDigitalCommunications,
        salesProcess,
        isDelayInsertion
    ): FullState => ({
        user,
        orderEntry,
        orderEntryV2,
        selectedVirtualAgent,
        isDigitalCommunications,
        salesProcess,
        isDelayInsertion,
    })
);

export const selectCurrentVaProductTypes = createSelector(
    fromUser.selectCurrentVirtualAgent,
    fromOrder.selectProductsTypes,
    (va, types) => ({ currentVirtualAg: va, productsTypes: types.allTypes })
);

export const selectChannelAndProfile = createSelector(
    fromUser.selectChannelCode,
    fromUser.selectAgentInfo,
    (ch, ag) => ({
        channel: ch as D365ChannelCode,
        agProfileCode: ag.ProfileCode,
    })
);

export const selectOcrFeedback = createSelector(
    fromOrder.selectOcrData,
    fromUser.selectContactLead,
    fromOrder.selectPod,
    fromOrder.selectPdr,
    (ocr, contactLead: ContactLeadInfo, pod: string, pdr: string) => ({
        name: contactLead?.contact?.firstname,
        lastname: contactLead?.contact?.lastname,
        mobilephone: contactLead?.contact?.mobilephone,
        email: contactLead?.contact?.emailaddress1,
        cf: contactLead?.contact?.egl_taxcode,
        pod,
        pdr,
        ocrData: ocr,
    })
);

export const selectSceltaFirmaData = createSelector(
    fromUser.selectCurrentVirtualAgent,
    fromUser.selectAgentInfo,
    fromApp.selectApplicationLocation,
    fromOrder.selectOrderEntryState,
    fromOrder.selectProductsInfo,
    (cva, agi, locat, orderState, prods) => ({
        currentVa: cva,
        agentInfo: agi,
        firma: orderState.firma,
        prodInfos: prods,
        appLocation: locat,
        plicoCode: orderState.numeroPlico,
        skipPod: orderState.skipPod,
        skipPdr: orderState.skipPdr,
        skipAteco: orderState.skipAteco,
        flowType: orderState.flowType,
        quoteStateModel: orderState.quoteStateModel,
    })
);

// export const selectIsPlicoApiStubbed = createSelector(
//     fromApp.selectFlowTypesWithStubbedApi,
//     fromOrder.selectFlowType,
//     (flowTypesWithStubbedApi: string[], flowType: FlowType) => {
//         const stubbedFlowTypes = (flowTypesWithStubbedApi || []).map((ft: string) => ft.toUpperCase());
//         if (stubbedFlowTypes.indexOf('ALL') !== -1 || stubbedFlowTypes.indexOf(flowType.toUpperCase()) !== -1) {
//             return true;
//         }
//         return false;
//     }
// );

export const selectTerminationData = createSelector(
    fromOrder.selectOrderEntryState,
    fromUser.selectContact,

    (oe, contact) => ({
        productsInfo: oe.infoProdotti,
        isMortisCausa: oe.termination?.isMortisCausa || false,
        termDate: oe.termination?.termDate,
        termAppointment: oe.termination?.termAppointment,
        termCosts: oe.termination?.termCosts,
        contact: contact,
        indirizzi: oe.indirizzi,
        commAddress: getFullAddressString(oe.indirizzi?.indirizzoComunicazioni) || '',
        customerCode: contact?.egl_customercode || '',
    })
);
