<p class="egl-new-convert-cart-to-quote-test-page__title">ConvertCartToQuote V2</p>
<!-- <input type="checkbox" id="isV1" name="isV1" [(ngModel)]="cart" /> -->
<!-- <label for="isV1">&nbsp;Call v1</label> -->

<textarea
    rows="4"
    class="egl-new-convert-cart-to-quote-test-page__modal"
    [(ngModel)]="textareaState"
    placeholder="Put here your salesup state OR your cartId in the following format -> '#cart_id' (eg: #a4C3O00000060aGUAQ)"
></textarea>

<div class="egl-new-convert-cart-to-quote-test-page__button-container">
    <input
        (click)="mapRequest()"
        type="submit"
        class="egl-new-convert-cart-to-quote-test-page__button"
        value="Dispatch, Create Req"
    />
    <input
        (click)="createQuote()"
        type="submit"
        class="egl-new-convert-cart-to-quote-test-page__button"
        value="Create Quote"
    />
</div>
