export class MuicipalityPodConfiguration {
    TransferBlockFeatureFlag: boolean;
    PODWarningFeatureFlag: boolean;
    Municipalities: Municipality[];
    OperativeMode: string[];

    constructor(data: Partial<MuicipalityPodConfiguration>) {
        this.TransferBlockFeatureFlag = data.TransferBlockFeatureFlag || false;
        this.PODWarningFeatureFlag = data.PODWarningFeatureFlag || false;
        this.Municipalities = data.Municipalities || [];
        this.OperativeMode = data.OperativeMode || [];
    }
}

export class Municipality {
    ISTAT: string;
    CAP: string;

    constructor(data: Partial<Municipality>) {
        this.ISTAT = data.ISTAT || '';
        this.CAP = data.CAP || '';
    }
}
