<ng-container *ngIf="isBarVisible | async">
    <div class="egl-contact-bar">
        <span [hidden]="!customerName">
            <div class="egl-contact-bar__inline">
                <p [ngClass]="flowTypeLabel ? 'egl-contact-bar__both__banner' : 'egl-contact-bar__paragraph'">
                    {{ 'ORDER_ENTRY.CONTACT_BAR.DESCRIPTION' | translate }}
                </p>
                <p
                    class="egl-contact-bar__info-container egl-contact-bar__info-container--beige"
                    [title]="customerName?.toUpperCase()"
                >
                    <span class="egl-contact-bar__icon-container egl-contact-bar__icon-container--beige">
                        <egl-icon [path]="'single-user'" [width]="11" [height]="11"></egl-icon>
                    </span>
                    <span class="egl-contact-bar__text-content" (click)="!!leadId && openModal()">
                        {{ customerName }}
                    </span>
                </p>
            </div>
        </span>

        <span [hidden]="!flowTypeLabel">
            <div class="egl-contact-bar__inline">
                <p [ngClass]="customerName ? 'egl-contact-bar__both__banner' : 'egl-contact-bar__paragraph'">
                    {{ 'ORDER_ENTRY.CONTACT_BAR.OPERATIVE_MODE' | translate }}
                </p>
                <p class="egl-contact-bar__info-container egl-contact-bar__info-container--blue">
                    <span class="egl-contact-bar__icon-container egl-contact-bar__icon-container--blue">
                        <egl-icon [path]="'flag'" [width]="11" [height]="11" [iconColor]="'egl-icon--white'"></egl-icon>
                    </span>

                    <span class="egl-contact-bar__text-content" [title]="flowTypeLabel">
                        {{ flowTypeLabel }}
                    </span>
                </p>
            </div>
        </span>
    </div>
</ng-container>

<ng-template #leadResponseModal>
    <egl-modal
        [modalRef]="modalRef"
        [title]="modalTitle"
        [description]="modalDescription"
        [txtBtnDx]="txtBtnDx"
        [txtBtnSx]="txtBtnSx"
        (btnDxClick)="esitaLead()"
    ></egl-modal>
</ng-template>
