import { Pipe, PipeTransform } from '@angular/core';
import { AptProductType } from '../enums/apttus/apt-product-type';
@Pipe({
    name: 'filterDiscountProducts',
    pure: false,
})
export class FilterDiscountProdutsPipe implements PipeTransform {
    transform(items: { productType: AptProductType }[]): any {
        if (!items?.length) return items;
        return items.filter(
            (item) =>
                ![AptProductType.ScontoStandAloneGas, AptProductType.ScontoStandAloneLuce].includes(item?.productType)
        );
    }
}
