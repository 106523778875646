import { getTransactionId } from '../../../../common/functions/misc.functions';

export class BaseTibcoRequest {
    InfoLog: InfoLog;

    constructor() {
        this.InfoLog = new InfoLog();
    }
}

class InfoLog {
    timestamp?: string;
    sourceSystem?: string;
    transactionId?: string;
    sessionId?: string;
    correlationId?: string;
    userId?: string;

    constructor() {
        this.timestamp = new Date().toString();
        this.sourceSystem = 'SALESUP';
        this.transactionId = getTransactionId();
    }
}
