import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AptSalesProcess } from '../../common/enums/apttus/apt-sales-process';
import { BoCommonFilters } from '../../modules/back-office/models/bo-common-filters';
import { GestioneConditions } from '../../modules/back-office/models/gestione-filter-list';
import { BoState } from '../models/bo-state';

const getSelectFeature = createFeatureSelector<BoState>('bo');
export const selectBoState = createSelector(getSelectFeature, (state: BoState) => state);
export const selectOpMode = createSelector(getSelectFeature, (state: BoState) => state.selectedOpMode);
export const selectMacroOpMode = createSelector(getSelectFeature, (state: BoState) => state.selectedMacroOpMode);
export const selectMostUsedAction = createSelector(getSelectFeature, (state: BoState) => state.selectedMostUsedAction);
export const selectInamissibilityMode = createSelector(
    getSelectFeature,
    (state: BoState) => state.selectedInamissibilityMode,
);
export const selectedCommodity = createSelector(getSelectFeature, (state: BoState) => state.selectedCommodity);

export const selectMonitoraggioFilters = createSelector(getSelectFeature, (state: BoState) => state.monitoraggioFilter);

const selectGestioneFilters = createSelector(getSelectFeature, (state: BoState) => state.gestioneFilter);
export const selectGestioneFilterBar = createSelector(
    selectGestioneFilters,
    selectOpMode,
    (conds: GestioneConditions, operativeMode: AptSalesProcess) => ({
        conds,
        operativeMode,
    }),
);
export const selectCarrelliFilterBar = createSelector(getSelectFeature, (state: BoState) => state.carrelliFilter);
export const selectCurrentTab = createSelector(getSelectFeature, (state: BoState) => state.selectedTab);

const selectCommonCondition = createSelector(getSelectFeature, (state: BoState): BoCommonFilters => {
    const filters = state.monitoraggioFilter || state.gestioneFilter;
    return {
        agencyBranch: filters?.agencyBranch,
        agent: filters?.agent,
        channels: filters?.channels,
        virtualAgencies: filters?.virtualAgencies,
    };
});

export const selectAgentFilter = createSelector(selectCommonCondition, (state: BoCommonFilters) => state.agent);
export const selectAgencyFilter = createSelector(selectCommonCondition, (state: BoCommonFilters) => state.agencyBranch);
export const selectChannelFilter = createSelector(selectCommonCondition, (state: BoCommonFilters) => state.channels);
export const selectVirtualAgencyFilter = createSelector(
    selectCommonCondition,
    (state: BoCommonFilters) => state.virtualAgencies,
);

export const selectMonitoraggioFilterBar = createSelector(
    selectMonitoraggioFilters,
    selectOpMode,
    selectInamissibilityMode,
    selectedCommodity,
    selectMacroOpMode,
    (conds, operativeMode, inamissibilityMode, commodityType, macroOperativeMode) => ({
        conds,
        operativeMode,
        inamissibilityMode,
        commodityType,
        macroOperativeMode,
    }),
);

export const selectBOQuoteId = createSelector(getSelectFeature, (state: BoState) => state.selectedQuoteId);
export const selectMassiveActionsFilters = createSelector(getSelectFeature, selectOpMode, (state, operativeMode) => ({
    ...(state.massiveActionsFilters || {}),
    operativeMode,
}));

export const selectBillInstalmentSelectedBill = createSelector(
    getSelectFeature,
    (state: BoState) => state.billInstalmentSelectedBill,
);
export const selectBillInstalmentClientAccountCode = createSelector(
    getSelectFeature,
    (state: BoState) => state.billInstalmentClientAccountCode,
);
