export enum AptPaymentInstrument {
    AddebitoCC = 'Addebito su Conto Corrente',
    Bollettino = 'Bollettino Postale',
    CartaCredito = 'Addebito su Carta di Credito',
    Altro = 'Altro (pagopa,portale Web,Bonifico semplice)',
    CreditoAlConsumo = 'Credito al Consumo',
    BonificoCessione = 'Bonifico Cessione del Credito',
    AvvisoDiPagamento = 'Avviso di pagamento',
    BonificoDetrazioneFiscale = 'Bonifico Detrazione Fiscale',
    BonificoDetrazioneFiscaleVistaUnica = 'Bonifico detrazione fiscale',
}
