<ng-container>
    <div class="order-entry-title__container" [hidden]="!title && !description">
        <div class="order-entry-title__text">
            <h2 class="order-entry-title__title" [hidden]="!title || !(title | autoFormat)" [innerHtml]="title | autoFormat"></h2>
            <p class="order-entry-title__description" [hidden]="!description || !(description | autoFormat)" [innerHtml]="description | autoFormat">
            
            </p>
        </div>
        <ng-content></ng-content>
    </div>
</ng-container>
