export enum DestinationUse {
    Home = 'Domestico',
    Appurtenances = 'Pertinenze',
}
/**
 * @description Nome del campo nella checkItems per passare il DestinationUse
 */
export const TYPE_OF_USE_APTTUS_FIELD = 'typeOfUse';

/**
 * @description egl_supply_use__c egl_power_typesofusage__c (campo formula) - egl_typeofusagepower_disco__c (campo grezzo, non usare lato SUP)
 */
export enum SupplyUse {
    DomesticoResidenziale = 'Domestico residente',
    DomesticoNonResidenziale = 'Domestico non residente',
    AltriUsi = 'Altri Usi',
    ColonnineDiRicaricaElettrica = 'Colonnine di ricarica elettrica',
    IlluminazionePubblica = 'Illuminazione pubblica',
}

// Campi decisi da GTW, modificare solo dopo passaggio con loro!!!
export enum SupplyUsePreventivo {
    DomesticoResidenziale = 'DOMESTICO RESIDENTE',
    DomesticoNonResidenziale = 'DOMESTICO NON RESIDENTE',
    IlluminazionePubblica = 'ILLUMINAZIONE PUBBLICA',
    AltriUsi = 'ALTRI USI',
}
