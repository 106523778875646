import { Injectable } from '@angular/core';
import { FlowType } from '../../../store/models/flow-type';

export enum Questions {
    ANY = 0b0000000000,

    // 'Luce?'
    POWER = 0b0000000001,

    // 'Gas?'
    GAS = 0b0000000010,

    // 'Il cliente intende attivare la fornitura per una pertinenza?'
    IS_BASEMENT = 0b0000000100,

    // 'Il cliente ha il contatore?'
    HAS_METER = 0b00000001000,

    // 'La tua fornitura è attiva?'
    IS_ACTIVE = 0b00000010000,

    // 'Il fornitore è Eni Plenitude?'
    IS_EGL = 0b00000100000,

    // 'Il contratto è intestato a te?'
    ACCOUNT_OWNERSHIP = 0b00001000000,

    // 'È allacciato alla rete?'
    // "C'è l'attacco per il contatore (mensola)?"
    IS_CONNECTED = 0b00100000000,

    // "L'impianto è stato recentemente costruito e mai utilizzato?"
    NEVER_USED = 0b01000000000,

    // "Il cliente ha modificato l'impianto esistente?"
    MODIFIED_WIRING = 0b10000000000,
}

@Injectable()
export class TroubleshootingValuesService {
    constructor() {
        // PER FAVORE NON AGGIUNGERE IL FLOWTYPE! NON HA SENSO!
    }

    public readonly salesProcessesQuestions: SalesProcessesQuestionsType = {
        [FlowType.SwitchIn]: (answer) =>
            answer >> 3 === (Questions.IS_ACTIVE + Questions.ACCOUNT_OWNERSHIP) >> 3 ||
            answer === Questions.POWER + Questions.HAS_METER + Questions.IS_ACTIVE ||
            answer === Questions.GAS + Questions.HAS_METER + Questions.IS_ACTIVE,
        [FlowType.Attivazione]: (answer) =>
            answer === Questions.ANY || answer === Questions.POWER + Questions.IS_BASEMENT,

        [FlowType.CPCommerciale]: (answer) =>
            answer >> 2 === (Questions.IS_ACTIVE + Questions.ACCOUNT_OWNERSHIP + Questions.IS_EGL) >> 2,
        [FlowType.VolturaCambioFornitore]: (answer) => answer >> 3 === Questions.IS_ACTIVE >> 3,

        [FlowType.Voltura]: (answer) =>
            [Questions.ANY, Questions.HAS_METER >> 2].includes(
                (answer ^ (Questions.IS_ACTIVE + Questions.IS_EGL)) >> 2
            ),
        [FlowType.AttivazioneA40]: (answer) =>
            [
                [Questions.NEVER_USED, Questions.MODIFIED_WIRING],
                [Questions.IS_CONNECTED, Questions.HAS_METER],
            ].reduce(
                (res, questions) => res && questions.some((question) => (answer & question) === question),
                (answer & Questions.GAS) === Questions.GAS
            ),
        [FlowType.AttivazioneGas]: (answer) =>
            (answer & (Questions.GAS + Questions.HAS_METER)) === Questions.GAS + Questions.HAS_METER ||
            answer === Questions.GAS + Questions.IS_CONNECTED,
        [FlowType.AttivazionePN1]: (answer) => answer === Questions.GAS,
        [FlowType.AttivazionePwrS01]: (answer) => answer === Questions.POWER + Questions.HAS_METER,
        [FlowType.AttivazionePwrA01]: (answer) =>
            [
                Questions.HAS_METER + Questions.NEVER_USED,
                Questions.IS_CONNECTED,
                Questions.HAS_METER + Questions.MODIFIED_WIRING,
            ].some((question) => answer === Questions.POWER + question),
        [FlowType.AttivazioneN02]: (answer) => answer === Questions.POWER,
    };

    public matchSalesProcess(answer: number): FlowType {
        return typeof answer !== 'number'
            ? null
            : ((Object.entries(this.salesProcessesQuestions).find(([, matcher]) => matcher(answer)) ||
                  [])[0] as FlowType) || null;
    }
}

export type SalesProcessesQuestionsType = {
    [key in FlowType]?: (Number) => boolean;
};
