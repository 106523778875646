export enum D365ChannelCode {
    AgenteSingolo = 'AGS',
    Agenzia = 'AGE',
    Dealer = 'DEA',
    Flagship = 'FLA',
    NegoziESE = 'ESE',
    AgenziaMicrobusiness = 'AGM',
    TelesellingInbound = 'TLI',
    TelesellingOutbound = 'TLO',
    WebAssistito = 'WAS',
    ComparatoreWeb = 'CWE',
    InsurancePartnerTeam = 'IPT',
    WebPull = 'WPU',
    Figitel = 'FIT',
    ExtraCare = 'EXC',
}

export enum ChannelCodeInverse {
    AGS = 'Agente Singolo',
    AGE = 'Agenzia',
    DEA = 'Dealer',
    FLA = 'Flagship',
    ESE = 'Negozi ESE',
    AGM = 'Agenzia Microbusiness',
    TLI = 'Teleselling Inbound',
    TLO = 'Teleselling Outbound',
    WAS = 'Web Assistito',
    CWE = 'Comparatore Web',
    IPT = 'Insurance Partner Team',
    WPU = 'Web Pull',
    FIT = 'Figitel',
    EXC = 'Extra care',
}

export enum ChannelCase {
    Chat = '281820001',
    Telefono = '1',
    Fax = '281820005',
    Email = '2',
    Posta = '281820006',
    PEC = '281820003',
    Web = '3',
    App = '281820007',
    Chatbot = '281820008',
    Facebook = '2483',
    ESE = '281820009',
    Flagship = '281820010',
    Plenitude = '4',
    IVR = '100000000',
    AssistenteVirtuale = '100000001',
}

export enum DescriptionChannelCase {
    FAX = '100000000',
    NC_FAX = '100000001',
    EMAIL = '100000002',
    NC_EMAIL = '100000003',
    SCANNER = '100000004',
    ES_SCANNER = '100000005',
}

export const REMOTE_CHANNELS = [
    D365ChannelCode.ExtraCare,
    D365ChannelCode.TelesellingInbound,
    D365ChannelCode.TelesellingOutbound,
    D365ChannelCode.ComparatoreWeb,
    D365ChannelCode.WebAssistito,
    D365ChannelCode.Figitel,
];
