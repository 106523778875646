import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Observable, of, throwError, TimeoutError } from 'rxjs';
import { catchError, map, take, timeout, switchMap, mergeMap, tap } from 'rxjs/operators';
import { LoggerService } from './logger.service';
import { LoadingService } from './loading.service';
import { AppConfig } from '../../models/app/app-config';
import { environment } from '../../../../environments/environment';
import { ApiService, ConfigurationService, PlatformConstants } from '@congacommerce/core';
import { isNil } from 'lodash';
import { PrivateConfigurationService } from './private-configuration.service';
import { getTransactionId } from '../../functions/misc.functions';
import { OAuthService } from '../oauth/oauth-services';
import { ifOp } from '../../functions/observable-operators';

@Injectable()
export class HttpRequestInterceptorService implements HttpInterceptor {
    private _privateConfig: AppConfig;
    constructor(
        private logger: LoggerService,
        private aptConfigService: ConfigurationService,
        private apiService: ApiService,
        private authService: OAuthService,
        private configSrv: PrivateConfigurationService
    ) {}

    /**
     * @return HttpEvent
     * @param request: HttpRequest
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.configInit();
        const showLoading = this.showLoading(request);
        const timeoutRequest = this._privateConfig?.httpRequestTimeout || 60000;
        return this.addHeaders(request).pipe(
            take(1),
            tap((request) => {
                this.traceHttpEvent(request);
            }),
            mergeMap((req: HttpRequest<any>) =>
                next.handle(req).pipe(
                    tap((httpEvent) => {
                        this.traceHttpEvent(<HttpResponse<any>>httpEvent);
                    }),
                    timeout(timeoutRequest),
                    catchError((error) => {
                        this.traceHttpEvent(error);
                        if (error instanceof HttpErrorResponse) {
                            if (error?.status === 0 && error.url.includes('apttus')) {
                                localStorage.removeItem(PlatformConstants.ACCESS_TOKEN);
                                return this.apiService.refreshToken().pipe(
                                    switchMap((res) => {
                                        req = req.clone({
                                            headers: req.headers.set('Authorization', `Bearer ${res.accessToken}`),
                                        });
                                        return next.handle(req);
                                    })
                                );
                            }
                        } else if (error instanceof TimeoutError) {
                            this.logger.error(
                                null,
                                `ERRORE DI TIMEOUT: la richiesta ${req.url} ha impiegato troppo tempo per rispondere`,
                                null,
                                !!this._privateConfig?.production
                            );
                        } else {
                            this.logger.error(
                                null,
                                'HTTP ERROR',
                                JSON.stringify(error),
                                error && this.canShowErrorToast(req.url)
                            );
                        }
                        return throwError(error);
                    }),
                    ifOp(showLoading, LoadingService.loaderOperator())
                )
            )
        );
    }

    /**
     * @description: Aggiunge parametri all'header della richiesta
     * @return HttpRequest
     * @param request: HttpRequest
     */
    private addHeaders(request: HttpRequest<any>): Observable<HttpRequest<any>> {
        if (this.isApimUrl(request?.url)) {
            return this.authService.getAuthToken().pipe(
                map(({ authorization, user }) =>
                    request.clone({
                        setHeaders: {
                            ...(!this.isEgonUrl(request.url) && { 'x-user': user }),
                            Authorization: `Bearer ${authorization}`,
                            transactionId: request.headers.get('transactionId') || getTransactionId(),
                            'x-cpqtoken': localStorage.getItem('access_token'),
                            'Ocp-apim-subscription-key': this._privateConfig?.appKeys?.ocpApimSubscriptionKey,
                            sourceSystem: request.headers.get('sourceSystem') || 'SalesApp',
                        },
                    })
                )
            );
        } else if (request.url.includes('apttus') || request.url.includes('congacloud')) {
            const accessToken = localStorage.getItem(PlatformConstants.ACCESS_TOKEN);
            const storefront = this.aptConfigService.get('storefront');
            if (!isNil(accessToken) && !request.headers.get('authorization')) {
                request = request.clone({
                    headers: request.headers.set('authorization', `Bearer ${accessToken}`),
                });
            }
            if (!isNil(storefront) && !request.headers.get('x-storefront')) {
                request = request.clone({
                    headers: request.headers.set('x-storefront', storefront),
                });
            }
        } else {
            request = request.clone({
                headers: request.headers
                    .delete('x-storefront', request.headers.get('x-storefront'))
                    .delete('authorization', request.headers.get('Authorization'))
                    .delete('x-account', request.headers.get('x-account')),
            });
        }
        return of(request);
    }

    /**
     * @description: Mostra un block ui di caricamento durante le request
     * @param request: richiesta http
     * @return: boolean
     */
    private showLoading(request: HttpRequest<any>): boolean {
        const showLoading = !request.headers.has('no-loading');
        const isEgonUrl = this.isEgonUrl(request.url);
        const isI18n = request.url.includes('i18');
        const isEcommerceSdkRequest = request.url.includes(environment.endpoint);
        const isAssetRequest = request.url.includes('/assets/');

        return showLoading && !isI18n && !isEcommerceSdkRequest && !isEgonUrl && !isAssetRequest;
    }

    private configInit(): boolean {
        if (this.configSrv.configLoaded && !this._privateConfig) {
            this._privateConfig = this.configSrv.config;
        }
        return !!this._privateConfig;
    }

    /**
     * @description: Verifica se è possibile mostrare il toast di errore in base alla configurazione alla blacklist prevista contenuta nella configruazione hideToastErrorByEndpoint
     * @return: boolean
     */
    private canShowErrorToast(url: string): boolean {
        return (
            (this._privateConfig?.hideToastErrorByEndpoint || []).find((f) => url.indexOf(f) !== -1) === undefined &&
            !!this._privateConfig?.production
        );
    }

    private isApimUrl(url: string): boolean {
        return url?.startsWith(this._privateConfig?.endpoints.apiMgt.baseUrl);
    }

    private isApttusCustomUrl(url: string): boolean {
        return url?.startsWith(this._privateConfig?.endpoints.apex.baseUrl);
    }

    private isEgonUrl(url: string): boolean {
        return url?.includes('egon/');
    }

    private canTraceHttpEvent(httpEvent: { url?: string }): boolean {
        return (
            this._privateConfig?.traceHttp &&
            (this.isApttusCustomUrl(httpEvent?.url) || this.isApimUrl(httpEvent?.url)) &&
            (httpEvent instanceof HttpResponse ||
                httpEvent instanceof HttpErrorResponse ||
                httpEvent instanceof HttpRequest)
        );
    }

    private traceHttpEvent(httpEvent: HttpResponse<unknown> | HttpErrorResponse | HttpRequest<unknown>): void {
        if (this.canTraceHttpEvent(httpEvent)) {
            const type = httpEvent instanceof HttpRequest ? 'REQUEST' : 'RESPONSE';
            const url: string = (httpEvent instanceof HttpRequest && httpEvent?.urlWithParams) || httpEvent?.url;
            this.logger.debug(`[HTTP TRACE - ${type} - ${url}]`, httpEvent);
        }
    }
}

export const NO_LOADING = {
    headers: new HttpHeaders({
        'no-loading': 'true',
    }),
};
