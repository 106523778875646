import { WIN_BACK_MAP, wrapperUpdateProductByIndex } from './reducer-utility.functions';
import * as v1A from '../actions/order-entry.actions';
import { AptCommodityType } from '../../common/enums/apttus/apt-commodity-typeof-sale';
import {
    CommonCommodityTechnicalDetails,
    ContactV2,
    MeterAppointment,
    OrderEntryState_v2,
    PaymentInfo,
    PaymentTool,
    Product,
    ProductConfigurations,
    TechnicalDetails,
} from '../models/order-entry-state_v2';
import { on, On } from '@ngrx/store';
import {
    costRecordToProductPriceMap,
    indirizziToProductAddresses,
    orderEntryV1ToEffect,
    orderEntryV1ToTechnicalDetails,
    orderEntryV1ToV2,
    termCostsToProductPriceMap,
} from '../../common/functions/transformation-v1-v2.functions';
import { getNumberOrNull, cleanCorrectors } from '../../common/functions/remap.functions';
import {
    CreditCheckStatus,
    CreditCheckDetails,
    DatiAnagraficiMB,
    Firma,
    TaxVatDetails,
} from '../models/order-entry-state';
import moment from 'moment';
import { AptPowerVoltage } from '../../common/enums/apttus/apt-power-voltage';
import { indirizzo2Address } from '../../common/functions/address.functions';
import { isCommodityFamily, flowTypeUtil, isOrderEntryStateV2 } from '../../common/functions/verifications.functions';
import { userStateSelector } from '../selectors/user.selectors';
import { D365CustomerSegment } from '../../common/enums/d365/d365-customer-segment';
import { getValidTypeOfUse } from '../../common/functions/transformation.functions';
import { FlowType, MacroFlowType } from '../models/flow-type';
import { getAvailablePower } from '../../common/functions/misc.functions';

/**
 * NON AGGIUNGERE setProductInfo
 */

export const orderEntryLegacyReducers: On<OrderEntryState_v2>[] = [
    on(v1A.setOrderEntryState, (_, { s: state }) => (isOrderEntryStateV2(state) ? state : orderEntryV1ToV2(state))),
    on(
        v1A.setPod,
        wrapperUpdateProductByIndex(
            ({ payload: { p } }) => ({
                podPdr: p,
            }),
            {
                overwrite: false,
                productIdx: AptCommodityType.Power,
            }
        )
    ),
    on(
        v1A.setPdr,
        wrapperUpdateProductByIndex(
            ({ payload: { p, meter } }) => ({
                podPdr: p,
                ...(meter
                    ? {
                          technicalDetails: {
                              meterSerialNumber: meter,
                          },
                      }
                    : {}),
            }),
            {
                overwrite: false,
                productIdx: AptCommodityType.Gas,
            }
        )
    ),
    on(v1A.setCartInfo, (state, { p }) => ({
        ...state,
        dagCode: p?.ownerDagCode,
        cartId: p?.cartId,
    })),
    on(v1A.setIndirizzi, (orderStateV2, { i }) => {
        const userState = userStateSelector.projector();

        const { mainAddress } = indirizziToProductAddresses(
            i,
            userState.cartSegment === D365CustomerSegment.Microbusiness && orderStateV2?.contact?.mainAddress !== false
                ? orderStateV2?.contact?.mainAddress
                : null
        );

        return {
            ...wrapperUpdateProductByIndex(
                ({ product }) => {
                    const { deliveryAddress, communicationAddress } = indirizziToProductAddresses(
                        i,
                        orderStateV2?.contact?.mainAddress !== false ? orderStateV2?.contact?.mainAddress : null,
                        product?.addressType
                    );

                    return {
                        deliveryAddress,
                        communicationAddress,
                    };
                },
                {
                    productIdx: 'ALL',
                    overwrite: false,
                }
            )(orderStateV2, i),
            contact: {
                ...(orderStateV2?.contact || ({} as ContactV2)),
                mainAddress,
            },
        };
    }),
    on(v1A.setFlowType, (orderStateV2, { flowType: payload }) => ({
        ...orderStateV2,
        flowType: payload,
    })),
    on(v1A.setDestinationUse, (orderStateV2, { typeOfUse }) => ({
        ...orderStateV2,
        products: (orderStateV2.products || []).map((product) =>
            product.powerOrGas === AptCommodityType.Power
                ? {
                      ...product,
                      configurations: {
                          ...(product.configurations || ({} as ProductConfigurations)),
                          destinationUse: typeOfUse,
                      },
                  }
                : product
        ),
    })),
    // Cessazione & Voltura
    on(v1A.setIsMortisCausa, (orderStateV2, { isMortisCausa }) => ({
        ...orderStateV2,
        products: (orderStateV2?.products || []).map((product) => ({
            ...product,
            isMortisCausa,
        })),
    })),
    on(v1A.setResponsabilitaCC, (orderStateV2, { payload }) => ({
        ...orderStateV2,
        responsabilitaCreditCheckModal: payload,
    })),
    on(v1A.setScoreCardStatus, (orderStateV2, { s }) => ({
        ...orderStateV2,
        creditCheckStatus: {
            ...(orderStateV2.creditCheckStatus || ({} as CreditCheckStatus)),
            ccDetails: {
                ...(orderStateV2.creditCheckStatus?.ccDetails || ({} as CreditCheckDetails)),
                cribis: s,
            },
            taxVatDetails: {
                ...(orderStateV2.creditCheckStatus.taxVatDetails || ({} as TaxVatDetails)),
            },
        },
    })),
    on(v1A.setQuoteId, (state, { id: q }) => ({
        ...state,
        quoteId: q,
    })),
    on(v1A.setIbanInsolutoNDS, (orderStateV2, { i }) => ({
        ...orderStateV2,
        creditCheckStatus: {
            ...(orderStateV2.creditCheckStatus || ({} as CreditCheckStatus)),
            ccDetails: {
                ...(orderStateV2.creditCheckStatus?.ccDetails || ({} as CreditCheckDetails)),
                unsolvedIbanNds: i,
            },
        },
    })),
    on(
        v1A.setAtecoMB,
        wrapperUpdateProductByIndex(
            ({ product, payload: { payload } }) => ({
                businessDetails: {
                    ateco:
                        product?.powerOrGas === AptCommodityType.Gas
                            ? payload?.codeAtecoGas
                            : product?.powerOrGas === AptCommodityType.Power
                            ? payload?.codeAtecoPower
                            : null,
                    atecoDescription: payload?.denominazione,
                    activity: payload?.attivita,
                    validated: payload?.validated,
                    sector: payload?.settore,
                },
            }),
            { overwrite: false }
        )
    ),
    on(
        v1A.setPowerConsumption,
        wrapperUpdateProductByIndex(
            ({ payload: { c } }) => ({
                technicalDetails: {
                    consumption: c?.declared,
                    typeOfUsage: getValidTypeOfUse(c?.typeOfUse, AptCommodityType.Power),
                },
            }),
            { overwrite: false, productIdx: AptCommodityType.Power }
        )
    ),
    on(
        v1A.setGasConsumption,
        wrapperUpdateProductByIndex(
            ({ payload: { c }, product }) => ({
                ...(product || ({} as Product)),
                technicalDetails: {
                    ...(product?.technicalDetails || ({} as TechnicalDetails)),
                    consumption: c?.consumption,
                    typeOfUsage: getValidTypeOfUse(c?.typeOfUse, product?.powerOrGas),
                },
            }),
            { overwrite: true, productIdx: AptCommodityType.Gas }
        )
    ),
    on(
        v1A.setGasMeterReading,
        wrapperUpdateProductByIndex(
            ({ payload: { c }, product, orderStateV2 }) => ({
                ...(product || ({} as Product)),
                technicalDetails: {
                    ...(product?.technicalDetails || ({} as TechnicalDetails)),
                    needed: {
                        ...(product?.technicalDetails?.needed || ({} as CommonCommodityTechnicalDetails)),
                        meterCounter:
                            !flowTypeUtil(orderStateV2?.flowType).inMacroFlowTypes(MacroFlowType.Administrative) &&
                            !flowTypeUtil(orderStateV2?.flowType).equalTo(FlowType.VolturaAmmIncorporazioneSocietaria)
                                ? c?.meterCounter
                                : null,
                    },
                    meterCounter: c?.meterCounterValidity ? c?.meterCounter : null,
                    gasMeterAdjustmentNumber: c?.meterCounterValidity ? c?.meterAdjustmentCounter : null,
                },
            }),
            { overwrite: true, productIdx: AptCommodityType.Gas }
        )
    ),
    on(
        v1A.setPowerHomeDropdownOption,
        v1A.setGasHomeDropdownOption,
        wrapperUpdateProductByIndex(
            ({ payload: { c } }) => ({
                configurations: {
                    homeTaxRelief: c,
                },
            }),
            { overwrite: false }
        )
    ),
    on(
        v1A.setTitolaritaImmobile,
        wrapperUpdateProductByIndex(
            ({ payload: { c } }) => ({
                configurations: {
                    propertyOwnership: c,
                },
            }),
            { overwrite: false }
        )
    ),
    on(v1A.setDatiPagamento, (state, { datiPagamento: p }) => ({
        ...state,
        products: (state?.products || []).map(({ paymentInfo, ...product }) => ({
            ...product,
            paymentInfo: {
                ...(paymentInfo || ({} as PaymentInfo)),
                paymentInstrument: p?.tipoPagamento,
                existingPaymentTool: p?.existingPaymentTool,
                paymentTool: {
                    ...(paymentInfo?.paymentTool || ({} as PaymentTool)),
                    id: p?.id,
                    iban: p?.formBonifico?.iban,
                    creditCardNumber: p?.existingPaymentTool,
                    creditCardExpirationDate: p?.creditCardExpirationDate,
                    deactivateOldPaymentTool: p?.deactivateOldPaymentTool,
                    membership: p?.formBonifico?.settoreAppartenenza,
                    bankAccountOwner: p?.formBonifico?.intestatarioConto,
                    holder: {
                        companyName: p?.formBonifico?.titolareConto?.ragioneSociale,
                        vatCode: p?.formBonifico?.titolareConto?.piva,
                        fiscalCode: p?.formBonifico?.titolareConto?.cf || p?.formBonifico?.titolareConto?.cfAzienda,
                        firstName: p?.formBonifico?.titolareConto?.nome,
                        lastName: p?.formBonifico?.titolareConto?.cognome,
                        fullName: p?.formBonifico?.titolareConto?.fullName,
                    },
                    sepaSubscriber: {
                        firstName: p?.formBonifico?.sottoscrittoreSepa?.nome,
                        lastName: p?.formBonifico?.sottoscrittoreSepa?.cognome,
                        fiscalCode: p?.formBonifico?.sottoscrittoreSepa?.cf,
                    },
                },
            },
        })),
    })),
    on(v1A.setFotoDocumenti, (state, { d }) => ({
        ...state,
        fotoDocumenti: d,
    })),
    on(v1A.setFirma, (state, { payload }) => ({
        ...state,
        firma: payload,
    })),
    on(v1A.setMP3Info, (state, { payload }) => ({
        ...state,
        mp3Info: payload,
    })),
    on(v1A.setPdfPlicoInfo, (state, { payload }) => ({
        ...state,
        uploadPdfPlicoInfo: payload,
    })),
    on(v1A.setNumeroPlico, (state, { payload }) => ({
        ...state,
        numeroPlico: payload,
    })),
    on(v1A.setPassaggioRapido, (state, { payload }) => ({
        ...state,
        products: (state?.products || []).map((product) => ({
            ...product,
            ...orderEntryV1ToEffect({ tipoEsecuzione: payload }, product?.family),
        })),
    })),
    on(v1A.setFornitureEsistentiSelezionate, (state, { s }) => ({
        ...state,
        fornitureAttiveSelezionate: s,
    })),
    on(v1A.setFornitureEsistenti, (state, { s }) => ({
        ...state,
        fornitureEsistenti: s,
    })),
    on(v1A.setPrivacyTratDatiPers, (state, { p }) => ({
        ...state,
        contact: {
            ...state?.contact,
            privacyTrattDatiPers: p,
        },
    })),
    on(v1A.setQuoteStateModel, (state, { s }) => ({
        ...state,
        quoteStateModel: s,
    })),
    on(v1A.setVerificaContatto, (state, { s }) => ({
        ...state,
        verificaContatto: s,
    })),
    on(v1A.resetOrderEntry, () => new OrderEntryState_v2()),
    on(v1A.setAgentBranch, (state, { h }) => ({
        ...state,
        branchAgenziaAgente: h,
    })),
    on(v1A.initSign, (state) => ({
        ...state,
        numeroPlico: null,
        uploadPdfPlicoInfo: null,
        mp3Info: null,
        firma: new Firma(),
    })),
    on(v1A.setIsWinBack, (state, { isWinBack, winBackType }) => ({
        ...state,
        products: (state.products || []).map((product) =>
            WIN_BACK_MAP[winBackType].includes(product.powerOrGas)
                ? {
                      ...product,
                      isWinBack,
                  }
                : { ...product }
        ),
    })),
    on(v1A.setWinBackType, (state, { payload }) => ({
        ...state,
        products: (state.products || []).map((product) => ({
            ...product,
            isWinBack: isCommodityFamily(product.family) ? WIN_BACK_MAP[payload].includes(product.powerOrGas) : false,
        })),
    })),
    on(v1A.setPlicoUrl, (state, { payload }) => ({
        ...state,
        plicoUrl: payload,
    })),
    on(v1A.setAnagraficaMB, (state, { payload }) => {
        const { companyAddress, ...anagraficaMb } = payload || ({} as DatiAnagraficiMB);
        return {
            ...state,
            anagraficaMb,
            contact: {
                ...state?.contact,
                mainAddress: indirizzo2Address(companyAddress),
            },
        };
    }),
    on(v1A.setOcrData, (state, { payload }) => ({
        ...state,
        ocrData: payload,
    })),

    on(
        v1A.setPotenzaImpegnataMB,
        wrapperUpdateProductByIndex(
            ({ payload: { payload } }) => ({
                technicalDetails: {
                    consumption: payload?.consumoInpt,
                    pwrInstantaneousPower:
                        getNumberOrNull(payload?.potenzaImpInputManuale) ||
                        getNumberOrNull(payload?.potenzaImpegnataCmb),
                    pwrAvailablePower: getNumberOrNull(payload?.potenzaDisponibileInp),
                    pwrVoltage: payload?.livelloTensione as AptPowerVoltage,
                },
            }),
            { productIdx: AptCommodityType.Power, overwrite: false }
        )
    ),
    on(v1A.setFatturazioneElettronica, (state, { payload }) => ({
        ...state,
        fatturazioneElettronica: payload,
    })),
    on(v1A.setImpersonatedAgent, (state, { payload }) => ({
        ...state,
        impersonatedAgent: {
            ...state.impersonatedAgent,
            ...payload,
        },
    })),
    on(v1A.setInvalidCf, (state, { payload }) => ({
        ...state,
        invalidCf: payload,
    })),
    on(v1A.setNumeroDomiciliazione, (orderStateV2, { code }) => ({
        ...orderStateV2,
        products: (orderStateV2?.products || []).map(({ paymentInfo, ...product }) => ({
            ...product,
            paymentInfo: {
                ...(paymentInfo || ({} as PaymentInfo)),
                numeroDomiciliazione: code,
            },
        })),
    })),
    on(v1A.setDeferredSaleStatus, (state, { enabled }) => ({
        ...state,
        deferredSaleEnabled: enabled,
    })),
    on(v1A.setCreditCheckStatus, (state, { s }) => ({
        ...state,
        creditCheckStatus: s
            ? {
                  ...s,
                  ccDetails: {
                      ...s.ccDetails,
                      checkBalanceExceeded:
                          s.ccDetails?.checkBalanceExceeded || state.creditCheckStatus?.ccDetails?.checkBalanceExceeded,
                  },
              }
            : undefined,
    })),
    on(v1A.setInsolutoNDS, (state, { i }) => ({
        ...state,
        creditCheckStatus: {
            ...state.creditCheckStatus,
            ccDetails: {
                ...state.creditCheckStatus?.ccDetails,
                unsolvedNds: i,
            },
        },
    })),
    on(v1A.setTaxVatStatus, (state, { s }) => ({
        ...state,
        creditCheckStatus: {
            ...state.creditCheckStatus,
            ccDetails: {
                ...state.creditCheckStatus?.ccDetails,
            },
            taxVatDetails: s,
        },
    })),
    on(v1A.setCheckBalance, (state, { checkBalanceExceeded }) => ({
        ...state,
        creditCheckStatus: {
            ...state.creditCheckStatus,
            ccDetails: {
                ...state.creditCheckStatus?.ccDetails,
                checkBalanceExceeded,
            },
        },
    })),
    on(v1A.setPaymentScore, (state, { p }) => ({
        ...state,
        creditCheckStatus: {
            ...state.creditCheckStatus,
            ccDetails: {
                ...state.creditCheckStatus?.ccDetails,
                paymentScore: p,
            },
            taxVatDetails: {
                ...state.creditCheckStatus.taxVatDetails,
            },
        },
    })),
    on(v1A.setCeaseReasonCode, (state, { c }) => ({
        // TODO è la stassa cosa della prop incident??? verificare
        ...state,
        creditCheckStatus: {
            ...state.creditCheckStatus,
            ccDetails: {
                ...state.creditCheckStatus?.ccDetails,
                ceaseReasonCode: c,
            },
            taxVatDetails: {
                ...state.creditCheckStatus.taxVatDetails,
            },
        },
    })),
    on(v1A.setIbanInsolutoNDS, (state, { i }) => ({
        ...state,
        creditCheckStatus: {
            ...state.creditCheckStatus,
            ccDetails: {
                ...state.creditCheckStatus?.ccDetails,
                unsolvedIbanNds: i,
            },
        },
    })),
    on(v1A.setIvassAnswers, (state, { p }) => ({
        ...state,
        ivassAnswers: p,
    })),
    on(v1A.setInsuranceDummyProductId, (state, { p }) => ({
        ...state,
        insuranceDummyProductId: p,
    })),
    on(v1A.setAgencyBranchForMonitoring, (state, { a }) => ({
        ...state,
        agencyBranchForMonitoring: a,
    })),
    on(v1A.setTerminationDate, (state, { s }) => ({
        ...state,
        products: (state?.products || []).map((product) => ({
            ...product,
            effectiveDate: s ? moment(s).toDate() : null,
        })),
    })),
    on(v1A.setSelfCertification, (state, { d }) => ({
        ...state,
        selfCertification: d,
    })),
    on(v1A.setTerminationTermContacts, (state, { p }) => ({
        ...state, // TODO implementare
    })),
    on(v1A.setAppointment, (state, { app }) => {
        const productAppointmentMap: {
            [key: string]: MeterAppointment;
        } = (app?.meters || []).reduce(
            (aggr, meterApp) => ({
                ...aggr,
                [meterApp?.lineItemId]: {
                    location: meterApp?.location,
                    available: meterApp?.isAvailable,
                    notes: meterApp?.notes,
                },
            }),
            {}
        );

        return {
            ...state,
            appointment: {
                timeslot: app?.timeSlot,
                firstName: app?.altContactFName,
                lastName: app?.altContactLName,
                prefix: app?.altContactPrefix,
                phone: app?.altContactPhone,
                presence: app?.presence,
                interphone: {
                    available: app?.interphoneAvailable,
                    notes: app?.interphoneNotes,
                },
            },
            products: (state?.products || []).map((product) => ({
                ...product,
                appointment: productAppointmentMap[product?.lineItemId],
            })),
        };
    }),
    on(v1A.setTerminationCosts, (state, { tc }) => {
        const priceMap = termCostsToProductPriceMap(tc);
        return {
            ...state,
            products: (state?.products || []).map((product) => ({
                ...product,
                prices: priceMap[product?.lineItemId] || product?.prices,
            })),
        };
    }),
    on(
        v1A.setAdministrativeTechnicalData,
        wrapperUpdateProductByIndex(
            ({ product, payload: { p, deleteCorrectors } }) => ({
                technicalDetails: cleanCorrectors(
                    {
                        ...product.technicalDetails,
                        ...orderEntryV1ToTechnicalDetails({
                            administrativeTechnicalData: p,
                        })[product.powerOrGas],
                    } || {},
                    deleteCorrectors
                ),
            }),
            { overwrite: false }
        )
    ),
    on(
        v1A.setPodActivationInfo,
        wrapperUpdateProductByIndex(
            ({ payload: { ai } }) => ({
                technicalDetails:
                    orderEntryV1ToTechnicalDetails({
                        activation: {
                            podActivationInfo: ai,
                        },
                    })[AptCommodityType.Power] || {},
            }),
            { overwrite: false, productIdx: AptCommodityType.Power }
        )
    ),
    on(
        v1A.setPotencyActivation,
        wrapperUpdateProductByIndex(
            ({ payload: { pot } }) => ({
                technicalDetails: {
                    needed: {
                        pwrInstantaneousPower: getNumberOrNull(pot),
                        pwrAvailablePower: getAvailablePower(getNumberOrNull(pot)),
                    },
                },
            }),
            { overwrite: false, productIdx: AptCommodityType.Power }
        )
    ),
    on(
        v1A.setPdrActivationInfo,
        wrapperUpdateProductByIndex(
            ({ payload: { ai } }) => ({
                technicalDetails:
                    orderEntryV1ToTechnicalDetails({
                        activation: {
                            pdrActivationInfo: ai,
                        },
                    })[AptCommodityType.Gas] || {},
            }),
            { overwrite: false, productIdx: AptCommodityType.Gas }
        )
    ),
    on(
        v1A.setPropertyTypeSelected,
        wrapperUpdateProductByIndex(
            ({ payload: { propertyTypeSelected } }) => ({
                configurations: {
                    propertyType: propertyTypeSelected,
                },
            }),
            { overwrite: false, productIdx: 'ALL' }
        )
    ),
    on(v1A.setCosts, (state, { costs }) => {
        const costMap = costRecordToProductPriceMap(costs?.records);
        return {
            ...state,
            products: (state.products || []).map((product) => ({
                ...product,
                prices: costMap[product?.lineItemId] || product.prices,
            })),
        };
    }),
    on(v1A.setLinkedCommodityQuoteId, (state, { quoteId }) => ({
        ...state,
        linkedCommodity: {
            ...(state?.linkedCommodity || ({} as any)),
            quoteId,
        },
    })),
    on(v1A.setCombinedSale, (state, { combinedSale }) => ({
        ...state,
        linkedCommodity: {
            ...(state?.linkedCommodity || ({} as any)),
            enabled: combinedSale,
        },
    })),
    on(v1A.setCommodityCartId, (state, { commodityCartId }) => ({
        ...state,
        linkedCommodity: {
            ...(state?.linkedCommodity || ({} as any)),
            cartId: commodityCartId,
        },
    })),
    on(v1A.setIncident, (state, { t }) => ({
        ...state,
        incident: t,
    })),
    on(
        v1A.setDestinationUse,
        wrapperUpdateProductByIndex(
            ({ payload: { typeOfUse } }) => ({
                configurations: {
                    destinationUse: typeOfUse,
                },
            }),
            { overwrite: false }
        )
    ),
    on(
        v1A.setFastwebTaxVatCode,
        wrapperUpdateProductByIndex(
            ({ payload: { code } }) => ({
                configurations: {
                    partnership: {
                        fw: {
                            taxVatCode: code,
                        },
                    },
                },
            }),
            { overwrite: false }
        )
    ),
    on(
        v1A.setFastwebContractInfo,
        wrapperUpdateProductByIndex(
            ({ payload: { cluster, accountNumber } }) => ({
                configurations: {
                    partnership: {
                        fw: {
                            cluster,
                            accountNumber,
                        },
                    },
                },
            }),
            { overwrite: false }
        )
    ),
    on(
        v1A.setEglPartnershipData,
        wrapperUpdateProductByIndex(
            ({ product, payload: { data } }) => ({
                ...product,
                configurations: {
                    ...(product.configurations || {}),
                    partnership: {
                        ...(product.configurations?.partnership || {}),
                        egl: data,
                    },
                },
            }),
            { overwrite: true }
        )
    ),
    on(
        v1A.setSkipCosts,
        wrapperUpdateProductByIndex(
            ({ payload: { skipCosts } }) => ({
                prices: { skip: skipCosts },
            }),
            { overwrite: false, productIdx: 'ALL' }
        )
    ),
];
