import { Component, ElementRef, HostListener, Input, OnChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { IWyvernProgress } from '../../interfaces/wyvern-bar.interface';
import { DragonRouterService } from '../../services/shared/router/dragon-router.service';

@Component({
    selector: 'egl-wyvern-bar',
    templateUrl: './wyvern-bar.component.html',
    styleUrls: ['./wyvern-bar.component.scss'],
})
export class WyvernBarComponent implements OnChanges {
    isHidden: boolean;
    currentStepPercent: number;
    currentStepDescription: string;
    showDropdown: boolean = false;
    @Input() routeData: IWyvernProgress;

    @ViewChild('btnToggleDropdown') btnToggleDropdown: ElementRef;
    @ViewChild('progressBarList') progressBarList: ElementRef;

    constructor(private routerSrv: DragonRouterService) {}

    ngOnChanges() {
        this.routeData?.position > 0 ? (this.isHidden = false) : (this.isHidden = true);
        this.currentStepPercent = (this.routeData?.position / this.routeData?.total) * 100;
        this.currentStepDescription = this.routeData?.descriptionId;
        this.showDropdown = false;
    }

    @HostListener('document:click', ['$event'])
    clickout(event: any) {
        if (this.btnToggleDropdown?.nativeElement?.contains(event.target)) {
            this.showDropdown = !this.showDropdown;
        } else if (!this.progressBarList?.nativeElement?.contains(event.target)) {
            this.showDropdown = false;
        }
    }

    public navigateTo(routePath: string) {
        return this.routerSrv.navigateToPath(routePath).subscribe();
    }

    public getRouteStatus(routeIdx: number): 'disabled' | 'current' | 'visited' {
        const currentRouteIdx = this.routeData?.position + this.routeData?.subPosition - 1;

        if (routeIdx === currentRouteIdx) {
            return 'current';
        }
        if (routeIdx < currentRouteIdx) {
            return 'visited';
        }
        return 'disabled';
    }
}
