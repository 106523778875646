import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, mergeMap, pairwise, startWith, take, tap } from 'rxjs/operators';
import { ApplicationLocationType, AppState, D365EntryPoint } from '../../../../store/models/app-state';
import { selectApplicationLocation, selectD365EntryPoint } from '../../../../store/selectors/app.selectors';
import { selectCartSegment } from '../../../../store/selectors/user.selectors';
import { RoutesPaths } from '../../../config/routes-paths';
import { D365TemplateName } from '../../../enums/d365/d365-template-name';
import { FLOW_TYPE_LABELS_MAP } from '../../../map/flow-type-labels-map';
import { NavigateToRequest } from '../../../models/d365/navigate-to-request';
import { AgentAuthorizationService } from '../../../services/app/agent-authorization.service';
import { CartUtilityService } from '../../../services/apttus/tables/cart/cart-utility.service';
import { D365Service } from '../../../services/d365/d365.service';
import { PrivateConfigurationService } from '../../../services/shared/private-configuration.service';
import { DragonRouterService } from '../../../services/shared/router/dragon-router.service';
import { SurveyService } from '../../../services/shared/survey.service';
import { WidgetService } from '../../../services/shared/widgets.service';
import { WyvernBarService } from '../../../services/shared/wyvern-bar.service';
import { EglModalWithFooterComponent } from '../../modals/egl-modal-with-footer/egl-modal-with-footer.component';
import { HeaderDdlElement, HeaderDropdownlist, MainHeaderService } from './service/main-header.service';

@Component({
    selector: 'egl-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class HeaderComponent implements OnInit, OnDestroy {
    @ViewChild('confirmModal', { static: false }) refModalConfirm: EglModalWithFooterComponent;
    private subs: Subscription[] = [];
    modalConfirm: ModalConfirm;
    showBack: boolean;
    showLogo: boolean;
    showMiniCart: boolean;
    showSwiDropdown: boolean;
    show: boolean;
    linkToCatalog: string;
    canSwitchInVip: boolean;
    isSurveyAvailable: boolean;
    currentPage: string;
    pageTranslateKey: string;
    apprendiEnabled: boolean;
    readonly d365EntryPoint = D365EntryPoint;

    readonly hideShowBack = [
        RoutesPaths.SettingsNoBack,
        RoutesPaths.Dashboard,
        RoutesPaths.DashboardInitial,
        RoutesPaths.ThankYouPage,
        RoutesPaths.CartActive,
        RoutesPaths.OrderEntry,
        RoutesPaths.TroubleShooting,
        RoutesPaths.CP,
    ];
    readonly showMiniCartPages = [
        RoutesPaths.Dashboard,
        RoutesPaths.DashboardInitial,
        RoutesPaths.OrderEntry,
        RoutesPaths.TroubleShootingCommodity,
        RoutesPaths.TroubleShootingManutenzione,
        RoutesPaths.AllProducts,
        RoutesPaths.CartActive,
    ];
    headersElements: HeaderDropdownlist[];
    entryPoint: D365EntryPoint;
    public routePageProgress$ = this.wyvernBarSrv.orderEntryEel();
    public l10nRoot$ = this.router.events.pipe(
        startWith(new NavigationEnd(null, null, null)),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.routeDataSquasher(this.router.routerState.root)),
        map(({ pageId }) => pageId as string),
    );

    constructor(
        private store: Store<AppState>,
        private routerSrv: DragonRouterService,
        private headerSrv: MainHeaderService,
        private cartUtilitySrv: CartUtilityService,
        private translateSrv: TranslateService,
        private surveyService: SurveyService,
        private agentAuthorizationSrv: AgentAuthorizationService,
        private router: Router,
        private d365Service: D365Service,
        private widgetService: WidgetService,
        private wyvernBarSrv: WyvernBarService,
        private prvSrv: PrivateConfigurationService,
    ) {}

    private routeDataSquasher(activatedRoute: ActivatedRoute, initialParams: Params = {}) {
        return {
            ...initialParams,
            ...(activatedRoute?.snapshot?.data || {}),
            ...(activatedRoute?.children?.length
                ? activatedRoute.children.reduce(
                      (aggr, childActivatedRoute) => this.routeDataSquasher(childActivatedRoute, aggr),
                      {},
                  )
                : {}),
        };
    }

    ngOnInit(): void {
        this.store
            .select(selectD365EntryPoint)
            .pipe(take(1))
            .subscribe((entryPoint) => (this.entryPoint = entryPoint));

        this.headersElements = this.headerSrv.headersElements;
        this.subscribeOnNavigationChange();

        this.apprendiEnabled = !!this.prvSrv.get<string>('apprendiUrl', '');
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    onDropdownSelect(element: HeaderDdlElement): void {
        this.cartUtilitySrv
            .currentCartIsEmpty()
            .pipe(
                mergeMap((isEmpty) =>
                    isEmpty
                        ? this.headerSrv.performActionDdl(element)
                        : of(
                              this.showConfirmModal(
                                  'HEADER.CONFIRM_BEFORE_NEW_CART',
                                  FLOW_TYPE_LABELS_MAP[element.value.flowType],
                                  () => this.headerSrv.performActionDdl(element),
                              ),
                          ),
                ),
                catchError(() => of(null)),
            )
            .subscribe();
    }

    onDiscardSelect(element: HeaderDdlElement): void {
        this.cartUtilitySrv.currentCartIsEmpty().subscribe((isEmpty) => {
            if (isEmpty) {
                this.headerSrv.discardActionDdl(element);
            } else {
                this.showConfirmModal(
                    'HEADER.DISCARD_BEFORE_NEW_CART',
                    FLOW_TYPE_LABELS_MAP[element.value.flowType],
                    () => this.headerSrv.discardActionDdl(element),
                );
            }
        });
    }

    private subscribeOnNavigationChange(): void {
        this.subs.push(
            this.router.events
                .pipe(
                    startWith(new NavigationEnd(null, this.router.url, null)),
                    filter((event) => event instanceof NavigationEnd),
                )
                .pipe(
                    mergeMap((event: RouterEvent) =>
                        this.store
                            .select(selectApplicationLocation)
                            .pipe(map((appLocation) => ({ appLocation, event }))),
                    ),
                )
                .subscribe(({ event, appLocation }) => {
                    this.showLogo = appLocation !== ApplicationLocationType.Web;
                    this.currentPage = event.url;
                    // SHOW HEADER LOGIC
                    this.show =
                        !this.currentPage.includes('/u/') &&
                        !this.currentPage.includes(RoutesPaths.AssetDetail.replace(':id', ''));

                    if (this.show) {
                        this.checkSurveyAvailability();
                        this.showMiniCart =
                            !!this.showMiniCartPages.find((route) => this.currentPage.includes(route)) &&
                            this.agentAuthorizationSrv.canSell;
                        this.showBack = !this.hideShowBack.find((route) => this.currentPage.includes(route));
                    }
                }),

            this.store.select(selectCartSegment).subscribe((segment) => {
                // Mostrare solo Voltura con Cambio Fornitore VIP si è Residential
                this.headerSrv.setIsResidential(segment);
                this.headerSrv.calculateElements();
                this.headersElements = this.headerSrv.headersElements;
            }),

            // Se ho terminato una quote di un processo amministrativo e torna al catalogo tramite pulsante
            // della TYP, riabilito automaticamente lo stesso processo amministrativo che dovrà esser eventualmente
            // disabilitato manualmente.
            this.router.events
                .pipe(
                    startWith(new NavigationEnd(null, this.router.url, null)),
                    filter(
                        (event) =>
                            event instanceof NavigationEnd &&
                            (event.url.includes(RoutesPaths.AllProducts) ||
                                event.url.includes(RoutesPaths.ThankYouPage)) &&
                            !!this.headerSrv.selectedHeaderConfig,
                    ),
                    pairwise(),
                    filter(
                        ([startRoute, endRoute]: [NavigationEnd, NavigationEnd]) =>
                            startRoute.url.includes(RoutesPaths.ThankYouPage) &&
                            endRoute.url.includes(RoutesPaths.AllProducts),
                    ),
                    tap(() => {
                        this.headerSrv.performActionDdl(this.headerSrv.selectedHeaderConfig);
                    }),
                )
                .subscribe(),
        );
    }

    private showConfirmModal(
        messageKey: string,
        operationKey: string,
        functionOnConfirm: () => Observable<void> | Promise<void> | void,
    ): void {
        this.modalConfirm = {
            title: 'Attenzione',
            confirmBtn: 'Conferma',
            message: this.translateSrv.instant(messageKey, { operation: this.translateSrv.instant(operationKey) }),
            onConfirmFunction: functionOnConfirm,
        };
        this.refModalConfirm.show();
    }

    private checkSurveyAvailability(): void {
        this.surveyService.showSurveyButton(this.currentPage).then((response) => {
            this.isSurveyAvailable = response;
        });
    }

    openSurveyModal(): void {
        this.surveyService.openSurvey(this.currentPage).then(() => this.checkSurveyAvailability());
    }

    goBack(): void {
        this.routerSrv.back();
    }

    updateWidget(): void {
        this.widgetService.update();
    }

    navigateTo(target: 'DASH' | 'SIEBEL'): void {
        if (target === 'DASH') {
            const navigateToRequest: NavigateToRequest = {
                pageInput: {
                    pageType: 'dashboard',
                },
            };
            this.d365Service.navigateToOrApmNavigation(D365TemplateName.Dashboard, {}, true, navigateToRequest);
        } else if (target === 'SIEBEL') this.d365Service.openSiebel();
    }

    navigateToCreateLead() {
        const navigateToRequest: NavigateToRequest = {
            pageInput: {
                pageType: 'entityrecord',
                entityName: 'lead',
                entityId: '00000000-0000-0000-0000-000000000000',
            },
        };

        this.d365Service.navigateToOrApmNavigation(
            D365TemplateName.EntityRecord,
            { entityName: 'lead' },
            true,
            navigateToRequest,
        );
    }

    openAPPrendi(): void {
        const url = this.prvSrv.get<string>('apprendiUrl', '');
        this.d365Service.openWebPage(url);
    }

    get isDashboard(): boolean {
        return (
            this.currentPage.includes(RoutesPaths.Dashboard) || this.currentPage.includes(RoutesPaths.DashboardInitial)
        );
    }

    get isHeaderOmnichannelButtonVisible(): boolean {
        return this.entryPoint === D365EntryPoint.Omnichannel && this.isDashboard;
    }

    get canCreateLeadFromHomePage(): boolean {
        return this.isHeaderOmnichannelButtonVisible && this.agentAuthorizationSrv.canCreateLeadFromHomePage;
    }

    getAsAny(value: unknown): any {
        return value;
    }
}

interface ModalConfirm {
    title: string;
    message: string;
    confirmBtn: string;
    onConfirmFunction: () => Observable<void> | Promise<void> | void;
}
