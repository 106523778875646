import { AppLocation } from '../../common/models/d365/app-location';
import { GeoLocation } from '../../common/models/app/geo-location';

export class AppState {
    connectionStatus = 'ONLINE';
    connectionSpeed?: string;
    applicationLocation?: ApplicationLocationType;
    currentPage = '';
    urlHash?: string;
    geoLocation?: GeoLocation;
    hasContactBar?: boolean;
    hasProgressBar?: boolean;
    hasDevBar?: boolean;
    backOfficeQuoteTab?: string;
    d365EntryPoint: D365EntryPoint = D365EntryPoint.Default;
    d365AppUrl: string = '';
    shouldOpenTroubleShooting: boolean = false;
}

export enum ApplicationLocationType {
    Mobile = 'Mobile',
    Web = 'Web',
}

export enum D365EntryPoint {
    Default = 'Default',
    Omnichannel = 'Omnichannel',
}
