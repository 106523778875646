import { AsyncPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, from, Observable } from 'rxjs';
import { concatMap, filter, map, mergeMap, take } from 'rxjs/operators';
import { EglState } from '../../store/reducers';
import { v2SelectFlowType, v2SelectVisibleProducts } from '../../store/selectors/order-entry-v2.selectors';
import { selectCartSegment } from '../../store/selectors/user.selectors';
import { D365CustomerSegment } from '../enums/d365/d365-customer-segment';
import { mapOrEmpty } from '../functions/observable-operators';
import { flowTypeToMacroFlowType, productsToL10NType } from '../functions/remap.functions';
import { flowTypeUtil } from '../functions/verifications.functions';
import { FlowTypeMode } from '../map/flow-type-mapping.config';

@Pipe({
    name: 'flowTranslate',
})
export class FlowTranslatePipe implements PipeTransform {
    constructor(private trasnlateSrv: TranslateService, private asyncPipe: AsyncPipe, private state: Store<EglState>) {}
    /**
     * @param rootKey La base della stringa di traduzione, esempio: 'ORDER_ENTRY.SIGNATURE.SIGNATURE_PRINTED_PAGE'
     * @param field La stringa finale di traduzione che vogliamo prendere, esempio: 'TITLE'
     */
    transform(rootKey: string, field?: string, l10nParams?: Object): string {
        if (!rootKey) return '';
        return this.asyncPipe.transform(
            this.get(rootKey, field, l10nParams).pipe(map((l10n) => (typeof l10n !== 'object' ? l10n : null)))
        );
    }

    get(
        rootKey: string,
        field?: string,
        l10nParams?: Object
    ): Observable<string | string[] | { [key: string]: string }> {
        return combineLatest([
            this.state.select(v2SelectFlowType),
            this.state.select(selectCartSegment).pipe(
                map(
                    (d365Segment) =>
                        (Object.entries(D365CustomerSegment).find(([, value]) => value === d365Segment) || [])[0]
                ),
                map((segment) => (segment || '').toUpperCase() || null)
            ), // RESIDENTIAL / MICROBUSINESS
            this.state.select(v2SelectVisibleProducts()),
        ]).pipe(
            take(1),
            map(([flowType, segment, products]) => ({
                flowType,
                segment,
                productType: productsToL10NType(products),
            })),
            mergeMap(({ flowType, segment, productType }) =>
                from(this.getContextL10nPaths({ flowType, segment, productType, rootKey, field })).pipe(
                    concatMap((path) => this.trasnlateSrv.get(path, l10nParams).pipe(filter((l10n) => l10n !== path))),
                    take(1),
                    mapOrEmpty(
                        (l10n) => l10n,
                        () => null
                    )
                )
            )
        );
    }

    private getContextL10nPaths({ flowType, segment, productType, rootKey, field }): string[] {
        const commercialFlowType = flowTypeUtil(flowType).withMode(FlowTypeMode.Commercial);
        const macroFlowType = flowTypeToMacroFlowType(flowType);
        const pathPriorities = [
            `${segment}.${productType}`,
            `${flowType}.${segment}`,
            `${commercialFlowType}.${segment}`,
            `${macroFlowType}.${segment}`,
            `DEFAULT.${segment}`,
            `${segment}`,
            `${productType}`,
            `${flowType}`,
            `${commercialFlowType}`,
            `${macroFlowType}`,
            `DEFAULT`,
        ];
        const [, altRootKey, altField] = (rootKey || '').match(/^(.+)\.([^.]+)$/i) || [];
        return [
            // Costruisco l'elenco dei possibili l10n path utilizzando il field fornito se presente
            ...pathPriorities.map(field ? (path) => `${rootKey}.${path}.${field}` : (path) => `${rootKey}.${path}`),
            // In assenza del field e in presenza di un rootKey composito, costruisco l'elenco dei possibili path, utilizzando una parte
            // del rootKey e l'ultima parte di questo come field
            ...(!field && altRootKey && altField
                ? pathPriorities.map((path) => `${altRootKey}.${path}.${altField}`)
                : []),
            // Utilizzo il rootKey più field o solo il rootKey come casistica di fallback
            field ? `${rootKey}.${field}` : rootKey,
            // Escludo tutti i path che contengono valori null o undefined
        ].filter((path) => !/\.(?:null|undefined)?(?:$|\.)/.test(path));
    }
}
