export enum SiNoType {
    Si = 'Si',
    No = 'No',
}

export enum YesNo {
    Yes = 'Y',
    No = 'N',
}

export enum OkKo {
    OK = 'OK',
    KO = 'KO',
}

export enum UpperSiNoType {
    Si = 'SI',
    No = 'NO',
}
