import { Expose } from 'class-transformer';

export class AgencyBranchForSubsidiary {
    @Expose()
    egl_name: string;
    @Expose()
    egl_agencybranchid: string;
    @Expose()
    egl_frcode: string;
}
