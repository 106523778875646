import { FieldMap } from '../../../utility/functions/map-fields';
import { FetchXmlQuery } from './fetch-xml-manager';

/**
 * Recupera i dati legati agli indirizzi del partner di riferimento
 * @param agencyBranchId Id ESE di riferimento
 */
export function getAgencyESEXmlQuery(agencyBranchId: string): FetchXmlQuery {
    const entity = 'egl_agencybranch_address';
    return {
        entity,
        query: `
    <?xml version="1.0" encoding="UTF-8"?>
        <fetch top="1">
            <entity name="${entity}">
            <attribute name="egl_fulladdress" />
            <filter>
            <condition attribute="egl_addresstypecode" operator="eq" value="100000000" />
            <condition attribute="egl_agencybranchid" operator="eq" value="${agencyBranchId}"/>
            </filter>
            <link-entity name="egl_agencybranch" from="egl_agencybranchid" to="egl_agencybranchid" alias="AgencyBranch">
            <attribute name="egl_code" />
            <attribute name="egl_name"/>
            </link-entity>
            </entity>
        </fetch>
        `,
    };
}

export interface D365AgencyESEInfo {
    egl_fulladdress: string; // Indirizzo partner tecnico
    ['AgencyBranch.egl_name']: string; // Nome partner tecnico
}

export interface AgencyESEInfo {
    partnerFullAddress: string; // Original: egl_fulladdress - Indirizzo partner tecnico
    partnerName: string; // Original: AgencyBranch.egl_name - Nome partner tecnico
}

export const D365ToAgencyESEFieldMap: FieldMap<D365AgencyESEInfo, AgencyESEInfo>[] = [
    { sourceField: 'egl_fulladdress', resultField: 'partnerFullAddress' },
    { sourceField: 'AgencyBranch.egl_name', resultField: 'partnerName' },
];
