import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Observable, of } from 'rxjs';
import { catchError, filter, map, share, take, tap, timeout } from 'rxjs/operators';
import {
    setApplicationLocation,
    setD365AppUrl,
    setD365EntryPoint,
    setGeoLocation,
} from '../../../store/actions/app.actions';
import { setAgentInfo, setContact, setLead } from '../../../store/actions/user.actions';
import { EglState } from '../../../store/reducers';
import { getRandomHash } from '../../functions/misc.functions';
import { D365PostMessage } from '../../models/app/d365-postmessage';
import { LoggerService } from '../shared/logger.service';
import { PrivateConfigurationService } from '../shared/private-configuration.service';
import { D365ResponseBuilder } from './d365.response.mapper';

@Injectable({ providedIn: 'root' })
export class BaseD365Service {
    private message$: Observable<any> = fromEvent(window, 'message').pipe(
        filter((evt: MessageEvent) => evt?.data?.type !== 'ACTION'),
        map((evt: MessageEvent) => evt?.data),
        share(),
    );

    private DISPATCHER_MAP = {
        applocationresponse: (content) => {
            this.store.dispatch(setApplicationLocation({ location: content.appLocation }));
            this.store.dispatch(setD365EntryPoint({ entryPoint: content.d365EntryPoint }));
            this.store.dispatch(setD365AppUrl({ appUrl: content.d365AppUrl }));
        },
        agentinforequest: (content) =>
            this.store.dispatch(
                setAgentInfo({ a: new D365ResponseBuilder(this.logger).agentInfoResponseToStore(content) }),
            ),
        contactresponse: (content) => this.store.dispatch(setContact({ c: content.queryresult, fcrm: true })),
        leadresponse: (content) => this.store.dispatch(setLead({ l: content.queryresult, fcrm: true })),
        geolocationrespponse: (content) => this.store.dispatch(setGeoLocation({ geolocation: content.position })),
    };

    constructor(
        protected logger: LoggerService,
        protected store: Store<EglState>,
        protected configSrv: PrivateConfigurationService,
        protected injector: Injector,
    ) {
        this.subscribeAutomaticListeners();
    }

    private subscribeAutomaticListeners() {
        this.message$
            .pipe(filter((response) => response?.type in this.DISPATCHER_MAP))
            .subscribe(({ type, content }) => this.DISPATCHER_MAP[type](content));
    }

    protected asyncPostMessage<S>(
        message: D365PostMessage,
        stringifiedResponseField?: string,
        timeoutOverride?: number,
    ): Observable<S> {
        const overrideContentType =
            typeof message?.content === 'object' || (message && typeof message === 'object' && !message.content);
        const id = getRandomHash() + new Date().getTime() + (Math.random() * 10 ** 8).toString(36);
        const originalType = (overrideContentType ? message?.content?.type : null) || '';
        const type = overrideContentType ? `${originalType}${id}` : message?.type;

        const operationName =
            message?.content?.requestmetadata?.operationName || message?.content?.entity || message?.type;
        this.traceHttpEvent('REQUEST', operationName, message);
        parent.window.postMessage(
            {
                ...(message || {}),
                content: overrideContentType
                    ? {
                          ...(message?.content || {}),
                          type,
                      }
                    : message?.content,
            },
            '*',
        );

        return this.message$.pipe(
            filter(
                (response) =>
                    ([message?.type, (message?.type).replace('request', 'response')].includes(response?.type) &&
                        !response?.content?.type) ||
                    [response?.type, response?.content?.type].includes(type),
            ),
            timeout(timeoutOverride || 30 * 1000),
            tap((response) => {
                this.traceHttpEvent<S>('RESPONSE', operationName, response);
            }),
            map(({ content }) =>
                typeof content === 'object' && 'type' in content ? { ...content, type: originalType } : content,
            ),
            map((content) => {
                if (content) {
                    let stringifiedData: string;
                    if (typeof content === 'string' && !stringifiedResponseField) {
                        stringifiedData = content;
                    } else if (
                        typeof content === 'object' &&
                        stringifiedResponseField &&
                        typeof content[stringifiedResponseField] === 'string'
                    ) {
                        stringifiedData = content[stringifiedResponseField];
                    }
                    if (stringifiedData) {
                        try {
                            return JSON.parse(stringifiedData);
                        } catch {}
                    }
                }
                return content;
            }),
            map((result) => result as S),
            catchError((err) => {
                console.error(err);
                return of(null);
            }),
            take(1),
        );
    }

    private traceHttpEvent<T>(
        type: 'REQUEST' | 'RESPONSE',
        operationName: string,
        httpEvent: D365PostMessage | T,
    ): void {
        if (this.configSrv?.config?.traceHttp) {
            this.logger.debug(`[HTTP TRACE - ${type} - ${operationName}]`, httpEvent);
        }
    }

    protected get translateSrv(): TranslateService {
        return this.injector.get(TranslateService);
    }

    protected get genericApiError(): string {
        return this.translateSrv.instant('ERROR.GENERIC.API_MESSAGE');
    }
}
