import { List } from 'lodash';
import { BaseApexApiResponse } from '../../../../common/interfaces/base-apex-api-respose';
import { EglTaxDeduction } from '../../../../common/models/apttus/tables/taxDeduction/egl-tax-deduction';

export interface TaxDeduction {
    id: string;
    code: string;
    name: string;
    percentage: string;
    description: string;
    productId?: string;
}
export class LocalStorageTaxDeductionsWrapper {
    cartId: string;
    taxDeductions: TaxDeduction[];
    constructor() {
        this.taxDeductions = [];
    }
}
