import { Injectable } from '@angular/core';
import { ApiService } from '@congacommerce/core';
import { CartService } from '@congacommerce/ecommerce';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, concatMap, first, map, mergeMap, take, tap } from 'rxjs/operators';
import { FlowTypeService } from '../../../../../modules/flow-type/services/flow-type.service';
import { setV2OrderEntryState } from '../../../../../store/actions/order-entry-v2.actions';
import { resetUserState } from '../../../../../store/actions/user.actions';
import { FlowType } from '../../../../../store/models/flow-type';
import { OrderEntryState_v2 } from '../../../../../store/models/order-entry-state_v2';
import { EglState } from '../../../../../store/reducers';
import { selectFlowType } from '../../../../../store/selectors/order-entry.selectors';
import {
    selectAgentInfo,
    selectCartSegment,
    selectCurrentVirtualAgent,
} from '../../../../../store/selectors/user.selectors';
import { AptSalesProcess } from '../../../../enums/apttus/apt-sales-process';
import { convertSegmentD365toApt, flowTypeToAptSalesProcess } from '../../../../functions/remap.functions';
import { allowedRestoreSalesProcess } from '../../../../functions/verifications.functions';
import { DeepPartial } from '../../../../interfaces/deep-partial';
import { EglCartExtended } from '../../../../models/apttus/tables/cart/egl-cart-extended';
import { LoadingService } from '../../../shared/loading.service';
import { LoggerService } from '../../../shared/logger.service';
import { ApttusService } from '../../apttus.service';
import { EglCartExtendedService } from './egl-cart-extended.service';
import { EglCartLightService } from './egl-cart-light.service';

@Injectable({ providedIn: 'root' })
export class CartUtilityService {
    constructor(
        private store: Store<EglState>,
        private cartSrv: CartService,
        private cartExtendedSrv: EglCartExtendedService,
        private cartLightSrv: EglCartLightService,
        private logger: LoggerService,
        private apiService: ApiService,
        private apttusSrv: ApttusService,
        private flowTypeSrv: FlowTypeService,
    ) {}

    /**
     * Return a new istance of EglCartExtended builded using EglState object
     * @returns a new istance of EglCartExtended builded using EglState object
     */
    public getNewIstanceOfCartFromState(cart = new EglCartExtended()): Observable<EglCartExtended> {
        return combineLatest([
            this.store.select(selectCurrentVirtualAgent),
            this.store.select(selectAgentInfo),
            this.store.select(selectFlowType),
            this.store.select(selectCartSegment),
        ]).pipe(
            take(1),
            map(([va, agentInfo, flowType, segment]) => {
                // egl_sales_process, egl_customer_type hanno valori di
                // default nel caso in cui non presenti dello state
                const egl_sales_process = flowTypeToAptSalesProcess(flowType) || AptSalesProcess.SwitchIn;
                return Object.assign(cart, {
                    egl_sales_process,
                    egl_agency_code: va?.VirtualAgency?.Code,
                    egl_sales_channel: va?.VirtualAgency?.Channel?.Code,
                    egl_DAG_code: agentInfo?.Agent?.Code,
                    egl_customer_type: convertSegmentD365toApt(segment),
                } as DeepPartial<EglCartExtended>);
            }),
        );
    }

    /**
     * Create and active a new cart using an istance created based from EglState; after that OrderEntryState will be reset.
     * @param deleteCurrentCart default = true. If you want to delete current cart before active new cart
     * @param resetFlowType default = false. If you want to apply the recovery rules of the FlowType
     * @returns
     */
    newCartFromState(deleteCurrentCart: boolean = true, resetFlowType: boolean = false): Observable<EglCartExtended> {
        const oldCartId = CartService.getCurrentCartId();

        return this.getNewIstanceOfCartFromState().pipe(
            tap((newCartIstance) => this.logger.info('newIstanceOfCartFromState:', newCartIstance)),
            mergeMap((newCartIstance) =>
                combineLatest([
                    // prima di resettare lo state mi memorizzo le info che dovrò manterenere
                    this.store.select(selectFlowType).pipe(take(1)),
                    <Observable<EglCartExtended>>this.cartSrv.createNewCart(newCartIstance, undefined, true),
                ]),
            ),
            tap(([flowType]) => {
                // resetto lo state e subito dopo allineo il cartSegment, flowType sulla base del carrello creato
                this.store.dispatch(resetUserState()); // questo reset NON perde l'informazione relariva al cartSegment
                this.store.dispatch(
                    setV2OrderEntryState({
                        state: new OrderEntryState_v2(
                            resetFlowType
                                ? !allowedRestoreSalesProcess(flowTypeToAptSalesProcess(flowType))
                                    ? FlowType.SwitchIn
                                    : flowType
                                : flowType,
                        ),
                    }),
                );
            }),
            mergeMap(([, createdCart]) =>
                deleteCurrentCart
                    ? this.cartSrv.deleteCart(Object.assign(new EglCartExtended(), { Id: oldCartId })).pipe(
                          tap((out) => out && this.logger.info(`cart ${oldCartId} deleted`)),
                          map(() => createdCart),
                      )
                    : of(createdCart),
            ),
            mergeMap(() => this.cartSrv.getMyCart().pipe(first())),
            tap((currentCart: EglCartExtended) => {
                currentCart;
                this.logger.info(
                    `created new cart. (cart switched from ${oldCartId} to ${currentCart.Id})`,
                    oldCartId !== currentCart.Id,
                );
            }),
            catchError((e) => {
                this.logger.error('newCartFromState', 'error during create new cart', e);
                return of(null);
            }),
            LoadingService.loaderOperator('Creazione nuovo carrello...'),
        );
    }

    /**
     * if current cart not contains PrimayLines then update cart using EglState; else create new cart using EglState
     * @param deleteCurrentCart default = true. If current cart contains PrimayLines specify if you want to delete current cart before active new cart.
     * @returns result of created/updated cart
     */
    updateOrCreateNewCart(deleteCurrentCart = true): Observable<EglCartExtended> {
        return this.currentCartIsEmpty().pipe(
            mergeMap((isEmpty) => (isEmpty ? this.updateCartFromState() : this.newCartFromState(deleteCurrentCart))),
        );
    }

    /**
     * Verify if curret cart contains PrimaryLines
     * @returns
     */
    currentCartIsEmpty(): Observable<boolean> {
        return this.getMyCartOnce().pipe(map((x) => x?.LineItems?.find((l) => l?.IsPrimaryLine) === undefined));
    }

    /**
     * update current cart overriding cart properties obtains from getNewIstanceOfCartFromState()
     * @returns result of update
     */
    updateCartFromState(): Observable<EglCartExtended> {
        return this.getMyCartOnce().pipe(
            concatMap((currentCart) => this.getNewIstanceOfCartFromState(currentCart)),
            concatMap((cart: EglCartExtended) => this.cartExtendedSrv.update([cart]).pipe(map((c) => c[0]))),
            map((updatedCart) => (this.cartSrv.refreshCart(), updatedCart)),
            map((updatedCart: EglCartExtended) => {
                this.logger.info(`cart updated. ${updatedCart.Id}`);
                console.info(updatedCart);
                return updatedCart;
            }),
        );
    }

    private getMyCartOnce(): Observable<EglCartExtended> {
        return this.cartSrv.getMyCart().pipe(
            take(1),
            map((c: EglCartExtended) => c),
        );
    }

    removeLineItemsFromCart(lineItemsIds: string[]) {
        if (lineItemsIds?.length) {
            return this.apiService.post(
                '/carts/' + CartService.getCurrentCartId() + '/items/delete?price=skip',
                lineItemsIds.map((Id) => ({
                    Id,
                })),
            );
        } else {
            return of(null);
        }
    }

    /**
     * Recupera il carrello corrente (prendendo l'id dal localstorage) da CPQ e lo ripubblica.
     * Ricalcola inoltre anche il flowType
     */
    refreshCurrentCart(): Observable<EglCartExtended> {
        return this.cartSrv.getCartWithId(CartService.getCurrentCartId()).pipe(
            tap((cart) => {
                this.apttusSrv.isItemRemovalInProgress(false);
                this.cartSrv.publish(cart);
            }),
            mergeMap((cart) => combineLatest([of(cart), this.flowTypeSrv.dispatchFlowType()])),
            map(([cart]) => <EglCartExtended>cart),
        );
    }

    public updateCartPriceDate(priceDate: Date): Observable<boolean> {
        return this.cartLightSrv.updateCurrentCartPricingDate(priceDate).pipe(
            map(() => true),
            catchError((err) => {
                this.logger.error(
                    'Aggiornamento pricing date',
                    "Si è verificato un errore durante l'aggiornamento della pricing date. Riprovare scegliendo un'altra data",
                    err,
                    true,
                );
                return of(false);
            }),
            LoadingService.loaderOperator('Aggiornamento carrello'),
        );
    }
}
