import { Injectable } from '@angular/core';
import { ACondition, AFilter, AObjectService } from '@congacommerce/core';
import { EglSalesEligibility, SalesEligibilityData } from '../../../../models/apttus/tables/cart/egl-sales-eligibility';
import { Product } from '../../../../../store/models/order-entry-state_v2';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EglSalesEligibilityService extends AObjectService {
    type = EglSalesEligibility;

    getProductClass(products: (Product & { visibleIdx: number })[]): Observable<SalesEligibilityData[]> {
        const rulesApiCalls$: Observable<SalesEligibilityData>[] = products.map((product) =>
            this.query({
                filters: [
                    new AFilter(
                        EglSalesEligibility,
                        [
                            new ACondition(
                                EglSalesEligibility,
                                'egl_BillBilling__c',
                                'Equal',
                                product.configurations.billCharge ? 'SI' : 'NO'
                            ),
                            new ACondition(
                                EglSalesEligibility,
                                'egl_termsOfPayment__c',
                                'Equal',
                                product.paymentInfo.paymentInstrument
                            ),
                            new ACondition(
                                EglSalesEligibility,
                                'egl_payType__c',
                                'Equal',
                                product.paymentInfo.paymentType
                            ),
                        ],
                        null,
                        'AND'
                    ),
                ],
            }).pipe(
                map((res: EglSalesEligibility[]) => res[0]),
                map((res) => ({
                    productClass: res?.egl_PClass,
                    creditAssignment: res?.egl_CreditAssignment,
                    installmentFlag: res?.egl_InstallmentFlag,
                    withHoldingTax: res?.egl_WithholdingTax,
                    productData: {
                        idx: product.idx,
                        visibleIdx: product.visibleIdx,
                        name: product.name,
                        billCharge: product.configurations.billCharge ? 'SI' : 'NO',
                        paymentInstrument: product.paymentInfo.paymentInstrument,
                        paymentType: product.paymentInfo.paymentType,
                        shippingMethod: product.configurations.invoiceShippingMethod,
                        lineItemId: product.lineItemId,
                    },
                }))
            )
        );
        return forkJoin(rulesApiCalls$).pipe(map((data) => data || []));
    }
}
