export class MorosityRequest {
    Orders: Orders;
}

interface Orders {
    totalSize: number;
    records: RecordMorosity[];
}

export interface RecordMorosity {
    AccountNum: string;
    Ammontare?: number;
    Comments?: string;
    DataVariazioneStato?: string; //è richiesto se il campo IsAdministrative è true.
    FraudType: FraudType;
    PdF: string;
    Priorita?: string;
    StazioneWF?: WFStation; // obbligatorio solo per le chiamate provenienti da Norma e deve essere valorizzato sempre con 3.
    TipologiaChiusura?: TipologiaChiusuraTypes;
    Cmoruepw?: string;
    CmorueGas?: string;
    NomeTemplate?: TemplateNameMorosity | '';
    IsAdministrative?: boolean;
    CodiceFiscaleVolturante?: string;
    CognomeVolturante?: string;
    NomeVolturante?: string;
    // I campi Pod, PdR e AccountNum sono mutuamente esclusivi.
    // Se viene popolato il campo AccountNum, deve essere popolato anche il campo PdF.
    // L'attuale versione della function non permette l'utilizzo di Pod o PdR.
    Pod?: string;
    PdR?: string;
}

export class MorosityResumingRequest {
    Orders: ResumingOrders;
}

interface ResumingOrders {
    totalSize: number;
    records: ResumingRecord[];
}

interface ResumingRecord {
    CodiceContoCliente: string;
    PdF: string;
    ServiceItem: string;
    StazioneWF: string;
    AssetId: string;
    OrderLineItemId: string;
    RevocationReason: string;
    CodiceOTI: string;
    CodicePraticaGTW: string;
    IsAdministrative: boolean;
    ResumeType: string;
    Comments: string;
}

export enum TipologiaChiusuraTypes {
    GASSimple = 1,
    PowerSimple,
    ComplexClose,
    CessAmmCA1,
    CessAmmCAE1,
    CessAmmCA2,
    CessAmmCA3,
    CessAmmCAE7,
    CessAmmCAE3,
    CessAmmCA7,
    CessAmmCA4,
    CessAmmCA5,
    CessAmmCAE4,
    CessAmmCAE5,
    CessAmmCAE8RV, // RIFIUTO VOLTURA
    CessAmmCAE8MD, // MOTIVI DIVERSI
}

export enum WFStation {
    ODC_Semplice = 1,
    ODC_Complesso,
    ODC_Default,
    CessazioneAmministrativa,
    Riapertura,
}

export enum FraudType {
    OrdiniDiChiusura = 1,
    CessazioneAmministrativa,
}

export enum TemplateNameMorosity {
    LetteraCessazioneRifiutoVoltura = 'LETTERA_CESSAZIONE_RIFIUTO_VOLTURA',
    LetteraRisoluzione = 'LETTERA_RISOLUZIONE',
    LetteraErrataAttivazioneNonNoto = 'LETTERA_ERRATA_ATTIVAZIONE_NON_NOTO',
    LetteraErrataAttivazioneNoto = 'LETTERA_ERRATA_ATTIVAZIONE_NOTO',
}
