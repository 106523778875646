import { ProductAttributeRule } from '@congacommerce/ecommerce';

export class EglProductAttributeRule extends ProductAttributeRule {
    Name: string;
}

export enum ConditionCriteriaEntity {
    Product = '$.Apttus_Config2__ProductId__r.',
    PAV = '$.Apttus_Config2__AttributeValueId__r.',
}
