import { Component } from '@angular/core';

@Component({
    selector: 'egl-fullscreen-outlet',
    template: `<main class="fullscreen-outlet">
        <router-outlet></router-outlet>
    </main>`,
})
export class FullscreenOutletComponent {
    constructor() {}
}
