export enum PowerConsumptionUse {
    ConsumoBasso = 'CONSUMO BASSO',
    ConsumoMedio = 'CONSUMO MEDIO',
    ConsumoAlto = 'CONSUMO ALTO',
}

export enum GasConsumptionUse {
    Cottura = 'COTTURA',
    AcquaCalda = 'ACQUA CALDA',
    Riscaldamento = 'RISCALDAMENTO',
    // AltriUsi = 'ALTRI USI',
    // Dichiarato = 'DECLARED',
    // AltriUsiTecnologico = 'USO TECNOLOGICO',
}

export enum GasConsumptionUseMB {
    usoNonProduttivo = 'ALTRI USI',
    usoAttivitaProduttiva = 'ALTRI USI TECNOLOGICO',
}

export enum GasConsumptionUseVariationMB {
    c1 = '080 ** RISCALDAMENTO',
    c2 = '030 ** ACQUA CALDA + COTTURA CIBI',
    c3 = '070 ** ACQUA CALDA + COTTURA CIBI + RISCALDAMENTO',
    c4 = '120 ** CONDIZIONAMENTO',
    c5 = '150 ** CONDIZIONAMENTO + RISCALDAMENTO',
    t1 = '170 ** TECNOLOGICO',
    t2 = '200 ** RISCALDAMENTO + TECNOLOGICO',
}

export type PowerTypeOfUse = PowerConsumptionUse;
export type GasTypeOfUse = GasConsumptionUse[] | GasConsumptionUseMB | GasConsumptionUseVariationMB;
export type TypeOfUse = PowerTypeOfUse | GasTypeOfUse;
