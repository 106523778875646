import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@congacommerce/elements';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { IconComponent } from '../../common/components/icon/icon.component';
import { DirectiveModule } from '../../common/directives/directive.module';
import { CommonPipesModule } from '../../common/pipes/pipes.module';
import { PanelProductInfoComponent } from './components/ap-summary-page/components/panels-product-info/panel-product-info/panel-product-info.component';

@NgModule({
    declarations: [PanelProductInfoComponent, IconComponent],
    imports: [
        CommonModule,
        CommonPipesModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        DirectiveModule,
        IconModule,
        AngularSvgIconModule.forRoot(),
    ],
    exports: [PanelProductInfoComponent, IconComponent],
})
export class AdoptPanelSharedModule {}
