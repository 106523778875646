import { Component, Input } from '@angular/core';

@Component({
    selector: 'egl-order-entry-title',
    templateUrl: './order-entry-title.component.html',
    styleUrls: ['./order-entry-title.component.scss'],
})
export class OrderEntryTitleComponent {
    @Input() title: string;
    @Input() description: string;
}
