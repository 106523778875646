import _ from 'lodash';
import { FullState } from '../../../../../store/models/full-state';
import { OrderEntryState_v2, Product } from '../../../../../store/models/order-entry-state_v2';
import { AptProductType } from '../../../../enums/apttus/apt-product-type';
import { D365CustomerSegment } from '../../../../enums/d365/d365-customer-segment';
import { SupplyUse } from '../../../../enums/shared/destination-use.enum';
import { address2Indirizzo, getFullAddressString } from '../../../../functions/address.functions';
import { cleanObj, findProductToShowResidentialAddress } from '../../../../functions/misc.functions';
import { calcTaxDeductionPrice } from '../../../../functions/price.functions';
import { containsCommodity, isTechProduct } from '../../../../functions/verifications.functions';
import { KeyValue } from '../../../../models/apttus/request-response/cart-to-quote-request';
import { ProductAttributeValues } from '../attribute-factory';

export class AttributeValues implements ProductAttributeValues {
    readonly OPTION_OR_TECHNICAL_PRODUCT = [
        AptProductType.Accessorio,
        AptProductType.Sconti,
        AptProductType.ScontoStandAloneGas,
        AptProductType.ScontoStandAloneLuce,
        AptProductType.ScontoStandAloneExtracommodity,
        AptProductType.Servizio,
        AptProductType.FornituraLuce,
        AptProductType.FornituraGas,
    ];
    protected state: FullState;
    protected currentProduct: Product;
    protected attr: AttrKeyValue = {};
    protected get orderEntry(): OrderEntryState_v2 {
        return this.state?.orderEntryV2;
    }

    keyValue(): KeyValue[] {
        return planeObjToKeyValue(this.attr || []);
    }

    constructor(state: FullState, currentProduct: Product) {
        this.state = state;
        this.currentProduct = currentProduct;
        this.attributesMapping();
    }
    attributesMapping(): void {
        // base mapping
        const isMicrobusiness = this.state?.user?.cartSegment === D365CustomerSegment.Microbusiness;
        const partnership = this.currentProduct?.configurations?.partnership;
        const indirizzoFornitura = address2Indirizzo(this.currentProduct?.deliveryAddress);
        const indirizzoResidenza = address2Indirizzo(this.orderEntry?.contact?.mainAddress);
        const stessoIndirizzoResidenza =
            getFullAddressString(indirizzoFornitura) === getFullAddressString(indirizzoResidenza);

        this.attr['egl_partnership__c'] = this.currentProduct?.configurations?.partnership?.selected;
        this.attr['egl_partner_accountnumber__c'] = partnership?.fw?.accountNumber;
        this.attr['egl_partner_customer_cluster__c'] = partnership?.fw?.cluster;
        this.attr['egl_selling_order_number__c'] = this.currentProduct?.configurations?.relatedOrderNumber;
        this.attr['egl_code_NewDom'] = this.currentProduct?.paymentInfo?.numeroDomiciliazione;

        this.attr['egl_propertyType__c'] = this.currentProduct?.configurations?.propertyType;
        this.attr['egl_Appointment_check__c'] = this.currentProduct?.appointment?.available;

        this.attr['egl_price_xComm__c'] =
            !containsCommodity(this.currentProduct?.productType) && !isTechProduct(this.currentProduct?.productType)
                ? _.isNaN(Number(this.currentProduct?.prices?.netPrice))
                    ? '0'
                    : Number(this.currentProduct?.prices?.netPrice).toFixed(2).toString()
                : '0';

        if (this.currentProduct?.configurations?.creditAssignmentAttr === 'SI') {
            this.attr['egl_net_amount_transferred__c'] = calcTaxDeductionPrice(this.currentProduct)
                .toFixed(2)
                .toString();
        }

        if (isMicrobusiness) {
            this.attr['egl_commercial_branch__c'] = this.currentProduct?.businessDetails?.activity;
            this.attr['egl_Commodity_Sector__c'] = this.currentProduct?.businessDetails?.sector;
            this.attr['egl_Customer_ATECO_Code__c'] = this.currentProduct?.businessDetails?.ateco;
            this.attr['egl_partner_subscriber_taxcode__c'] = partnership?.fw?.taxVatCode;
        } else {
            this.attr['egl_partner_subscriber_fiscalcode__c'] = partnership?.fw?.taxVatCode;
            // isResidential: true if is not technical product and i have question residential address in orderEntry
            // or is one salesprocess started from existing asset and his 'destinationUse' == 'Domestico'
            if (!this.OPTION_OR_TECHNICAL_PRODUCT.includes(this.currentProduct?.productType)) {
                const isResidentialByOrderEntry = findProductToShowResidentialAddress({
                    products: [this.currentProduct],
                    cartSegment: this.state?.user?.cartSegment,
                })
                    ? stessoIndirizzoResidenza
                    : null;
                const isResidentialByAsset = this.currentProduct?.configurations?.supplyUse
                    ? this.currentProduct.configurations.supplyUse === SupplyUse.DomesticoResidenziale
                    : null;

                this.attr['egl_isresidential__c'] = isResidentialByOrderEntry || isResidentialByAsset;
            }
        }
    }
}

const planeObjToKeyValue = (obj: any): KeyValue[] => {
    const cleanAttibutes = cleanObj(obj);
    return Object.entries(cleanAttibutes)?.map(([key, value]) => ({ key, value: value.toString() }));
};

export interface AttrKeyValue {
    [key: string]: string | number | boolean;
}
