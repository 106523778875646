export class CheckPdfResponse {
    esito: EsitoResponse;
    modalitaOperativa?: string;
    descrizioneEsito?: string;
}

export enum EsitoResponse {
    OK = 'OK',
    KO = 'KO',
}

// const req ={
//     filter: {
//         key:    'CODICEPRATICA' |
//                 'CODICECLIENTE' |
//                 'CF' |
//                 'DATACREAZIONE' |
//                 'STATOPRATICA' |
//                 'TIPOFORNITURA' |
//                 'POD-PDR'
//         value: string; // in caso di data formato GG-MM-AAAA
//     }[];

//     sortable: {
//         key: 'TIPOPRATICA' | 'CODICE-ERRORE';
//         type: 'ASC' | 'DESC';
//     };

//     take: number; // quanti record a video il servizio deve restituire
//     skip: number;
// }

const req1 = {
    codiceCliente: '',
    tipoProcesso: '', // salesprocess
    codiceForitura: '',
    dataEvento: '',
    pod: '',
    pdr: '',
    codiceFiscale: '',
    codicePratica: '',
    stateModel: '',
    ordinamentoPer: 'TIPOPRATICA',
    tipoOrdinamento: 'ASC',
    take: 50, // quanti record a video il servizio deve restituire
    skip: 0,
};

const res = {
    result: '001',
    status: 'Success',
    response: {
        totalCount: 1222,
        list: [
            {
                id: 1,
                insertDate: '2022-03-18T15:00:44.51',
                codiceCliente: 'xxxx',
                salesProcess: 'Variazione Anag',
                supplyCode: 'xxxx',
                source: 'D365/BPM/BW6',
                supplyPoint: 'string',
                dataEvento: '2022-03-02T00:00:00',
                commodityType: 'GAS',
                tipologiaServizio: 'stri',
                tipoRichiesta: 'str',
                cfCliente: 'string',
                flgPersgiur: 's',
                pivaCliente: 'string',
                anagBenNome: 'string',
                nomeCliente: 'string',
                anagBenCognome: 'string',
                cognomeCliente: 'string',
                emailCliente: 'string',
                telefonoCliente: 'string',
                nomeReferente: 'string',
                referente: 'string',
                cognomeReferente: 'string',
                emailReferente: 'string',
                telefonoReferente: 'string',
                ubiToponimo: 'string',
                ubiVia: 'string',
                ubiCiv: 'string',
                ubiCap: 'string',
                ubiIstat: 'string',
                ubiLocalita: 'string',
                ubiComune: 'string',
                ubiProv: 'string',
                ubiNazione: 'string',
                ubiAltro: 'string',
                codPdr: 'string',
                codPod: 'string',
                classificazioneFinale: 'string',
                flgPersgiurSito: 's',
                pivaSito: 'string',
                esaToponimo: 'string',
                esaVia: 'string',
                esaCiv: 'string',
                esaCap: 'string',
                esaIstat: 'string',
                esaLocalita: 'string',
                esaComune: 'string',
                esaProvincia: 'string',
                esaNazione: 'string',
                esaAltro: 'string',
                residenza: 'string',
                dataValRes: 'string',
                anagFattCf: 'string',
                anagFattPiva: 'string',
                anagFattCfStraniero: 's',
                anagFattNome: 'string',
                anagFattCognome: 'string',
                anagFattRagSoc: 'string',
                anagBenCf: 'string',
                productName: 'string',
                codiceUfficio: 'string',
                settMerceologico: 'string',
                splitPayment: 'string',
                ragSocCliente: 'string',
                segTension: 'string',
                recapBenToponimo: 'string',
                recapBenVia: 'string',
                recapBenCiv: 'string',
                recapBenCap: 'string',
                recapBenIstat: 'string',
                recapBenLocalita: 'string',
                recapBenComune: 'string',
                recapBenProv: 'string',
                recapBenNazione: 'string',
                recapBenAltro: 'string',
                dataInizioContr: 'string',
                aliquotaIva: 'string',
                regIva: 'string',
                aliquotaAccise: 'string',
                codAssog: 'string',
                addizRegionale: 'string',
                addizionaleProvinciale: 'string',
                addizionaleComunale: 'string',
                altreInformazioni: 'string',
                flgMercatoLibero: 's',
                mittente: 'string',
                stato: 'Aperto',
                stateModel: 'Da processare',
            },
        ],
    },
};

const reqCopy = {
    id: 1,
    insertDate: '2022-03-18T15:00:44.51',
    codiceCliente: 'xxxx',
    salesProcess: 'Variazione Anag',
    supplyCode: 'xxxx',
    source: 'D365/BPM/BW6',
    supplyPoint: 'string',
    dataEvento: '2022-03-02T00:00:00',
    commodityType: 'GAS',
    tipologiaServizio: 'stri',
    tipoRichiesta: 'str',
    cfCliente: 'string',
    flgPersgiur: 's',
    pivaCliente: 'string',
    anagBenNome: 'string',
    nomeCliente: 'string',
    anagBenCognome: 'string',
    cognomeCliente: 'string',
    emailCliente: 'string',
    telefonoCliente: 'string',
    nomeReferente: 'string',
    referente: 'string',
    cognomeReferente: 'string',
    emailReferente: 'string',
    telefonoReferente: 'string',
    ubiToponimo: 'string',
    ubiVia: 'string',
    ubiCiv: 'string',
    ubiCap: 'string',
    ubiIstat: 'string',
    ubiLocalita: 'string',
    ubiComune: 'string',
    ubiProv: 'string',
    ubiNazione: 'string',
    ubiAltro: 'string',
    codPdr: 'string',
    codPod: 'string',
    classificazioneFinale: 'string',
    flgPersgiurSito: 's',
    pivaSito: 'string',
    esaToponimo: 'string',
    esaVia: 'string',
    esaCiv: 'string',
    esaCap: 'string',
    esaIstat: 'string',
    esaLocalita: 'string',
    esaComune: 'string',
    esaProvincia: 'string',
    esaNazione: 'string',
    esaAltro: 'string',
    residenza: 'string',
    dataValRes: 'string',
    anagFattCf: 'string',
    anagFattPiva: 'string',
    anagFattCfStraniero: 's',
    anagFattNome: 'string',
    anagFattCognome: 'string',
    anagFattRagSoc: 'string',
    anagBenCf: 'string',
    productName: 'string',
    codiceUfficio: 'string',
    settMerceologico: 'string',
    splitPayment: 'string',
    ragSocCliente: 'string',
    segTension: 'string',
    recapBenToponimo: 'string',
    recapBenVia: 'string',
    recapBenCiv: 'string',
    recapBenCap: 'string',
    recapBenIstat: 'string',
    recapBenLocalita: 'string',
    recapBenComune: 'string',
    recapBenProv: 'string',
    recapBenNazione: 'string',
    recapBenAltro: 'string',
    dataInizioContr: 'string',
    aliquotaIva: 'string',
    regIva: 'string',
    aliquotaAccise: 'string',
    codAssog: 'string',
    addizRegionale: 'string',
    addizionaleProvinciale: 'string',
    addizionaleComunale: 'string',
    altreInformazioni: 'string',
    flgMercatoLibero: 's',
    mittente: 'string',
    stato: 'Aperto',
    stateModel: 'Da processare',
};
