import { Expose } from "class-transformer";

export class D365Incident {

    @Expose()
    title: string;

    @Expose()
    ticketnumber: string;

    @Expose({ name: '_customerid_value@OData.Community.Display.V1.FormattedValue' })
    customerid: string;

    @Expose({ name: 'createdon@OData.Community.Display.V1.FormattedValue' })
    createdon: string;

    @Expose()
    incidentid: string;

    @Expose({ name: 'caseorigincode@OData.Community.Display.V1.FormattedValue' })
    caseorigincode: string;

    @Expose({ name: 'statecode@OData.Community.Display.V1.FormattedValue' })
    statecode: string;

    @Expose({ name: 'statuscode@OData.Community.Display.V1.FormattedValue' })
    statuscode: string;

    @Expose({ name: 'egl_caserequesttype@OData.Community.Display.V1.FormattedValue' })
    caserequesttype: string;

    @Expose({ name: 'egl_casecharacteristic@OData.Community.Display.V1.FormattedValue' })
    casecharacteristic: string;

    @Expose({ name: '_egl_caselevel1id_value@OData.Community.Display.V1.FormattedValue' })
    caselevel1id: string;

    @Expose({ name: '_egl_caselevel2id_value@OData.Community.Display.V1.FormattedValue' })
    caselevel2id: string;

    @Expose({ name: '_egl_caselevel3id_value@OData.Community.Display.V1.FormattedValue' })
    caselevel3id: string;

}


