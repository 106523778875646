import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AptPaymentInstrument } from '../../../common/enums/apttus/apt-payment-instrument';
import { BaseApiResponse, StatusResponse } from '../../../common/interfaces/base-api-response';
import { PaymentTool, PaymentToolResponse } from '../../../common/models/app/payment-tools.response';
import { ServiceError } from '../../../common/models/app/service-error';
import { ApiMngApi, BaseProvider } from '../../../common/providers/base-provider';
import { ApiService } from '../../../common/services/shared/api.service';
import { LoggerService } from '../../../common/services/shared/logger.service';
import { PrivateConfigurationService } from '../../../common/services/shared/private-configuration.service';
import { CeaseExpiredCdcResponse } from '../models/domiciliation-ceaseExpiredCdc.response';
import { UnusedCdCData, UnusedCdCResponse } from '../models/domiciliation-getUnusedCdC.response';

@Injectable({ providedIn: 'root' })
export class DomiciliationProvider extends BaseProvider {
    constructor(
        private api: ApiService,
        translateSrv: TranslateService,
        protected configSrv: PrivateConfigurationService,
        private loggerService: LoggerService,
    ) {
        super(configSrv, translateSrv);
    }

    /**
     * @description: Recupera le carte di credito inutilizzate
     * @param AccountNumber: billingAccount
     * @return: Observable<UnusedCdCResponse>
     */

    getUnusedCdC(AccountNumber: string): Observable<UnusedCdCData[]> {
        return this.api
            .postAsync<BaseApiResponse<UnusedCdCResponse>>(this.getApiMngApiUrl(ApiMngApi.GetUnusedCdc), {
                AccountNumber,
            })
            .pipe(
                tap((res) => {
                    if (res?.status !== StatusResponse.Success) {
                        throw new ServiceError(
                            res?.errorManagement?.errorCode || 'GetUnusedCdc error',
                            res?.errorManagement?.errorDescription || this.GENERIC_ERROR,
                            'LOW',
                        );
                    }
                }),
                catchError((err: ServiceError) => {
                    this.loggerService.error('Caricamento strumenti di pagamento', err?.message, err, true);
                    return of(null);
                }),
                map((res: BaseApiResponse<UnusedCdCResponse>) => res?.response?.UnusedCdCData || []),
            );
    }

    ceaseExpiredCdc(paymentTool: UnusedCdCData): Observable<BaseApiResponse<CeaseExpiredCdcResponse>> {
        return this.api
            .postAsync<BaseApiResponse<CeaseExpiredCdcResponse>>(this.getApiMngApiUrl(ApiMngApi.CeaseExpiredCdc), {
                paymentToolId: paymentTool.PaymentToolId,
            })
            .pipe(
                tap((res) => {
                    if (
                        res?.status !== StatusResponse.Success ||
                        res?.response?.response?.status !== StatusResponse.Success
                    ) {
                        throw new ServiceError(
                            res?.errorManagement?.errorCode || 'CeaseExpiredCdc error',
                            res?.errorManagement?.errorDescription || this.GENERIC_ERROR,
                            'LOW',
                        );
                    }
                }),
            );
    }

    /**
     * @description: Recupera la lista delle modalità di pagamento. Se il billingPreference viene passato in input
     * allora vengono recuperati solo gli strumenti di pagamento attivi
     * @param billingAccount: conto
     * @param billingPreference: preferenze
     */
    getPaymentTools(billingAccount: string, billingPreference?: string): Observable<PaymentTool[]> {
        return this.api
            .postAsync<BaseApiResponse<PaymentToolResponse>>(this.getApiMngApiUrl(ApiMngApi.GetPaymentTools), {
                billingAccount,
                billingPreference,
            })
            .pipe(
                tap((res) => {
                    if (res?.status !== StatusResponse.Success || !res?.response) {
                        this.loggerService.error('getPaymentTool', 'Error calling "getPaymentTool" api', false);
                        throw new Error(res?.errorManagement?.errorDescription);
                    }
                }),
                map((res) =>
                    (res.response?.paymentTools || []).map((p) => {
                        const uniqueBillingPreferences = p.billingPreference.filter((billingFilter, index) => {
                            const firstIndex = p.billingPreference.findIndex(
                                (billingFind) =>
                                    billingFind.billingPreferenceCode === billingFilter.billingPreferenceCode,
                            );
                            return index === firstIndex;
                        });
                        return {
                            ...p,
                            type: p.creditCardNumber
                                ? AptPaymentInstrument.CartaCredito
                                : AptPaymentInstrument.AddebitoCC,
                            billingPreference: uniqueBillingPreferences,
                        };
                    }),
                ),
            );
    }
}
