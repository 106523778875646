<div
    class="egl-spinner"
    [ngStyle]="{
        'padding-top': config?.paddingTop,
        'padding-bottom': config?.paddingBottom,
        'padding-right': config?.paddingRight,
        'padding-left': config?.paddingLeft,
        left: config?.left,
        position: config?.position
    }"
>
    <div class="spinner-border" role="status">
        <span class="sr-only">Caricamento...</span>
    </div>
</div>
