import { Injectable } from '@angular/core';
import { UserService } from '@congacommerce/ecommerce';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AptUserService extends UserService {
    /**
     * Ovverride default functionality that retrieve language from Salesforce configuration.
     * @returns
     */
    getLanguage(): Observable<string> {
        return of(environment.defaultLanguage);
    }
}
