import { ATable, AObject, AField } from '@congacommerce/core';
import { AptPaymentType } from '../../../../enums/apttus/apt-payment-type';
import { AptPaymentInstrument } from '../../../../enums/apttus/apt-payment-instrument';
import { AptProductType } from '../../../../enums/apttus/apt-product-type';

@ATable({
    sobjectName: 'egl_tax_deduction__mdt',
    aqlName: null,
})
export class EglTaxDeduction extends AObject {
    @AField({ soql: 'egl_tax_deduction_id__c' })
    egl_tax_deduction_id: AptProductType;

    @AField({ soql: 'egl_tax_deduction_percentage__c' })
    egl_tax_deduction_percentage: AptPaymentType;

    @AField({ soql: 'egl_tax_deduction_description__c' })
    egl_tax_deduction_description: AptPaymentInstrument;
}
