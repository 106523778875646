// Questa response non è conforme allo standard delle altre response di Apttus. Sarebbe da rifattorizzare con Apttus
export class ReworkResponse {
    Status: ReworkStatusResponse;
    Result: 'Error' | 'Success';
    ErrorMessage?: string;
    constructor() {
        this.Status = ReworkStatusResponse.Failed;
    }
}

export enum ReworkStatusResponse {
    Success = '001',
    Failed = '002',
}
