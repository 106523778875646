import { Injectable, Injector } from '@angular/core';
import {
    ACondition,
    AObjectService,
    CacheService,
    ConfigurationService,
    MetadataService,
    PlatformService,
} from '@congacommerce/core';
import { ProductAttributeGroupMemberExtended } from '../../../../models/apttus/tables/product/egl-product-attribute-group-member-extended';
import { tap, map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SequenceBehavior } from '../../utility/egl-product-option.service';

@Injectable({
    providedIn: 'root',
})
export class EglProductAttributeGroupMemberService extends AObjectService {
    type = ProductAttributeGroupMemberExtended;
    private productAttributeGroupCache$: { [key: string]: Observable<ProductAttributeGroupMemberExtended[]> } = {};
    constructor(
        cacheService: CacheService,
        platformService: PlatformService,
        metadataService: MetadataService,
        configurationService: ConfigurationService,
        injector: Injector
    ) {
        super(cacheService, platformService, metadataService, configurationService, injector);
    }
    getProductAttributeGroupMembers(productId: string): Observable<ProductAttributeGroupMemberExtended[]> {
        this.productAttributeGroupCache$[productId] =
            this.productAttributeGroupCache$[productId] ||
            this.where([new ACondition(this.type, 'Apttus_Config2__ProductId__c', 'Equal', productId)]).pipe(
                shareReplay(1)
            );
        return this.productAttributeGroupCache$[productId];
    }

    hasOrderEntryConfigurableAttributeGroup(productId: string): Observable<boolean> {
        return this.getProductAttributeGroupMembers(productId).pipe(
            map(
                (attributeGroups) =>
                    !!(attributeGroups || []).find(
                        (group) => group.Sequence === SequenceBehavior.HiddenButRequiredIntoOrderEntry
                    )
            )
        );
    }
}
