import { Injectable, Injector } from '@angular/core';
import { CartService, ChargeType, PriceListItem, Product, ProductService } from '@congacommerce/ecommerce';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { switchMap, map, filter, take } from 'rxjs/operators';
import { set, first, defaultTo } from 'lodash';
import * as _moment from 'moment';
import { MemoizeAll } from 'lodash-decorators';
import { EglProductExtended } from '../../../../models/apttus/tables/product/egl-product-extended';
import { CacheService, PlatformService, MetadataService, ConfigurationService } from '@congacommerce/core';
import { LoggerService } from '../../../shared/logger.service';
import { EglCartExtended } from '../../../../models/apttus/tables/cart/egl-cart-extended';
import { isNullOrUndefined } from '../../../../functions/verifications.functions';
import { EglPriceListItem } from '../../../../models/apttus/tables/product/egl-price-list-item';

@Injectable({
    providedIn: 'root',
})
export class EglProductService extends ProductService {
    /**
     *
     */
    constructor(
        cacheService: CacheService,
        platformService: PlatformService,
        metadataService: MetadataService,
        configurationService: ConfigurationService,
        injector: Injector,
        private logger: LoggerService,
        private cartSrv: CartService
    ) {
        super(cacheService, platformService, metadataService, configurationService, injector);
    }

    @MemoizeAll()
    fetch(id: string): Observable<EglProductExtended> {
        //Controllare se è possibile rimuovere il behaviorsubject senza regressioni
        console.info('Inside custom Product Service', id);
        const subject = new BehaviorSubject(null);
        this.plService
            .getPriceListId()
            .pipe(
                switchMap((priceListId) => {
                    const productRes$ = this.turboApiService.get(
                        `/pricing/dataadmin/turbo/search/pricelists/${priceListId}/products/${id}`
                    );
                    const categories$ = this.categoryService.getCategoriesByProductId(id);
                    return combineLatest([productRes$, categories$]);
                }),
                switchMap(([productRes, categories]) => {
                    const product = plainToClass(EglProductExtended, productRes?.Entity, {
                        excludeExtraneousValues: true,
                    });
                    set(product, 'Categories', categories);
                    return this.translatorService.translateData([product]);
                }),
                map(first),
                take(1)
            )
            .subscribe((product) => subject.next(product));
        return subject.pipe(filter((r) => r != null));
    }

    filterInvalidProducts(products: Product[]) {
        const cart = <EglCartExtended>this.cartSrv['state']?.getValue();
        let productList = [];
        if (products?.length) {
            const pricingDate = _moment(cart?.PricingDate || new Date()).valueOf();
            productList = (products as unknown as EglProductExtendedFilter[]).filter((p: EglProductExtendedFilter) => {
                const pliList = defaultTo(
                    (p?.PriceLists || []).filter(
                        (item) =>
                            item.ChargeType === ChargeType.StandardPrice || item.ChargeType === ChargeType.Subscription
                    ),
                    [(p?.PriceLists || [])[0]]
                ) as unknown as EglPriceListItemFilter[];
                const isProductExpired =
                    (p.ExpirationDate && p.ExpirationDate < pricingDate) ||
                    (p.EffectiveDate && p.EffectiveDate > pricingDate);
                let isPliExpired = true;
                pliList.forEach((pli) => {
                    if (
                        (!isNullOrUndefined(pli?.ListPrice) || !isNullOrUndefined(pli?.egl_percent_value)) &&
                        (!pli.ExpirationDate || pli.ExpirationDate > pricingDate) &&
                        (!pli.EffectiveDate || pli.EffectiveDate < pricingDate)
                    ) {
                        isPliExpired = false;
                    }
                });
                if (!isProductExpired && !isPliExpired) {
                    return p;
                }
                this.logger.warn(
                    `Product '${p.Name} - ${p.Id}' has not valid PLI so has been removed - Is product expired: ${isProductExpired} - Is PLI expired: ${isPliExpired}`
                );
            });
        }

        return productList;
    }
}

type EglProductExtendedFilter = Omit<EglProductExtended, 'ExpirationDate' | 'EffectiveDate'> & {
    ExpirationDate: number;
    EffectiveDate: number;
};

type EglPriceListItemFilter = Omit<EglPriceListItem, 'ExpirationDate' | 'EffectiveDate'> & {
    ExpirationDate: number;
    EffectiveDate: number;
};
