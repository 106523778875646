import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { DdlElement } from '../../../../../interfaces/ddl-element';

@Component({
    selector: 'egl-header-dropdown',
    templateUrl: './header-dropdown.component.html',
    styleUrls: ['./header-dropdown.component.scss'],
})
export class HeaderDropdownComponent {
    @Input() lov: Array<DdlElement>;
    @Input() placeholder: string;
    @Output() select = new EventEmitter<any>();
    @Output() discard = new EventEmitter<any>();
    @ViewChild('ddContent', { static: false }) ddContent: ElementRef;
    isOpen: boolean = false;

    constructor() {}

    onItemClick(value: string): void {
        this.select.emit(value);
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.ddContent?.nativeElement?.contains(event.target)) {
            this.isOpen = !this.isOpen;
        } else {
            this.isOpen = false;
        }
    }

    onDiscard(): void {
        this.discard.emit(this.selectedValue);
    }

    public get selectedValue(): DdlElement {
        if (!this.lov) return undefined;
        return this.lov.find((l: DdlElement) => l.selected === true);
    }

    getAsAny(value: unknown): any {
        return value;
    }
}
