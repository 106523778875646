import { AField, AObject, ATable } from '@congacommerce/core';

@ATable({
    sobjectName: 'Apttus_Config2__OrderLineItem__c',
})
export class EglOrderLineItem extends AObject {
    @AField({ soql: 'egl_GTW_EffectiveDate' })
    egl_GTW_EffectiveDate: string;

    @AField({ soql: 'egl_GTW_practicalCode' })
    egl_GTW_practicalCode: string;

    @AField({ soql: 'egl_GTW_Status' })
    egl_GTW_Status: string;

    @AField({ soql: 'egl_GTW_Timestamp' })
    egl_GTW_Timestamp: Date;

    @AField({ soql: 'egl_GTW_praticalSIICode' })
    egl_GTW_praticalSIICode: string;

    @AField({ soql: 'egl_GTW_TR_Causal' })
    egl_GTW_TR_Causal: string;

    @AField({ soql: 'egl_GTW_TR_Description' })
    egl_GTW_TR_Description: string;
}
