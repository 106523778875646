export enum AptQuoteStatus {
    Cancellato = 'CANCELLATO',
    InAttesaFirma = 'IN ATTESA FIRMA',
    Firmato = 'FIRMATO',
    InAttesaConferma = 'IN ATTESA CONFERMA',
    Confermato = 'CONFERMATO',
    DaGestire = 'DA GESTIRE',
    DaValidareBo = 'DA VALIDARE BO',
    Rifiutato = 'RIFIUTATO',
    Sospeso = 'SOSPESO',
    Completato = 'COMPLETATO',

    InGestione = 'IN GESTIONE',
    InCompilazione = 'IN COMPILAZIONE',

    InAttesaPagamento = 'IN ATTESA DI PAGAMENTO',
    PagamentoNonEffettuato = 'PAGAMENTO NON EFFETTUATO DAL CLIENTE',
}
