import { ErrorHandler, Injectable } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { AppInsightsService } from './app-insights.service';

@Injectable({
    providedIn: 'root',
})
export class MonitoringErrorHandler extends ErrorHandler {
    constructor(private appInsightsService: AppInsightsService) {
        super();
    }

    handleError(error: any): void {
        this.appInsightsService.logException(error, SeverityLevel.Critical); // Manually log exception
        super.handleError(error);
    }
}
