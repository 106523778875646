import { Injectable, Injector } from '@angular/core';
import {
    AssetLineItem,
    Product,
    CartItem,
    OrderLineItem,
    ProductAttributeValue,
    ProductOptionService,
    QuoteLineItem,
    ProductAttributeGroupMember,
} from '@congacommerce/ecommerce';
import { of, combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
    get,
    find,
    isNil,
    set,
    isEmpty,
    cloneDeep,
    first,
    filter as _filter,
    defaultTo,
    map as _map,
    remove,
} from 'lodash';
import { CacheService, PlatformService, MetadataService, ConfigurationService } from '@congacommerce/core';
import { EglProductService } from '../tables/product/egl-product.service';
import * as moment from 'moment';
import { mapOrEmpty } from '../../../functions/observable-operators';
import { operationTypeOrFlowTypeToSalesProcess } from '../../../functions/remap.functions';
import { FlowType } from '../../../../store/models/flow-type';
import { LoggerService } from '../../shared/logger.service';
import { EglState } from '../../../../store/reducers';
import { Store } from '@ngrx/store';
import { selectFlowType } from '../../../../store/selectors/order-entry.selectors';

@Injectable()
export class EglProductOptionService extends ProductOptionService {
    configurationType: ConfigurationType = ConfigurationType.ProductDetail;

    private CONFIGURATION_TYPE_SEQUENCE_BEHAVIOR: {
        [key in ConfigurationType]: (sequence: number) => boolean;
    } = {
        [ConfigurationType.OrderEntry]: (sequence) =>
            ![SequenceBehavior.HiddenButRequiredIntoOrderEntry].includes(sequence),
        [ConfigurationType.ProductDetail]: (sequence) =>
            [
                SequenceBehavior.Hidden,
                SequenceBehavior.HiddenButRequiredIntoOrderEntry,
                SequenceBehavior.HiddenButRequiredInSaleabilityModal,
            ].includes(sequence),
        [ConfigurationType.SaleabilityModal]: (sequence) =>
            ![SequenceBehavior.HiddenButRequiredInSaleabilityModal].includes(sequence),
    };

    constructor(
        cacheService: CacheService,
        platformService: PlatformService,
        metadataService: MetadataService,
        configurationService: ConfigurationService,
        injector: Injector,
        private logger: LoggerService,
        private eglProductService: EglProductService,
        private store: Store<EglState>
    ) {
        super(cacheService, platformService, metadataService, configurationService, injector);
    }

    getProductOptionTree(
        productId: string,
        relatedTo?: CartItem | QuoteLineItem | AssetLineItem | OrderLineItem,
        applyFilter?: 'none' | 'items' | 'changes',
        changes?: Array<CartItem>,
        inputBundleProduct?: Product
    ): Observable<Product> {
        return combineLatest([
            inputBundleProduct ? of(inputBundleProduct) : this.eglProductService.fetch(productId),
            this.cartService.getMyCart(),
            this.productAttributeValueService.describe(),
            this.store.select(selectFlowType),
        ]).pipe(
            take(1),
            filter(([product]) => !!product),
            switchMap(([product, cart, metadata, flowType]) => {
                const productAttributeGroupList = _map(get(product, 'AttributeGroups', []), 'AttributeGroup');
                set(
                    product,
                    'ProductAttributeMatrixViews',
                    _filter(get(product, 'ProductAttributeMatrixViews'), (r) => r.Active)
                );
                remove(get(product, 'ProductAttributeMatrixViews'), (r) => {
                    // TODO Capire se serve
                    const date = r?.AttributeValueMatrix?.ExpirationDate || moment('2999-01-01').toDate();
                    const expiredate = moment(date).format('YYYY-MM-DD');
                    return moment(expiredate).isBefore(moment(new Date()).format('YYYY-MM-DD'));
                });
                const translatedAttributeGroups$ = this.translatorService.translateData(productAttributeGroupList);
                let cartItems$ = this.cartItemService.getCartItemsForProduct(product, 1, cart);
                if (!isEmpty(changes)) {
                    cartItems$ = of(changes);
                } else if (!isNil(get(relatedTo, 'AssetLineItem'))) {
                    cartItems$ = this.cartItemService.getCartItemsForAsset(relatedTo as CartItem, cart);
                } else if (!isNil(relatedTo)) {
                    cartItems$ = this.cartItemService.getOptionsForItem(relatedTo, cart) as Observable<CartItem[]>;
                }
                return combineLatest([cartItems$, translatedAttributeGroups$]).pipe(
                    map((res) => {
                        const bundleProduct = cloneDeep(product);
                        const cartItem = find(first(res), (i) => get(i, 'LineType') === 'Product/Service');
                        const attrValue = isNil(changes)
                            ? defaultTo(
                                  get(relatedTo, 'AttributeValue', get(cartItem, 'AttributeValue')),
                                  new ProductAttributeValue()
                              )
                            : get(cartItem, 'AttributeValue');
                        set(cartItem, 'AttributeValue', attrValue);
                        bundleProduct.set('item', cloneDeep(cartItem));
                        return this.groupOptionGroups(bundleProduct, attrValue, first(res), applyFilter, metadata);
                    }),
                    map((product) => ({ product, flowType }))
                );
            }),
            tap(({ product, flowType }) => {
                this.removeAttributeGroupsByFlowType(product, flowType);
                this.setHiddenBySequence(product);
                this.expandFirstAccordion(product);
            }),
            mapOrEmpty(
                ({ product }) => product,
                () => null
            )
        );
    }

    /**
     * Sequence = 7 : Hide from Product Configuration Page
     * Sequence = 77 : Hide from Product Configuration Page but required into orderEntry
     * Sequence = 777 : Hide from Normal Product Configuration Page, Hide from orderEntry but required into saleability modal
     * @param product
     */
    private setHiddenBySequence(product: Product): void {
        product.AttributeGroups?.forEach((attributeGroupMember) => {
            if (this.isHiddenBySequence(attributeGroupMember.Sequence)) {
                attributeGroupMember.set('hide', true);
            }
        });
        product.OptionGroups?.forEach((optionGroupMember) => {
            if (this.isHiddenBySequence(optionGroupMember.Sequence)) {
                optionGroupMember.set('hide', true);
                optionGroupMember.IsHidden = true;
            }
        });
    }

    private expandFirstAccordion(product: Product): void {
        // the first visible attribute will be Expanded
        product.AttributeGroups?.find((attribute) => !attribute.get('hide'))?.set('expanded', true);
        if (!product.AttributeGroups?.find((attribute) => attribute.get('expanded'))) {
            // if no attributeGroup is expanded, try to expand optionGroup
            product.OptionGroups?.find((option) => !option?.IsHidden)?.set('expanded', true);
        }
    }

    private removeAttributeGroupsByFlowType(product: Product, flowType: FlowType): void {
        // Removing attribute grups by sales precess configuration
        if (product?.AttributeGroups) {
            product.AttributeGroups = product.AttributeGroups.filter(
                (group) => !this.isToRemoveAttributeGroupBySalesProcess(group, flowType)
            );
        }
    }

    private isHiddenBySequence(sequence: number): boolean {
        return this.CONFIGURATION_TYPE_SEQUENCE_BEHAVIOR[this.configurationType](sequence);
    }

    /**
     * Return TRUE if AttributeGroupMember should be REMOVED (differet by hidden). FALSE, if not.
     * @param groupMember
     * @param flowType
     */
    private isToRemoveAttributeGroupBySalesProcess(
        groupMember: ProductAttributeGroupMember & { SalesProcess?: string },
        flowType: FlowType
    ): boolean {
        // Allowed combinations:
        //   - NO VALUE <-- keep attribute group
        //   - ALL_BUT:VARIAZIONE TECNICA LAVORI|VARIAZIONE TECNICA VERIFICHE <-- keep attribute group always except to specified salesProcess
        //   - VARIAZIONE TECNICA LAVORI|VARIAZIONE TECNICA VERIFICHE <-- keep only for specified salesProcess
        const salesProcessStr = groupMember?.SalesProcess;
        const currentSalesProcess = operationTypeOrFlowTypeToSalesProcess(flowType);

        let removeAttGroup = false; // if TRUE, remove, attribute group (this is different from hiding. Different from sequence = 7)
        if (salesProcessStr?.startsWith('ALL_BUT')) {
            const salesProcessS = salesProcessStr.split(':')[1] || '';
            removeAttGroup = salesProcessS.split('|').includes(currentSalesProcess);
        } else if (salesProcessStr) {
            removeAttGroup = !salesProcessStr.split('|').includes(currentSalesProcess);
        }

        if (removeAttGroup) {
            this.logger.info(
                `AttributeGroupMemeber. "${groupMember?.AttributeGroup?.Name || 'EMPTY_NAME'}" was removed.`
            );
        }
        return removeAttGroup;
    }
}

export enum SequenceBehavior {
    Hidden = 7,
    HiddenButRequiredIntoOrderEntry = 77,
    HiddenButRequiredInSaleabilityModal = 777,
}
enum ConfigurationType {
    OrderEntry = 'order-entry',
    ProductDetail = 'product-detail',
    SaleabilityModal = 'saleability-modal',
}
