export enum AptSignatureMode {
    Doi = 'DOI',
    Cartaceo = 'CARTACEO',
    CartaceoPreviaStampa = 'CARTACEO PREVIA STAMPA',
    VocalOrder = 'VOCAL ORDER',
    Grafometrica = 'GRAFOMETRICA',
    DoiSenzaVocalOrderLight = 'DOI SENZA VOCAL ORDER LIGHT',
    DoiSenzaMp3Light = 'DOI SENZA MP3 LIGHT',
    NessunaFirma = 'NESSUNA FIRMA',
}
