import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OrderEntryProvider } from '../../../modules/common/order-entry/providers/order-entry-provider';
import { LoggerService } from './logger.service';

@Injectable()
export class ConsumptionValidationService {
    constructor(private orderEntryProvider: OrderEntryProvider, private logger: LoggerService) {}

    consumptionValidationService(
        totMis: number,
        PdfCode: string,
        totCorr?: number,
        effectiveDate?: Date
    ): Observable<number> {
        return this.orderEntryProvider
            .consumptionValidation({
                caller: 'SUP',
                dataLettura: (effectiveDate || new Date()).toISOString(),
                codicePDF: PdfCode,
                totMis: totMis,
                ...(totCorr && { totCorr: totCorr }),
            })
            .pipe(
                tap((res) => {
                    if (!res?.response) {
                        throw new Error(`Errore! - ${res?.errorManagement?.errorDescription}`);
                    } else if (!res?.response?.isValid) {
                        this.logger.warn(
                            `Esito valida consumo: ${res?.response?.info}` || 'Nessuna informazione disponibile'
                        );
                        throw new Error('Errore! Lettura non coerente con quanto analizzato dal servizio Net@');
                    }
                }),
                map(() => totMis)
            );
    }
}
