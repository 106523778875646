import { createAction, props } from '@ngrx/store';
import { appActionString as c } from './action-types';
import { GeoLocation } from '../../common/models/app/geo-location';
import { ApplicationLocationType, D365EntryPoint } from '../models/app-state';

export const setConnectionStatus = createAction(c.CONNECTION_CHANGED, props<{ status: string }>());
export const setConnectionSpeed = createAction(c.CONNECTION_SPEED, props<{ speed: string }>());
export const setApplicationLocation = createAction(
    c.APPLICATION_LOCATION,
    props<{ location: ApplicationLocationType }>()
);
export const setCurrentPage = createAction(c.SET_CURRENT_PAGE, props<{ url: string }>());
export const setUrlHash = createAction(c.SET_URL_HASH, props<{ hash: string }>());
export const setGeoLocation = createAction(c.SET_GEO_LOCATION, props<{ geolocation: GeoLocation }>());
export const setContactBarVisibility = createAction(c.SET_CONTACT_BAR_VISIBILITY, props<{ v: boolean }>());
export const setProgressBarVisibility = createAction(c.SET_PROGRESS_BAR_VISIBILITY, props<{ v: boolean }>());
export const setDevBarVisibility = createAction(c.SET_DEV_BAR_VISIBILITY, props<{ v: boolean }>());
export const setBOQuoteTab = createAction(c.SET_BO_QUOTE_TAB, props<{ t: string }>());
export const setD365EntryPoint = createAction(c.SET_D365_ENTRY_POINT, props<{ entryPoint: D365EntryPoint }>());
export const setD365AppUrl = createAction(c.SET_D365_APP_URL, props<{ appUrl: string }>());
export const setShouldOpenTroubleshooting = createAction(
    c.SET_SHOULD_OPEN_TROUBLESHOOTING,
    props<{ openTroubleshooting: boolean }>()
);
