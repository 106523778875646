export enum AptOrderLineStatus {
    Draft = 'Draft',
    Pending = 'Pending',
    InFulfillment = 'In Fulfillment',
    PartiallyFulfilled = 'Partially Fulfilled',
    Fulfilled = 'Fulfilled',
    Activated = 'Activated',
    Cancelled = 'Cancelled',
    InAmendment = 'In Amendment',
    BeingAmended = 'Being Amended',
    PendingCancellation = 'Pending Cancellation',
    BeingCancelled = 'Being Cancelled',
    Superseded = 'Superseded',
}
