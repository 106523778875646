import { EventEmitter, Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, mergeMap, scan, tap, timeout } from 'rxjs/operators';

enum TraceServerity {
    None,
    Info,
    Warn,
    Error,
    Debug,
}
@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private static logSeverity = TraceServerity.Warn;
    private static DEFAULT_MESSAGE = 'Attendere';
    private static readonly RESET = Symbol('reset');
    private static readonly ADD = Symbol('add');
    private static readonly REMOVE = Symbol('remove');
    private static readonly TOGGLE = Symbol('toggle');
    private static readonly UPDATE = Symbol('update');
    private static loaderStatus = new EventEmitter<{ symbol: string | symbol; message?: string }>();
    private static LOADER_TIMEOUT_SEC = 60;
    private static DEBOUNCE_TIME_MS = 60;

    private static SYMBOL_MAP: { [key in symbol]: number } = {
        [LoadingService.RESET]: Number.NEGATIVE_INFINITY,
        [LoadingService.ADD]: +1,
        [LoadingService.REMOVE]: -1,
        [LoadingService.UPDATE]: 0,
        [LoadingService.TOGGLE]: NaN,
    };

    public static readonly LOADER_CLASS = {
        WRAPPER: 'egl-loader-wrapper',
        MESSAGE: 'egl-loader-message',
        ROLLER: 'egl-loader-roller',
    };

    private static loaderStatus$ = LoadingService.loaderStatus
        .pipe(
            map((msg) => ({
                message: msg?.message,
                counter: LoadingService.SYMBOL_MAP[msg.symbol],
            })),
            tap((msg) => LoadingService.consoleDebug(JSON.stringify(msg))),
            scan((accumulator, newEvent) => {
                const rawCounter = isNaN(newEvent?.counter)
                    ? +!accumulator?.counter
                    : accumulator?.counter + newEvent?.counter;
                if (rawCounter < 0) {
                    LoadingService.consoleWarn(`LOADER accumulator counter < 0 - Too many Hide invoked`);
                }
                const counter = Math.max(0, rawCounter);
                const message = counter ? newEvent?.message || accumulator?.message : null;
                LoadingService.consoleDebug(
                    `LOADER accumulator.counter  ${accumulator?.counter} -> counter ${counter} | message ${message}`
                );
                return {
                    message,
                    counter,
                };
            }),
            debounceTime(LoadingService.DEBOUNCE_TIME_MS),
            mergeMap((data) =>
                data.counter > 0
                    ? of(data).pipe(timeout(LoadingService.LOADER_TIMEOUT_SEC * 1000 - LoadingService.DEBOUNCE_TIME_MS))
                    : of(data)
            ),
            map(({ message, counter }) => (counter ? LoadingService.formatMessage(message) : null)),
            catchError(() => of(null)),
            filter(() => !!document.getElementById(LoadingService.LOADER_CLASS.WRAPPER)),
        );
    
    private static loaderStatusListener = LoadingService.loaderStatus$.subscribe((message) => {
        if (message) {
            if (message !== LoadingService.formatMessage(LoadingService.DEFAULT_MESSAGE)) {
                LoadingService.consoleInfo(`Loading message: ${message}`);
            }
            document.getElementById(LoadingService.LOADER_CLASS.MESSAGE).textContent = message;
            if (!document.getElementById(LoadingService.LOADER_CLASS.WRAPPER).classList.contains('active')) {
                // show
                LoadingService.consoleDebug('Loading was shown');
                document.getElementById(LoadingService.LOADER_CLASS.WRAPPER).classList.add('active');
            } else {
                // update message
                LoadingService.consoleDebug('Loading message was changed');
            }
        } else {
            // hide
            LoadingService.consoleDebug('Loading was hidden');
            document.getElementById(LoadingService.LOADER_CLASS.WRAPPER).classList.remove('active');
        }
    });

    public static readonly change: Observable<boolean> = LoadingService.loaderStatus$.pipe(
        map(message => !!message),
        distinctUntilChanged()
    );

    static get isActive(): boolean {
        return document.getElementById(LoadingService.LOADER_CLASS.WRAPPER).classList.contains('active');
    }

    static get currentMessage(): string {
        return document.getElementById(LoadingService.LOADER_CLASS.WRAPPER)?.textContent;
    }

    static loaderOperator<T>(message?: string): MonoTypeOperatorFunction<T> {
        const id = `${message || ''} ${Math.round(Math.random() * 100 + 1)}`;
        return (source: Observable<T>) =>
            new Observable((subscriber) => {
                this.consoleDebug(`Operator Rxjs - Show - ${id}`);
                this.show(message);
                subscriber.next(null);
                subscriber.complete();
            }).pipe(
                mergeMap(() => source),
                finalize(() => {
                    this.consoleDebug(`Operator Rxjs - Hide - ${id}`);
                    this.hide();
                })
            );
    }

    static hide(): void {
        this.consoleDebug(`Hide`);
        this.loaderStatus.emit({ symbol: this.REMOVE });
    }

    static abort(): void {
        this.consoleDebug(`Abort`);
        this.loaderStatus.emit({ symbol: this.RESET });
    }

    static toggle(): void {
        this.consoleDebug(`Toggle`);
        this.loaderStatus.emit({ symbol: this.TOGGLE });
    }

    static show(msg?: string): void {
        this.consoleDebug(`Show - ${msg}`);
        this.loaderStatus.emit({ symbol: this.ADD, message: msg });
    }

    static update(msg: string): void {
        this.consoleDebug(`Update - ${msg}`);
        this.loaderStatus.emit({ symbol: this.UPDATE, message: msg });
    }

    private static formatMessage(msg?: string): string {
        return `${(msg || this.DEFAULT_MESSAGE).replace(/\.{3}$/, '')}...`;
    }

    private static consoleDebug(msg?: string): void {
        if (this.logSeverity >= TraceServerity.Debug) {
            console.trace(`[⌚] Loader - ${msg}`);
        }
    }
    private static consoleInfo(msg?: string): void {
        if (this.logSeverity >= TraceServerity.Info) {
            console.info(`[⌚] Loader - ${msg}`);
        }
    }

    private static consoleWarn(msg?: string): void {
        if (this.logSeverity >= TraceServerity.Warn) {
            console.warn(`[⌚] Loader - ${msg}`);
        }
    }
}
