import { AField, AObject, ATable } from '@congacommerce/core';

@ATable({
    sobjectName: 'egl_PaymentTool__c',
})
export class EglPaymentTool extends AObject {
    constructor() {
        super();
    }
    @AField({ soql: 'egl_iban__c' })
    egl_iban__c: string;

    @AField({ soql: 'egl_settore_appartenenza__c' })
    egl_settore_appartenenza__c: string;
}
