import moment from 'moment';
import { AttributeValues } from './base-attribute-values';

export class ComplexAttributeValues extends AttributeValues {
    attributesMapping(): void {
        super.attributesMapping();

        this.attr['egl_used_goods_in_stock__c'] = this.currentProduct?.configurations?.usedGoodsInStock;
        this.attr['egl_raee__c'] = this.currentProduct?.configurations?.raee;
        this.attr['egl_down_payment__c'] = this.currentProduct?.paymentInfo?.paymentDeposit;
        this.attr['egl_consumer_credit_campaign__c'] = this.currentProduct?.configurations?.creditCampaign;
        this.attr['egl_credit_assignment__c'] = this.currentProduct?.configurations?.creditAssignmentAttr;
        this.attr['egl_neta_cessioneCredito_flag__c'] = this.currentProduct?.configurations?.creditAssignment;
        this.attr['egl_product_installation_date__c'] = moment(
            this.currentProduct?.configurations?.installmentDate,
        ).format('YYYY-MM-DD');
        this.attr['egl_tax_detraction__c'] =
            this.currentProduct?.configurations.productTaxDeduction?.taxDeductionDescription;
        this.attr['egl_neta_productClass__c'] = this.currentProduct?.configurations?.productClass;
        this.attr['egl_appointment_ese__c'] =
            this.currentProduct?.subsidiary?.agencyBranch?.egl_agencybranchid || this.currentProduct?.ese?.Id;
    }
}
