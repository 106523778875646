/**
 * @author Marco Ricupero ft Andreea Stegariu, Felice Lombardi, Ajay Kumar, Giovanni Bazan 🝲
 * @version 3.0.0
 */
import { FlowType, MacroFlowType } from '../../../../store/models/flow-type';
import { Regex } from '../../../config/regex';
import { RoutesPaths } from '../../../config/routes-paths';
import { AptQuoteStatus } from '../../../enums/apttus/apt-quote-status';
import { AptQuoteSubStatus } from '../../../enums/apttus/apt-quote-sub-status';
import { ACTIVATION_FLOW_TYPES, TERMINATION_FLOW_TYPES } from '../../../map/flow-type-mapping.config';
import {
    checkDataOwnershipChangeConsinstency,
    CheckPortalRegistrationEmail,
    dispatchDefaultConsumptionForAppurtenancesCommodity,
    dispatchDefaultPotentialityForActivationGasCommodity,
    dragonGetContractCodeNoSign,
    dragonGetContractCodeNoSignAndAgencyBranch,
    dragonIsCustomerRegistered,
    dragonSaveQuoteLavoriVerificheV2,
    dragonSaveQuotePreventivo,
    dragonSaveQuotePreventivoAumPot,
    dragonSaveQuoteV2,
    dragonSetQuoteStatus,
    dragonSetQuoteStatusSubstatus,
    maintenanceAppointmentCycleInitializer,
    multiSupplyProductFinalizer,
    multiSupplySingleProductInitializer,
    PROCESS_STATE_ENRICHER,
    productConfigStepInitializer,
    remoteMeterCheck,
    retrieveVoltSerialNumberNeta,
    scoreCard,
    transferModel,
} from './dragon-helper-functions';
import { OrderEntryPathConfiguration } from './dragon-router.type';

const CART_PAGE_MATCHER = /^\/?carts\/active/;
const CATALOG_PAGE = `${RoutesPaths.AllProducts}/${RoutesPaths.ProductListCategorySubCategory}`;

/**
 * Definire le configurazioni in base ai flow types
 * è possibile configurare sequenze parziali
 * L'elenco di configurazioni ha una priorità dall'alto in basso, mantenere in coda le configurazioni generiche
 * Il primo elemento della railway NON è una destinazione ma solo un match per andare alla rotta successiva
 * Si consiglia di utilizzare espressioni regolari per matchare entry point complessi o come primo record della railway
 */
export const ORDER_ENTRY_PATHS: OrderEntryPathConfiguration[] = [
    // TERMINATION (Cessazione)
    {
        flowTypes: TERMINATION_FLOW_TYPES,
        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.Costs,
            dragonGetContractCodeNoSignAndAgencyBranch,
            RoutesPaths.TerminationMortisCausa,
            RoutesPaths.TerminationContacts,
            RoutesPaths.ActivationOrTerminationDate,
            RoutesPaths.TerminationAppointment,
            RoutesPaths.SummaryPage,
            RoutesPaths.SceltaFirma,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.FirmaVocalOrder,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.ThankYouPage,
        ],
    },
    // VOLTURA COMM, VOLTURA VIP
    {
        flowTypes: [FlowType.Voltura, FlowType.VolturaAmmVip],
        railway: [
            RoutesPaths.TypologiesUse,
            CATALOG_PAGE,
            CART_PAGE_MATCHER,
            RoutesPaths.TransferTypology,
            RoutesPaths.DatiGenerici,
            CheckPortalRegistrationEmail,
            RoutesPaths.DatiContatto,
            checkDataOwnershipChangeConsinstency,
            RoutesPaths.DoiConfermaCliente,
            RoutesPaths.ConfigPdf,
            multiSupplySingleProductInitializer,
            {
                requiredParams: ['itemId'],
                railway: [
                    RoutesPaths.IndirizziNew,
                    RoutesPaths.DatiPagamento,
                    RoutesPaths.CodiceAteco,
                    RoutesPaths.PotenzaImpegnata,
                    RoutesPaths.ConsumoLuce,
                    RoutesPaths.CustomerHabits,
                    dispatchDefaultConsumptionForAppurtenancesCommodity,
                    retrieveVoltSerialNumberNeta,
                    remoteMeterCheck,
                    RoutesPaths.ConsumptionValidation,
                    RoutesPaths.ConsumoGas,
                    productConfigStepInitializer,
                    RoutesPaths.ProductConfigurationStep,
                    multiSupplyProductFinalizer,
                    RoutesPaths.ConfigPdf,
                ],
            },
            //ATTENZIONE: la pagina di presa appuntamento è vincolata dal metodo _checkAppointment in IndirizziNew e DatiPagamento, se saltate non verrà mostrata la pagina di presa appuntamento in riferimento a #227224 - #228329 - Ciclo di navigazione sulla pagina di presa appuntamento
            maintenanceAppointmentCycleInitializer,
            {
                requiredParams: ['appointment'],
                railway: [RoutesPaths.MaintenanceAppointment],
            },
            scoreCard,
            transferModel,
            RoutesPaths.TransferModel,
            RoutesPaths.PanelConfiguration,
            RoutesPaths.MarketProfiling,
            RoutesPaths.Costs,
            RoutesPaths.DecretoCasa,
            RoutesPaths.SummaryPage,
            RoutesPaths.SceltaFirma,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.TipoEsecuzione,
            RoutesPaths.DatiAgentePiu,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.UploadDocumento,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.FirmaVocalOrder,
            RoutesPaths.SceltaContatto,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // VOLTURA AMM SI/NO PROV
    {
        flowTypes: [FlowType.VolturaAmmNoProvisioning, FlowType.VolturaAmmSiProvisioning],
        railway: [
            RoutesPaths.TypologiesUse,
            CATALOG_PAGE,
            CART_PAGE_MATCHER,
            RoutesPaths.TransferTypology,
            RoutesPaths.DatiGenerici,
            CheckPortalRegistrationEmail,
            RoutesPaths.DatiContatto,
            RoutesPaths.ConfigPdf,
            multiSupplySingleProductInitializer,
            {
                requiredParams: ['itemId'],
                railway: [
                    RoutesPaths.IndirizziNew,
                    RoutesPaths.CodiceAteco,
                    RoutesPaths.PotenzaImpegnata,
                    RoutesPaths.ConsumoLuce,
                    retrieveVoltSerialNumberNeta,
                    dispatchDefaultConsumptionForAppurtenancesCommodity,
                    RoutesPaths.DatiTecniciAmministrativi,
                    RoutesPaths.DatesSelfreading,
                    RoutesPaths.ConsumoGas,
                    multiSupplyProductFinalizer,
                    RoutesPaths.ConfigPdf,
                ],
            },
            RoutesPaths.MarketProfiling,
            RoutesPaths.DatiPagamento,
            RoutesPaths.Costs,
            RoutesPaths.SummaryPage,
            RoutesPaths.SceltaFirma,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.SignatureDate,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.FirmaVocalOrder,
            RoutesPaths.SceltaContatto,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // VOLTURA AMM INC SOC
    {
        flowTypes: [FlowType.VolturaAmmIncorporazioneSocietaria],
        railway: [
            RoutesPaths.TypologiesUse,
            CATALOG_PAGE,
            CART_PAGE_MATCHER,
            RoutesPaths.TransferTypology,
            RoutesPaths.DatiGenerici,
            CheckPortalRegistrationEmail,
            RoutesPaths.DatiContatto,
            RoutesPaths.ConfigPdf,
            multiSupplySingleProductInitializer,
            {
                requiredParams: ['itemId'],
                railway: [
                    RoutesPaths.IndirizziNew,
                    RoutesPaths.CodiceAteco,
                    RoutesPaths.PotenzaImpegnata,
                    retrieveVoltSerialNumberNeta,
                    RoutesPaths.DatiTecniciAmministrativi,
                    RoutesPaths.DatesSelfreading,
                    RoutesPaths.ConsumoGas,
                    multiSupplyProductFinalizer,
                    RoutesPaths.ConfigPdf,
                ],
            },
            scoreCard,
            RoutesPaths.MarketProfiling,
            RoutesPaths.DatiPagamento,
            RoutesPaths.Costs,
            RoutesPaths.SummaryPage,
            RoutesPaths.SceltaFirma,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.SignatureDate,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.UploadDocumento,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.FirmaVocalOrder,
            RoutesPaths.SceltaContatto,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // ACTIVATION
    {
        flowTypes: ACTIVATION_FLOW_TYPES,
        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.TroubleShootingAttivazione,
            RoutesPaths.DatiGenerici,
            CheckPortalRegistrationEmail,
            RoutesPaths.DatiContatto,
            checkDataOwnershipChangeConsinstency,
            RoutesPaths.DoiConfermaCliente,
            RoutesPaths.ConfigPdf,
            multiSupplySingleProductInitializer,
            {
                requiredParams: ['itemId'],
                railway: [
                    RoutesPaths.IndirizziNew,
                    RoutesPaths.CodiceAteco,
                    RoutesPaths.DatiPod,
                    RoutesPaths.PotenzaImpegnata,
                    RoutesPaths.ConsumoLuce,
                    dispatchDefaultConsumptionForAppurtenancesCommodity,
                    RoutesPaths.DatiPdrAttivazione,
                    RoutesPaths.ConsumoGas,
                    dispatchDefaultPotentialityForActivationGasCommodity,
                    RoutesPaths.DatiTecniciAmministrativi,
                    RoutesPaths.Costs,
                    RoutesPaths.DatiPagamento,
                    multiSupplyProductFinalizer,
                    RoutesPaths.ConfigPdf,
                ],
            },
            scoreCard,
            maintenanceAppointmentCycleInitializer,
            {
                requiredParams: ['appointment'],
                railway: [RoutesPaths.MaintenanceAppointment],
            },
            RoutesPaths.AttivazioneTipologiaImmobile,
            RoutesPaths.DecretoCasa,
            RoutesPaths.AttivazioneDataAppuntamento,
            RoutesPaths.AttivazioneDatiAppuntamento,
            RoutesPaths.SummaryPage,
            RoutesPaths.SceltaFirma,
            RoutesPaths.SignatureDate,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.InserimentoManualeDocumento,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.DatiAgentePiu,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.FirmaVocalOrder,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // VOLTURA CON SWITCH COMM, VOLTURA CON SWITCH VIP
    {
        flowTypes: [FlowType.VolturaCambioFornitore, FlowType.VolturaCambioFornitoreVip],
        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.Costs,
            RoutesPaths.DatiGenerici,
            CheckPortalRegistrationEmail,
            RoutesPaths.DatiContatto,
            checkDataOwnershipChangeConsinstency,
            RoutesPaths.DoiConfermaCliente,
            RoutesPaths.ConfigPdf,
            multiSupplySingleProductInitializer,
            {
                requiredParams: ['itemId'],
                railway: [
                    RoutesPaths.IndirizziNew,
                    RoutesPaths.CodiceAteco,
                    RoutesPaths.PotenzaImpegnata,
                    RoutesPaths.ConsumoLuce,
                    RoutesPaths.CustomerHabits,
                    dispatchDefaultConsumptionForAppurtenancesCommodity,
                    RoutesPaths.DatiPod,
                    RoutesPaths.DatiPagamento,
                    RoutesPaths.PanelConfiguration,
                    multiSupplyProductFinalizer,
                    RoutesPaths.ConfigPdf,
                ],
            },
            scoreCard,
            maintenanceAppointmentCycleInitializer,
            {
                requiredParams: ['appointment'],
                railway: [RoutesPaths.MaintenanceAppointment],
            },
            scoreCard,
            RoutesPaths.DecretoCasa,
            RoutesPaths.SummaryPage,
            RoutesPaths.SceltaFirma,
            RoutesPaths.DatiAgentePiu,
            RoutesPaths.UploadDocumento,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.FirmaVocalOrder,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.SceltaContatto,
            PROCESS_STATE_ENRICHER[MacroFlowType.VolturaSwitchIn],
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // VOLTURA CON SWITCH AMM REC
    {
        flowTypes: [
            FlowType.VolturaCambioFornitoreAmmRecupero2A,
            FlowType.VolturaCambioFornitoreAmmRecupero2B,
            FlowType.VolturaCambioFornitoreAmmRecupero2C,
        ],
        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.DatiGenerici,
            CheckPortalRegistrationEmail,
            RoutesPaths.DatiContatto,
            RoutesPaths.IndirizziNew,
            RoutesPaths.CodiceAteco,
            RoutesPaths.PotenzaImpegnata,
            RoutesPaths.ConsumoLuce,
            dispatchDefaultConsumptionForAppurtenancesCommodity,
            RoutesPaths.DatiPod,
            RoutesPaths.DatiTecniciAmministrativi,
            RoutesPaths.DatiPagamento,
            RoutesPaths.SummaryPage,
            RoutesPaths.SceltaFirma,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.TipoEsecuzione,
            RoutesPaths.SignatureDate,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.ThankYouPage,
        ],
    },
    // EXTRACOMMODITY
    {
        flowTypes: [FlowType.ExtraCommoditySale],
        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.ModalitaAcquisizione,
            RoutesPaths.DatiGenerici,
            CheckPortalRegistrationEmail,
            RoutesPaths.DatiContatto,
            checkDataOwnershipChangeConsinstency,
            RoutesPaths.DoiConfermaCliente,
            RoutesPaths.ConfigPdf,
            multiSupplySingleProductInitializer,
            {
                requiredParams: ['itemId'],
                railway: [
                    RoutesPaths.IndirizziNew,
                    RoutesPaths.DatiPagamento,
                    productConfigStepInitializer,
                    RoutesPaths.ProductConfigurationStep,
                    multiSupplyProductFinalizer,
                    //NB: se viene effettuato il controllo per skippare la presa appuntamento, viene effettuato direttamente un navigate al maintanance-appointment bypassando il dragon-router -> bug #293464
                    RoutesPaths.ConfigPdf,
                ],
            },
            //ATTENZIONE: la pagina di presa appuntamento è vincolata dal metodo _checkAppointment in IndirizziNew e DatiPagamento, se saltate non verrà mostrata la pagina di presa appuntamento in riferimento a #227224 - #228329 - Ciclo di navigazione sulla pagina di presa appuntamento
            maintenanceAppointmentCycleInitializer,
            {
                requiredParams: ['appointment'],
                railway: [RoutesPaths.MaintenanceAppointment],
            },
            scoreCard,
            RoutesPaths.SummaryPage,
            RoutesPaths.SceltaFirma,
            RoutesPaths.DatiAgentePiu,
            RoutesPaths.TipoEsecuzione,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.UploadDocumento,
            RoutesPaths.FirmaVocalOrder,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.SceltaContatto,
            RoutesPaths.PrivacyDatiPersonali,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // SWITCH-IN COMM, SWITCH-IN VIP
    {
        flowTypes: [FlowType.SwitchIn, FlowType.SwitchInAmmVip],
        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.ModalitaAcquisizione,
            RoutesPaths.DatiGenerici,
            CheckPortalRegistrationEmail,
            RoutesPaths.PsFastwebPersonalData,
            RoutesPaths.PsFastwebActiveContracts,
            RoutesPaths.DatiContatto,
            checkDataOwnershipChangeConsinstency,
            RoutesPaths.DoiConfermaCliente,
            RoutesPaths.ConfigPdf,
            multiSupplySingleProductInitializer,
            {
                requiredParams: ['itemId'],
                railway: [
                    RoutesPaths.IndirizziNew,
                    RoutesPaths.DatiPagamento,
                    RoutesPaths.CodiceAteco,
                    RoutesPaths.PotenzaImpegnata,
                    RoutesPaths.ConsumoLuce,
                    RoutesPaths.CustomerHabits,
                    dispatchDefaultConsumptionForAppurtenancesCommodity,
                    RoutesPaths.ConsumoGas,
                    RoutesPaths.DatiPod,
                    RoutesPaths.PanelConfiguration,
                    RoutesPaths.DatiPdr,
                    multiSupplyProductFinalizer,
                    RoutesPaths.ConfigPdf,
                ],
            },
            scoreCard,
            maintenanceAppointmentCycleInitializer,
            {
                requiredParams: ['appointment'],
                railway: [RoutesPaths.MaintenanceAppointment],
            },
            RoutesPaths.SummaryPage,
            RoutesPaths.SceltaFirma,
            RoutesPaths.TipoEsecuzione,
            RoutesPaths.DatiAgentePiu, // nuova pagina aggiunta US #56322
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.UploadDocumento,
            RoutesPaths.FirmaVocalOrder,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.SceltaContatto,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // SWITCH-IN AMM DEL153
    {
        flowTypes: [FlowType.SwitchInAmmDelibera153],
        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.ModalitaAcquisizione,
            RoutesPaths.DatiGenerici,
            CheckPortalRegistrationEmail,
            RoutesPaths.PsFastwebPersonalData,
            RoutesPaths.PsFastwebActiveContracts,
            RoutesPaths.DatiContatto,
            RoutesPaths.ConfigPdf,
            multiSupplySingleProductInitializer,
            {
                requiredParams: ['itemId'],
                railway: [
                    RoutesPaths.IndirizziNew,
                    RoutesPaths.CodiceAteco,
                    RoutesPaths.PotenzaImpegnata,
                    RoutesPaths.ConsumoLuce,
                    RoutesPaths.DatiPod,
                    RoutesPaths.ConsumoGas,
                    RoutesPaths.DatiPdr,
                    multiSupplyProductFinalizer,
                    RoutesPaths.ConfigPdf,
                ],
            },
            RoutesPaths.DatiPagamento,
            RoutesPaths.SummaryPage,
            RoutesPaths.SceltaFirma,
            RoutesPaths.TipoEsecuzione,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.FirmaVocalOrder,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.SceltaContatto,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // SWITCH-IN AMM REC
    {
        flowTypes: [FlowType.SwitchInAmmRecupero2A, FlowType.SwitchInAmmRecupero2B, FlowType.SwitchInAmmRecupero2C],
        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.DatiGenerici,
            CheckPortalRegistrationEmail,
            RoutesPaths.PsFastwebPersonalData,
            RoutesPaths.PsFastwebActiveContracts,
            RoutesPaths.DatiContatto,
            RoutesPaths.ConfigPdf,
            multiSupplySingleProductInitializer,
            {
                requiredParams: ['itemId'],
                railway: [
                    RoutesPaths.IndirizziNew,
                    RoutesPaths.CodiceAteco,
                    RoutesPaths.PotenzaImpegnata,
                    RoutesPaths.ConsumoLuce,
                    dispatchDefaultConsumptionForAppurtenancesCommodity,
                    RoutesPaths.DatiPod,
                    RoutesPaths.ConsumoGas,
                    RoutesPaths.DatiPdr,
                    RoutesPaths.DatiTecniciAmministrativi,
                    multiSupplyProductFinalizer,
                    RoutesPaths.ConfigPdf,
                ],
            },
            RoutesPaths.DatiPagamento,
            RoutesPaths.SummaryPage,
            RoutesPaths.SceltaFirma,
            RoutesPaths.TipoEsecuzione,
            RoutesPaths.SignatureDate,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.FirmaVocalOrder,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.SceltaContatto,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // SCONTO STANDALONE
    {
        flowTypes: [FlowType.ScontoStandalone],
        railway: [CART_PAGE_MATCHER, dragonGetContractCodeNoSign, dragonSaveQuoteV2, RoutesPaths.ThankYouPage],
    },
    // SOSPENSIONE, SOSPENSIONE AMM
    {
        flowTypes: [FlowType.Sospensione, FlowType.SospensioneAmministrativa],
        railway: [RoutesPaths.SuspensionForm, RoutesPaths.MorosityThankYouPage],
    },
    // RIAPERTURA, RIAPERTURA AMM
    {
        flowTypes: [FlowType.Riapertura, FlowType.RiaperturaAmministrativa],
        railway: [RoutesPaths.ResumingForm, RoutesPaths.MorosityThankYouPage],
    },
    // INTERRUZIONE, INTERRUZIONE AMM
    {
        flowTypes: [FlowType.Interruzione, FlowType.InterruzioneAmministrativa],
        railway: [RoutesPaths.InterruptionForm, RoutesPaths.MorosityThankYouPage],
    },
    // ANNULLAMENTO INTERRUZIONE, ANNULLAMENTO RIAPERTURA, ANNULLAMENTO SOSPENSIONE
    {
        flowTypes: [
            FlowType.AnnullamentoInterruzione,
            FlowType.AnnullamentoRiapertura,
            FlowType.AnnullamentoSospensione,
        ],
        railway: [RoutesPaths.MorosityCancelForm, RoutesPaths.MorosityThankYouPage],
    },
    // Cessazione Amministrativa per Morosità
    {
        flowTypes: [
            FlowType.CA1,
            FlowType.CA1AMM,
            FlowType.CA2,
            FlowType.CA2AMM,
            FlowType.CA3,
            FlowType.CA3AMM,
            FlowType.CA4,
            FlowType.CA4AMM,
            FlowType.CA5,
            FlowType.CA5AMM,
            FlowType.CA7,
            FlowType.CA7AMM,
            FlowType.CAE1,
            FlowType.CAE1AMM,
            FlowType.CAE3,
            FlowType.CAE3AMM,
            FlowType.CAE4,
            FlowType.CAE4AMM,
            FlowType.CAE5,
            FlowType.CAE5AMM,
            FlowType.CAE7,
            FlowType.CAE7AMM,
            FlowType.CAE8,
            FlowType.CAE8AMM,
            FlowType.CAE8RIFVOLT,
            FlowType.CAE8RIFVOLTAMM,
        ],
        railway: [RoutesPaths.AdminTermination, RoutesPaths.MorosityThankYouPage],
    },
    // DOMICILIAZIONE MODIFICA, DOMICILIAZIONE REVOCA, DOMICILIAZIONE ATTIVAZIONE
    {
        flowTypes: [FlowType.DomiciliazioneModifica, FlowType.DomiciliazioneRevoca, FlowType.DomiciliazioneAttivazione],
        railway: [
            Regex.DOMICILIATION_INIT_ROUTE,
            RoutesPaths.DomiciliationSummary,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.ThankYouPage,
        ],
    },
    // DOMICILIAZIONE ATTIVAZIONE NUOVO METODO DI PAGAMENTO, DOMICILIAZIONE MODIFICA NUOVO METODO DI PAGAMENTO
    {
        flowTypes: [
            FlowType.DomiciliazioneAttivazioneInserimentoPaymentTool,
            FlowType.DomiciliazioneModificaInserimentoPaymentTool,
        ],
        railway: [
            Regex.DOMICILIATION_INIT_ROUTE,
            RoutesPaths.DomiciliationSummary,
            RoutesPaths.SceltaFirma,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.FirmaVocalOrder,
            RoutesPaths.SceltaContatto,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // CAMBIO TIPOLOGIA FORNITURA
    {
        flowTypes: [FlowType.CambioTipologiaFornitura],
        toggleField: 'isCambioTipologiaFornituraEnabled',
        railway: [
            RoutesPaths.CTFEffectiveDate,
            RoutesPaths.CTFSupplyChoice,
            RoutesPaths.CPProductList,
            RoutesPaths.CPProductDetails,
            RoutesPaths.CPProductConfiguration,
            RoutesPaths.CartActive,
            RoutesPaths.DatiPagamento,
            RoutesPaths.SummaryPage,
            RoutesPaths.SceltaFirma,
            RoutesPaths.DatiAgentePiu,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.FirmaVocalOrder,
            RoutesPaths.SceltaContatto,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // CP COMM
    {
        flowTypes: [FlowType.CPCommerciale],
        railway: [
            RoutesPaths.CPProductList,
            RoutesPaths.CPProductDetails,
            RoutesPaths.CPProductConfiguration,
            RoutesPaths.CartActive,
            RoutesPaths.DatiGenerici,
            dragonIsCustomerRegistered,
            CheckPortalRegistrationEmail,
            RoutesPaths.DatiContatto,
            RoutesPaths.ConfigPdf,
            multiSupplySingleProductInitializer,
            {
                requiredParams: ['itemId'],
                railway: [
                    RoutesPaths.IndirizziNew,
                    RoutesPaths.Costs,
                    RoutesPaths.DatiPagamento,
                    productConfigStepInitializer,
                    RoutesPaths.ProductConfigurationStep,
                    multiSupplyProductFinalizer,
                    RoutesPaths.ConfigPdf,
                    RoutesPaths.PanelConfiguration,
                ],
            },
            maintenanceAppointmentCycleInitializer,
            {
                requiredParams: ['appointment'],
                railway: [RoutesPaths.MaintenanceAppointment],
            },

            RoutesPaths.SummaryPage,
            checkDataOwnershipChangeConsinstency,
            RoutesPaths.DoiConfermaCliente,
            RoutesPaths.SceltaFirma,
            RoutesPaths.TipoEsecuzione,
            RoutesPaths.DatiAgentePiu,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.UploadDocumento,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.FirmaVocalOrder,
            RoutesPaths.SceltaContatto,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // CP RETROATTIVO EX-NOVO
    {
        flowTypes: [FlowType.CPRetroattivoExNovo],
        railway: [
            RoutesPaths.CPActivationDate, // "Data attivazione"
            RoutesPaths.CPProductList,
            RoutesPaths.CPProductDetails,
            RoutesPaths.CPProductConfiguration,
            RoutesPaths.CartActive,
            RoutesPaths.Costs,
            RoutesPaths.DatiGenerici,
            RoutesPaths.DatiPagamento,
            RoutesPaths.SummaryPage,
            RoutesPaths.SceltaFirma,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.UploadDocumento,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.FirmaVocalOrder,
            RoutesPaths.SceltaContatto,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // CP RETROATTIVO ASSET CESSATO
    {
        flowTypes: [FlowType.CPRetroattivoAssetCessato],
        railway: [
            RoutesPaths.CPActivationDate, // "Data attivazione"
            RoutesPaths.CPProductList,
            RoutesPaths.CPProductDetails,
            RoutesPaths.CPProductConfiguration,
            RoutesPaths.CartActive,
            CheckPortalRegistrationEmail,
            RoutesPaths.DatiGenerici,
            RoutesPaths.PsFastwebPersonalData,
            RoutesPaths.PsFastwebActiveContracts,
            RoutesPaths.DatiContatto,
            RoutesPaths.IndirizziNew,
            RoutesPaths.CodiceAteco,
            RoutesPaths.PotenzaImpegnata,
            RoutesPaths.ConsumoLuce,
            RoutesPaths.DatiPod,
            RoutesPaths.ConsumoGas,
            RoutesPaths.DatiPdr,
            scoreCard,
            RoutesPaths.DatiPagamento,
            RoutesPaths.SummaryPage,
            RoutesPaths.SceltaFirma,
            RoutesPaths.PrivacyDatiPersonali,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.ThankYouPage,
        ],
    },
    // CP AMM, CP DELIBERA
    {
        flowTypes: [FlowType.CPAmministrativo, FlowType.CPDelibera],
        railway: [
            RoutesPaths.CartActive,
            RoutesPaths.SummaryPage,
            dragonSetQuoteStatus(AptQuoteStatus.Confermato, AptQuoteSubStatus.AttesaSottomissioneOrdine),
            dragonSaveQuoteV2,
            RoutesPaths.ThankYouPage, // è chiamata dal router.service
        ],
    },
    // VARIAZIONE COMMERCIALE
    {
        flowTypes: [FlowType.VariazioneCommerciale],
        railway: [CART_PAGE_MATCHER, dragonSaveQuoteV2, RoutesPaths.ThankYouPage],
    },
    // VARIAZIONE TECNICA VERIFICHE, VARIAZIONE TECNICA VERIFICHE AMM
    // VARIAZIONE TECNICA LAVORI, VARIAZIONE TECNICA LAVORI AMM
    // VARIAZIONE TECNICA LAVORI DIMINUZIONE POTENZA, VARIAZIONE TECNICA LAVORI DIMINUZIONE POTENZA AMM
    {
        flowTypes: [
            FlowType.VariazioneTecnicaVerifiche,
            FlowType.VariazioneTecnicaVerificheAmministrativa,
            FlowType.VariazioneTecnicaLavori,
            FlowType.VariazioneTecnicaLavoriAmministrativa,
            FlowType.VariazioneTecnicaLavoriDiminuzionePotenza,
            FlowType.VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa,
        ],

        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.DatiContatto,
            RoutesPaths.ModalitaInvio,
            RoutesPaths.InfoDistributore,
            RoutesPaths.SceltaCase,
            RoutesPaths.VTRiepilogo,
            dragonSaveQuoteLavoriVerificheV2,
            RoutesPaths.ThankYouPage,
        ],
    },
    // VARIAZIONE TECNICA LAVORI PREVENTIVO, VARIAZIONE TECNICA LAVORI PREVENTIVO AMM
    // VARIAZIONE TECNICA LAVORI PREVENTIVO VARIAZIONE FASI, VARIAZIONE TECNICA LAVORI PREVENTIVO VARIAZIONE FASI AMM
    // VARIAZIONE TECNICA LAVORI PREVENTIVO VARIAZIONE TENSIONE, VARIAZIONE TECNICA LAVORI PREVENTIVO VARIAZIONE TENSIONE AMM
    // VARIAZIONE TECNICA LAVORI PREVENTIVO MODIFICA IMPIANTO, VARIAZIONE TECNICA LAVORI PREVENTIVO MODIFICA IMPIANTO AMM
    {
        flowTypes: [
            // VariazioneTecnica DROP 2
            FlowType.VariazioneTecnicaLavoriPreventivo,
            FlowType.VariazioneTecnicaLavoriPreventivoAmministrativa,
            FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasi,
            FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasiAmministrativa,
            FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensione,
            FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensioneAmministrativa,
            /* VT 2.2 - SPR19_PM1 */
            FlowType.VariazioneTecnicaLavoriPreventivoModificaImpianto,
            FlowType.VariazioneTecnicaLavoriPreventivoModificaImpiantoAmministrativa,
        ],
        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.DatiContatto,
            RoutesPaths.ModalitaInvio,
            RoutesPaths.InfoDistributore,
            RoutesPaths.CostoPreventivo,
            RoutesPaths.SceltaCase,
            RoutesPaths.VTRiepilogo,
            dragonSaveQuotePreventivo,
            RoutesPaths.ThankYouPage,
        ],
    },

    // VARIAZIONE TECNICA LAVORI PREVENTIVO AUMENTO POTENZA, VARIAZIONE TECNICA LAVORI PREVENTIVO AUMENTO POTENZA AMM
    {
        flowTypes: [
            FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza,
            FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa,
        ],
        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.InfoDistributore,
            RoutesPaths.TipoPreventivoCosti,
            RoutesPaths.AccettazioneImmediata,
            RoutesPaths.SceltaCase,
            RoutesPaths.SceltaFirma,
            RoutesPaths.ModalitaInvio,
            RoutesPaths.VTRiepilogo,
            RoutesPaths.FirmaVocalOrder,
            RoutesPaths.InserimentoManualeDocumento,
            RoutesPaths.FirmaDoiFull,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuotePreventivoAumPot,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // VARIAZIONE ATECO, VARIAZIONE TIPOLOGIA FORNITURA GAS
    {
        // Variazioni Tecniche DROP 3
        flowTypes: [FlowType.VariazioneTipologiaFornituraGas],
        railway: [RoutesPaths.VariazioneParametri, dragonSaveQuoteV2, RoutesPaths.ThankYouPage],
    },
    {
        flowTypes: [FlowType.VariazioneAteco],
        railway: [RoutesPaths.VariazioneParametriAteco, dragonSaveQuoteV2, RoutesPaths.ThankYouPage],
    },
    // ALLINEA TIPOLOGIA UTILIZZO DISTRIBUTORE
    {
        flowTypes: [FlowType.AllineaTipologiaUtilizzoDistributore],
        railway: [RoutesPaths.ThankYouPage],
    },
    // VARIAZIONE INDIRIZZO
    {
        flowTypes: [FlowType.VariazioneIndirizzo],
        railway: [RoutesPaths.VariazioneIndirizzi, RoutesPaths.ThankYouPage],
    },
    // AGGREGA, DISAGGREGA
    {
        flowTypes: [FlowType.Aggrega, FlowType.Disaggrega],
        railway: [
            RoutesPaths.AggregationAccountSelection,
            RoutesPaths.AggregationDateSelection,
            RoutesPaths.AggregationSummary,
            RoutesPaths.AggregationThankYouPage,
        ],
    },
    // EXTRACOMMODITY - CESSAZIONE EXTRACOMMODITY (Polizza, Manutenzione, SmartHome)
    {
        flowTypes: [FlowType.CessazioneExtracommodity],
        railway: [
            CART_PAGE_MATCHER,
            // Per ora commento creazione codice plico
            //dragonGetContractCodeNoSign,
            RoutesPaths.SummaryPage,
            dragonSaveQuoteV2,
            RoutesPaths.ThankYouPage,
        ],
    },
    {
        flowTypes: [FlowType.DisattivazioneCdC],
        railway: [RoutesPaths.DeactivationCdC, RoutesPaths.ThankYouPage],
    },
    //ADOTTA UN PANNELLO
    {
        flowTypes: [FlowType.AggiungiModificaPannello],
        railway: [
            RoutesPaths.AddOrModifyPanel,
            RoutesPaths.OrderSummaryPanel,
            RoutesPaths.SceltaFirma,
            RoutesPaths.TipoEsecuzione,
            RoutesPaths.DatiAgentePiu,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.UploadDocumento,
            RoutesPaths.FirmaVocalOrder,
            RoutesPaths.SceltaContatto,
            RoutesPaths.PrivacyDatiPersonali,
            dragonSetQuoteStatusSubstatus,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    {
        flowTypes: [FlowType.RimuoviPannello],
        railway: [RoutesPaths.RemovePanel, RoutesPaths.OrderSummaryPanel, RoutesPaths.ThankYouPage],
    },
];
