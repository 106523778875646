import { appReducer } from './app.reducer';
import { userReducer } from './user.reducer';
import { UserState } from '../models/user-state';
import { AppState } from '../models/app-state';
import { boReducer } from './bo.reducer';
import { BoState } from '../models/bo-state';
import { OrderEntryState_v2 } from '../models/order-entry-state_v2';
import { orderEntryV2Reducer } from './order-entry-v2.reducer';

export interface EglState {
    app?: AppState;
    user: UserState;
    orderEntry: OrderEntryState_v2;
    bo: BoState;
}

export const reducers = {
    app: appReducer,
    user: userReducer,
    orderEntry: orderEntryV2Reducer,
    bo: boReducer,
};
