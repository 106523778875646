import moment from 'moment';
import { AttributeValues } from './base-attribute-values';

export class MaintenanceAttributeValues extends AttributeValues {
    attributesMapping(): void {
        super.attributesMapping();

        this.attr['egl_neta_rateizzo_flag__c'] = this.currentProduct?.configurations?.installmentFlag;
        this.attr['egl_neta_cessioneCredito_flag__c'] = this.currentProduct?.configurations?.creditAssignment;
        this.attr['egl_neta_ritenutaDAcconto_flag__c'] = this.currentProduct?.configurations?.withHoldingTax;
        this.attr['egl_neta_productClass__c'] = this.currentProduct?.configurations?.productClass;
        this.attr['egl_Appliance_Out_Of_Warranty_01__c'] = this.currentProduct?.configurations?.hasWarranty;
        this.attr['egl_First_Appointment__c'] = moment(this.currentProduct?.booking?.startTime).format('YYYY-MM-DD');
        this.attr['egl_product_installation_date__c'] = moment(
            this.currentProduct?.configurations?.installmentDate
        ).format('YYYY-MM-DD');
        this.attr['egl_Heating_System_type__c'] = this.currentProduct?.configurations?.heatingSystemType;
        this.attr['egl_Type_Water_Heater__c'] = this.currentProduct?.configurations?.typeWaterHeater;
    }
}
