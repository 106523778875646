import {
    AObjectService,
    CacheService,
    ConfigurationService,
    MetadataService,
    PlatformService,
} from '@congacommerce/core';
import { Injectable, Injector } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { EglOrderLineItem } from '../../../../models/apttus/tables/order/egl-order-line-item';
import { LoadingService } from '../../../shared/loading.service';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class EglOrderLineItemService extends AObjectService {
    constructor(
        cacheSrv: CacheService,
        plSrv: PlatformService,
        mtSrv: MetadataService,
        confSrv: ConfigurationService,
        inj: Injector
    ) {
        super(cacheSrv, plSrv, mtSrv, confSrv, inj);
        this.setType(EglOrderLineItem);
    }

    updateDdlAndPublish(cart: EglOrderLineItem): Observable<EglOrderLineItem> {
        return this.update([cart]).pipe(
            take(1),
            map(([updatedCart]) => updatedCart as EglOrderLineItem),
            LoadingService.loaderOperator('Aggiornamento OLI')
        );
    }
}
