export enum D365DataOwnershipChangeOutcome {
    DoiSigned = 'CONFERMATO',
    DoiSendKo = 'INVIO KO',
    DoiCanceled = 'RIFIUTATO',
    DoiExpired = 'SCADUTO',
    DoiSent = 'INVIATO',
    DoiCanceledFromBo = 'CANCELLATO',
    DoiExpiredFromApim = 'SCADUTO DA APIM',
    DoiPendingSend = 'ATTESA INVIO',
}
