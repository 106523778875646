import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryOptions } from '@congacommerce/core';
import { CartItem, CartService } from '@congacommerce/ecommerce';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { set } from 'lodash';
import moment from 'moment';
import { combineLatest, EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { QuoteDetail } from '../../modules/back-office/models/quotedetailresponse-response';
import { StatusCancellationRequest } from '../../modules/status/cancellation/models/status-cancellation.request';
import { StatusCancellationResponse } from '../../modules/status/cancellation/models/status-cancellation.response';
import { setV2CustomerIsRegistered } from '../../store/actions/order-entry-v2.actions';
import { MastershipType } from '../../store/models/user-state';
import { EglState } from '../../store/reducers';
import {
    v2SelectAnagraficaMb,
    v2SelectCurrentProduct,
    v2SelectDistinctCommodities,
    v2SelectIsCustomerRegistered,
    v2SelectVisibleProducts,
} from '../../store/selectors/order-entry-v2.selectors';
import {
    selectFlowType,
    selectOperationType,
    selectOrderEntryState,
} from '../../store/selectors/order-entry.selectors';
import { selectContactLead, selectCurrentVirtualAgent, selectUserState } from '../../store/selectors/user.selectors';
import { AptContactRole } from '../enums/apttus/apt-mb-representative';
import { AptOperationType } from '../enums/apttus/apt-operation-type';
import { AptProductFamily } from '../enums/apttus/apt-product-family';
import { AptSaleabilityOperationType, AptSalesProcess } from '../enums/apttus/apt-sales-process';
import { D365AccountMigrated } from '../enums/d365/d365-account-migrated';
import { D365CustomerSegment } from '../enums/d365/d365-customer-segment';
import { DocTypeOpenText } from '../enums/shared/doc-types-open-text';
import { jsonTryParse } from '../functions/misc.functions';
import { flowTypeToAptSalesProcess } from '../functions/remap.functions';
import { isFlowTypeAdminForPriceList } from '../functions/verifications.functions';
import { BaseApexApiResponse, StatusApexResponse } from '../interfaces/base-apex-api-respose';
import { BaseApiResponse, StatusResponse } from '../interfaces/base-api-response';
import { CheckItemsRequest } from '../models/apex-rest/check-item-request';
import { CheckAttributeItem, CheckAttributeResponse } from '../models/apex-rest/checkattribute-response';
import { ApttusDoOperationResponse } from '../models/apex-rest/doi-resubmission';
import {
    EligibilityCheckRequest,
    UseCaseType as ElegiblityCheckUseCaseType,
} from '../models/apex-rest/eligibility-check-request';
import { EligibilityCheckResponse } from '../models/apex-rest/eligibility-check-response';
import { MailBlacklistRequest, MailBlacklistResponse } from '../models/apex-rest/mail-blacklist';
import { SalesupStateResponse } from '../models/apex-rest/salesup-state-response';
import { ScontiListResponse, ScontoListItem } from '../models/apex-rest/scontilist-response';
import { CloseTaskRequest } from '../models/apim/close-task/close-task-request';
import { CloseTaskResponse } from '../models/apim/close-task/close-task-response';
import { retrievCaseRequest } from '../models/apim/retrieveCase-request';
import { RetrieveCaseResponse } from '../models/apim/retrieveCase-response.model';
import { UpsertTaskRequest } from '../models/apim/upsert-task/upsert-task-request';
import { UpsertTaskResponse } from '../models/apim/upsert-task/upsert-task-response';
import { UpsertCaseReqAPIM } from '../models/apim/upsertCase-request';
import { UpsertCaseResAPIM } from '../models/apim/upsertCase-response';
import { UpsertEmailReq } from '../models/apim/upsertEmail-request';
import { UpsertEmailRes } from '../models/apim/upsertEmail-response';
import { ArrearsResponse } from '../models/app/arrears.response';
import { CheckPriceListAmm, DiscountDetails } from '../models/app/check-pricelist-amm.response';
import { InfoLog } from '../models/app/credit-check.request';
import { GenerateDocumentOT } from '../models/app/generate-document-ot-response';
import { RecuperaDatiRegistrazioneRequest } from '../models/app/recupera-dati-registrazione.request';
import { RecuperaDatiRegistrazioneResponse } from '../models/app/recupera-dati-registrazione.response';
import { RetrieveDataSalesupRequest } from '../models/app/recupera-dati-salesup.request';
import { RetrieveDataSalesupResponse } from '../models/app/recupera-dati-salesup.response';
import { ServiceError } from '../models/app/service-error';
import { StandaloneDiscountResponse } from '../models/app/standalone-discount-response';
import { UniqueRegistrationEmailResponse } from '../models/app/unique-registration-mail.response';
import { CheckBeforeOrder, CheckBeforeOrderResponse } from '../models/apttus/models/check_before_order_response';
import { ApttusUpsertRequest } from '../models/apttus/request-response/apttus-upsert-request';
import { EglProductExtended } from '../models/apttus/tables/product/egl-product-extended';
import { EglBoReason } from '../models/apttus/tables/quote/egl-bo-reason';
import { Contact } from '../models/user/contact';
import { EglCartLightService } from '../services/apttus/tables/cart/egl-cart-light.service';
import { ApiService } from '../services/shared/api.service';
import { FeatureToggleService } from '../services/shared/feature-toggle.service';
import { NO_LOADING } from '../services/shared/http-request-interceptor.service';
import { LoadingService } from '../services/shared/loading.service';
import { LoggerService } from '../services/shared/logger.service';
import { PrivateConfigurationService } from '../services/shared/private-configuration.service';
import { AptCustomerType } from './../enums/apttus/apt-customer-type';
import { ApexApi, ApiMngApi, ApttusApi, BaseProvider } from './base-provider';

@Injectable({ providedIn: 'root' })
export class CommonProvider extends BaseProvider {
    constructor(
        private api: ApiService,
        protected configSrv: PrivateConfigurationService,
        private store: Store<EglState>,
        private toggleService: FeatureToggleService,
        private loggerService: LoggerService,
        translateSrv: TranslateService,
        private eglCartLightSrv: EglCartLightService,
    ) {
        super(configSrv, translateSrv);
    }

    generateDocumentOT(
        apttusQuoteId: string,
        documentType: DocTypeOpenText,
    ): Observable<BaseApiResponse<GenerateDocumentOT>> {
        return this.store.select(selectFlowType).pipe(
            take(1),
            mergeMap((flowType) => {
                const stubbedFlowTypes = (this.configSrv.config.plicoApiStubbedFor || []).map((ft: string) =>
                    ft.toUpperCase(),
                );
                const isApiStubbed =
                    stubbedFlowTypes.indexOf('ALL') !== -1 || stubbedFlowTypes.indexOf(flowType.toUpperCase()) !== -1;
                const api = isApiStubbed ? ApiMngApi.StubGenerateDocOT : ApiMngApi.GenerateDocOT;
                const payload = {
                    docType: documentType,
                    ...(isApiStubbed && { quoteId: apttusQuoteId }),
                    ...(!isApiStubbed && { id: apttusQuoteId }),
                };
                return this.api.postAsync<BaseApiResponse<GenerateDocumentOT>>(this.getApiMngApiUrl(api), payload);
            }),
        );
    }

    generateDocumentOT_obs(
        apttusQuoteId: string,
        documentType: DocTypeOpenText,
    ): Observable<BaseApiResponse<GenerateDocumentOT>> {
        return this.store.select(selectFlowType).pipe(
            take(1),
            mergeMap((flowType) => {
                const stubbedFlowTypes = (this.configSrv.config.plicoApiStubbedFor || []).map((ft: string) =>
                    ft.toUpperCase(),
                );
                const isApiStubbed =
                    stubbedFlowTypes.indexOf('ALL') !== -1 || stubbedFlowTypes.indexOf(flowType.toUpperCase()) !== -1;
                const api = isApiStubbed ? ApiMngApi.StubGenerateDocOT : ApiMngApi.GenerateDocOT;

                if (!isApiStubbed) {
                    return this.api.postAsync<BaseApiResponse<GenerateDocumentOT>>(this.getApiMngApiUrl(api), {
                        id: apttusQuoteId,
                        docType: documentType,
                    });
                } else {
                    return this.api.postAsync<BaseApiResponse<GenerateDocumentOT>>(this.getApiMngApiUrl(api), {
                        quoteId: apttusQuoteId,
                        docType: documentType,
                    });
                }
            }),
        );
    }

    private async genericDoApttusAction(params?: any): Promise<ApttusDoOperationResponse> {
        return this.api
            .postAsync<ApttusDoOperationResponse>(this.getApexApiUrl(ApexApi.DoApttusAction), params)
            .toPromise()
            .catch(() => {
                return null;
            });
    }

    // verifica l'univocita di contract code
    async isContractCodeValid(contractCode: string): Promise<boolean> {
        const req = {
            ContractCode: {
                Code: contractCode,
                Operation: AptOperationType[7],
            },
        };
        const resp = await this.genericDoApttusAction(req);
        return resp && resp.Result === '001';
    }

    /**
     * @description: recupera lo state (Redux) da una tabella su SF
     * @param productconfigurationid: è l'id del carrello a cui è associato lo state
     */
    getSalesUpState(productconfigurationid: string): Observable<SalesupStateResponse> {
        return this.api.getAsync<SalesupStateResponse>(this.getApexApiUrl(ApexApi.GetSalesUpState), {
            ...NO_LOADING,
            params: { productconfigurationid },
        });
    }

    /**
     * @description: salva lo state (Redux) su una tabella su SF
     * @param productconfigurationid: è l'id del carrello a cui è associato lo state
     * @param serializedState: è lo state Redux
     */
    saveSalesUpState(
        productconfigurationid: string,
        serializedState: string,
        version: number,
        callingSource: string,
    ): Observable<{ Status: string; Result: string; ErrorMessage: string }> {
        return this.api
            .postAsync<{
                Status: string;
                Result: string;
                ErrorMessage: string;
            }>(
                this.getApexApiUrl(ApexApi.SaveSalesUpState),
                { productconfigurationid, state: serializedState },
                undefined,
                NO_LOADING,
            )
            .pipe(
                tap((resp) => {
                    if (resp?.Result !== '001') {
                        throw new Error(resp?.ErrorMessage || 'Save SalesUP state failed');
                    }
                    this.loggerService.info(
                        `SalesUP state version ${version} saved at time ${new Date().toLocaleTimeString()}. Triggered by '${
                            callingSource || 'unknown'
                        }'`,
                        jsonTryParse(serializedState),
                    );
                }),
            );
    }

    /**
     * @description: invoca la hadless API 'QUERY'
     * @param q: query da eseguire
     */
    query<T>(q: string): Observable<T> {
        return this.api.getAsync<T>(this.getApttusApiUrl(ApttusApi.QueryData), { q });
    }

    /**
     * @description: search CF or PIVA on customer base
     * @param fieldName: 'CF' | 'PIVA' | 'CODCONTO' | 'CODCLIENTE'
     * @param fieldValue: value to search
     * @param source: 'H' for HomePage, 'O' for OrderEntry
     * @param skipCC: skip Credit Check
     * @param skipInsolutoNDS: skip controllo Insoluto NDS
     */

    getSalesUpClientData = (
        fieldName: 'CF' | 'PIVA' | 'CODCONTO' | 'CODCLIENTE' | 'COD_PDRPOD' | 'MATRICOLA',
        fieldValue: string,
        source: 'H' | 'O',
        skipCC: boolean,
        skipInsolutoNDS: boolean,
        skipCF?: boolean,
        getActivatingSupplies?: boolean,
    ): Observable<BaseApiResponse<RetrieveDataSalesupResponse>> => {
        const req: RetrieveDataSalesupRequest = {
            fieldName,
            fieldValue,
            source,
            skipCC,
            skipInsolutoNDS,
            skipCF,
            getActivatingSupplies,
            skipAnagrafica: false,
        };

        const endpoint =
            this.toggleService?.isSwitchInE2EEnabled || this.toggleService?.isVolturaSempliceEnabled
                ? ApiMngApi.RetrieveCustomerData
                : ApiMngApi.RetrieveDataSalesUp;

        return this.getBusinessTransactionId().pipe(
            switchMap((businessTrxId) => {
                const options = {
                    headers: {
                        businessTrxId,
                    },
                };

                return this.api
                    .postAsync<
                        BaseApiResponse<RetrieveDataSalesupResponse>
                    >(this.getApiMngApiUrl(endpoint), req, 0, options)
                    .pipe(
                        tap((res) => {
                            // codice E03_1 indica che apim ha trovato più di un risultato
                            if (
                                res instanceof HttpErrorResponse ||
                                (res?.errorManagement && res?.errorManagement?.errorCode !== 'E03_1')
                            ) {
                                this.loggerService.error(
                                    null,
                                    res?.errorManagement?.errorDescription || 'Errore in fase di ricerca cliente',
                                );
                                throw new ServiceError(
                                    'API_ERROR',
                                    res?.errorManagement?.errorDescription || 'Errore in fase di ricerca cliente',
                                    'HIGH',
                                );
                            }
                        }),
                        map((res) => {
                            // Aggiungi proprietà 'esito' (calcolato con esitoCF, creditCheck e insolutoNDS)
                            if (res?.response?.datiCliente?.esiti) {
                                const esito = !Object.values(res?.response?.datiCliente?.esiti || {}).some(
                                    (value) => typeof value === 'object' && value?.esito === false,
                                );
                                set(res, 'response.datiCliente.esiti.esito', esito);
                            }
                            return res;
                        }),
                        // Rettifica tipologia contatto nel caso di Microbusiness
                        map((res) =>
                            res?.response?.datiCliente?.anagrafica?.piva &&
                            ![AptContactRole.RappresentanteLegale, AptContactRole.Referente].includes(
                                res.response.datiCliente.anagrafica.tipoInterlocutore,
                            )
                                ? {
                                      ...res,
                                      response: {
                                          ...res.response,
                                          datiCliente: {
                                              ...res.response.datiCliente,
                                              anagrafica: {
                                                  ...res.response.datiCliente.anagrafica,
                                                  tipoInterlocutore: AptContactRole.RappresentanteLegale,
                                              },
                                          },
                                      },
                                  }
                                : res,
                        ),
                    );
            }),
        );
    };

    /**
     * @description: Esegue la hadless api di query
     * @param entityName: nome dell'entità
     * @param queryOptions: body della request
     * @return: Observable<T>
     */
    queryHadless<T>(entityName: string, queryOptions: QueryOptions, hideLoading: boolean = false): Observable<T> {
        const options = {
            headers: new HttpHeaders(),
        };
        if (hideLoading) {
            options.headers = options.headers.append('no-loading', 'true');
        }

        return this.api.postAsync<T>(
            this.getApttusApiUrl(ApttusApi.Query).replace('{entityName}', entityName),
            queryOptions,
            0,
            options,
        );
    }

    /**
     * @description: Esegue la headless api generica di Upsert
     * @param entityName nome dell'entità
     * @param body body della request
     * @return: Observable<T>
     */
    genericUpsertHeadless<T>(entityName: string, body: any[]): Observable<T> {
        return this.api.putAsync<T>(this.getApttusApiUrl(ApttusApi.Generic).replace('{entityName}', entityName), body);
    }

    /**
     * @description Esegue l'insert o l'update dell'entità EglBoReason
     * @param data La richiesta che contiene i record da aggiornare/inserire
     * @returns Observable<HttpResponse<any>>
     */
    upsertBoReasons(data: ApttusUpsertRequest<EglBoReason>): Observable<HttpResponse<any>> {
        return this.api.patchAsync(
            this.appConfig.endpoints.apttus.baseUrl,
            'services/data/v50.0/composite/sobjects/egl_bo_reason__c',
            'Id',
            data,
        );
    }

    /**
     * @description: Esegue la delete tramite l'api BOReasonDelete
     * @param param: parametro della request
     * @return: Observable<T>
     */
    deleteBoReasons(id: string): Observable<BaseApexApiResponse> {
        return this.api.deleteAsync<BaseApexApiResponse>(this.getApexApiUrl(ApexApi.Delete), {
            id: id,
        });
    }

    /**
     * @description Effattua chiamata per restituire i dati di registrazione
     * @param request: La richiesta che contiene i record da aggiornare/inserire
     * @returns Observable<HttpResponse<any>>
     */
    retrieveRegistrationData(request: RecuperaDatiRegistrazioneRequest): Observable<boolean> {
        return this.api
            .postAsync<BaseApiResponse<RecuperaDatiRegistrazioneResponse>>(
                this.getApiMngApiUrl(ApiMngApi.RetrieveRegistrationData),
                request,
            )

            .pipe(
                tap((response) => {
                    if (response?.status !== StatusResponse.Success) {
                        throw new ServiceError(
                            response?.errorManagement?.errorCode,
                            response.errorManagement?.errorDescription,
                        );
                    }
                }),
                map((res) => !!res?.response?.registrato),
            );
    }

    isCustomerRegistered(): Observable<boolean> {
        const registeredResponse$ = combineLatest([
            this.store.select(selectContactLead),
            this.store.select(v2SelectAnagraficaMb),
            this.store.select(v2SelectDistinctCommodities('ALL')),
        ]).pipe(
            take(1),
            map(([{ contact }, anagraficaMb, commodities]) => ({
                codiceCliente: contact?.egl_customercode,
                partitaIVA: anagraficaMb?.piva || contact?.egl_vatcode,
                codiceFiscale: contact?.egl_taxcode || anagraficaMb?.cf,
                codicePdRPoDList: commodities?.map((product) => product?.podPdr).filter(Boolean),
                contoCliente: contact?.egl_customeraccountcode,
            })),
            mergeMap(({ codicePdRPoDList, ...request }) =>
                forkJoin(
                    codicePdRPoDList.map((codicePdRPoD) => this.retrieveRegistrationData({ ...request, codicePdRPoD })),
                ),
            ),
            map((results) => !results.some((result) => !result)),
            // Qualora il servizio non rispondesse rendo disabilitato il form dati contatto
            catchError(() => of(true)),
            tap((isRegistered) => {
                this.store.dispatch(setV2CustomerIsRegistered({ isRegistered }));
            }),
        );

        return this.store.select(v2SelectIsCustomerRegistered).pipe(
            take(1),
            mergeMap((isRegistered) => (typeof isRegistered === 'boolean' ? of(isRegistered) : registeredResponse$)),
        );
    }

    /**
     * @description La funzionalità di DettaglioMorositaContiCliente è quella di fornire a Dynamics i dettagli sulla morosita dei conti associati ad un cliente.
     * https://dev.azure.com/DevOps-Applications-EGL/Front%20End%20Eni/_wiki/wikis/Front-End-Eni.wiki/4286/Dettaglio-Morosit%C3%A0-Conti-Cliente
     * @returns Observable<BaseApiResponse<ArrearsResponse>>
     */
    getArrearsByAccount(customerCode: string): Observable<ArrearsResponse> {
        return this.api
            .postAsync<BaseApiResponse<ArrearsResponse>>(
                this.getApiMngApiUrl(ApiMngApi.RetrieveArrearsByAccount),
                {
                    CodiceCliente: customerCode,
                    ContoCliente: '',
                },
                undefined,
                NO_LOADING,
            )
            .pipe(
                tap((response) => {
                    if (response?.result === '002') {
                        throw new Error(
                            `${response?.errorManagement?.errorCode}: ${
                                response?.errorManagement?.errorDescription || 'Si è verificato un problema'
                            }`,
                        );
                    }
                }),
                catchError((err) => {
                    this.loggerService.error('Error while retrieving data from APIM.', err, null, false, false);
                    return EMPTY;
                }),
                map((c) => c.response),
            );
    }

    /**
     * @description Effettua chiamata per controllare se la mail fornita in ingresso è in blacklist
     * @param mail La mail da controllare
     * @param hideLoading Nasconde lo spinner di caricamento
     * @returns Observable<MailBlacklistResponse>
     */
    isEmailBlacklisted(mail: string, hideLoading = true): Observable<MailBlacklistResponse> {
        const options = {
            headers: new HttpHeaders(),
        };
        if (hideLoading) {
            options.headers = options.headers.append('no-loading', 'true');
        }
        const request = new MailBlacklistRequest([mail]);
        return this.api
            .postAsync<MailBlacklistResponse>(this.getApexApiUrl(ApexApi.MailBlacklist), request, 0, options)
            .pipe(catchError(() => of(null)));
    }

    /**
     * @description Effettua un Cancel o un Amend di un ordine
     * @param req object con i parametri della request
     */
    cancelOrAmend(req: StatusCancellationRequest): Observable<StatusCancellationResponse> {
        return this.api.postAsync<StatusCancellationResponse>(this.getApiMngApiUrl(ApiMngApi.CancelOrAmend), req);
    }

    checkItems(req: CheckItemsRequest): Observable<void> {
        return this.api.postAsync<BaseApexApiResponse>(this.getApexApiUrl(ApexApi.CheckItems), req).pipe(
            tap((res) => {
                if (res?.status !== StatusApexResponse.Success) {
                    // Per le casistiche in cui il messaggio di errore da vedere è quello di SUP
                    const basePath = 'ERROR.CHECK_ITEMS';
                    const errorMessage = `${basePath}.${res?.result}`;
                    const resolvedError = this.translateSrv.instant(errorMessage);
                    const isResolved = this.translateSrv.instant(errorMessage) !== errorMessage;
                    throw new ServiceError(
                        res?.result,
                        (isResolved && resolvedError) ||
                            res?.errorMessage ||
                            this.translateSrv.instant('ERROR.GENERIC.MESSAGE'),
                        res?.result ? 'LOW' : 'HIGH',
                        { isResolved },
                    );
                }
            }),
            map(() => null),
        );
    }

    checkBeforeOrder(req: { cartId: string }): Observable<CheckBeforeOrderResponse> {
        return this.api.postAsync<CheckBeforeOrder>(this.getApexApiUrl(ApexApi.Checkbeforeorder), req).pipe(
            tap((res) => {
                if (res?.status !== StatusResponse.Success) {
                    this.loggerService.toastError(
                        `Al momento non è possibile procedere con la creazione dell'ordine a causa di un disservizio temporaneo.Vi invitiamo a riprovare più tardi.`,
                        'Attenzione',
                        true,
                    );
                    throw new ServiceError(
                        res?.result,
                        res?.errorMessage || this.translateSrv.instant('ERROR.GENERIC.MESSAGE'),
                        res?.result ? 'LOW' : 'HIGH',
                    );
                }
            }),
            map((res) => res?.response),
        );
    }

    /**
     * @description: consente di recuperare l'elenco degli attributi variati per un carrello di variazione commerciale in base al suo identificativo
     * @param productconfigurationid: identificativo del carrello per il quale si desidera recuperare gli attributi variati.
     */
    getModifiedAttributes(productconfigurationid: string): Observable<CheckAttributeResponse> {
        return this.api
            .getAsync<CheckAttributeResponse>(this.getApexApiUrl(ApexApi.GetModifiedAttributes), {
                ...NO_LOADING,
                params: { productconfigurationid },
            })
            .pipe(
                mergeMap((response) => {
                    if (response.Status === '002') {
                        this.loggerService.error(
                            'Error while retrieving data from Apttus.',
                            response.Message,
                            null,
                            false,
                            false,
                        );
                        response.Items = [];
                    } else
                        response.Items = response?.Message
                            ? JSON.parse(response.Message).map((c: any) => {
                                  return {
                                      ...c,
                                      newValue: c.newValue == 'Y' ? 'Si' : c.newValue == 'N' ? 'No' : c.newValue,
                                      oldValue: c.oldValue == 'Y' ? 'Si' : c.oldValue == 'N' ? 'No' : c.oldValue,
                                  } as CheckAttributeItem;
                              })
                            : null;

                    return of(response);
                }),
            );
    }

    /**
     * @description: consente di recuperare l'elenco degli sconti disponibili per un determinato cliente
     */
    getDiscountList(contact: Contact, campaignRecords: Array<any>, unsolved: boolean): Observable<ScontiListResponse> {
        return this.api
            .postAsync<ScontiListResponse>(
                this.getApexApiUrl(ApexApi.EligibilityCheck),
                {
                    useCase: ElegiblityCheckUseCaseType.DiscountList,
                    isSiebelCustomer: contact.EglMigration === D365AccountMigrated.SIEBEL,
                    operationType: AptSaleabilityOperationType.ScontoStandalone,
                    customerCode: contact.egl_customercode,
                    customerType:
                        contact.egl_customersegmentcode === D365CustomerSegment.Residential
                            ? AptCustomerType[AptCustomerType.Residenziale]
                            : AptCustomerType[AptCustomerType.Microbusiness],
                    salesChannel: '',
                    unsolved,
                    email: !!contact.emailaddress1,
                    records: campaignRecords,
                } as EligibilityCheckRequest,
                undefined,
                NO_LOADING,
            )
            .pipe(
                mergeMap((response) => {
                    if (response.Status === '002') {
                        this.loggerService.error(
                            'Error while retrieving data from Apttus.',
                            response.Message,
                            null,
                            false,
                            false,
                        );
                        response.Items = [];
                    } else
                        response.Items =
                            response?.Message && !['OK', 'KO'].includes(response?.Message)
                                ? JSON.parse(response.Message).map((c: any) => {
                                      return {
                                          ...c,
                                      } as ScontoListItem;
                                  })
                                : [];
                    return of(response);
                }),
            );
    }

    createStandaloneDiscountCart(
        assetLineItemId: string,
        productId: string,
    ): Observable<BaseApiResponse<StandaloneDiscountResponse>> {
        return this.store.select(selectUserState).pipe(
            take(1),
            switchMap((userState) => {
                const body = {
                    AssetId: assetLineItemId,
                    ProductId: productId,
                    User: userState.agentInfo.DomainName,
                };
                return this.api.postAsync<BaseApiResponse<StandaloneDiscountResponse>>(
                    this.getApiMngApiUrl(ApiMngApi.StandaloneDiscount),
                    body,
                );
            }),
        );
    }

    discountEligibilityCheck(
        useCaseType: ElegiblityCheckUseCaseType,
        campaignRecords: Array<any>,
        cartId?: string,
    ): Observable<EligibilityCheckResponse> {
        return combineLatest([
            this.store.select(selectOperationType),
            this.store.select(selectUserState),
            this.store.select(selectCurrentVirtualAgent),
            this.store.select(selectOrderEntryState),
        ]).pipe(
            take(1),
            mergeMap(([operationType, user, currentVirtualAgent, orderEntry]) => {
                return this.api
                    .postAsync<EligibilityCheckResponse>(
                        this.getApexApiUrl(ApexApi.EligibilityCheck),
                        {
                            useCase: useCaseType,
                            isSiebelCustomer: user.customerMastership === MastershipType.Siebel,
                            operationType,
                            customerCode: user.contact?.egl_customercode,
                            customerType:
                                user?.cartSegment === D365CustomerSegment.Residential
                                    ? AptCustomerType[AptCustomerType.Residenziale]
                                    : AptCustomerType[AptCustomerType.Microbusiness],
                            salesChannel: currentVirtualAgent?.VirtualAgency?.Channel?.Code,
                            unsolved: !orderEntry?.creditCheckStatus?.canProceed,
                            cartId,
                            email: !!user.contact?.emailaddress1,
                            records: campaignRecords,
                        } as EligibilityCheckRequest,
                        undefined,
                        {
                            headers: new HttpHeaders({
                                'no-loading': 'true',
                            }),
                        },
                    )
                    .pipe(
                        mergeMap((response) => {
                            if (response.Status == '002') {
                                this.loggerService.error(
                                    'Error while retrieving data from Apttus.',
                                    response.Message,
                                    null,
                                    false,
                                    false,
                                );
                            }
                            return of(response);
                        }),
                    );
            }),
        );
    }

    assetFileExists(path: string): Observable<boolean> {
        return this.api.getBlobFile(path).pipe(
            map(() => true),
            catchError(() => of(false)),
        );
    }

    /**
     * @description Effettua la chiamata per controllare se esistono già case e moduli istanza associati al cliente
     * @param accountNumber Codice Cliente
     * @param fiscalCode Codice Fiscale
     * @param taxCode Piva
     * @returns Observable<RetrieveCaseResponse[]>
     */
    retrieveCase(
        accountNumber: string,
        fiscalCode?: string,
        taxCode?: string,
    ): Observable<RetrieveCaseResponse[] | Error> {
        const req: retrievCaseRequest = {
            AccountNumber: accountNumber,
            FiscalCode: fiscalCode,
            TaxCode: taxCode,
        };
        return this.api
            .postAsync<BaseApiResponse<RetrieveCaseResponse[]>>(this.getApiMngApiUrl(ApiMngApi.RetrieveCase), req)
            .pipe(
                tap((response) => {
                    if (response?.status !== StatusResponse.Success) {
                        throw new ServiceError(
                            response?.errorManagement?.errorCode,
                            response.errorManagement?.errorDescription,
                        );
                    }
                }),
                map((res) => res?.response),
                catchError((error: Error) => {
                    this.loggerService.error('Errore chiamata recupero case', error?.message, error, true);
                    return of(error);
                }),
            );
    }

    /**
     * @description La chiamata permette la gestione dei task su D365 tramite API di APIM
     * @param req La request da mandare di tipo UpsertTaskRequest
     * @returns Observable<UpsertTaskResponse>
     */
    upsertTask(req: UpsertTaskRequest): Observable<UpsertTaskResponse> {
        return this.api
            .postAsync<BaseApiResponse<UpsertTaskResponse>>(this.getApiMngApiUrl(ApiMngApi.UpsertTask), req)
            .pipe(
                map((response) => {
                    if (
                        (response?.result !== '001' || response?.status !== StatusResponse.Success) &&
                        response instanceof BaseApiResponse
                    ) {
                        throw new ServiceError(
                            response?.errorManagement?.errorCode,
                            response.errorManagement?.errorDescription,
                        );
                    }
                    return response.response;
                }),
                catchError((error: Error) => {
                    this.loggerService.error('Errore chiamata gestione case', error?.message, error, true);
                    return throwError(error);
                }),
            );
    }

    closeTask(req: CloseTaskRequest): Observable<CloseTaskResponse> {
        return this.api
            .postAsync<BaseApiResponse<CloseTaskResponse>>(this.getApiMngApiUrl(ApiMngApi.CloseTask), req)
            .pipe(
                map((response) => {
                    if (
                        (response?.result !== '001' || response?.status !== StatusResponse.Success) &&
                        response instanceof BaseApiResponse
                    ) {
                        throw new ServiceError(
                            response?.errorManagement?.errorCode,
                            response.errorManagement?.errorDescription,
                        );
                    }
                    return response.response;
                }),
                catchError((error: Error) => {
                    this.loggerService.error('Errore chiamata gestione case', error?.message, error, true);
                    return throwError(error);
                }),
            );
    }

    /**
     * @description La chiamata permette la gestione dei case su D365 tramite API di APIM
     * @param req La request da mandare di tipo UpsertCaseReq
     * @returns Observable<UpsertCaseRes | Error>
     */
    upsertCase(req: UpsertCaseReqAPIM): Observable<UpsertCaseResAPIM | Error> {
        return this.api
            .postAsync<BaseApiResponse<UpsertCaseResAPIM> | UpsertCaseResAPIM>(
                this.getApiMngApiUrl(ApiMngApi.UpsertCase),
                req,
            )

            .pipe(
                tap((response) => {
                    if (
                        (response?.result !== '001' || response?.status !== StatusResponse.Success) &&
                        response instanceof BaseApiResponse
                    ) {
                        throw new ServiceError(
                            response?.errorManagement?.errorCode,
                            response.errorManagement?.errorDescription,
                        );
                    }
                }),
                map((res) => res as UpsertCaseResAPIM),
                catchError((error: Error) => {
                    this.loggerService.error('Errore chiamata gestione case', error?.message, error, true);
                    return of(error);
                }),
            );
    }

    /**
     * @description La chiamata permette di caricare un file nel blob storage di Azure in base alle options
     * @param file Il file da caricare mandare di tipo Binary
     * @returns Observable<StatusResponse | Error>
     */
    putBlob(file: File, options: { headers: HttpHeaders }): Observable<StatusResponse | Error> {
        const binaryFile = new FileReader();
        return this.api
            .putAsync<BaseApiResponse<StatusResponse> | HttpErrorResponse>(
                this.getApiMngApiUrl(ApiMngApi.PutBlob),
                binaryFile.readAsBinaryString(file),
                undefined,
                options,
            )

            .pipe(
                tap((response) => {
                    if (response instanceof HttpErrorResponse) {
                        throw new ServiceError(
                            response?.error?.errorManagement?.errorCode,
                            response?.error?.errorManagement?.errorDescription,
                        );
                    }
                }),
                map((res) => (res as BaseApiResponse<StatusResponse>)?.status),
                catchError((error: Error) => {
                    this.loggerService.error('Errore chiamata gestione case', error?.message, error, true);
                    return of(error);
                }),
            );
    }

    /**
     * @description Effettua la chiamata per inviare automaticamente l'email da D365
     * @returns Observable<UpsertEmailRes | Error>
     */
    retrieveUpsertEmail(quote: QuoteDetail, filePDF: File, caseNumber: string): Observable<UpsertEmailRes | Error> {
        const req: UpsertEmailReq = {
            InfoLog: {} as InfoLog,
            type: 'INBOUND_ESKER',
            sender: {
                senderCode: quote?.customerCode,
                senderType: 'account',
                emailAddress: quote?.contactEmail,
            },
            to: [
                {
                    recipientEmailAddress: 'eni@eniplenitude.com',
                },
            ],
            body: 'Salve, allego la documentazione richiesta.',
            businessObjectType: 'INCIDENT',
            businessObjectId: caseNumber,
            dateOfReceipt: moment().format('DD/MM/YYYY'),
            protocolInDate: moment().format('DD/MM/YYYY'),
            protocolInNumber: 'INSTANCEFORM_1',
            quoteCode: quote?.name,
            attachments: [
                {
                    blobStorageAccountName: 'sta01q1awwe1782',
                    blobStorageContainer: 'communicationinbound',
                    blobStoragePath: 'doctoarchive',
                    blobFileName: filePDF?.name + '.pdf',
                    fileType: 'PRATICHE_IN',
                },
            ],
            status: '4',
        };

        return this.api
            .postAsync<BaseApiResponse<UpsertEmailRes>>(this.getApiMngApiUrl(ApiMngApi.UpsertEmail), req)
            .pipe(
                tap((response) => {
                    if (response?.status !== StatusResponse.Success) {
                        throw new ServiceError(
                            response?.errorManagement?.errorCode,
                            response.errorManagement?.errorDescription,
                        );
                    }
                }),
                map((res) => res?.response),
                catchError((error: Error) => {
                    this.loggerService.error('Errore chiamata recupero case', error?.message, error, true);
                    return of(error);
                }),
            );
    }

    checkUniqueRegistrationEmail(codFiscalPiva: string): Observable<string> {
        return this.api
            .postAsync<BaseApiResponse<UniqueRegistrationEmailResponse>>(
                this.getApiMngApiUrl(ApiMngApi.CheckUniqueRegistrationEmail),
                {
                    codFiscalPiva: codFiscalPiva,
                },
            )
            .pipe(
                map((res) => {
                    if (!res?.response?.isCE) {
                        return '';
                    }

                    return res?.response?.email || '';
                }),
                catchError(() => of('')),
            );
    }

    checkQuoteCorrelation(request: any): Observable<any> {
        return this.api.postAsync<BaseApiResponse<any>>(this.getApiMngApiUrl(ApiMngApi.CheckQuoteCorrelation), request);
    }

    /**
     * @description: Ottiene l'ID della transazione aziendale.
     * Questo ID viene generato in base al tipo di flusso corrente e ai prodotti visibili selezionati.
     * Utilizza le informazioni sul tipo di flusso e sui prodotti per costruire un'identificazione unica della transazione.
     * @returns Un Observable che emette l'ID della transazione aziendale.
     */
    getBusinessTransactionId(): Observable<string> {
        return combineLatest([this.store.select(selectFlowType), this.store.select(v2SelectVisibleProducts())]).pipe(
            take(1),
            map(([flowType, products]) => {
                const salesProcess = flowTypeToAptSalesProcess(flowType);
                const billingPreferenceCode = products[0]?.paymentInfo?.paymentTool?.billingPreferenceCode; //CODICE_CONTO_CLIENTE
                const cartId = CartService.getCurrentCartId(); //CODICE_CARRELLO
                const TRANSACTION_ID_MAP: {
                    [key in AptSalesProcess | 'DEFAULT']?: string;
                } = {
                    [AptSalesProcess.ModificaDomiciliazione]: `SUP|${AptSalesProcess.ModificaDomiciliazione}|//${billingPreferenceCode}`,
                    [AptSalesProcess.AttivazioneDomiciliazione]: `SUP|${AptSalesProcess.AttivazioneDomiciliazione}|//${billingPreferenceCode}`,
                    ['DEFAULT']: `SUP|${salesProcess}|${cartId}//`,
                };
                return TRANSACTION_ID_MAP[salesProcess] || TRANSACTION_ID_MAP['DEFAULT'];
            }),
        );
    }
    checkAndUpdatePricingListOnCart(cartItems: CartItem[], prodToAdd: EglProductExtended): Observable<void> {

        return combineLatest([
            of(prodToAdd),
            of(cartItems),
            this.store.select(v2SelectCurrentProduct()),
            this.store.select(selectFlowType),
        ]).pipe(
            take(1),
            filter(([prodToAdd, cartItems, product, flowType]) => {
                const family = (prodToAdd?.Family as AptProductFamily) || product?.family;
                const isCommodityLegacy = [
                    AptProductFamily.CommodityLegacy,
                    AptProductFamily.CommodityLegacyFull,
                ].includes(family);
                const priceList = this.getPriceList(cartItems) || product?.configurations?.priceList;
                const isFlowTypeAdmin = isFlowTypeAdminForPriceList(flowType);
                this.loggerService.info(
                    'family: ',
                    family,
                    'priceList: ',
                    priceList,
                    'isFlowTypeAdmin: ',
                    isFlowTypeAdmin,
                );
                return !!(isCommodityLegacy && priceList && isFlowTypeAdmin);
            }),
            map(([prodToAdd, cartItems, product]) => {
                return {
                    commodityPriceList: this.getPriceList(cartItems) || product?.configurations?.priceList,
                    productId: prodToAdd?.Id || product?.productId,
                };
            }),
            mergeMap(({ commodityPriceList, productId }) =>
                this.api
                    .postAsync<BaseApiResponse<CheckPriceListAmm>>(this.getApiMngApiUrl(ApiMngApi.CheckPriceListAmm), {
                        productId,
                        commodityPriceList,
                    })
                    .pipe(
                        tap((response) => {
                            if (response?.status !== StatusResponse.Success) {
                                throw new ServiceError(
                                    'TOAST_MSG',
                                    this.translateSrv.instant('ERROR.CONFIGURATION.PRICELIST_ERROR'),
                                    'LOW',
                                );
                            }
                            if (response?.status === StatusResponse.Success && !response?.response?.isCompatible) {
                                throw new ServiceError(
                                    'TOAST_MSG',
                                    this.translateSrv.instant('ERROR.CONFIGURATION.PRICELIST_NOT_COMPATIBLE'),
                                    'LOW',
                                );
                            }
                        }),
                        mergeMap(() => this.eglCartLightSrv.updateCartPriceList(commodityPriceList)),
                    ),
            ),
            tap((res) => {
                if (!res) {
                    throw new ServiceError(
                        'TOAST_MSG',
                        this.translateSrv.instant('ERROR.CONFIGURATION.PRICELIST_NOT_UPDATED'),
                        'LOW',
                    );
                }
                return of(null);
            }),
            defaultIfEmpty(null),
            LoadingService.loaderOperator(),
        );
    }

    getPriceList(cartItems: CartItem[]): string {
        const priceLists = cartItems.map((li) => li?.AttributeValue?.['egl_pricelist__c']).filter(Boolean);
        return priceLists.length ? priceLists[0] : '';
    }

    getDiscountDetails(productId: string): Observable<DiscountDetails> {
        return this.api
            .getAsync<
                BaseApiResponse<DiscountDetails>
            >(this.getApiMngApiUrl(ApiMngApi.DiscountDetails).replace('{productId}', productId))
            .pipe(
                tap((response) => {
                    if (response?.status !== StatusResponse.Success) {
                        throw new ServiceError(
                            'TOAST_MSG',
                            this.translateSrv.instant('ORDER_ENTRY.MULTIPRODUCT.CONFIG_PDF.SCONTO.ERROR'),
                            'LOW',
                        );
                    }
                }),
                map((response) => response.response),
                catchError((err) => {
                    throw new ServiceError(
                        'TOAST_MSG',
                        this.translateSrv.instant('ORDER_ENTRY.MULTIPRODUCT.CONFIG_PDF.SCONTO.ERROR'),
                        'LOW',
                    );
                }),
            );
    }
}
