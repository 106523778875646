import { ATable, AObject, AField } from '@congacommerce/core';
import { AptPaymentInstrument } from '../../../../enums/apttus/apt-payment-instrument';
import { AptPaymentType } from '../../../../enums/apttus/apt-payment-type';
import { NetaProductClass } from '../../../../enums/apttus/neta-product-class';
import { AptBillType } from '../../../../enums/apttus/apt-bill-type';

@ATable({
    sobjectName: 'egl_payment_type__mdt',
    aqlName: null,
})
export class EglSalesEligibility extends AObject {
    /**
     *  egl_addebitoinbolletta__c
     */
    @AField({ soql: 'egl_BillBilling__c' })
    egl_BillBilling: 'SI' | 'NO';

    @AField({ soql: 'egl_termsOfPayment__c' })
    egl_termsOfPayment: AptPaymentInstrument;

    @AField({ soql: 'egl_payType__c' })
    egl_payType: AptPaymentType;

    @AField({ soql: 'egl_PClass__c' })
    egl_PClass: NetaProductClass;

    @AField({ soql: 'egl_WithholdingTax__c' })
    egl_WithholdingTax: boolean;

    @AField({ soql: 'egl_CreditAssignment__c' })
    egl_CreditAssignment: boolean;

    @AField({ soql: 'egl_InstallmentFlag__c' })
    egl_InstallmentFlag: boolean;
}

export interface SalesEligibilityData {
    productClass: NetaProductClass;
    creditAssignment: boolean;
    withHoldingTax: boolean;
    installmentFlag: boolean;
    productData: {
        idx: number;
        visibleIdx: number;
        name: string;
        billCharge: 'SI' | 'NO';
        paymentInstrument: AptPaymentInstrument;
        paymentType: AptPaymentType;
        shippingMethod: AptBillType;
        lineItemId: string;
    };
}
