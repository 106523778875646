import { Injectable, Injector } from '@angular/core';
import {
    AObjectService,
    CacheService,
    ConfigurationService,
    MetadataService,
    PlatformService,
} from '@congacommerce/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { setLead } from '../../../../../../app/store/actions/user.actions';
import { D365CustomerSegment } from '../../../../../../app/common/enums/d365/d365-customer-segment';
import { convertSegmentD365toApt } from '../../../../../../app/common/functions/remap.functions';
import { EglCartExtended } from '../../../../../../app/common/models/apttus/tables/cart/egl-cart-extended';
import { Lead } from '../../../../../../app/common/models/user/lead';
import { EglState } from '../../../../../../app/store/reducers';
import { selectLead } from '../../../../../../app/store/selectors/user.selectors';
import { LoadingService } from '../../../shared/loading.service';
import { ApttusService } from '../../apttus.service';

@Injectable({
    providedIn: 'root',
})
export class EglCartExtendedService extends AObjectService {
    constructor(
        cacheSrv: CacheService,
        plSrv: PlatformService,
        mtSrv: MetadataService,
        confSrv: ConfigurationService,
        inj: Injector,
        private store: Store<EglState>,
        private apttusSrv: ApttusService
    ) {
        super(cacheSrv, plSrv, mtSrv, confSrv, inj);
        this.setType(EglCartExtended);
    }

    /**
     * @description Verifica se esiste un lead in corso di offertazione, in caso positivo creo il carrello associato a leadid e con il tag per la scontistica
     * @returns void
     */
    async checkAndCreateCart(newSegment: D365CustomerSegment): Promise<void> {
        //Fix per bug #131630, in caso di cambio di settore con lead in offertazione vengono persi i riferimenti del lead
        const customerType = convertSegmentD365toApt(newSegment);
        const lead: Lead = await this.store.select(selectLead).pipe(take(1)).toPromise();

        if (!lead) {
            this.apttusSrv.clearAndCreateNewCart(null, convertSegmentD365toApt(newSegment));
            return;
        }

        //in caso di cambio effettivo con lead è necessario cambiare il customer segment code nello state per consentire un filtraggio coerente quando viene selezionata la categoria d'interesse
        lead.egl_customersegmentcode = newSegment;
        this.store.dispatch(setLead({ l: lead }));

        const eglCartExtended = new EglCartExtended();
        eglCartExtended.egl_leadid = lead.egl_code;
        eglCartExtended.egl_tag = lead.egl_tag;

        this.apttusSrv.createNewCart(eglCartExtended, customerType).subscribe((cart: EglCartExtended) => {
            LoadingService.hide();
        });
    }
}
