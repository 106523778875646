export enum D365DataOwnershipChangeStatuscodes {
    Draft = 1, //bozza
    DoiSigned = 2, // confermato
    DoiCanceled = 100000001, //Annullato
    DoiExpired = 100000002, //SCADUTO
    DoiSent = 100000003, //NOT PROCESSED
    DoiCanceledFromBo = 100000004, //ANNULLATO DA BO
    DoiExpiredFromApim = 100000005, //annullato da apim
    DoiPendingSend = 100000006, //in attesa invio
    DoiSendKo = 100000007, //Invio KO
}
