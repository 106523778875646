import { EgonRequest } from '../../modules/switch-in/order-entry/models/egon-request';
import { Indirizzo } from '../../modules/switch-in/order-entry/models/indirizzi';
import { Address, Product } from '../../store/models/order-entry-state_v2';
import { AptAddressType } from '../enums/apttus/apt-address-type';
import { FornituraEsistente } from '../../store/models/order-entry-state';
import { AutoCompleteItem } from '../components/egon-autocomplete/egon-autocomplete.component';
import { Regex } from '../config/regex';
import { EglAddress } from '../models/apttus/tables/address/egl-address';
import { EgonNormalizedResponse } from '../models/egon/egon-normalization-response';
import { cleanObj } from './misc.functions';
import { getCivicAndSuffix, getCountryIso2166Alpha3, getNumberOrNull } from './remap.functions';
import { RawCombinedSaleProduct } from '../../modules/common/order-entry/models/check-commodity-response';
import { istatCodeNormalizer, capNormalizer, getCompleteCivic, getFullAddress } from './string-format.functions';
import { isAddress, isEgonRequest, isIndirizzo, isValidIndirizzo } from './verifications.functions';
import { EgonCivicAutocomplete } from '../providers/egon-provider';

// Bug 222964 - Necessario avere "ITALIA" come default altrimenti si rompono i sistemi a valle
const DEFAULT_COUNTRY = 'ITALIA';

export function eglAddress2Address(eglAddress: EglAddress): Address {
    const {
        egl_city,
        egl_postalcode,
        egl_province,
        egl_region,
        egl_address_prefix,
        egl_address_line1,
        egl_address_line2,
        egl_country,
        egl_istat,
        egl_streetmap_code,
        egl_address_certified,
    }: EglAddress = eglAddress || ({} as EglAddress);
    const address = {
        municipality: egl_city,
        cap: egl_postalcode,
        province: egl_province,
        region: egl_region,
        toponym: egl_address_prefix,
        street: egl_address_line1,
        ...getCivicAndSuffix(egl_address_line2),
        shortProvince: egl_province,
        country: egl_country,
        iso3166Alpha3: getCountryIso2166Alpha3(egl_country),
        istatCodeProv: (istatCodeNormalizer(egl_istat, 6) || '')?.substring(0, 3),
        istatCodeMunicipality: (istatCodeNormalizer(egl_istat, 6) || '')?.substring(3),
        streetEgonCode: Regex.STREET_EGON_CODE.test(egl_streetmap_code) ? getNumberOrNull(egl_streetmap_code) : null,
        certified: egl_address_certified,
    };

    return reviewAddress(address);
}
export interface IEgonData {
    CDXISO: string;
    DSXDPT: string;
    DSXCNL: string;
    DSXVIA: string;
    CDPOBJ: number;
    DSXDUG: string;
    NRPNUMCIV: number;
    DSXESP: string;
    DSXREG: string;
    WPXISO: string;
    DSXDS1: string;
}

export function address2Egon(address: Partial<Address>): Partial<IEgonData> {
    return {
        WPXISO: address?.iso3166Alpha3 || getCountryIso2166Alpha3(address?.country) || 'ITA',
        CDXISO: address?.iso3166Alpha3 || getCountryIso2166Alpha3(address?.country),
        DSXDPT: address?.province || address?.shortProvince,
        DSXCNL: address?.municipality,
        DSXVIA: address?.street,
        CDPOBJ: address?.streetEgonCode,
        DSXDUG: address?.toponym,
        NRPNUMCIV: getNumberOrNull(address?.civic),
        DSXESP: address?.civicSuffix,
        DSXREG: address?.region,
        DSXDS1: address?.district,
    };
}

export function address2EgonRequest(address: Address): EgonRequest {
    if (
        !address ||
        !Object.entries(address || {}).filter(([key, value]) => !['iso3166Alpha3', 'country'].includes(key) && !!value)
            ?.length
    ) {
        return null;
    }
    return {
        iso3: address?.iso3166Alpha3 || getCountryIso2166Alpha3(address?.country),
        level: null,
        id: address?.civicEgonCode || address?.streetEgonCode,
        country: address?.country,
        region: address?.region,
        province: address?.province,
        province_code: address?.shortProvince,
        city: address?.municipality,
        zipcode: address?.cap,
        street: address?.toponym ? `${address?.toponym} ${address?.street}` : address?.street,
        number: getNumberOrNull(address?.civic),
        exponent: address?.civicSuffix,
        fullAddress: address?.fullAddress,
        district1: address?.district,
    };
}

export function address2AutoCompleteItem(address: Address): AutoCompleteItem {
    return {
        egonId: address?.streetEgonCode,
        fullName: getFullAddress(address),
        egonItem: address2EgonRequest(address),
        civic: getCompleteCivic(address),
        egonCivicoId: address?.civicEgonCode,
        egonMcz1Istcom: address?.istatCodeMunicipality,
        egonMcz1Istpro: address?.istatCodeProv,
        zip: address?.cap,
    };
}

function egonRequest2RawAddress(data: EgonRequest): RawAddress {
    return {
        civicEgonCode: null,
        streetEgonCode: data?.id,
        toponym: null,
        country: data?.country,
        region: data?.region,
        province: data?.province,
        shortProvince: data?.province_code,
        municipality: data?.city,
        district: data?.district1,
        cap: data?.zipcode,
        street: data?.street,
        civic: getNumberOrNull(data?.number),
        civicSuffix: data?.exponent || (!getNumberOrNull(data?.number) && data?.number ? data?.number + '' : null),
        istatCodeMunicipality: null,
        istatCodeProv: null,
        iso3166Alpha3: data?.iso3 || getCountryIso2166Alpha3(data?.country),
        certified: false,
    };
}

export function egonRequest2Address(egonRequest: EgonRequest): Address {
    return reviewAddress(egonRequest2RawAddress(egonRequest));
}

export function egonNormalizedResponse2Address(normResp: EgonNormalizedResponse): Address {
    const sog = normResp?.SOG?.[0];
    const toponym = sog?.INR_620?.DSXSTRUFF?.DSXST1?.DSXDUG || null;
    const street = sog?.INR_620?.DSXSTRUFF?.DSXST1?.DSXVIA || sog?.INR_020?.DSXST1?.DSXVIA;
    const civic = sog?.INR_620?.CIVICOST1?.NRPNUMCIV?.lValue || sog?.INR_020?.DSXST1?.NRPNUMCIV?.lValue || null;
    const civicSuffix = sog?.INR_620?.CIVICOST1?.DSXESP || sog?.INR_020?.DSXST1?.DSXESP || '';
    const district = sog?.INR_620?.DSXPLCUFF?.DSXDS1 || sog?.INR_020?.DSXPLC?.DSXDS1 || null;
    const municipality = sog?.INR_620?.DSXPLCUFF?.DSXCNL || sog?.INR_020?.DSXPLC?.DSXCNL;
    const province = sog?.INR_620?.DSXPLCUFF?.DSXDPT || sog?.INR_020?.DSXPLC?.DSXDPT;
    const shortProvince = sog?.INR_620?.DSXPLCSIG?.DSXDPT;
    const cap = capNormalizer(sog?.INR_620?.CDXZIP);
    const region = sog?.INR_620?.DSXPLCUFF?.DSXREG || sog?.INR_620?.DSXPLCABB?.DSXREG;
    const country = sog?.INR_620?.DSXPLCUFF?.DSXCNY || sog?.INR_620?.CDXISO || sog?.RGS_010?.CDXISO || sog?.PAR?.CDXISO;
    const mcz627 = (normResp?.SOG || []).find((sog) => sog?.MCZ_627)?.MCZ_627;
    const istatCode = istatCodeNormalizer(sog?.INR_620?.CDXSTAPLCOBJ?.CDXCNL, 6) || '';
    const iso3166Alpha3 =
        sog?.INR_620?.CDXISO ||
        sog?.RGS_010?.CDXISO ||
        sog?.PAR?.CDXISO ||
        getCountryIso2166Alpha3(sog?.INR_620?.DSXPLCUFF?.DSXCNY);
    const civicEgonCode = civic || civicSuffix ? sog?.INR_620?.CIVICOST1?.CDPOBJCIV?.lValue : null;
    const streetEgonCode = sog?.INR_620?.CDPSTRST1?.CDPSTR?.lValue;
    const address = {
        toponym,
        street,
        civic,
        civicSuffix,
        district,
        municipality,
        region,
        shortProvince,
        province,
        cap,
        country,
        istatCodeProv:
            (istatCode && istatCode.padStart(6, '0')?.substring(0, 3)) || istatCodeNormalizer(mcz627?.MCZ_1_ISTPRO),
        istatCodeMunicipality:
            (istatCode && istatCode.padStart(6, '0')?.substring(3)) || istatCodeNormalizer(mcz627?.MCZ_1_ISTCOM),
        iso3166Alpha3,
        civicEgonCode,
        streetEgonCode,
    };
    return reviewAddress(address);
}

function indirizzo2RawAddress(src: Indirizzo): RawAddress {
    const istatCode = istatCodeNormalizer(src?.codiceIstat || '', 6) || '';
    return cleanObj({
        toponym: src?.toponomastica,
        street: src?.via,
        ...getCivicAndSuffix(src?.civico),
        district: src?.district,
        municipality: src?.comune,
        region: src?.region,
        shortProvince: src?.prov,
        province: src?.province,
        cap: src?.cap,
        country: src?.country,
        iso3166Alpha3: src?.iso3 || getCountryIso2166Alpha3(src?.country),
        istatCodeProv: src?.codiceIstatProvincia || istatCode.substring(0, 3) || null,
        istatCodeMunicipality: src?.codiceIstatComune || istatCode.substring(3) || null,

        civicEgonCode: src?.codiceEgon,
        streetEgonCode: src?.codiceEgonStreet,
        certified: src?.certificato,
    }) as RawAddress;
}

export function indirizzo2Address(indirizzo: Indirizzo): Address {
    return reviewAddress(indirizzo2RawAddress(indirizzo));
}

export function productsToAddresses(products: Product[]): {
    supplyAddress: Address;
    shippingAddress: Address;
    communicationAddress: Address;
} {
    return {
        supplyAddress: (products || []).find(
            (product) => product?.addressType === AptAddressType.Fornitura && product?.deliveryAddress?.street
        )?.deliveryAddress,
        shippingAddress: (products || []).find(
            (product) => product?.addressType === AptAddressType.Spedizione && product?.deliveryAddress?.street
        )?.deliveryAddress,
        communicationAddress: (products || []).find((product) => product?.communicationAddress?.street)
            ?.communicationAddress,
    };
}

export function address2Indirizzo(address: Address | false, validOrNull = false): Indirizzo {
    if (!isAddress(address)) {
        return null;
    }
    const country = address?.country || address?.street ? 'Italia' : null;
    const indirizzo = {
        toponomastica: address?.toponym,
        codiceEgon: address?.civicEgonCode, // codice Egon civico
        codiceEgonStreet: address?.streetEgonCode, // codice Egon della strada
        via: address?.street,
        civico: getCompleteCivic(address),
        district: address?.district,
        comune: address?.municipality,
        region: address?.region,
        prov: address?.shortProvince,
        cap: address?.cap,
        certificato: !!address?.civicEgonCode || !!address?.streetEgonCode,
        country,
        iso3: address?.iso3166Alpha3 || (country && getCountryIso2166Alpha3(country)),
        province: address?.province,
        city: address?.municipality,
        codiceIstat:
            address?.istatCodeProv && address?.istatCodeMunicipality
                ? `${address?.istatCodeProv}${address?.istatCodeMunicipality}`
                : null,
        codiceIstatProvincia: address?.istatCodeProv,
        codiceIstatComune: address?.istatCodeMunicipality,
        nomeCompleto: getFullAddress(address),
    };
    return validOrNull && !isValidIndirizzo(indirizzo) ? null : indirizzo;
}

export function fornituraEsistente2Address(fornituraEsistente: FornituraEsistente): Address {
    return fornituraEsistente?.via
        ? reviewAddress({
              toponym: fornituraEsistente?.toponimo,
              street: fornituraEsistente?.via,
              ...getCivicAndSuffix(fornituraEsistente?.civico),
              municipality: fornituraEsistente?.citta,
              province: fornituraEsistente?.provincia,
              shortProvince: fornituraEsistente?.provincia,
              region: fornituraEsistente?.region,
              cap: capNormalizer(fornituraEsistente?.cap),
              istatCodeProv: (istatCodeNormalizer(fornituraEsistente?.codiceIstat, 6) || '')?.substring(0, 3),
              istatCodeMunicipality: (istatCodeNormalizer(fornituraEsistente?.codiceIstat, 6) || '')?.substring(3),
          })
        : null;
}

export function eglAddress2Indirizzo(eglAddress: EglAddress): Indirizzo {
    return address2Indirizzo(eglAddress2Address(eglAddress));
}
export function indirizzo2EgonResponse(address: Indirizzo): EgonRequest {
    return address2EgonRequest(indirizzo2Address(address));
}
export function egonNormalizedResponse2Indirizzo(normResp: EgonNormalizedResponse): Indirizzo {
    return address2Indirizzo(egonNormalizedResponse2Address(normResp));
}
export function egonNormalizedResponse2AutoCompleteItem(egonResponse: EgonNormalizedResponse): AutoCompleteItem {
    return address2AutoCompleteItem(egonNormalizedResponse2Address(egonResponse));
}
export function fornituraEsistente2Indirizzo(fornituraEsistente: FornituraEsistente): Indirizzo {
    return address2Indirizzo(fornituraEsistente2Address(fornituraEsistente));
}

export function getCivicSuffixOrNull(value: string | null | undefined): string | null {
    if (value === null || typeof value === 'undefined' || value === 'null') {
        return null;
    }
    return value;
}

export const combinedSaleResponse2Address = (combinedSale: RawCombinedSaleProduct): Partial<Address> =>
    cleanObj({
        toponym: combinedSale?.toponym,
        street: combinedSale?.addressLine1,
        municipality: combinedSale?.city,
        region: combinedSale?.county,
        shortProvince: combinedSale?.province,
        cap: combinedSale?.postalCode,
        fullAddress: combinedSale?.fullAddress,
        ...getCivicAndSuffix(combinedSale?.addressLine2),
        country: combinedSale?.state,
    });

export function reviewAddress(
    address: Omit<Address, 'fullAddress' | 'certified' | 'country' | 'iso3166Alpha3'> &
        Partial<Pick<Address, 'country' | 'iso3166Alpha3'>>
): Address {
    return (
        address && {
            ...address,
            country: address.country || DEFAULT_COUNTRY,
            iso3166Alpha3: address.iso3166Alpha3 || getCountryIso2166Alpha3(address.country || DEFAULT_COUNTRY),
            certified:
                !!address?.streetEgonCode &&
                address?.street &&
                address?.municipality &&
                address?.istatCodeProv &&
                address?.istatCodeMunicipality &&
                address?.shortProvince &&
                (address?.civic || address?.civicSuffix) &&
                !Regex.EXPIRED_CAP.test(address?.cap),
            fullAddress: getFullAddress(address),
        }
    );
}

export function getFullAddressString(egonResponse: EgonRequest): string;
export function getFullAddressString(indirizzo: Indirizzo): string;
export function getFullAddressString(address: Address): string;
export function getFullAddressString(
    prefix: string,
    street: string,
    number: string,
    city: string,
    provinceCode: string,
    cap: string
): string;
export function getFullAddressString(
    prefixOrResponse: string | EgonRequest | Indirizzo | Address,
    ...args: string[]
): string {
    let address: Partial<Address>;
    if ((['undefined', 'string'].includes(typeof prefixOrResponse) || prefixOrResponse === null) && args.length === 5) {
        address = {
            toponym: prefixOrResponse as string,
            street: args[0],
            ...getCivicAndSuffix(args[1] as string),
            municipality: args[2] as string,
            shortProvince: args[3] as string,
            cap: args[4] as string,
        }; //[prefixOrResponse as string, ...args];
    } else if (isIndirizzo(prefixOrResponse)) {
        address = indirizzo2RawAddress(prefixOrResponse);
    } else if (isEgonRequest(prefixOrResponse)) {
        address = egonRequest2RawAddress(prefixOrResponse);
    } else if (isAddress(prefixOrResponse)) {
        address = prefixOrResponse;
    } else {
        return '';
    }
    return getFullAddress(address);
}

export function validateCivicInfo(address: Address, civicSuggest: EgonCivicAutocomplete[]): Address {
    const isCivicOnly = address?.civic && !address?.civicSuffix;
    const isSuffixOnly = !address?.civic && !!address?.civicSuffix;
    const isCivicAndSuffix = address?.civicSuffix && !!address?.civic;
    const correctCivic =
        civicSuggest.find((civicData) =>
            isCivicOnly
                ? civicData?.civic == address?.civic?.toString()
                : isSuffixOnly
                ? civicData?.subCivic?.toUpperCase() == address.civicSuffix?.toUpperCase()
                : isCivicAndSuffix
                ? civicData?.civic == address?.civic?.toString() &&
                  civicData?.subCivic?.toUpperCase() == address?.civicSuffix?.toUpperCase()
                : null
        ) || null;
    address.civic = correctCivic?.civic ? Number(correctCivic.civic) || null : null;
    address.civicSuffix = correctCivic?.subCivic ? correctCivic.subCivic?.toUpperCase() || null : null;
    address.civicEgonCode = correctCivic?.id || null;
    return address;
}

type RawAddress = Omit<Address, 'fullAddress' | 'cerified'>;
