import { CurrencyPipe, DatePipe, DecimalPipe, JsonPipe, PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Regex } from '../config/regex';
import { getNumberOrNull } from '../functions/remap.functions';
import { FlowTranslatePipe } from './flow-translate.pipe';

type AutoFormatHandledType =
    | 'string'
    | 'number'
    | 'decimalNumber'
    | 'bigint'
    | 'currency'
    | 'boolean'
    | 'undefined'
    | 'Date'
    | 'Moment'
    | 'object'
    | 'i18n'
    | 'percent';

type CustomAutoFormatHandledType = 'Date' | 'Moment' | 'undefined' | 'i18n';

@Pipe({ name: 'autoFormat' })
export class AutoFormatPipe implements PipeTransform {
    private readonly DATA_TYPE_MAP: Record<AutoFormatHandledType, (value: unknown) => string> = {
        string: (value: string) => value,
        number: (value: number | string) => value.toString(),
        decimalNumber: (value: number | string) => this.decimalPipe.transform(value),
        bigint: (value: number) => this.decimalPipe.transform(value),
        currency: (value: number | string) => this.formatCurrency(value),
        boolean: (value: boolean) => this.translateService.instant(`COMMON.${value ? 'YES' : 'NO'}`),
        undefined: () => this.translateService.instant('COMMON.EMPTY'),
        Date: (value: Date) => this.datePipe.transform(moment(value).toDate(), 'shortDate'),
        Moment: (value: moment.Moment) => this.formatMoment(value),
        object: (value: Record<string, unknown>) => this.jsonPipe.transform(value),
        i18n: (value: string) => this.formatI18n(value),
        percent: (value: number | string) => this.percentPipe.transform(value, '1.0-2'),
    };

    private readonly CUSTOM_TYPE_MAP: Record<CustomAutoFormatHandledType, (value: unknown) => boolean> = {
        Date: (value: Date | string) => value instanceof Date || Regex.ISO_8601.test(value),
        Moment: (value) => moment.isMoment(value),
        undefined: (value) => value === null || (typeof value === 'string' && !value.trim()),
        i18n: (value: string) => Regex.L10N_TOKEN_MATCHER.test(value),
    };

    constructor(
        private decimalPipe: DecimalPipe,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private translateService: TranslateService,
        private jsonPipe: JsonPipe,
        private percentPipe: PercentPipe,
        private flowTranslatePipe: FlowTranslatePipe,
    ) {}

    transform(value: unknown, customType?: 'currency' | 'decimalNumber' | 'percent' | 'string'): string {
        const type = customType || this.getValueType(value);
        return this.DATA_TYPE_MAP[type](value);
    }

    private getAutoFormatHandledType(value: unknown): AutoFormatHandledType {
        return !['function', 'symbol'].includes(typeof value) ? (typeof value as AutoFormatHandledType) : 'undefined';
    }

    private getValueType(value: unknown): AutoFormatHandledType {
        const handledFormatType: AutoFormatHandledType = (Object.entries(this.CUSTOM_TYPE_MAP).find(([, verificator]) =>
            verificator(value),
        ) || [])[0] as AutoFormatHandledType;
        return handledFormatType || this.getAutoFormatHandledType(value);
    }

    private formatCurrency(value: number | string): string {
        const numValue = getNumberOrNull(value);
        const ROOT_L10N_KEY = 'ORDER_ENTRY.COSTS.TABLE_FIELDS';
        return typeof numValue === 'number'
            ? this.currencyPipe.transform(numValue)
            : this.flowTranslatePipe.transform(ROOT_L10N_KEY, 'NOT_AVAILABLE');
    }

    private formatMoment(value: moment.Moment): string {
        return value.isValid()
            ? this.datePipe.transform(value.toDate(), 'shortDate')
            : this.DATA_TYPE_MAP.undefined(value);
    }

    private formatI18n(value: string): string {
        const i18n = this.flowTranslatePipe.transform(value);
        // Se traduzione è uguale alla chiave restituisco ''
        // Se la translate trova qualcosa, restituisco la traduzione
        return i18n === value && /[^.]+\.[^.]+/.test(value) ? '' : typeof i18n === 'string' || i18n ? i18n : value;
    }
}
