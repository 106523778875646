import { AptAddressType } from '../../enums/apttus/apt-address-type';
import { AptProductType } from '../../enums/apttus/apt-product-type';
import { D365AccountMigrated } from '../../enums/d365/d365-account-migrated';
import { D365CustomerSegment } from '../../enums/d365/d365-customer-segment';

export interface D365Asset {
    egl_asset: {
        egl_assetid: string;
        egl_cpqid: string;
        egl_podpdr: string;
        egl_pdf?: string;
        egl_productname: string;
        egl_productcode: string;
        egl_category: AptProductType;
        egl_allocatedpower: string;
        egl_availablepower: string;
        egl_pdr_serialnumber: string;
    };
    egl_billingpreference: {
        egl_code: string;
    };
    egl_address: {
        egl_code: string;
        egl_addresstype: AptAddressType;
        egl_city: string;
        egl_istatcode: string;
        egl_country: string;
        egl_province: string;
        egl_street: string;
        egl_streetnumber: string;
        egl_zipcode: string;
    };
    account: {
        egl_customercode: string;
    };
    egl_toponym: {
        egl_name: string;
    };
}

export interface D365AccountContactAddressRaw {
    accountid: string;
    /**
     * @description AccountNumber
     */
    egl_customercode: string;
    egl_lastname: string;
    egl_firstname: string;
    /**
     * @description Ragione Sociale
     */
    name: string;
    /**
     * @description Codice Fiscale
     */
    egl_taxcode: string;
    /**
     * @description Partita IVA
     */
    egl_vatcode: string;
    egl_privacy1: boolean | string;
    egl_privacy2: boolean | string;
    egl_privacy3: boolean | string;
    // egl_winbackproducttype: string; @deprecated
    // egl_winback: boolean | string; @deprecated
    egl_preferredchannel: string;
    egl_customeraccountcode: string;
    numberofemployees: string;
    revenue: string;
    preferredcontactmethodcode: number;

    telephone1Prefix: {
        egl_callingcode: string;
    };
    telephone1: string;

    telephone2Prefix: {
        egl_callingcode: string;
    };
    telephone2: string;

    telephone3Prefix: {
        egl_callingcode: string;
    };
    telephone3: string;
    emailaddress1: string;
    address: {
        egl_code: string;
        egl_city: string;
        egl_istatcode: string;
        egl_country: string;
        egl_province: string;
        egl_street: string;
        egl_streetnumber: string;
        egl_zipcode: string;
        toponym: {
            egl_name: string;
        };
    };
    addressTypeRole: {
        egl_name: D365AddressRoleType;
    };
    egl_migration_mastercode: D365AccountMigrated;
    egl_customersegmentcode: D365CustomerSegment;
    contact: {
        egl_taxcode: string;
        lastname: string;
        firstname: string;
        egl_migration_mastercode: D365AccountMigrated;
    };
    contactRelationship: {
        egl_contactid: string;
    };
    contactRole: {
        egl_name: D365ContactType;
    };

    legalForm?: { egl_code: string };
    egl_portalregistration: boolean;
}

export interface D365Account
    extends Omit<
        D365AccountContactAddressRaw,
        | 'contact'
        | 'contactRelationship'
        | 'contactRole'
        | 'address'
        | 'addressTypeRole'
        | 'telephone1Prefix'
        | 'telephone2Prefix'
        | 'telephone3Prefix'
        | 'egl_privacy1'
        | 'egl_privacy2'
        | 'egl_privacy3'
        | 'egl_winback'
        | 'egl_vulnerabilitytype_disabled'
        | 'egl_vulnerabilitytype_over75'
        | 'egl_vulnerabilitytype_saemapre'
        | 'egl_vulnerabilitytype_socialbonus'
    > {
    egl_privacy1: boolean;
    egl_privacy2: boolean;
    egl_privacy3: boolean;

    telephone1Prefix: D365AccountContactAddressRaw['telephone1Prefix']['egl_callingcode'];
    telephone2Prefix: D365AccountContactAddressRaw['telephone2Prefix']['egl_callingcode'];
    telephone3Prefix: D365AccountContactAddressRaw['telephone3Prefix']['egl_callingcode'];
    egl_vulnerabilitytype_disabled?: boolean | string;
    egl_vulnerabilitytype_over75?: boolean | string;
    ageRange?: number;
    egl_vulnerabilitytype_saemapre?: boolean | string;
    egl_vulnerabilitytype_socialbonus?: boolean | string;
    addresses: {
        [addressTypeRole in D365AccountContactAddressRaw['addressTypeRole']['egl_name']]?: Omit<
            D365AccountContactAddressRaw['address'],
            'toponym'
        > & {
            toponym: D365AccountContactAddressRaw['address']['toponym']['egl_name'];
        };
    };
    contacts: {
        [contactRole in D365ContactType]?: D365AccountContactAddressRaw['contact'] &
            D365AccountContactAddressRaw['contactRelationship'];
    };
}

export enum D365AddressRoleType {
    Comunicazione = 'COMUNICAZIONE',
    Domicilio = 'DOMICILIO',
    Residenza = 'RESIDENZA',
    SedeLegale = 'SEDE LEGALE',
    Spedizione = 'SPEDIZIONE',
}

export enum D365ContactType {
    AltroNonParente = 'Altro Non Parente',
    AltroParente = 'Altro Parente',
    Cliente = 'Cliente',
    Coniuge = 'Coniuge',
    ConsigliereDiCondominio = 'Consigliere di condominio',
    DestinatarioAggiuntivo = 'Destinatario aggiuntivo',
    FigliaFiglio = 'Figlia/Figlio',
    Genitore = 'Genitore',
    LegaleRappresentante = 'Legale Rappresentante',
    Referente = 'Referente',
    Tutore = 'Tutore',
}
