import { AttributeValues } from './base-attribute-values';

export class PannelloVirtualeAttributeValues extends AttributeValues {
    attributesMapping(): void {
        super.attributesMapping();

        this.attr['egl_POD__c'] = this.currentProduct?.podPdr;
        this.attr['egl_addebitoinbolletta__c'] = 'SI';
        this.attr['egl_Invoice_Shipping_Method__c'] = this.currentProduct?.shippingMethod;
    }
}
