import { AgentInfo } from '../../common/models/user/agent';
import { Lead } from '../../common/models/user/lead';
import { Contact } from '../../common/models/user/contact';
import { CustomerInfo } from '../../common/models/user/customer';
import { D365CustomerSegment } from '../../common/enums/d365/d365-customer-segment';
import { DataOwnershipChangeRequest } from '../../common/models/d365/data-ownership-change.request';

export class UserState {
    contact: Contact;
    lead: Lead;
    fromCrm: boolean;
    agentInfo: AgentInfo;
    customerInfo: CustomerInfo;
    cartSegment: D365CustomerSegment;
    customerMastership: MastershipType;
    dataOwnershipChange: DataOwnershipChange;
}

export enum MastershipType {
    Siebel = 'SIEBEL',
    SiebelBloccato = 'SIEBEL BLOCCATO',
    D365 = 'D365',
    New = 'NEW',
}

export type ExistingMastershipType = Exclude<MastershipType, MastershipType.New>;

export class DataOwnershipChange {
    currentState: string;
    data: DataOwnershipChangeData = new DataOwnershipChangeData();
}

export class DataOwnershipChangeData extends DataOwnershipChangeRequest {
    DataOwnershipChangeId: string;
    code: string;
    expirationDate?: string;
    status: number;
    taxCode: string;
    vatCode?: string;
    success: boolean;
}
