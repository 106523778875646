<section class="egl-progress-bar-activity__wrapper" [hidden]="isHidden">
    <div class="egl-progress-bar-activity__container">
        <div
            class="egl-progress-bar-activity"
            role="progressbaractivity"
            [ngStyle]="{ width: currentStepPercent + '%' }"
            aria-valuenow="currentStepPercent"
            aria-valuemin="0"
            aria-valuemax="100"
        ></div>
    </div>

    <p class="egl-progress-bar-activity__step-description" [ngSwitch]="!currentStepDescription">
        <egl-icon
            [path]="showDropdown ? 'single-light-down' : 'single-light-right'"
            [width]="10"
            [height]="10"
            class="egl-progress-bar-activity__icon"
        ></egl-icon>
        <ng-container *ngSwitchCase="false">
            <span class="egl-progress-bar-activity__step-description-page-title" #btnToggleDropdown>
                {{ 'COMMON.PROGRESS_BAR.' + routeData?.descriptionId | translate | uppercase }}
            </span>
            <span>&nbsp;- (Pagina {{ routeData?.position }} di {{ routeData?.total }}) &#160;</span>
        </ng-container>
        <span *ngSwitchDefault> Pagina {{ routeData?.position }} di {{ routeData?.total }} &#160;</span>
        <span [hidden]="!routeData?.subTotal"
            >&nbsp;- &#160;(Sezione {{ routeData?.subPosition }} di {{ routeData?.subTotal }})
        </span>
    </p>
    <ul class="egl-progress-bar-list" *ngIf="showDropdown" #progressBarList>
        <ng-container *ngFor="let railwayStop of routeData?.journey; let id = index">
            <li class="egl-progress-bar-item" [ngSwitch]="getRouteStatus(id)">
                <div
                    class="egl-progress-bar-item__circle-status"
                    [ngClass]="'egl-progress-bar__status--' + getRouteStatus(id)"
                ></div>
                <a
                    class="egl-progress-bar-item__link"
                    (click)="navigateTo(railwayStop?.routePath)"
                    *ngSwitchCase="'visited'"
                >
                    {{ 'COMMON.PROGRESS_BAR.' + railwayStop?.descriptionId | translate }}
                </a>
                <span
                    class="egl-progress-bar-item__text"
                    [tooltip]="
                        ('COMMON.PROGRESS_BAR.' + railwayStop?.descriptionId | translate).length > 25
                            ? ('COMMON.PROGRESS_BAR.' + railwayStop?.descriptionId | translate)
                            : ''
                    "
                    *ngSwitchDefault
                >
                    {{ 'COMMON.PROGRESS_BAR.' + railwayStop?.descriptionId | translate }}
                </span>
            </li>
        </ng-container>
    </ul>
</section>
