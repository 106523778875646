<div class="egl-header-dropdown" #ddContent>
    <button
        type="button"
        class="egl-header-dropdown__btn"
        [ngClass]="{ active: selectedValue }"
        (click)="selectedValue ? onDiscard() : undefined"
        [title]="selectedValue ? selectedValue.name : placeholder || '---'"
    >
        <p class="egl-header-dropdown__title">
            <span class="egl-header-dropdown__title-selected">{{
                selectedValue ? selectedValue.name : placeholder || '---'
            }}</span>
        </p>
        <egl-icon
            [path]="'single-light-down'"
            [width]="12"
            [height]="12"
            [ngClass]="selectedValue ? 'egl-header-dropdown__icon-none' : 'egl-header-dropdown__icon'"
        ></egl-icon>
        <egl-icon
            [ngClass]="selectedValue ? 'egl-header-dropdown__icon' : 'egl-header-dropdown__icon-none'"
            [path]="'x_1'"
            [width]="12"
            [height]="12"
        ></egl-icon>
    </button>
    <div
        class="egl-header-dropdown__select"
        [ngClass]="isOpen ? 'egl-header-dropdown__select--open' : ''"
        *ngIf="!selectedValue"
    >
        <p #options *ngFor="let e of lov" class="egl-header-dropdown__option" (click)="onItemClick(getAsAny(e))">
            {{ e.name }}
        </p>
    </div>
</div>
