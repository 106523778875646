import { createAction, props } from '@ngrx/store';
import { orderEntryActionString as c } from './action-types';
import { Indirizzi } from '../../modules/switch-in/order-entry/models/indirizzi';
import { FormDatiPagamento } from '../../modules/common/order-entry/models/form-dati-pagamento';
import { FotoDocumenti, SelfCertificationData } from '../../modules/common/order-entry/models/foto-documenti';
import {
    GasConsumption,
    PowerConsumption,
    FornituraEsistente,
    DomandePrivacy,
    Firma,
    Mp3Info,
    QuoteStateModel,
    PlicoPdfInfo,
    VerificaContatto,
    TipoEsecuzione,
    CartInfo,
    ContractCode,
    DatiAnagraficiMB,
    AtecoMB,
    PotenzaImpegnataMB,
    FatturazioneElettronica,
    OrderEntryState,
    InvalidCf,
    TitolaritaImmobile,
    CreditCheckStatus,
    TaxVatDetails,
    Appointment,
    WinBackType,
    TermContacts,
    TermCosts,
    PodActivationInfo,
    PdrActivationInfo,
    PropertyTypes,
    AdministrativeTechnicalData,
    CostData,
    CommodityEstateCardinality,
    Incident,
    SelfReading,
} from '../models/order-entry-state';
import { VirtualAgentBackOffice } from '../../common/models/user/agent';
import { HistorizedAgencyBranch } from '../../common/models/user/historized-agency-branch';
import { OcrData } from '../../modules/switch-in/order-entry/models/ocr-data';
import { QuestionIvassState } from '../../modules/switch-in/products/models/ivass-question';
import { AptSaleabilityOperationType, AptSalesProcess } from '../../common/enums/apttus/apt-sales-process';
import { FlowType } from '../models/flow-type';
import { DestinationUse } from '../../common/enums/shared/destination-use.enum';
import { partnershipActionString as p } from './action-types';
import { EglPartnershipData } from '../../modules/common/partnership/models/partnership-info';
import { CheckBalanceResult } from '../../modules/common/order-entry/models/check-balance.response';

export const setOrderEntryState = createAction(c.SET_ORDER_ENTRY_STATE, props<{ s: OrderEntryState }>());
export const setCartInfo = createAction(c.SET_CART_INFO, props<{ p: CartInfo }>());
export const setIndirizzi = createAction(c.SET_DATI_FORNITURA, props<{ i: Indirizzi }>());
export const setPod = createAction(c.SET_POD, props<{ p: string }>());
export const setPdr = createAction(c.SET_PDR, props<{ p: string; meter?: string }>());
export const setPowerConsumption = createAction(c.SET_POWER_CONSUMPTION, props<{ c: PowerConsumption }>());
export const setPowerHomeDropdownOption = createAction(
    c.SET_POWER_HOME_DROPDOWN_OPTION,
    props<{ c: CommodityEstateCardinality }>()
);
export const setGasConsumption = createAction(c.SET_GAS_CONSUMPTION, props<{ c: GasConsumption }>());
export const setGasMeterReading = createAction(c.SET_GAS_METER_READING, props<{ c: SelfReading }>());
export const setGasHomeDropdownOption = createAction(
    c.SET_GAS_HOME_DROPDOWN_OPTION,
    props<{ c: CommodityEstateCardinality }>()
);
export const setTitolaritaImmobile = createAction(c.SET_TITOLARITA_IMMOBILE, props<{ c: TitolaritaImmobile }>());
export const setDatiPagamento = createAction(c.SET_DATI_PAGAMENTO, props<{ datiPagamento: FormDatiPagamento }>());
export const setFotoDocumenti = createAction(c.SET_FOTO_DOCUMENTI, props<{ d: FotoDocumenti }>());
export const setFirma = createAction(c.SET_FIRMA, props<{ payload: Firma }>());
export const setMP3Info = createAction(c.SET_MP3_INFO, props<{ payload: Mp3Info }>());
export const setPdfPlicoInfo = createAction(c.SET_PDF_PLICO_INFO, props<{ payload: PlicoPdfInfo }>());

export const setNumeroPlico = createAction(c.SET_NUMERO_PLICO, props<{ payload: ContractCode }>());
export const setPassaggioRapido = createAction(c.SET_PASSAGGIO_RAPIDO, props<{ payload: TipoEsecuzione }>());
export const setFornitureEsistentiSelezionate = createAction(
    c.SET_EXISTING_SELECTED_SUPPLIES,
    props<{ s: FornituraEsistente[] }>()
);
export const setFornitureEsistenti = createAction(c.SET_EXISTING_SUPPLIES, props<{ s: FornituraEsistente[] }>());
export const setPrivacyTratDatiPers = createAction(c.SET_PRIVACY_TRAT_PERS, props<{ p: DomandePrivacy }>());
export const setQuoteStateModel = createAction(c.SET_QUOTE_STATE_MODEL, props<{ s: QuoteStateModel }>());
export const setVerificaContatto = createAction(c.SET_VERIFICA_CONTATTO, props<{ s: VerificaContatto }>());
export const resetOrderEntry = createAction(c.RESET_ORDER_ENTRY);
export const setAgentBranch = createAction(c.SET_AGENT_BRANCH, props<{ h: HistorizedAgencyBranch }>());
export const initSign = createAction(c.INIT_SIGN);
export const setIsWinBack = createAction(c.SET_WINBACK, props<{ isWinBack: boolean; winBackType?: WinBackType }>());
export const setPlicoUrl = createAction(c.SET_PLICO_URL, props<{ payload: string }>());
export const setAnagraficaMB = createAction(c.SET_ANAGRAFICA_MB, props<{ payload: DatiAnagraficiMB }>());
export const setOcrData = createAction(c.SET_OCR_DATA, props<{ payload: OcrData }>());
export const setAtecoMB = createAction(c.SET_ATECO_MB, props<{ payload: AtecoMB }>());
export const setPotenzaImpegnataMB = createAction(c.SET_POTENZA_IMPEGNATA_MB, props<{ payload: PotenzaImpegnataMB }>());
export const setFatturazioneElettronica = createAction(c.SET_FATT_ELETT, props<{ payload: FatturazioneElettronica }>());
export const setImpersonatedAgent = createAction(
    c.SET_IMPERSONATED_AGENT,
    props<{ payload: VirtualAgentBackOffice }>()
);
export const setTroubleshootingPowerDone = createAction(c.SET_TROUBLESHOOTING_POWER_DONE, props<{ done: boolean }>());
export const setTroubleshootingGasDone = createAction(c.SET_TROUBLESHOOTING_GAS_DONE, props<{ done: boolean }>());
export const setInvalidCf = createAction(c.SET_INVALID_CF, props<{ payload: InvalidCf }>());
export const setResponsabilitaCC = createAction(c.SET_MODAL_CREDITCHECK, props<{ payload: boolean }>());
export const setFlowType = createAction(c.SET_FLOW_TYPE, props<{ flowType: FlowType }>());
export const setSalesProcess = createAction(c.SET_SALES_PROCESS, props<{ payload: AptSalesProcess }>());
export const setOperationType = createAction(c.SET_OPERATION_TYPE, props<{ payload: AptSaleabilityOperationType }>());
export const setNumeroDomiciliazione = createAction(c.SET_NUMERO_DOMICILIAZIONE, props<{ code: string }>());
export const setCreditCheckStatus = createAction(c.SET_CREDIT_CHECK_STATUS, props<{ s: CreditCheckStatus }>());
export const setInsolutoNDS = createAction(c.SET_INSOLUTO_NDS, props<{ i: string }>());
export const setTaxVatStatus = createAction(c.SET_TAX_VAT_STATUS, props<{ s: TaxVatDetails }>());
export const setScoreCardStatus = createAction(c.SET_SCORE_CARD_STATUS, props<{ s: string }>());
export const setPaymentScore = createAction(c.SET_PAYMENT_SCORE, props<{ p: string }>());
export const setCeaseReasonCode = createAction(c.SET_CEASE_REASON_CODE, props<{ c: string }>());
export const setIbanInsolutoNDS = createAction(c.SET_IBAN_INSOLUTO_NDS, props<{ i: string }>());
export const setDeferredSaleStatus = createAction(c.SET_DEFERRED_SALE_STATUS, props<{ enabled: boolean }>());
export const setIsMortisCausa = createAction(c.SET_IS_MORTIS_CAUSA, props<{ isMortisCausa: boolean }>());
export const setTerminationCartId = createAction(c.SET_TERMINATION_CART_ID, props<{ t: string }>());
export const setTerminationDate = createAction(c.SET_TERMINATION_DATE, props<{ s: string }>());
export const setSelfCertification = createAction(c.SET_SELF_CERTIFICATION, props<{ d: SelfCertificationData }>());
export const setTerminationTermContacts = createAction(c.SET_TERMINATION_TERM_CONTACTS, props<{ p: TermContacts[] }>());

export const setIvassAnswers = createAction(c.SET_IVASS_ANSWERS, props<{ p: QuestionIvassState[] }>());
export const setInsuranceDummyProductId = createAction(c.SET_INSURANCE_DUMMY_PRODUCT_ID, props<{ p: string }>());

export const setAgencyBranchForMonitoring = createAction(c.SET_AGENCY_BRANCH_FOR_MONITORING, props<{ a: string }>());
export const setAppointment = createAction(
    c.SET_TERMINATION_APPOINTMENT,
    props<{ app: Appointment; orderEntryFlowSection: OrderEntryFlowSection }>()
);
export const setWinBackType = createAction(c.SET_WINBACKTYPE, props<{ payload: WinBackType }>());
export const setTerminationCosts = createAction(c.SET_TERMINATION_COSTS, props<{ tc: TermCosts[] }>());
export const setAdministrativeTechnicalData = createAction(
    c.SET_ADMINISTRATIVE_TECHNICAL_DATA,
    props<{ p: AdministrativeTechnicalData; deleteCorrectors?: boolean }>()
);
export const setPodActivationInfo = createAction(c.SET_POD_ACTIVATION_INFO, props<{ ai: PodActivationInfo }>());
export const setPotencyActivation = createAction(c.SET_POTENCY_ACTIVATION, props<{ pot: string }>());
export const setPdrActivationInfo = createAction(c.SET_PDR_ACTIVATION_INFO, props<{ ai: PdrActivationInfo }>());
export const setQuoteId = createAction(c.SET_QUOTE_ID, props<{ id: string }>());
export const setSkipCosts = createAction(c.SET_SKIP_COSTS, props<{ skipCosts: boolean }>());
export const setCosts = createAction(c.SET_COSTS, props<{ costs: CostData }>());
export const setPropertyTypeSelected = createAction(
    c.SET_PROPERTY_TYPE_SELECTED,
    props<{ propertyTypeSelected: PropertyTypes }>()
);
export type OrderEntryFlowSection = 'termination' | 'activation';
export const setLinkedCommodityQuoteId = createAction(c.SET_LINKED_COMMODITY_QUOTE_ID, props<{ quoteId: string }>());
export const setCombinedSale = createAction(c.SET_COMBINED_SALE, props<{ combinedSale: boolean }>());

//TODO LORENZO: VALUTARE DI RINOMINARE PERCHE' VIENE UTILIZZATO, OLTRE ALLA VENDITA ABBINATA, ANCHE PER VARIAZIONI COMMERCIALI.
export const setCommodityCartId = createAction(c.SET_COMMODITY_CART_ID, props<{ commodityCartId: string }>());
export const setIncident = createAction(c.SET_INCIDENT, props<{ t: Incident }>());
export const setDestinationUse = createAction(c.SET_TYPE_OF_USE, props<{ typeOfUse: DestinationUse }>());

export const setFastwebTaxVatCode = createAction(p.SET_FASTWEB_TAX_VAT_CODE, props<{ code: string }>());
export const setFastwebContractInfo = createAction(
    p.SET_FASTWEB_CONTRACT_INFO,
    props<{ cluster: string; accountNumber: string }>()
);
export const setEglPartnershipData = createAction(p.SET_EGL_PARTNERSHIP_DATA, props<{ data: EglPartnershipData }>());
export const setCheckBalance = createAction(c.SET_CHECK_BALANCE, props<{ checkBalanceExceeded: CheckBalanceResult }>());
