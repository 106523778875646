export class VoltageLevel {
    code: string;
    value: number;
    transcodingValue: number;

    constructor(code?: string, value?: number, transcodingValue?: number) {
        this.code = code;
        this.value = isNaN(value) ? null : value;
        this.transcodingValue = isNaN(transcodingValue) ? null : transcodingValue;
    }
}
