import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CacheService, PlatformService } from '@congacommerce/core';
import { UserService } from '@congacommerce/ecommerce';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { ApplicationLocationType } from '../../store/models/app-state';
import { EglState } from '../../store/reducers';
import { selectApplicationLocation } from '../../store/selectors/app.selectors';
import { selectAgentInfo } from '../../store/selectors/user.selectors';
import { EglUserService } from '../services/apttus/tables/user/egl-user.service';
import { D365Service } from '../services/d365/d365.service';
import { OAuthService } from '../services/oauth/oauth-services';
import { LoggerService } from '../services/shared/logger.service';
import { PrivateConfigurationService } from '../services/shared/private-configuration.service';

@Injectable()
export class SalesForceLoginGuard implements CanActivate {
    constructor(
        private store: Store<EglState>,
        private userSrv: UserService,
        private eglUserSrv: EglUserService,
        private configSrv: PrivateConfigurationService,
        private platformSrv: PlatformService,
        private cacheSrv: CacheService,
        private d365Srv: D365Service,
        private logger: LoggerService,
        private oauthSrv: OAuthService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return combineLatest([
            this.store.select(selectAgentInfo),
            this.userSrv.isGuest().pipe(
                switchMap((isGuest) =>
                    isGuest
                        ? of('guest')
                        : this.userSrv.me().pipe(
                              switchMap((user) => this.eglUserSrv.getFederationId(user?.Id)),
                              map((user) => user?.egl_federationidentifier__c)
                          )
                )
            ),
            this.store
                .select(selectApplicationLocation)
                .pipe(map((appLocation) => appLocation === ApplicationLocationType.Mobile)),
        ]).pipe(
            filter(([agentInfo]) => !!agentInfo),
            take(1),
            switchMap(([{ DomainName: d365DomainName }, federationId, isMobile]) => {
                if (d365DomainName?.toUpperCase() !== federationId?.toUpperCase()) {
                    return isMobile && federationId !== 'guest' ? this.mobileForceLogout() : this.forceLogin();
                }
                return of(true);
            })
        );
    }

    private forceLogin() {
        // L'utente si è disconnesso da D365 e si è loggato con un'altra utenza
        // Forzo il logout per aggiornare il token di sessione
        this.logger.warn('Forcing logout: D365 user <> of Apttus user');
        return this.platformSrv.logout().pipe(
            switchMap(() => this.cacheSrv.clear()),
            switchMap(() => this.oauthSrv.salesforceLogin()),
            switchMap(() => of(false)),
            take(1)
        );
    }

    private mobileForceLogout() {
        // Se da tablet piuttosto che redirezionare sulla pagina di oauth redireziono l'utenza su una pagina
        // contenente le istruzioni per disconnettere.
        // E' un fix per problema loop in login da tablet #39453
        const crmUri = this.configSrv.config.redirectUri.crm.split('/')[2];
        const redirectUriMobile = `${this.configSrv.config.oauthBaseUrl}${
            this.configSrv.config.clientId
        }&redirect_uri=${encodeURI(
            location.protocol + this.configSrv.config.redirectUri.mobile
        )}%2F&scope=&state=jsforce0.redirect.4sh0nvndizf`;
        this.d365Srv.topNavigationRequest(
            `${this.configSrv.config.tabletForceLogoutWarningPage}?crmUri=https://${crmUri}&redirectUri=${btoa(
                redirectUriMobile
            )}`
        );
        return of(false);
    }
}
