export enum InadmissibilityFilterType {
    All = 'ALL',
    Inadmissible = 'INADMISSIBLE',
    Admissible = 'ADMISSIBLE',
}

export enum InadmissibilityFilterSubType {
    All = 'ALL',
    Reworkable = 'REWORKABLE',
}
