import { AptLineStatus } from '../../enums/apttus/apt-line-status';
import { AptPaymentInstrument } from '../../enums/apttus/apt-payment-instrument';
import { AptProductType } from '../../enums/apttus/apt-product-type';

export class PaymentToolResponse {
    paymentTools: PaymentTool[];
}
export interface PaymentTool {
    id: string;
    type?: AptPaymentInstrument;
    billingPreference: BillingPreference[];
    stato?: PaymentToolsStatus;
    iban?: string;
    holder?: PaymentToolHolder;
    sepaSubscriber?: SepaSubscriber;
    creditCardNumber?: string;
    creditCardExpirationDate?: string;
}
interface PaymentToolHolder {
    fiscalcode: string; // contiene cf / piva in caso di MB
    firstname: string;
    lastname: string;
    fullname: string;
}
interface SepaSubscriber {
    taxcode: string;
    firstname: string;
    lastname: string;
}
interface BillingPreference {
    id: string;
    billingPreferenceCode: string;
}

export interface PaymentAsset {
    id: string;
    productType: AptProductType;
    productName: string;
    indirizzo: string;
    pod?: string;
    pdr?: string;
    status?: AptLineStatus;
}

export enum PaymentToolsStatus {
    Attivo = 'ATTIVO',
    InAttivazione = 'In attivazione',
    Revocato = 'Revocato',
    Annullato = 'Annullato',
    DaConfermare = 'DA CONFERMARE',
    Cessato = 'CESSATO',
}
