export enum FlowType {
    // Cessazione
    Cessazione = 'CESSAZIONE',
    CessazioneAmministrativa = 'CESSAZIONE_AMMINISTRATIVA', // Verificare con apttus che abbiano aggiunto Cessazione Amministrativa in 'egl_sales_process__c'

    // Voltura
    Voltura = 'VOLTURA',
    VolturaAmmVip = 'VOLTURA_AMM_VIP',
    VolturaAmmSiProvisioning = 'VOLTURA_AMM_SI_PROVISIONING',
    VolturaAmmNoProvisioning = 'VOLTURA_AMM_NO_PROVISIONING',
    VolturaAmmIncorporazioneSocietaria = 'VOLTURA_AMM_INCORPORAZIONE_SOCIETARIA',

    // Voltura con Switch/Cambio fornitore
    VolturaCambioFornitore = 'VOL_SW_IN',
    VolturaCambioFornitoreVip = 'VOL_SW_IN_VIP',
    VolturaCambioFornitoreAmmRecupero2A = 'VOL_SW_IN_AMM_RECUPERO_2A', // SWIN Amm. scenario 2A (cliente presente, asset presente in stato cessato)
    VolturaCambioFornitoreAmmRecupero2B = 'VOL_SW_IN_AMM_RECUPERO_2B', // SWIN Amm. scenario 2B (cliente presente, asset assente)
    VolturaCambioFornitoreAmmRecupero2C = 'VOL_SW_IN_AMM_RECUPERO_2C', // SWIN Amm. scenario 2C (cliente assente, asset assente)

    // Attivazione
    Attivazione = 'ATTIVAZIONE', // flusso virtuale
    // Attivazioni complesse
    AttivazioneAmministrativa = 'ATTIVAZIONE_AMMINISTRATIVA', // Attivazione Amministrativa
    // AttivazioneGasConContatore = 'ATTIVAZIONE_SEMP_GAS_CONTATORE', // GAS - SUBENTRO Con Contatore
    // AttivazioneGasSenzaContatore = 'ATTIVAZIONE_SEMP_GAS_NO_CONTATORE', // GAS - SUBENTRO Senza Contatore
    AttivazioneGas = 'ATTIVAZIONE_GAS',
    AttivazioneA40 = 'ATTIVAZIONE_COMP', // GAS - Attivazione soggetta a delibera 40/41
    AttivazionePN1 = 'ATTIVAZIONE_PREV', // GAS - Preventivo nuovo impianto
    AttivazionePwrS01 = 'ATTIVAZIONE_SEMP_LUCE', // LUCE - SUBENTRO
    AttivazioneN02 = 'ATTIVAZIONE_ALLACCIO_CON_POSA', // LUCE - Nuova conessione con attivazione
    AttivazionePwrA01 = 'ATTIVAZIONE_ISTANZA', // LUCE - Attivazione preposati a parità di condizioni di connessione

    // Attivazione Amministrativa
    AttivazioneAmmRecupero = 'ATTIVAZIONE_AMM_RECUPERO',
    AttivazioneAmmRecuperoS01 = 'ATTIVAZIONE_AMM_RECUPERO_POWER', // ATTIVAZIONE Amm. Subentro
    AttivazioneAmmRecuperoA01 = 'ATTIVAZIONE_AMM_RECUPERO_GAS', // ATTIVAZIONE Amm. Attivazione semplice
    AttivazioneAmmVip = 'ATTIVAZIONE_VIP',
    AttivazioneAmmVipGasA01 = 'ATTIVAZIONE_SEMPLICE_VIP',
    AttivazioneAmmVipPowerS01 = 'SUBENTRO_VIP',
    AttivazioneAmmGasA01 = 'ATTIVAZIONE_SEMP_AMM_GAS', //GAS - ATTIVAZIONE AMM.
    AttivazioneAmmPwrS01 = 'SUBENTRO_AMM_PWR', // LUCE - SUBENTRO AMM.

    // Switch-in
    SwitchIn = 'SW_IN',
    SwitchInAmmVip = 'SW_IN_AMM_VIP', // SWIN Amm. scenario 1, VIP
    SwitchInAmmRecupero2A = 'SW_IN_AMM_RECUPERO_2A', // SWIN Amm. scenario 2A (cliente presente, asset presente in stato cessato)
    SwitchInAmmRecupero2B = 'SW_IN_AMM_RECUPERO_2B', // SWIN Amm. scenario 2B (cliente presente, asset assente)
    SwitchInAmmRecupero2C = 'SW_IN_AMM_RECUPERO_2C', // SWIN Amm. scenario 2C (cliente assente, asset assente)
    SwitchInAmmDelibera153 = 'SW_IN_AMM_DEL153', // SWIN Amm. scenario 3, come SWIN Amm. scenario 2A. Cliente EGL passato a ENEL involontariamente

    // Domiciliazione
    DomiciliazioneRevoca = 'DOM_REVOCA',
    DomiciliazioneModifica = 'DOM_MODIFICA',
    DomiciliazioneAttivazione = 'DOM_ATTIVAZ',
    DomiciliazioneAttivazioneInserimentoPaymentTool = 'DOM_ATTIVAZ_NEW_PAYMENT_TOOL',
    DomiciliazioneModificaInserimentoPaymentTool = 'DOM_MODIFICA_NEW_PAYMENT_TOOL',

    // Cambio Prodotto
    CPCommerciale = 'CP_COM',
    CPRetroattivoExNovo = 'CP_AMM_EXNOVO',
    CPDelibera = 'CP_DEL',
    CPAmministrativo = 'CP_AMM',
    CPRetroattivoAssetCessato = 'CP_AMM_CESSATO',

    // Attivazione Complessa Manuale
    AttivazioneAmmN02 = 'PREVENTIVO_ATTIVAZIONE_PWR_AMM', // LUCE
    AttivazionePwrAmmA01 = 'ATTIVAZIONE_ISTANZA_AMM', // LUCE
    AttivazioneAmmA40 = 'ATTIVAZIONE_CON_ACCERTAMENTO_AMM', // GAS
    AttivazioneAmmPN1 = 'PREVENTIVO_ATTIVAZIONE_GAS_AMM', // GAS

    // Attivazione Complessa Vip
    AttivazioneVipN02 = 'PREVENTIVO_ATTIVAZIONE_PWR_VIP', // LUCE
    AttivazionePwrVipA01 = 'ATTIVAZIONE_ISTANZA_VIP', // LUCE
    AttivazioneVipA40 = 'ATTIVAZIONE_CON_ACCERTAMENTO_VIP', // GAS
    AttivazioneVipPN1 = 'PREVENTIVO_ATTIVAZIONE_GAS_VIP', // GAS

    // Variazioni Tecniche
    // VT DROP 1
    VariazioneTecnicaVerifiche = 'VARIAZIONE_TECNICA_VERIFICHE',
    VariazioneTecnicaLavori = 'VARIAZIONE_TECNICA_LAVORI',
    VariazioneTecnicaLavoriDiminuzionePotenza = 'VARIAZIONE_TECNICA_LAVORI_DIMINUZIONE_POTENZA',
    VariazioneTecnicaVerificheAmministrativa = 'VARIAZIONE_TECNICA_VERIFICHE_AMMINISTRATIVA',
    VariazioneTecnicaLavoriAmministrativa = 'VARIAZIONE_TECNICA_LAVORI_AMMINISTRATIVA',
    VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa = 'VARIAZIONE_TECNICA_LAVORI_DIMINUZIONE_POTENZA_AMMINISTRATIVA',
    // DROP 2
    // VariazioneTecnicaReclami = 'VARIAZIONE_TECNICA_RECLAMI',
    VariazioneTecnicaLavoriPreventivo = 'VARIAZIONE_TECNICA_LAVORI_PREVENTIVO',
    VariazioneTecnicaLavoriPreventivoAumentoPotenza = 'VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_AUMENTO_POTENZA',
    VariazioneTecnicaLavoriPreventivoVariazioneFasi = 'VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_VARIAZIONE_FASI',
    VariazioneTecnicaLavoriPreventivoVariazioneTensione = 'VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_VARIAZIONE_TENSIONE',
    VariazioneTecnicaLavoriPreventivoAmministrativa = 'VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_AMMINISTRATIVA',
    VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa = 'VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_AUMENTO_POTENZA_AMMINISTRATIVA',
    VariazioneTecnicaLavoriPreventivoVariazioneFasiAmministrativa = 'VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_VARIAZIONE_FASI_AMMINISTRATIVA',
    VariazioneTecnicaLavoriPreventivoVariazioneTensioneAmministrativa = 'VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_VARIAZIONE_TENSIONE_AMMINISTRATIVA',
    // DROP 2.1
    VariazioneTecnicaLavoriPreventivoModificaImpianto = 'VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_MODIFICA_IMPIANTO',
    VariazioneTecnicaLavoriPreventivoModificaImpiantoAmministrativa = 'VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_MODIFICA_IMPIANTO_AMMINISTRATIVA',
    // DROP3
    VariazioneAteco = 'MODIFICA_PARAMETRI_FORNITURA_ATECO',
    VariazioneTipologiaFornituraGas = 'MODIFICA_PARAMETRI_FORNITURA_GAS',
    VariazioneIndirizzo = 'MODIFICA_INDIRIZZO_FORNITURA',
    AllineaTipologiaUtilizzoDistributore = 'MC1_CAMBIO_UTILIZZO_POWER',

    // Variazione commerciale
    VariazioneCommerciale = 'VAR_COMM',

    // Sospensione
    Sospensione = 'SOSPENSIONE',
    SospensioneAmministrativa = 'SOSPENSIONE_AMMINISTRATIVA',
    RevocaSospensione = 'REVOCA_SOSPENSIONE',
    AnnullamentoSospensione = 'ANNULLAMENTO_SOSPENSIONE',

    // Interruzione
    Interruzione = 'INTERRUZIONE',
    InterruzioneAmministrativa = 'INTERRUZIONE_AMMINISTRATIVA',
    RevocaInterruzione = 'REVOCA_INTERRUZIONE',
    AnnullamentoInterruzione = 'ANNULLAMENTO_INTERRUZIONE',

    // Riapertura
    Riapertura = 'RIAPERTURA',
    RiaperturaAmministrativa = 'RIAPERTURA_AMMINISTRATIVA',
    RevocaRiapertura = 'REVOCA_RIAPERTURA',
    AnnullamentoRiapertura = 'ANNULLAMENTO_RIAPERTURA',

    // Sconto standalone
    ScontoStandalone = 'SC_STA',

    // Cessazione Amministrativa per Morosità
    CA1 = 'CA1',
    CA1AMM = 'CA1_AMMINISTRATIVA',
    CA2 = 'CA2',
    CA2AMM = 'CA2_AMMINISTRATIVA',
    CA3 = 'CA3',
    CA3AMM = 'CA3_AMMINISTRATIVA',
    CA4 = 'CA4',
    CA4AMM = 'CA4_AMMINISTRATIVA',
    CA5 = 'CA5',
    CA5AMM = 'CA5_AMMINISTRATIVA',
    CA7 = 'CA7_MOTIVI_DIVERSI',
    CA7AMM = 'CA7_MOTIVI_DIVERSI_AMMINISTRATIVA',
    CAE1 = 'CAE1',
    CAE1AMM = 'CAE1_AMMINISTRATIVA',
    CAE3 = 'CAE3',
    CAE3AMM = 'CAE3_AMMINISTRATIVA',
    CAE4 = 'CAE4',
    CAE4AMM = 'CAE4_AMMINISTRATIVA',
    CAE5 = 'CAE5',
    CAE5AMM = 'CAE5_AMMINISTRATIVA',
    CAE7 = 'CAE7',
    CAE7AMM = 'CAE7_AMMINISTRATIVA',
    CAE8 = 'CAE8_MOTIVI_DIVERSI',
    CAE8AMM = 'CAE8_MOTIVI_DIVERSI_AMMINISTRATIVA',
    CAE8RIFVOLT = 'CAE8_RIFIUTO_VOLTURA',
    CAE8RIFVOLTAMM = 'CAE8RIFVOLT_RIFIUTO_VOLTURA_AMMINISTRATIVA',

    RevocaRisoluzioneContrattuale = 'REVOCA_RISOLUZIONE_CONTRATTUALE',
    RevocaAltriProcessi = 'REVOCA_ALTRI',

    // Aggrega Disaggrega Fornitura
    Aggrega = 'AGGREGA',
    Disaggrega = 'DISAGGREGA',

    CambioTipologiaFornitura = 'CAMBIO_TIPOLOGIA_FORNITURA',

    // Extracommodity
    ExtraCommoditySale = 'EXTRACOMMODITY_SALE',
    // Cessazione extracommodity
    CessazioneExtracommodity = 'CESSAZIONE_EXTRACOMMODITY',

    //Deactivation
    DisattivazioneCdC = 'DISATTIVAZIONE_CARTE_DI_CREDITO',

    //ADOTTA UN PANNELLO
    AggiungiModificaPannello = 'AGGIUNGI_MODIFICA_PANNELLO',
    RimuoviPannello = 'RIMUOVI_PANNELLO',
}

export enum MacroFlowType {
    SwitchIn = 'SWITCH_IN',
    VolturaSwitchIn = 'VOLTURA_SWITCH_IN',
    CambioProdotto = 'CAMBIO_PRODOTTO',
    Voltura = 'VOLTURA',
    Cessazione = 'CESSAZIONE',
    Attivazione = 'ATTIVAZIONE',
    // AttivazioneSemplice = 'ATTIVAZIONE_SEMPLICE',
    AttivazioneComplessa = 'ATTIVAZIONE_COMPLESSA',
    Domiciliazione = 'DOMICILIAZIONE',
    Variazione = 'VARIAZIONE',
    VariazioneParametri = 'VARIAZIONE_PARAMETRI',
    ScontoStandalone = 'SCONTO_STANDALONE',
    AggregaDisaggrega = 'AGGREGA_DISAGGREGA',
    Administrative = 'AMMINISTRATIVO',
    Vip = 'VIP',
    Recovery = 'RECUPERO',
    Preventivi = 'PREVENTIVI',
    Morosity = 'MOROSITA',
    Extracommodity = 'EXTRACOMMODITY',
    Pannello = 'PANNELLO',
}
