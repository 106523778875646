import { AptDoiConfirmModeType } from '../enums/apttus/apt-doi-confirm-mode-type';
import { D365ConfirmationType } from '../enums/d365/d365-confirmation-type';

export const CONFIRMATION_TYPE_MAP: {
    [key in AptDoiConfirmModeType]: D365ConfirmationType;
} = {
    [AptDoiConfirmModeType.CheckCall]: D365ConfirmationType.CheckCall,
    [AptDoiConfirmModeType.CheckDoi]: D365ConfirmationType.CheckDOI,
    [AptDoiConfirmModeType.TacitoAssensoSms]: D365ConfirmationType.TacitApprovalSMS,
    [AptDoiConfirmModeType.NessunaConferma]: D365ConfirmationType.NessunaConferma,
};
