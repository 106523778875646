<div class="egl-mostusedactions__radiobuttons" *ngIf="enabled">
    <egl-radiobutton-highlighted
        *ngFor="let action of actionDefinitions"
        [label]="action.label"
        [value]="action"
        [checked]="isSelected(action)"
        (onSelect)="onSelectAction($event)"
        class="egl-mostusedactions__radiobutton-container"
        [disabled]="disabled"
    ></egl-radiobutton-highlighted>
</div>
