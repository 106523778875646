import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ApttusModule, ImagePipe } from '@congacommerce/core';
import { PriceListCategory, PricingModule } from '@congacommerce/ecommerce';
import {
    AddressModule,
    AlertModule,
    BreadcrumbModule,
    ButtonModule,
    ConfigurationSummaryModule,
    ConstraintPopoverModule,
    ConstraintRuleModule,
    DirectivesModule,
    IconModule,
    InputDateModule,
    InputFieldModule,
    MiniCartModule,
    MiniProfileModule,
    OutputFieldModule,
    PriceModule,
    ProductAttributeModule,
    TaxPopHoverModule,
} from '@congacommerce/elements';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LaddaModule } from 'angular2-ladda';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { itLocale } from 'ngx-bootstrap/locale';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AdoptPanelSharedModule } from '../../modules/adopt-panel/adopt-panel-shared.module';
import { AsyncFormValidators } from '../async-form-validators';
import { DirectiveModule } from '../directives/directive.module';
import { CommonPipesModule } from '../pipes/pipes.module';
import { EgonProvider } from '../providers/egon-provider';
import { EglCartExtendedService } from '../services/apttus/tables/cart/egl-cart-extended.service';
import { ConsumptionValidationService } from '../services/shared/consumption-validation.service';
import { SetLabelsService } from '../services/shared/set-labels.service';
import { TroubleshootingQuestionsService } from '../services/shared/troubleshooting-questions.service';
import { TroubleshootingValuesService } from '../services/shared/troubleshooting-values.service';
import { AccordionComponent } from './accordion/accordion.component';
import { AlertMsgComponent } from './alert/alert-msg/alert-msg.component';
import { EglAddToCartComponent } from './apttus/egl-add-to-cart/egl-add-to-cart.component';
import { EglInputFieldComponent } from './apttus/egl-input-field/egl-input-field.component';
import { EglLineItemTableRowComponent } from './apttus/egl-line-item-table-row/egl-line-item-table-row.component';
import { EglMiniCartComponent } from './apttus/egl-mini-cart/egl-mini-cart.component';
import { EglProductAttributeComponent } from './apttus/egl-product-attribute/egl-product-attribute.component';
import { EglProductCardBaseComponent } from './apttus/egl-product-card-base/egl-product-card-base.component';
import { EglProductCardComponent } from './apttus/egl-product-card/egl-product-card.component';
import { EglProductConfigurationComponent } from './apttus/egl-product-configuration/egl-product-configuration.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { BenefitComponent } from './benefit/benefit.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CommandBarComponent } from './command-bar/command-bar.component';
import { BtnCaricamentoMassivoComponent } from './command-bar/components/btn-caricamento-massivo/btn-caricamento-massivo.component';
import { ResultsTableComponent } from './command-bar/components/results-table/results-table.component';
import { ConfigBoxComponent } from './config-box/config-box.component';
import { ContactBarComponent } from './contact-bar/contact-bar.component';
import { D365LookupObjectComponent } from './d365/d365-lookup-object/d365-lookup-object.component';
import { DatePickerComponent } from './data-picker/date-picker.component';
import { DsCheckboxComponent } from './design-system/forms/ds-checkbox/ds-checkbox.component';
import { DsOutcomeComponent } from './design-system/messages/ds-outcome/ds-outcome.component';
import { DsSmallNoticeComponent } from './design-system/messages/ds-small-notice/ds-small-notice.component';
import { DsTabsComponent } from './design-system/tabs/ds-tabs/ds-tabs.component';
import { NewConvertCartToQuoteTestPageComponent } from './dev-bar/components/new-convert-cart-to-quote-test-page/new-convert-cart-to-quote-test-page.component';
import { DevBarComponent } from './dev-bar/dev-bar.component';
import { ProgressDndComponent } from './drag-and-drop/components/progress-dnd/progress-dnd.component';
import { DragAndDropComponent } from './drag-and-drop/drag-and-drop.component';
import { EgonAutocompleteComponent } from './egon-autocomplete/egon-autocomplete.component';
import { EgonTigerAddressComponent } from './egon-tiger-address/egon-tiger-address.component';
import { FooterComponent } from './footer/footer.component';
import { EglCheckboxPaneComponent } from './forms/egl-checkbox-pane/egl-checkbox-pane.component';
import { EglCheckboxComponent } from './forms/egl-checkbox/egl-checkbox.component';
import { EglDropdownNoLabelComponent } from './forms/egl-dropdown-no-label/egl-dropdown-no-label.component';
import { EglDropdownPrefixPhoneComponent } from './forms/egl-dropdown-prefix-phone/egl-dropdown-prefix-phone.component';
import { EglDropdownComponent } from './forms/egl-dropdown/egl-dropdown.component';
import { EglInputErrorMessageComponent } from './forms/egl-input-error-message/egl-input-error-message.component';
import { EglInputTextComponent } from './forms/egl-input-text/egl-input-text.component';
import { EglInputfieldComponent } from './forms/egl-inputfield/egl-inputfield.component';
import { EglOutlineTextBtnComponent } from './forms/egl-outline-text-btn/egl-outline-text-btn.component';
import { EglRadioCheckComponent } from './forms/egl-radio-check/egl-radio-check.component';
import { EglRadiobuttonHighlightedComponent } from './forms/egl-radiobutton-highlighted/egl-radiobutton-highlighted.component';
import { EglRadiobuttonComponent } from './forms/egl-radiobutton/egl-radiobutton.component';
import { EglSelectablePaneComponent } from './forms/egl-selectable-pane/egl-selectable-pane.component';
import { EglTextareaComponent } from './forms/egl-textarea/egl-textarea.component';
import { OptionPaneComponent } from './forms/option-pane/option-pane.component';
import { GenericDynamicsWidgetComponent } from './generic-dynamics-widget/generic-dynamics-widget.component';
import { GenericFormLayoutComponent } from './generic-form-layout/generic-form-layout.component';
import { HeaderButtonComponent } from './header/main/components/header-button/header-button.component';
import { HeaderDropdownComponent } from './header/main/components/header-dropdown/header-dropdown.component';
import { HeaderComponent } from './header/main/header.component';
import { MainHeaderService } from './header/main/service/main-header.service';
import { UserInfoComponent } from './header/user-info/user-info.component';
import { InfoBannerComponent } from './info-banner/info-banner.component';
import { KeySellingPointComponent } from './key-selling-points/key-selling-point.component';
import { LinkComponent } from './link/link.component';
import { ModalImgPreviewComponent } from './modal-img-preview/modal-img-preview.component';
import { ModalComponent } from './modal/modal.component';
import { EglModalCustomComponent } from './modals/egl-modal-custom/egl-modal-custom.component';
import { EglModalWithFooterComponent } from './modals/egl-modal-with-footer/egl-modal-with-footer.component';
import { OrderEntrySubtitleComponent } from './order-entry-subtitle/order-entry-subtitle.component';
import { OrderEntryTitleComponent } from './order-entry-title/order-entry-title.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { EglPopoverComponent } from './popover/egl-popover/egl-popover.component';
import { ProductCommodityComponent } from './product-commodity/product-commodity.component';
import { ProductDetailsComplexComponent } from './product-details-complex/product-details-complex/product-details-complex.component';
import { ProductInformationsListComponent } from './product-details/product-informations-list/product-informations-list.component';
import { ProductSummaryBoxSmallComponent } from './product-details/product-summary-box-small/product-summary-box-small.component';
import { ProductSummaryComponent } from './product-details/product-summary/product-summary.component';
import { ProductDocumentComponent } from './product-document/product-document.component';
import { ProductOfferComponent } from './product-offer/product-offer.component';
import { ProgressBarValueComponent } from './progress-bar-value/progress-bar-value.component';
import { RectangleIconComponent } from './rectangle-icon/rectangle-icon.component';
import { RectangleMenuComponent } from './rectangle-menu/rectangle-menu.component';
import { RibbonComponent } from './ribbon/ribbon.component';
import { SimpleTitleComponent } from './simple-title/simple-title.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StepDescriptionComponent } from './step-description/step-description.component';
import { SummaryBarComponent } from './summary-bar/summary-bar.component';
import { TableComponent } from './table/table.component';
import { TagComponent } from './tag/tag.component';
import { TroubleshooterPipe } from './troubleshooting-modalita/troubleshooter.pipe';
import { TroubleshootingModalitaComponent } from './troubleshooting-modalita/troubleshooting-modalita.component';
import { TrueFalseAnswerComponent } from './true-false-answer/true-false-answer.component';
import { UploadMp3ModalComponent } from './upload-mp3-modal/upload-mp3-modal.component';
import { DynamicFormQuestionComponent } from './widget-form/dynamic-form-question/dynamic-form-question.component';
import { WidgetFormComponent } from './widget-form/widget-form.component';
import { EglConsumerCreditWoComponent } from './work-order-tables/consumer-credit-wo/consumer-credit-wo.component';
import { EglDocumentaryCheckWoComponent } from './work-order-tables/documentary-check-wo/documentary-check-wo.component';
import { EglFieldServiceWoComponent } from './work-order-tables/field-service-wo/field-service-wo.component';
import { WyvernBarComponent } from './wyvern-bar/wyvern-bar.component';

defineLocale('it', itLocale);

@NgModule({
    imports: [
        AdoptPanelSharedModule,
        CommonModule,
        MiniProfileModule,
        MiniCartModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        TypeaheadModule.forRoot(),
        TranslateModule.forChild(),
        LaddaModule,
        RouterModule,
        FormsModule,
        ApttusModule,
        PricingModule,
        IconModule,
        PriceModule,
        NgScrollbarModule,
        TooltipModule.forRoot(),
        OutputFieldModule,
        BreadcrumbModule,
        ButtonModule,
        DirectivesModule,
        InputFieldModule,
        ReactiveFormsModule,
        AlertModule,
        ConstraintPopoverModule,
        PopoverModule,
        TaxPopHoverModule,
        DirectiveModule,
        CarouselModule,
        PdfViewerModule,
        ProductAttributeModule,
        NgSelectModule,
        BsDatepickerModule,
        NgOptionHighlightModule,
        AddressModule,
        InputDateModule,
        AutocompleteLibModule,
        ConfigurationSummaryModule,
        ConstraintRuleModule,
        AngularSvgIconModule.forRoot(),
        HttpClientModule,
        CommonPipesModule,
    ],
    exports: [
        HeaderComponent,
        UserInfoComponent,
        LaddaModule,
        EglMiniCartComponent,
        EglProductCardComponent,
        EglProductCardBaseComponent,
        ContactBarComponent,
        EglProductConfigurationComponent,
        PdfViewerComponent,
        EglAddToCartComponent,
        DevBarComponent,
        RectangleMenuComponent,
        StepDescriptionComponent,
        TrueFalseAnswerComponent,
        ModalComponent,
        CheckboxComponent,
        EglLineItemTableRowComponent,
        UploadMp3ModalComponent,
        FooterComponent,
        RectangleIconComponent,
        EgonAutocompleteComponent,
        D365LookupObjectComponent,
        ModalImgPreviewComponent,
        EglDropdownComponent,
        EglRadiobuttonComponent,
        EglDropdownNoLabelComponent,
        EglInputfieldComponent,
        EglPopoverComponent,
        EglCheckboxPaneComponent,
        EglCheckboxComponent,
        EglTextareaComponent,
        EglModalCustomComponent,
        EglModalWithFooterComponent,
        DragAndDropComponent,
        SpinnerComponent,
        ProgressDndComponent,
        EglSelectablePaneComponent,
        EglRadiobuttonHighlightedComponent,
        BenefitComponent,
        KeySellingPointComponent,
        AccordionComponent,
        ProductOfferComponent,
        ProductDocumentComponent,
        DatePickerComponent,
        DsCheckboxComponent,
        DsOutcomeComponent,
        DsSmallNoticeComponent,
        ProductSummaryComponent,
        ProductInformationsListComponent,
        TableComponent,
        CommandBarComponent,
        BtnCaricamentoMassivoComponent,
        ResultsTableComponent,
        BsDatepickerModule,
        WidgetFormComponent,
        DsTabsComponent,
        SummaryBarComponent,
        LinkComponent,
        OrderEntryTitleComponent,
        OrderEntrySubtitleComponent,
        ProductSummaryBoxSmallComponent,
        AutocompleteComponent,
        EglRadioCheckComponent,
        GenericFormLayoutComponent,
        RibbonComponent,
        InfoBannerComponent,
        ProgressBarValueComponent,
        ProductCommodityComponent,
        EglOutlineTextBtnComponent,
        TroubleshootingModalitaComponent,
        EglDropdownPrefixPhoneComponent,
        TroubleshooterPipe,
        NewConvertCartToQuoteTestPageComponent,
        GenericDynamicsWidgetComponent,
        ConfigBoxComponent,
        OptionPaneComponent,
        EgonTigerAddressComponent,
        AlertMsgComponent,
        EglInputTextComponent,
        EglFieldServiceWoComponent,
        EglConsumerCreditWoComponent,
        EglDocumentaryCheckWoComponent,
        ProductDetailsComplexComponent,
        SimpleTitleComponent,
        AdoptPanelSharedModule,
    ],
    declarations: [
        HeaderComponent,
        UserInfoComponent,
        EglMiniCartComponent,
        ContactBarComponent,
        EglProductCardComponent,
        EglProductConfigurationComponent,
        EglProductAttributeComponent,
        PdfViewerComponent,
        EglAddToCartComponent,
        DevBarComponent,
        RectangleMenuComponent,
        RectangleIconComponent,
        StepDescriptionComponent,
        TrueFalseAnswerComponent,
        ModalComponent,
        EglInputFieldComponent,
        CheckboxComponent,
        EglLineItemTableRowComponent,
        UploadMp3ModalComponent,
        EgonAutocompleteComponent,
        DatePickerComponent,
        D365LookupObjectComponent,
        ModalImgPreviewComponent,
        EglDropdownComponent,
        EglRadiobuttonComponent,
        EglDropdownNoLabelComponent,
        EglInputfieldComponent,
        EglPopoverComponent,
        EglCheckboxPaneComponent,
        EglCheckboxComponent,
        EglTextareaComponent,
        EglModalCustomComponent,
        EglModalWithFooterComponent,
        EglRadioCheckComponent,
        DragAndDropComponent,
        ProgressDndComponent,
        SpinnerComponent,
        EglSelectablePaneComponent,
        EglRadiobuttonHighlightedComponent,
        DsCheckboxComponent,
        DsOutcomeComponent,
        DsSmallNoticeComponent,
        EglProductCardBaseComponent,
        BenefitComponent,
        KeySellingPointComponent,
        AccordionComponent,
        ProductOfferComponent,
        ProductDocumentComponent,
        ProductSummaryComponent,
        ProductInformationsListComponent,
        TableComponent,
        CommandBarComponent,
        BtnCaricamentoMassivoComponent,
        ResultsTableComponent,
        WidgetFormComponent,
        DynamicFormQuestionComponent,
        SummaryBarComponent,
        WidgetFormComponent,
        DynamicFormQuestionComponent,
        DsTabsComponent,
        FooterComponent,
        TagComponent,
        LinkComponent,
        OrderEntryTitleComponent,
        OrderEntrySubtitleComponent,
        ProductSummaryBoxSmallComponent,
        AutocompleteComponent,
        GenericFormLayoutComponent,
        RibbonComponent,
        InfoBannerComponent,
        ProgressBarValueComponent,
        ProductCommodityComponent,
        EglOutlineTextBtnComponent,
        TroubleshootingModalitaComponent,
        TroubleshooterPipe,
        EglDropdownPrefixPhoneComponent,
        HeaderDropdownComponent,
        HeaderButtonComponent,
        NewConvertCartToQuoteTestPageComponent,
        GenericDynamicsWidgetComponent,
        ConfigBoxComponent,
        OptionPaneComponent,
        EgonTigerAddressComponent,
        AlertMsgComponent,
        WyvernBarComponent,
        EglInputTextComponent,
        EglInputErrorMessageComponent,
        EglFieldServiceWoComponent,
        EglConsumerCreditWoComponent,
        EglDocumentaryCheckWoComponent,
        ProductDetailsComplexComponent,
        SimpleTitleComponent,
    ],
    providers: [
        PriceListCategory,
        BsModalService,
        EgonProvider,
        AsyncFormValidators,
        ImagePipe,
        TroubleshooterPipe,
        TroubleshootingQuestionsService,
        TroubleshootingValuesService,
        MainHeaderService,
        EglCartExtendedService,
        ConsumptionValidationService,
        SetLabelsService,
    ],
})
export class ComponentModule {
    constructor(private bsLocaleService: BsLocaleService) {
        this.bsLocaleService.use('it');
    }
}
