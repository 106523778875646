import { BOType } from '../common/configuration/bo-table-search-fields.config';
import { FilterCommodityType } from './../../../common/enums/apttus/apt-commodity-typeof-sale';
import { AptSalesProcess } from './../../../common/enums/apttus/apt-sales-process';
export class DownloadListExcelRequest {
    Filtri: ExcelFiltri;
    View: BOType;
    Profile: string;

    constructor(viewType: BOType, profile: string) {
        this.View = viewType;
        this.Filtri = new ExcelFiltri();
        this.Profile = profile;
    }
}

export class ExcelFiltri {
    Data: ExcelData;
    Stati: string[];
    salesProcess: AptSalesProcess;
    sortByField: string;
    sortByType: 'DESC' | 'ASC';
    searchKey?: string;
    searchValue?: string;
    type: string;
    subType: string;
    agencyBranch?: string[];
    channel?: string[];
    agency?: string[];
    commodityType?: FilterCommodityType;

    constructor() {
        this.Data = new ExcelData();
    }
}

export class ExcelData {
    tipodata: string;
    dataDa: string;
    dataA: string;
    UTCDateTimeOffset: number;

    constructor() {
        this.UTCDateTimeOffset = new Date().getTimezoneOffset() * -1;
    }
}
