import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

export type IconColor =
    | 'egl-icon--white'
    | 'egl-icon--yellow'
    | 'egl-icon--red'
    | 'egl-icon--grey'
    | 'egl-icon--medium-grey'
    | 'egl-icon--light-grey'
    | 'egl-icon--light-grey-disabled'
    | 'egl-icon--blue'
    | 'egl-icon--grey-a6'
    | 'egl-icon--black'
    | '#21ad72'
    | '';

@Component({
    selector: 'egl-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnChanges {
    @Input() withCircle?: boolean;
    // NOTE iconColor può avere questi valori: egl-icon--white, egl-icon--yellow, egl-icon--red, egl-icon--grey, egl-icon--medium-grey, egl-icon--light-grey, egl-icon--light-grey-disabled, egl-icon--blue e egl-icon--grey-a6
    @Input() iconColor?: IconColor = 'egl-icon--grey';

    @Input() path: string;
    @Input() width? = 16;
    @Input() height? = 16;

    constructor() {}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.path?.currentValue) {
            this.path = this.getPathResource(changes.path.currentValue);
        }
    }

    getPathResource(iconName: string) {
        return `/assets/svg/${iconName}.svg`;
    }
}
