import { D365ChannelCode } from '../../../common/enums/d365/d365-channel-code';
import { D365ProfileCode } from '../../../common/enums/d365/d365-profile-code';
import { FilterCommodityType } from './../../../common/enums/apttus/apt-commodity-typeof-sale';
import { AptSalesProcess } from './../../../common/enums/apttus/apt-sales-process';

export class MonitoraggioOrderRequest {
    Filtri: MonitoraggioFiltri;
    Obfuscate: boolean = false;
    Profile: D365ProfileCode;
    UserChannel: D365ChannelCode;
    constructor() {
        this.Filtri = new MonitoraggioFiltri();
        this.Obfuscate = false;
    }
}

export class MonitoraggioFiltri {
    skip: number;
    take: number;
    status: string[];
    dateFilter: 'signDate' | 'createDate' | '';
    dateFrom: string;
    dateTo: string;
    sortByField: string;
    sortByType: 'ASC' | 'DESC';
    generic: SearchOrderFields;
    salesProcess: AptSalesProcess;
    type: string;
    subType?: string;
    commodityType: FilterCommodityType;
}

export class SearchOrderFields {
    key: string;
    value: string;
}
