import { BaseApexApiResponse } from '../../../common/interfaces/base-apex-api-respose';

export class CombinedSalesItemsResponse extends BaseApexApiResponse {
    items: {
        combinedSaleItem: {
            lineItem?: string;
            product: {
                attributes?: {
                    type: string;
                    url: string;
                };
                Name: string;
                ProductCode: string;
                Id?: String;
            };
            podpdr?: string;
        };
    }[];
}
