import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ApiService } from '../services/shared/api.service';
import { EgonRequestCity, EgonRequestZip } from '../models/egon/egon-request';
import { CNL, EgonResponseCity, EgonResponseNumber, EgonResponseZip, ZIP } from '../models/egon/egon-response';
import { EgonNormalizedResponse } from '../models/egon/egon-normalization-response';
import { Observable, of } from 'rxjs';
import { ApiMngApi, BaseProvider } from './base-provider';
import { PrivateConfigurationService } from '../services/shared/private-configuration.service';
import { cleanObj } from '../functions/misc.functions';
import { EgonRequest } from '../../modules/switch-in/order-entry/models/egon-request';
import { LoggerService } from '../services/shared/logger.service';
import { getCivicAndSuffix, getCountryIso2166Alpha3, getNumberOrNull } from '../functions/remap.functions';
import { Address } from '../../store/models/order-entry-state_v2';
import { address2Egon, getCivicSuffixOrNull, getFullAddressString } from '../functions/address.functions';
import { isAddress } from '../functions/verifications.functions';
import { Regex } from '../config/regex';
import { getCivicSuffix, getCompleteCivic } from '../functions/string-format.functions';
import { ServiceError } from '../models/app/service-error';
import { EgonMode } from '../directives/tiger-egon/tiger-egon.types';

@Injectable()
export class EgonProvider extends BaseProvider {
    constructor(
        private api: ApiService,
        protected configSrv: PrivateConfigurationService,
        private logger: LoggerService,
        translateSrv: TranslateService
    ) {
        super(configSrv, translateSrv);
    }

    private readonly SUGGEST_CACHE: { [key: string]: Observable<EgonRequest[]> } = {};
    private readonly SEARCH_BY_CACHE: { [key: string]: Observable<EgonCivicAutocomplete[]> } = {};
    private readonly NORM_CACHE: { [key: string]: Observable<EgonNormalizedResponse> } = {};

    suggest(query: string, type: string, onlyIta: boolean = true): Observable<EgonRequest[]> {
        const request = `Suggest${type.charAt(0).toUpperCase()}${type.slice(1)}`;
        const params = {
            query: query,
            ...(onlyIta && {
                restrict_level: 'country',
                restrict_id: '38000000001', // egon id to country = ITA
            }),
        };

        const cacheKey = (JSON.stringify(params) + request).toUpperCase();

        const call =
            this.SUGGEST_CACHE[cacheKey] ||
            this.api.getAsync<EgonRequest[]>(this.getApiMngApiUrl(ApiMngApi[request]), params).pipe(
                catchError((err: Error) => {
                    this.logger.error(err?.message, err?.name, false);
                    return of([]);
                })
            );

        this.SUGGEST_CACHE[cacheKey] = call;

        return call;
    }

    searchByZip(zipCode: string): Observable<ZIP[]> {
        const params = new EgonRequestZip(zipCode);
        return this.api.getAsync<EgonResponseZip>(this.getApiMngApiUrl(ApiMngApi.SearchByZip), params).pipe(
            map((res) => res?.ZIP_AREA_OUT?.ZIP),
            catchError((err: Error) => {
                this.logger.error(err?.message, err?.name, false);
                return of([]);
            })
        );
    }

    searchByCity(city: string): Observable<CNL[]> {
        const params = new EgonRequestCity(city);
        return this.api.getAsync<EgonResponseCity>(this.getApiMngApiUrl(ApiMngApi.SearchByCity), params).pipe(
            map((res) => res?.CNL_AREA_OUT?.CNL),
            catchError((err: Error) => {
                this.logger.error(err?.message, err?.name, false);
                return of([]);
            })
        );
    }

    searchByStreetNumber(completeCivic: number | string, streetCode: number): Observable<EgonCivicAutocomplete[]>;
    searchByStreetNumber(address: Address): Observable<EgonCivicAutocomplete[]>;
    searchByStreetNumber(
        numberOrAddress: number | string | Address,
        streetCode?: number
    ): Observable<EgonCivicAutocomplete[]> {
        const address = isAddress(numberOrAddress)
            ? numberOrAddress
            : ({
                  ...getCivicAndSuffix(numberOrAddress),
                  streetEgonCode: streetCode,
              } as Partial<Address>);
        const params = cleanObj({
            LST: 'LstCiv',
            NR9CND: 100, //Limite di elementi (civici) tornati dalla chiamata ad Egon
            CDPOBJ: address.streetEgonCode, //Id della strada
            WPXISO: address.iso3166Alpha3 || getCountryIso2166Alpha3(address?.country) || 'ITA',
            NRPNUMCIV: getNumberOrNull(address?.civic),
            FLXSYN: 1, //Permette di estrarre solo i civici certificati
            /* ...address2Egon(
                isAddress(numberOrAddress)
                    ? numberOrAddress
                    : {
                          ...getCivicAndSuffix(numberOrAddress),
                          streetEgonCode: streetCode,
                      }
            ),  */
        });

        const cacheKey = JSON.stringify(params).toUpperCase();

        const call =
            this.SEARCH_BY_CACHE[cacheKey] ||
            this.api
                .getAsync<EgonResponseNumber>(this.getApiMngApiUrl(ApiMngApi.SearchByStreetNumber), cleanObj(params))
                .pipe(
                    map((res) =>
                        cleanObj(res?.CIV_AREA_OUT?.CIV || [])
                            .map((civ) =>
                                (civ?.NRPNUMCIV?.lValue || civ?.DSXESP) &&
                                !(!civ?.NRPNUMCIV?.lValue && (getNumberOrNull(civ?.DSXESP) || '') + '' === civ?.DSXESP)
                                    ? {
                                          id: civ?.CDPOBJCIV?.lValue,
                                          civic: civ?.NRPNUMCIV?.lValue?.toString(),
                                          subCivic: civ?.DSXESP,
                                      }
                                    : null
                            )
                            .filter(Boolean)
                    ),
                    catchError((err: Error) => {
                        this.logger.error(err?.message, err?.name, false);
                        return of([]);
                    })
                );

        this.SEARCH_BY_CACHE[cacheKey] = call;

        return call;
    }

    private egonBaseNormalize(
        egonRequest: EgonRequest,
        ignoreError: boolean = false,
        mode: EgonMode = null
    ): Observable<EgonNormalizedResponse> {
        const civicNumber = getNumberOrNull(egonRequest?.number);
        const civicSuffix = getCivicSuffixOrNull(
            getCivicSuffix(getCompleteCivic({ civic: egonRequest?.number, civicSuffix: egonRequest?.exponent }))
        );
        const params = cleanObj({
            DSXDS1: egonRequest?.district1 || null,
            CDXISO: egonRequest?.iso3 || getCountryIso2166Alpha3(egonRequest?.country) || 'ITA',
            DSXDPT: egonRequest?.province,
            DSXVIA: egonRequest?.street || egonRequest?.fullAddress, // VIA, puo contenere indirizzo completo di civico
            NRPNUMCIV: civicNumber ? Math.min(Math.max(civicNumber, 1), 65535) : undefined,
            DSXESP: civicSuffix,
            DSXCNL: egonRequest?.city,
            CDXZIP: !Regex.EXPIRED_CAP.test(egonRequest?.zipcode) ? egonRequest?.zipcode : undefined,
            ...(!egonRequest?.city && { DSXREG: egonRequest?.region }), // REGIONE, non serve passare la regione se c'è il comune
        });

        if (!params.DSXVIA) {
            return of(null);
        }

        const cacheKey = JSON.stringify(params).toUpperCase();

        const call =
            this.NORM_CACHE[cacheKey] ||
            this.api.getAsync<EgonNormalizedResponse>(this.getApiMngApiUrl(ApiMngApi.Normalize), cleanObj(params)).pipe(
                tap((response) => {
                    //Ignoriamo gli errori di validazione sul civico dal momento che quando selezioniamo un valore dall'autocomplete l'indirizzo non viene passato (a meno che non lo specifichiamo nell'input)
                    //MA se il civico esiste allora tengo in considerazione anche gli errori civico
                    const egonError = (response?.SOG?.[0]?.SEG?.ERR_620_ELE?.ERR_620 || []).find(
                        (err) =>
                            (err?.CDPSEG?.lValue > 0 && err?.CDPSEG?.lValue < 500) ||
                            //804 => COMPLEMENTO ERRATO (Suffisso errato)
                            //811 => DUG ERRATA
                            err?.CDPSEG?.lValue === 804 ||
                            err?.CDPSEG?.lValue === 811 ||
                            ((civicNumber || mode === 'fullAddress') &&
                                err?.CDPSEG?.lValue > 500 &&
                                err?.CDPSEG?.lValue <= 506)
                    );
                    if (!ignoreError) {
                        if (response?.ERR?.DSXERR && !/ok/i.test(response?.ERR?.DSXERR)) {
                            throw new ServiceError(null, response?.ERR?.DSXERR || 'Egon error occurred', 'HIGH');
                        }
                        if (egonError) {
                            throw new ServiceError(
                                egonError?.CDPSEG?.lValue + '',
                                egonError?.DSXSEG || 'GENERIC_ERROR',
                                'LOW'
                            );
                        }
                    } else if (ignoreError && egonError) {
                        throw new ServiceError(null, 'Egon error occurred');
                    }
                })
            );

        this.NORM_CACHE[cacheKey] = call;

        return call;
    }

    egonNormalize(
        egonRequest: EgonRequest,
        ignoreError: boolean = false,
        mode: EgonMode = null
    ): Observable<EgonNormalizedResponse> {
        return this.egonBaseNormalize(egonRequest, ignoreError, mode).pipe(
            catchError((err: ServiceError) => {
                if (!ignoreError && err?.code === '325' && egonRequest?.id) {
                    return this.api
                        .getAsync<EgonRequest[]>(this.getApiMngApiUrl(ApiMngApi.SuggestStreet), {
                            restrict_id: egonRequest?.id,
                            level: 'street',
                            query: getFullAddressString(egonRequest),
                        })
                        .pipe(
                            map(
                                (results) =>
                                    ((results || []).find((result) => result?.id === egonRequest?.id) || results?.[0])
                                        ?.district1
                            ),
                            tap((district) => {
                                if (!district) {
                                    throw err;
                                }
                            }),
                            mergeMap((district1) =>
                                this.egonBaseNormalize({
                                    ...egonRequest,
                                    district1,
                                })
                            )
                        );
                } else if (err?.code === '327' && egonRequest?.id) {
                    this.logger.error(null, this.translateSrv.instant('ERROR.EGON.MULTICAP_ERROR'), err, true);
                } else if (err?.code === '502' && egonRequest?.id) {
                    this.logger.error(null, this.translateSrv.instant('ERROR.EGON.CIVIC_NOT_CERTIFIED'), err, true);
                } else if (err?.code === '501' && egonRequest?.id) {
                    this.logger.error(null, this.translateSrv.instant('ERROR.EGON.INVALID_CIVIC'), err, true);
                }
                throw err;
            }),
            catchError((err: Error) => {
                ServiceError.isServiceError(err) && err?.level === 'LOW'
                    ? this.logger.warn('Egon ' + err?.message || 'Egon error occurred', mode === 'fullAddress')
                    : this.logger.error('Egon', err?.message || 'Egon error occurred', err, mode === 'fullAddress');

                throw err;
            })
        );
    }
}

export interface EgonCivicAutocomplete {
    id: number;
    civic: string;
    subCivic?: string;
}
