import { AField, AObject, ATable } from '@congacommerce/core';
import { Type } from 'class-transformer';
import { EglProductLight } from '../product/egl-product-light';
import { EglCartPAVLight } from '../pav/egl-cart-p-a-v-light';
import { EglAddress } from '../address/egl-address';
import { EglAccountLocationLight } from '../user/egl-account-location-light';
import { AptLineStatus } from '../../../../enums/apttus/apt-line-status';
import { AptLineType } from '../../../../enums/apttus/apt-line-type';
import { AptPricingStatus } from '../../../../enums/apttus/apt-pricing-status';
import { EglProductOptionComponent } from '../product/egl-product-option-component';

@ATable({
    sobjectName: 'Apttus_Config2__LineItem__c',
    aqlName: null,
})
export class EglCartItemLight extends AObject {
    constructor() {
        super();
        this.Product = null;
        this.IsPrimaryLine = null;
        this.AttributeValue = null;
        this.Product = null;
    }

    @AField({ soql: 'IsPrimaryLine' })
    IsPrimaryLine: boolean;

    @AField({ soql: 'Apttus_Config2__LocationId__r', expand: 'deep' })
    @Type(() => EglAccountLocationLight)
    Location: EglAccountLocationLight;

    @AField({
        soql: 'Apttus_Config2__AttributeValueId__r',
        expand: 'shallow',
    })
    @Type(() => EglCartPAVLight)
    AttributeValue: EglCartPAVLight;

    @AField({ soql: 'LineStatus' })
    LineStatus: AptLineStatus;

    @AField({ soql: 'LineType' })
    LineType: AptLineType;

    @AField({ soql: 'ParentBundleNumber' })
    ParentBundleNumber: number;

    @AField({ soql: 'LineNumber' })
    LineNumber: number;

    @AField({ soql: 'StartDate' })
    StartDate: string;

    @AField({
        soql: 'Apttus_Config2__ProductId__r',
        expand: 'shallow',
    })
    @Type(() => EglProductLight)
    Product: EglProductLight;

    @AField({
        soql: 'egl_service_address_id__r',
        expand: 'shallow',
    })
    @Type(() => EglAddress)
    egl_service_address: EglAddress;

    @AField({
        soql: 'egl_shipment_address_id__r',
        expand: 'shallow',
    })
    @Type(() => EglAddress)
    egl_shipment_address: EglAddress; // indirizzo di fornitura

    @AField({
        soql: 'Apttus_Config2__OptionId__r',
        expand: 'shallow',
    })
    @Type(() => EglProductLight)
    Option: EglProductLight;

    @AField({ soql: 'egl_supply_code' })
    egl_supply_code: string;

    @AField({ soql: 'PricingStatus' })
    PricingStatus: AptPricingStatus;

    @AField({ soql: 'Apttus_Config2__ProductId__c' })
    productId: string;

    @AField({ soql: 'Apttus_Config2__NetPrice__c ' })
    netPrice: number;

    @AField({ soql: 'egl_brick_type__c ' })
    egl_brick_type: string;

    @AField({ soql: 'egl_brick_subtype__c ' })
    egl_brick_subtype: string;

    @AField({ soql: 'egl_is_power_for_outbuilding__c' })
    egl_is_power_for_outbuilding: boolean;

    @AField({ soql: 'Apttus_Config2__Quantity__c' })
    Quantity: number;

    @AField({ soql: 'NetPrice' })
    NetPrice: number;

    @AField({ soql: 'ListPrice' })
    ListPrice: number;

    @AField({ soql: 'ProductOption' })
    ProductOption: EglProductOptionComponent;

    @AField({ soql: 'PriceUom' })
    PriceUom: 'Percentuale' | 'Euro';

    @AField({ soql: 'egl_percentage_value__c' })
    egl_percentage_value: number;

    @AField({ soql: 'CurrencyIsoCode' })
    CurrencyIsoCode: string;

    @AField({ soql: 'Account__c' })
    paymentDeposit: string;

    @AField({ soql: 'egl_ItemGeneratedByRule__c' })
    egl_ItemGeneratedByRule: string;
}
