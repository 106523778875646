import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'egl-radiobutton-highlighted',
    templateUrl: './egl-radiobutton-highlighted.component.html',
    styleUrls: ['./egl-radiobutton-highlighted.component.scss'],
})
export class EglRadiobuttonHighlightedComponent {
    @Input() label: string;
    @Input() value: any;
    @Input() checked: boolean;
    @Input() disabled: boolean;
    @Input() layout: string;
    @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

    onClick(event: any): void {
        this.onSelect.emit(this.value);
        //this.checked = true;
    }
}
