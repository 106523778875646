import { Injectable } from '@angular/core';
import { AppInsightsService } from './app-insights.service';
import { ToastrService } from 'ngx-toastr';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { sanitizeLogMessage } from '../../functions/string-format.functions';
const DEFAULT_PREFIX = '[EGL]';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    constructor(private appInsightsSrv: AppInsightsService, private injector: Injector) {}

    debug(msg: string, ...optionalParams: any[]) {
        msg = sanitizeLogMessage(msg);
        optionalParams = this.sanitizeOptParams(optionalParams);
        console.info(this.getFormattedMsg(msg), ...optionalParams);
        this.appInsightsSrv.logTrace(
            { message: this.getAIMessage(msg), severityLevel: SeverityLevel.Verbose },
            optionalParams
        );
    }

    info(msg: string, ...optionalParams: any[]): void {
        msg = sanitizeLogMessage(msg);
        optionalParams = this.sanitizeOptParams(optionalParams);
        console.info(this.getFormattedMsg(msg), ...optionalParams);
        this.appInsightsSrv.logTrace(
            { message: this.getAIMessage(msg), severityLevel: SeverityLevel.Information },
            optionalParams
        );
    }

    toastInfo(msg: string, ...optionalParams: any[]) {
        msg = sanitizeLogMessage(msg);
        this.info(msg, ...optionalParams);
        if (this.toastrSrv) {
            const title = this.translateSrv.instant('COMMON.INFO_ALERT');
            this.toastrSrv.info(msg, title);
        }
    }

    toastSuccess(title: string, msg: string, ...optionalParams: any[]): void {
        msg = sanitizeLogMessage(msg);
        optionalParams = this.sanitizeOptParams(optionalParams);
        console.info(this.getFormattedMsg(msg), ...optionalParams);
        this.appInsightsSrv.logTrace({ message: msg, severityLevel: SeverityLevel.Information }, optionalParams);
        if (this.toastrSrv) {
            const toastTitle = title || this.translateSrv.instant('COMMON.INFO_ALERT');
            this.toastrSrv.success(msg, toastTitle);
        }
    }

    toastError(msg: string, title?: string, enableHtml?: boolean) {
        if (this.toastrSrv) {
            this.toastrSrv.error(msg, title || 'Attenzione', { enableHtml: !!enableHtml });
        }
    }

    warn(msg: string, showAlert?: boolean, ...optionalParams: any[]): void {
        msg = sanitizeLogMessage(msg);
        optionalParams = this.sanitizeOptParams(optionalParams);
        console.warn(this.getFormattedMsg(msg), ...optionalParams);
        this.appInsightsSrv.logTrace(
            { message: this.getAIMessage(msg), severityLevel: SeverityLevel.Warning },
            optionalParams
        );
        if (showAlert && this.toastrSrv) {
            const title = this.translateSrv.instant('COMMON.INFO_ALERT');
            this.toastrSrv.warning(msg, title);
        }
    }

    error(title?: string, msg?: string, error?: any, showAlert?: boolean, ...optionalParams: any[]): void {
        title = sanitizeLogMessage(title);
        msg = sanitizeLogMessage(msg);
        optionalParams = this.sanitizeOptParams(optionalParams);
        title = title || 'Attenzione';
        msg = msg || 'Si è verificato un errore';
        let fullMsg = `${title}: ${msg}`;
        const logParams = optionalParams && error ? optionalParams.concat(error) : optionalParams || error;
        console.error(this.getFormattedMsg(fullMsg), ...logParams);
        const errorMessage = typeof error === 'string' ? error : error?.message;

        fullMsg +=
            error?.status || errorMessage
                ? ` ERROR STATUS: ${error?.status || 'null'} - MESSAGE: ${errorMessage}`
                : error
                ? ` - ${error}`
                : '';
        this.appInsightsSrv.logTrace(
            { message: this.getAIMessage(fullMsg), severityLevel: SeverityLevel.Error },
            logParams
        );
        if (showAlert && this.toastrSrv) {
            this.toastrSrv.error(msg, title);
        }
    }

    private getFormattedMsg(msg: string): string {
        // use defaultPrefix only if message string not start with prefix [...]
        return /^\[[A-Z0-9]+\] /.test(msg || '') ? msg : `${DEFAULT_PREFIX} ${msg}`;
    }

    private sanitizeOptParams(params: any[]): any[] {
        if (!params) {
            return params;
        }
        return params.map((p) => (typeof p === 'string' ? sanitizeLogMessage(p) : p));
    }

    private getAIMessage(message: string): string {
        return `[SUP] ${message}`;
    }

    private get toastrSrv(): ToastrService {
        return this.injector.get(ToastrService);
    }

    public get translateSrv(): TranslateService {
        return this.injector.get(TranslateService);
    }
}
