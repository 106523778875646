import { NgModule } from '@angular/core';
import { EglAssetLineItemService } from '../../common/services/apttus/tables/asset/egl-assetline-item.service';
import { AssetLoaderResolver } from './resolvers/asset-loader.resolver';
import { TarantulaResolver } from './resolvers/tarantula.resolver';
import { DataLoaderService } from './services/data-loader.service';

@NgModule({
    declarations: [],
    providers: [AssetLoaderResolver, TarantulaResolver, EglAssetLineItemService, DataLoaderService],
})
export class DataLoaderModule {}
