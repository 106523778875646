import { AptSalesProcess } from '../../../../common/enums/apttus/apt-sales-process';
import { BaseTibcoRequest } from './tibco-base-request';

export class CheckPdfApttusRequest extends BaseTibcoRequest {
    AssetId?: string;
    SalesProcess: AptSalesProcess;
    PodPdr: string;

    constructor(podPdr: string, salesProcess: AptSalesProcess) {
        super();
        this.PodPdr = podPdr;
        this.SalesProcess = salesProcess;
    }
}
