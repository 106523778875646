import { FlowType } from '../../store/models/flow-type';

export const FLOW_TYPE_LABELS_MAP: {
    [key in FlowType]: string;
} = {
    [FlowType.SwitchIn]: 'OPERATIVE_MODES.SWITCH_IN',
    [FlowType.SwitchInAmmVip]: 'OPERATIVE_MODES.SWITCH_IN_AMM_VIP',
    [FlowType.SwitchInAmmRecupero2A]: 'OPERATIVE_MODES.SWITCH_IN_AMM_RECUPERO',
    [FlowType.VolturaCambioFornitore]: 'OPERATIVE_MODES.SWITCH_IN_VOLTURA',
    [FlowType.SwitchInAmmDelibera153]: 'OPERATIVE_MODES.SWITCH_IN_AMM_DEL153',
    [FlowType.SwitchInAmmRecupero2B]: 'OPERATIVE_MODES.SWITCH_IN_AMM_RECUPERO',
    [FlowType.SwitchInAmmRecupero2C]: 'OPERATIVE_MODES.SWITCH_IN_AMM_RECUPERO',

    [FlowType.CPCommerciale]: 'OPERATIVE_MODES.CHANGE_PROD_COMMERCIAL',
    [FlowType.CPDelibera]: 'OPERATIVE_MODES.CHANGE_PROD_DELIBERATE',
    [FlowType.CPAmministrativo]: 'OPERATIVE_MODES.CHANGE_PROD_RETROACTIVE',

    [FlowType.CPRetroattivoExNovo]: 'OPERATIVE_MODES.CHANGE_PROD_EXNOVO',
    [FlowType.CPRetroattivoAssetCessato]: 'OPERATIVE_MODES.CHANGE_PROD_CESSATO',

    [FlowType.DomiciliazioneAttivazione]: 'OPERATIVE_MODES.DOMICILIATION_ATT',
    [FlowType.DomiciliazioneAttivazioneInserimentoPaymentTool]: 'OPERATIVE_MODES.DOMICILIATION_ATT',
    [FlowType.DomiciliazioneModifica]: 'OPERATIVE_MODES.DOMICILIATION_MOD',
    [FlowType.DomiciliazioneModificaInserimentoPaymentTool]: 'OPERATIVE_MODES.DOMICILIATION_MOD',
    [FlowType.DomiciliazioneRevoca]: 'OPERATIVE_MODES.DOMICILIATION_REV',
    [FlowType.Cessazione]: 'OPERATIVE_MODES.TERMINATION',
    [FlowType.CessazioneAmministrativa]: 'OPERATIVE_MODES.ADMINISTRATIVE_TERMINATION',
    [FlowType.Sospensione]: 'OPERATIVE_MODES.SUSPENSION',
    [FlowType.SospensioneAmministrativa]: 'OPERATIVE_MODES.SUSPENSION_ADMIN',
    [FlowType.RevocaSospensione]: 'OPERATIVE_MODES.SUSPENSION_CANCEL',
    [FlowType.VariazioneCommerciale]: 'OPERATIVE_MODES.VAR_COMM',
    [FlowType.ScontoStandalone]: 'OPERATIVE_MODES.SCONTO_STANDALONE',
    [FlowType.Interruzione]: 'OPERATIVE_MODES.INTERRUPTION',
    [FlowType.InterruzioneAmministrativa]: 'OPERATIVE_MODES.INTERRUPTION_ADMIN',
    [FlowType.RevocaInterruzione]: 'OPERATIVE_MODES.INTERRUPTION_CANCEL',
    [FlowType.Voltura]: 'OPERATIVE_MODES.VOLTURA',
    [FlowType.VolturaAmmVip]: 'OPERATIVE_MODES.VOLTURA_AMM_VIP',
    [FlowType.VolturaAmmSiProvisioning]: 'OPERATIVE_MODES.VOLTURA_AMM_SI_PROVISIONING',
    [FlowType.VolturaAmmNoProvisioning]: 'OPERATIVE_MODES.VOLTURA_AMM_NO_PROVISIONING',
    [FlowType.VolturaAmmIncorporazioneSocietaria]: 'OPERATIVE_MODES.VOLTURA_AMM_INCORPORAZIONE_SOCIETARIA',
    [FlowType.Attivazione]: 'OPERATIVE_MODES.ACTIVATION',
    [FlowType.AttivazioneGas]: 'OPERATIVE_MODES.ACTIVATION_GAS',
    [FlowType.AttivazionePwrS01]: 'OPERATIVE_MODES.ACTIVATION_PWR',
    [FlowType.AttivazioneAmmRecuperoS01]: 'OPERATIVE_MODES.ACTIVATION_AMM_PWR',
    [FlowType.AttivazioneAmmRecuperoA01]: 'OPERATIVE_MODES.ACTIVATION_AMM_GAS',
    [FlowType.AttivazioneAmmRecupero]: 'OPERATIVE_MODES.ACTIVATION_AMM',
    [FlowType.AttivazioneAmministrativa]: 'OPERATIVE_MODES.ATTIVAZIONE_AMM',
    [FlowType.AttivazioneAmmGasA01]: 'OPERATIVE_MODES.ATTIVAZIONE_SEMP_AMM_GAS',
    [FlowType.AttivazioneAmmPwrS01]: 'OPERATIVE_MODES.SUBENTRO_AMM_PWR',
    [FlowType.AttivazioneAmmVip]: 'OPERATIVE_MODES.ACTIVATION_VIP',
    [FlowType.AttivazioneAmmVipGasA01]: 'OPERATIVE_MODES.ACTIVATION_GAS_VIP',
    [FlowType.AttivazioneAmmVipPowerS01]: 'OPERATIVE_MODES.ACTIVATION_PWR_VIP',
    [FlowType.AttivazionePwrAmmA01]: 'OPERATIVE_MODES.ACTIVATION_COMP_A01_AMM',
    [FlowType.AttivazioneAmmN02]: 'OPERATIVE_MODES.ACTIVATION_COMP_N02_AMM',
    [FlowType.AttivazioneAmmA40]: 'OPERATIVE_MODES.ATTIVAZIONE_CON_ACCERTAMENTO_AMM',
    [FlowType.AttivazioneAmmPN1]: 'OPERATIVE_MODES.PREVENTIVO_E_ATTIVAZIONE_AMM',
    [FlowType.AttivazionePwrVipA01]: 'OPERATIVE_MODES.ACTIVATION_COMP_A01_VIP',
    [FlowType.AttivazioneVipN02]: 'OPERATIVE_MODES.ACTIVATION_COMP_N02_VIP',
    [FlowType.AttivazioneVipA40]: 'OPERATIVE_MODES.ATTIVAZIONE_CON_ACCERTAMENTO_VIP',
    [FlowType.AttivazioneVipPN1]: 'OPERATIVE_MODES.PREVENTIVO_E_ATTIVAZIONE_VIP',

    [FlowType.AttivazionePwrA01]: 'OPERATIVE_MODES.ATTIVAZIONE_CON_ACCERTAMENTO',
    [FlowType.AttivazioneN02]: 'OPERATIVE_MODES.PREVENTIVO_E_ATTIVAZIONE',

    [FlowType.Riapertura]: 'OPERATIVE_MODES.RESUMING',
    [FlowType.RiaperturaAmministrativa]: 'OPERATIVE_MODES.RESUMING_ADMIN',
    [FlowType.RevocaRiapertura]: 'OPERATIVE_MODES.RESUMING_CANCEL',
    [FlowType.AttivazioneA40]: 'OPERATIVE_MODES.ATTIVAZIONE_CON_ACCERTAMENTO',
    [FlowType.AttivazionePN1]: 'OPERATIVE_MODES.PREVENTIVO_E_ATTIVAZIONE',

    [FlowType.VariazioneTecnicaVerifiche]: 'OPERATIVE_MODES.VARIAZIONE_TECNICA_VERIFICHE',
    [FlowType.VariazioneTecnicaVerificheAmministrativa]: 'OPERATIVE_MODES.VARIAZIONE_TECNICA_VERIFICHE_AMMINISTRATIVA',

    [FlowType.VariazioneTecnicaLavori]: 'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI',
    [FlowType.VariazioneTecnicaLavoriAmministrativa]: 'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI_AMMINISTRATIVA',

    [FlowType.VariazioneTecnicaLavoriDiminuzionePotenza]:
        'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI_DIMINUZIONE_POTENZA',
    [FlowType.VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa]:
        'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI_DIMINUZIONE_POTENZA_AMMINISTRATIVA',

    [FlowType.VariazioneTecnicaLavoriPreventivo]: 'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI_PREVENTIVO',
    [FlowType.VariazioneTecnicaLavoriPreventivoAmministrativa]:
        'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_AMMINISTRATIVA',

    [FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza]:
        'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_AUMENTO_POTENZA',
    [FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa]:
        'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_AUMENTO_POTENZA_AMMINISTRATIVA',

    [FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasi]:
        'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_VARIAZIONE_FASI',
    [FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasiAmministrativa]:
        'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_VARIAZIONE_FASI_AMMINISTRATIVA',

    [FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensione]:
        'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_VARIAZIONE_TENSIONE',
    [FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensioneAmministrativa]:
        'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_VARIAZIONE_TENSIONE_AMMINISTRATIVA',

    [FlowType.VariazioneTecnicaLavoriPreventivoModificaImpianto]:
        'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_MODIFICA_IMPIANTO',
    [FlowType.VariazioneTecnicaLavoriPreventivoModificaImpiantoAmministrativa]:
        'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI_PREVENTIVO_MODIFICA_IMPIANTO_AMMINISTRATIVA',

    [FlowType.VariazioneAteco]: 'OPERATIVE_MODES.MODIFICA_PARAMETRI_FORNITURA',
    [FlowType.VariazioneTipologiaFornituraGas]: 'OPERATIVE_MODES.MODIFICA_PARAMETRI_FORNITURA',
    [FlowType.VariazioneIndirizzo]: 'OPERATIVE_MODES.MODIFICA_INDIRIZZO_FORNITURA',
    [FlowType.AllineaTipologiaUtilizzoDistributore]: 'OPERATIVE_MODES.ALLINEA_TIPOLOGIA_UTILIZZO_DISTRIBUTORE',

    [FlowType.CA1]: 'OPERATIVE_MODES.CA1',
    [FlowType.CA1AMM]: 'OPERATIVE_MODES.CA1_AMMINISTRATIVA',
    [FlowType.CA2]: 'OPERATIVE_MODES.CA2',
    [FlowType.CA2AMM]: 'OPERATIVE_MODES.CA2_AMMINISTRATIVA',
    [FlowType.CA3]: 'OPERATIVE_MODES.CA3',
    [FlowType.CA3AMM]: 'OPERATIVE_MODES.CA3_AMMINISTRATIVA',
    [FlowType.CA4]: 'OPERATIVE_MODES.CA4',
    [FlowType.CA4AMM]: 'OPERATIVE_MODES.CA4_AMMINISTRATIVA',
    [FlowType.CA5]: 'OPERATIVE_MODES.CA5',
    [FlowType.CA5AMM]: 'OPERATIVE_MODES.CA5_AMMINISTRATIVA',
    [FlowType.CA7]: 'OPERATIVE_MODES.CA7_MOTIVI_DIVERSI',
    [FlowType.CA7AMM]: 'OPERATIVE_MODES.CA7_MOTIVI_DIVERSI_AMMINISTRATIVA',
    [FlowType.CAE1]: 'OPERATIVE_MODES.CAE1',
    [FlowType.CAE1AMM]: 'OPERATIVE_MODES.CAE1_AMMINISTRATIVA',
    [FlowType.CAE3]: 'OPERATIVE_MODES.CAE3',
    [FlowType.CAE3AMM]: 'OPERATIVE_MODES.CAE3_AMMINISTRATIVA',
    [FlowType.CAE4]: 'OPERATIVE_MODES.CAE4',
    [FlowType.CAE4AMM]: 'OPERATIVE_MODES.CAE4_AMMINISTRATIVA',
    [FlowType.CAE5]: 'OPERATIVE_MODES.CAE5',
    [FlowType.CAE5AMM]: 'OPERATIVE_MODES.CAE5_AMMINISTRATIVA',
    [FlowType.CAE7]: 'OPERATIVE_MODES.CAE7',
    [FlowType.CAE7AMM]: 'OPERATIVE_MODES.CAE7_AMMINISTRATIVA',
    [FlowType.CAE8]: 'OPERATIVE_MODES.CAE8_MOTIVI_DIVERSI',
    [FlowType.CAE8AMM]: 'OPERATIVE_MODES.CAE8_MOTIVI_DIVERSI_AMMINISTRATIVA',
    [FlowType.CAE8RIFVOLT]: 'OPERATIVE_MODES.CAE8_RIFIUTO_VOLTURA',
    [FlowType.CAE8RIFVOLTAMM]: 'OPERATIVE_MODES.CAE8RIFVOLT_RIFIUTO_VOLTURA_AMMINISTRATIVA',
    [FlowType.VolturaCambioFornitoreAmmRecupero2A]: 'OPERATIVE_MODES.VOLTURA_CAMBIO_FORNITORE_AMMINISTRATIVO',
    [FlowType.VolturaCambioFornitoreAmmRecupero2B]: 'OPERATIVE_MODES.VOLTURA_CAMBIO_FORNITORE_AMMINISTRATIVO',
    [FlowType.VolturaCambioFornitoreAmmRecupero2C]: 'OPERATIVE_MODES.VOLTURA_CAMBIO_FORNITORE_AMMINISTRATIVO',
    [FlowType.VolturaCambioFornitoreVip]: 'OPERATIVE_MODES.VOLTURA_CAMBIO_FORNITORE_VIP',

    [FlowType.Aggrega]: 'OPERATIVE_MODES.AGGREGATION',
    [FlowType.Disaggrega]: 'OPERATIVE_MODES.DISAGGREGATION',
    [FlowType.CambioTipologiaFornitura]: 'OPERATIVE_MODES.CAMBIO_TIPOLOGIA_FORNITURA',

    [FlowType.AnnullamentoSospensione]: 'OPERATIVE_MODES.ANNULLAMENTO_SOSPENSIONE',
    [FlowType.AnnullamentoInterruzione]: 'OPERATIVE_MODES.ANNULLAMENTO_INTERRUZIONE',
    [FlowType.AnnullamentoRiapertura]: 'OPERATIVE_MODES.ANNULLAMENTO_RIAPERTURA',
    [FlowType.RevocaRisoluzioneContrattuale]: 'OPERATIVE_MODES.REVOCA_RISOLUZIONE_CONTRATTUALE',
    [FlowType.RevocaAltriProcessi]: 'OPERATIVE_MODES.REVOCA_ALTRI',
    [FlowType.ExtraCommoditySale]: 'OPERATIVE_MODES.EXTRACOMMODITY',
    [FlowType.CessazioneExtracommodity]: 'OPERATIVE_MODES.CESSAZIONE_EXTRACOMMODITY',
    [FlowType.DisattivazioneCdC]: 'OPERATIVE_MODES.DISATTIVAZIONE_CDC',
    [FlowType.AggiungiModificaPannello]: 'OPERATIVE_MODES.AGGIUNGI_MODIFICA_PANNELLO',
    [FlowType.RimuoviPannello]: 'OPERATIVE_MODES.RIMUOVI_PANNELLO',
};
