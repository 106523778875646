export class ReplaceAttachmentRequest {
    File: File;
    JsonBody: {
        FileName: string;
        flowType: string;
        tipoAllegato: string;
        codiceRds: string;
        codicePlico: string;
        originalExtension: string;
        quoteId: string;
    };
}
