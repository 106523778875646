import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RoutesPaths } from '../config/routes-paths';
import { PrivateConfigurationService } from '../services/shared/private-configuration.service';
import { UtilityService } from '../services/shared/utility.service';

@Injectable()
export class MaintenanceGuard implements CanActivate {
    constructor(
        private configSrv: PrivateConfigurationService,
        private router: Router,
        private utilitySrv: UtilityService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.utilitySrv.isMaintenanceMode$().pipe(
            tap((isMaintenanceMode) => {
                if (isMaintenanceMode) {
                    this.router.navigate([RoutesPaths.Offline], {
                        queryParams: { message: this.configSrv.config?.offlineMessage },
                    });
                }
            }),
            map((isMaintenanceMode) => !isMaintenanceMode)
        );
    }
}
