import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { RoutesPaths } from '../../../config/routes-paths';
import { AgentInfo } from '../../../models/user/agent';
import { DragonRouterService } from '../../../services/shared/router/dragon-router.service';
import { filter, map } from 'rxjs/operators';
import { selectAgentInfo, selectCurrentVirtualAgent } from '../../../../store/selectors/user.selectors';
import { convertSegmentD365toApt } from '../../../functions/remap.functions';
import { EglState } from '../../../../store/reducers';

@Component({
    selector: 'egl-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    segment: string;
    initials: string;
    channel$: Observable<string>;

    constructor(private router: DragonRouterService, private store: Store<EglState>) {}

    ngOnInit(): void {
        this.mapProps();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    mapProps(): void {
        this.channel$ = this.store
            .select(selectCurrentVirtualAgent)
            .pipe(map((data) => data.VirtualAgency?.Channel?.Name));

        this.subscriptions.push(
            this.store
                .select(selectAgentInfo)
                .pipe(filter((x) => !!x?.Agent))
                .subscribe((x: AgentInfo) => {
                    this.segment = convertSegmentD365toApt(x.UserConfiguration.LastUsedCustomerSegment);
                    this.initials = this.setInitials(x.Agent.Name);
                })
        );
    }

    openSettings(): void {
        this.router.navigate([RoutesPaths.Settings]);
    }

    /**
     * Return initials of Name and Surname
     * @returns initials of Name and Surname of current agent
     */
    setInitials(completeName: string): string {
        if (!completeName) return '';
        const initialsArray: string[] = completeName.replace(/[^a-zA-Z- ]/g, '').match(/\b(\w)/g);
        return initialsArray.slice(0, 2).join('').toUpperCase();
    }
}
