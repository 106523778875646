/* tslint:disable:variable-name */
import { AField, AObject, ATable } from '@congacommerce/core';
import { AptPartnerships } from '../../../../../modules/common/partnership/enums/apt-partnerships';
import { AptBillType } from '../../../../enums/apttus/apt-bill-type';
import { AptCommodityType } from '../../../../enums/apttus/apt-commodity-typeof-sale';
import { HoursBundle } from '../../../../enums/apttus/apt-hours-bundle';
import { AptPaymentFrequency } from '../../../../enums/apttus/apt-payment-frequency';
import { AptPaymentInstrument } from '../../../../enums/apttus/apt-payment-instrument';
import { AptPaymentType } from '../../../../enums/apttus/apt-payment-type';

@ATable({
    sobjectName: 'Apttus_Config2__ProductAttributeValue__c',
})
export class EglCartPAVLight extends AObject {
    constructor() {
        super();
    }

    @AField({ soql: 'egl_Invoice_Shipping_Method__c' }) // modalita spedizione
    egl_Invoice_Shipping_Method__c: AptBillType = null;

    @AField({ soql: 'egl_Payment_Instrument__c' })
    egl_Payment_Instrument__c: AptPaymentInstrument = null;

    @AField({ soql: 'egl_IVASS_01__c' })
    egl_IVASS_01__c: string;

    @AField({ soql: 'egl_IVASS_02__c' })
    egl_IVASS_02__c: string;

    @AField({ soql: 'egl_IVASS_03__c' })
    egl_IVASS_03__c: string;

    @AField({ soql: 'egl_commodity_supplytype__c' })
    egl_commodity_supplytype__c: AptCommodityType;

    @AField({ soql: 'egl_Insurance_Type__c' })
    egl_Insurance_Type__c: AptCommodityType;

    @AField({ soql: 'egl_POD__c' })
    egl_POD__c: AptCommodityType;

    @AField({ soql: 'egl_PDR__c' })
    egl_PDR__c: AptCommodityType;

    @AField({ soql: 'egl_isresidential__c' })
    egl_isresidential__c: AptCommodityType;

    @AField({ soql: 'egl_selling_order_number__c' })
    egl_selling_order_number__c: string;

    @AField({ soql: 'egl_Place_Name__c' })
    egl_Place_Name__c: string;

    @AField({ soql: 'egl_Address__c' })
    egl_Address__c: string;

    @AField({ soql: 'egl_Civic_Number__c' })
    egl_Civic_Number__c: string;

    @AField({ soql: 'egl_City__c' })
    egl_City__c: string;

    @AField({ soql: 'egl_Province__c' })
    egl_Province__c: string;

    @AField({ soql: 'egl_Postal_Code__c' })
    egl_Postal_Code__c: string;

    @AField({ soql: 'egl_partnership__c' })
    egl_partnership__c: AptPartnerships;

    @AField({ soql: 'egl_Payment_Frequency__c' })
    egl_Payment_Frequency__c: AptPaymentFrequency;

    @AField({ soql: 'egl_Payment_Type__c' })
    egl_Payment_Type__c: AptPaymentType;

    @AField({ soql: 'egl_installment_duration__c' })
    egl_installment_duration__c: number;

    @AField({ soql: 'egl_combined_sale_insurance__c' })
    egl_combined_sale_insurance__c: 'No' | string;

    @AField({ soql: 'egl_combined_sale_insurance__c' })
    egl_green_option__c: 'Y' | 'N';

    @AField({ soql: 'egl_Hours_Bundle__c' }) // monoraria - bioraria
    egl_Hours_Bundle__c: HoursBundle;

    @AField({ soql: 'egl_Required_Power__c' })
    egl_Required_Power__c: string;

    @AField({ soql: 'egl_engaged_power_required__c' })
    egl_engaged_power_required__c: string;

    @AField({ soql: 'egl_predeterminate_estimate__c' })
    egl_predeterminate_estimate__c: string;

    @AField({ soql: 'egl_Available_Power__c' })
    egl_Available_Power__c: number;

    @AField({ soql: 'egl_available_power_required__c' })
    egl_available_power_required__c: string;

    @AField({ soql: 'egl_voltage_required__c' })
    egl_voltage_required__c: string;

    @AField({ soql: 'egl_voltage__c' }) // Usare ENUM
    egl_voltage__c: string;

    @AField({ soql: 'egl_num_of_phases_available__c' })
    egl_num_of_phases_available__c: string; // 'MONOFASE' | 'TRIFASE'

    @AField({ soql: 'egl_num_of_phases_required__c' })
    egl_num_of_phases_required__c: string; // 'MONOFASE' | 'TRIFASE'

    @AField({ soql: 'egl_contact_customer__c' })
    egl_contact_customer__c: string; // 'SI' | 'NO'

    @AField({ soql: 'egl_customer_request_date__c' })
    egl_customer_request_date__c: Date;

    @AField({ soql: 'egl_commodity_distributor__c' })
    egl_commodity_distributor__c: string;

    @AField({ soql: 'egl_supply_use__c' })
    egl_supply_use__c: string;

    @AField({ soql: 'egl_using_registration_tool__c' })
    egl_using_registration_tool__c: string;

    @AField({ soql: 'egl_gas_potential_consumption__c' })
    egl_gas_potential_consumption__c: string;

    @AField({ soql: 'egl_power_typesofusage__c' })
    egl_power_typesofusage__c: string;

    @AField({ soql: 'egl_Appliance_Brand__c' })
    egl_Appliance_Brand__c: string;

    @AField({ soql: 'egl_Appliance_type_01__c ' })
    egl_Appliance_type_01__c: string;

    @AField({ soql: 'egl_product_lastname__c ' })
    egl_product_lastname__c: string;

    @AField({ soql: 'egl_product_firstname__c ' })
    egl_product_firstname__c: string;

    @AField({ soql: 'egl_Floor__c' })
    egl_Floor__c: string;

    @AField({ soql: 'egl_Day_Of_Birth__c' })
    egl_Day_Of_Birth__c: string;

    @AField({ soql: 'egl_addebitoinbolletta__c,' })
    egl_addebitoinbolletta__c: string;

    @AField({ soql: 'egl_vulnerabilitytype_socialbonus__c,' })
    egl_vulnerabilitytype_socialbonus__c: boolean;

    @AField({ soql: 'egl_vulnerabilitytype_saemapre__c,' })
    egl_vulnerabilitytype_saemapre__c: boolean;

    @AField({ soql: 'egl_vulnerabilitytype_disabled__c,' })
    egl_vulnerabilitytype_disabled__c: boolean;

    @AField({ soql: 'egl_vulnerabilitytype_over75__c,' })
    egl_vulnerabilitytype_over75__c: boolean;

    @AField({ soql: 'egl_Brand_Model_Object_Maintenance__c,' })
    egl_Brand_Model_Object_Maintenance__c: string;

    @AField({ soql: 'egl_Age_Object_Maintenance__c,' })
    egl_Age_Object_Maintenance__c: string;

    @AField({ soql: 'egl_Heating_System_type__c,' })
    egl_Heating_System_type__c: string;

    @AField({ soql: 'egl_Type_Water_Heater__c,' })
    egl_Type_Water_Heater__c: string;

    @AField({ soql: 'egl_Heating_System_Fuel_Type__c,' })
    egl_Heating_System_Fuel_Type__c: string;

    @AField({ soql: 'egl_Centralized_System__c,' })
    egl_Centralized_System__c: boolean;

    @AField({ soql: 'egl_Warranty_Object_Maintenance__c,' })
    egl_Warranty_Object_Maintenance__c: boolean;

    @AField({ soql: 'egl_product_installation_date__c' })
    egl_product_installation_date__c: string;

    @AField({ soql: 'egl_tax_detraction__c,' })
    egl_tax_detraction__c: string;

    @AField({ soql: 'egl_down_payment__c' })
    egl_down_payment__c: number;

    @AField({ soql: 'egl_raee__c' })
    egl_raee__c: string;

    @AField({ soql: 'egl_override_list_price__c' })
    egl_override_list_price__c: number;

    @AField({ soql: 'egl_credit_assignment__c' })
    egl_credit_assignment__c: string;

    @AField({ soql: 'egl_consumer_credit_campaign__c' })
    egl_consumer_credit_campaign__c: string;

    @AField({ soql: 'egl_pricelist__c' })
    egl_pricelist__c: string;

    @AField({ soql: 'egl_consequential__c' })
    egl_consequential__c: string;

    @AField({ soql: 'egl_used_goods_in_stock__c' })
    egl_used_goods_in_stock__c: string;

    @AField({ soql: 'egl_related_offer__c' })
    egl_related_offer__c: string;
}
