import { Injectable, Injector } from '@angular/core';
import {
    ACondition,
    AObjectService,
    CacheService,
    ConfigurationService,
    MetadataService,
    PlatformService,
} from '@congacommerce/core';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ProductOptionGroup } from '@congacommerce/ecommerce';
import { Observable } from 'rxjs';
import { SequenceBehavior } from '../../utility/egl-product-option.service';

@Injectable({
    providedIn: 'root',
})
export class EglProductOptionGroupService extends AObjectService {
    type = ProductOptionGroup;
    private productOptionGroupCache$: { [key: string]: Observable<ProductOptionGroup[]> } = {};
    constructor(
        cacheService: CacheService,
        platformService: PlatformService,
        metadataService: MetadataService,
        configurationService: ConfigurationService,
        injector: Injector
    ) {
        super(cacheService, platformService, metadataService, configurationService, injector);
    }
    getProductOptionGroups(productId: string): Observable<ProductOptionGroup[]> {
        this.productOptionGroupCache$[productId] =
            this.productOptionGroupCache$[productId] ||
            this.where([new ACondition(this.type, 'Apttus_Config2__ProductId__c', 'Equal', productId)]).pipe(
                shareReplay(1)
            );
        return this.productOptionGroupCache$[productId];
    }

    hasOrderEntryConfigurableOptionGroups(productId: string): Observable<boolean> {
        return this.getProductOptionGroups(productId).pipe(
            map(
                (optionGroups) =>
                    !!(optionGroups || []).find(
                        (group) => group.Sequence === SequenceBehavior.HiddenButRequiredIntoOrderEntry
                    )
            )
        );
    }
}
