import { Injectable } from '@angular/core';
import { CartService } from '@congacommerce/ecommerce';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { TroubleShootingResultType } from '../../../common/components/troubleshooting-modalita/troubleshooting-modalita.component';
import { AptLineStatus } from '../../../common/enums/apttus/apt-line-status';
import {
    flowTypeToAptSalesProcess,
    getQuestionsResultFromDestinationUse,
} from '../../../common/functions/remap.functions';
import { eglCart2Products } from '../../../common/functions/transformation.functions';
import {
    containsProductInsurance,
    flowTypeUtil,
    hasOnlyExtraCommodities,
} from '../../../common/functions/verifications.functions';
import { EglCartExtended } from '../../../common/models/apttus/tables/cart/egl-cart-extended';
import { EglCartLightService } from '../../../common/services/apttus/tables/cart/egl-cart-light.service';
import { FeatureToggleService } from '../../../common/services/shared/feature-toggle.service';
import { LoggerService } from '../../../common/services/shared/logger.service';
import { setFlowType } from '../../../store/actions/order-entry.actions';
import { FlowType, MacroFlowType } from '../../../store/models/flow-type';
import { Product } from '../../../store/models/order-entry-state_v2';
import { EglState } from '../../../store/reducers';
import { selectDestinationUse, selectFlowType } from '../../../store/selectors/order-entry.selectors';

@Injectable({
    providedIn: 'root',
})
export class FlowTypeService {
    constructor(
        private cartSrv: CartService,
        private eglCartLightService: EglCartLightService,
        private store: Store<EglState>,
        private logger: LoggerService,
        private toggleSrv: FeatureToggleService,
    ) {}
    private troubleshootingResult: TroubleShootingResultType = null;

    /**
     *  Calcola il flowType in base alle risposte date nel troubleshooting
     * @param response
     * @returns
     */
    calculateFlowTypeByTrobleshootingResponse(
        response: TroubleShootingResultType,
    ): Observable<TroubleShootingResultType> {
        if (!response?.flowType) {
            throw new Error(" TroubleShootingResultType doesn't have flowType property");
        }

        return combineLatest([this.store.select(selectFlowType), this.store.select(selectDestinationUse)]).pipe(
            take(1),
            // Handling AdministrativeSwitchIn Flow Types
            map(([currentFlowType, stateDestinationUse]) => ({
                flowType: !flowTypeUtil(currentFlowType).mode
                    ? flowTypeUtil(response?.flowType).hasSameMacroFlowType(currentFlowType)
                        ? // Se il macroFlowType non è cambiato e non posso determinare la modalità conservo il flowType originale
                          currentFlowType
                        : // Se il macroFlowType è cambiato e non posso determinare la modalità utilizzo il flowType del troubleshooting
                          response?.flowType
                    : // Se posso distinguere la modalità nel flowType converto il flowType nel troubleshooting con la stessa del flowType di provenienza
                      flowTypeUtil(response?.flowType).withMode(flowTypeUtil(currentFlowType).mode),
                stateDestinationUse,
            })),
            map(({ flowType, ...data }) => ({
                ...data,
                // Per tutte le attivazioni reimposto il flowType con quello virtuale mantenendo la stessa modalità
                flowType: flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Attivazione)
                    ? flowTypeUtil(FlowType.Attivazione).withMode(flowTypeUtil(flowType).mode)
                    : flowType,
            })),
            map(({ flowType, stateDestinationUse }) => ({
                flowType,
                questionResult: !!stateDestinationUse
                    ? getQuestionsResultFromDestinationUse(stateDestinationUse, response.questionResult)
                    : response.questionResult,
            })),
            tap((tsResult) => {
                this.troubleshootingResult = tsResult;
                this.logger.info('onSetFlowtype: ' + tsResult.flowType);
            }),
        );
    }

    /**
     * Aggiorna il flowType nello store e nel carrello. Se è stato eseguito il troubleshooting viene utilizzato il flowType del troubleshooting
     * altrimenti viene calcolato in base al carrello al fine di determinare se è un ExtraCommoditySale
     * - se il carrello contiene Extracommodity + Commodity => FlowType Commodity
     * - se il carrello contiene solo Extracommodity => FlowType ExtraCommoditySale
     * @returns Observable<FlowType> il flowType aggiornato nel carrello
     */
    dispatchFlowType(): Observable<FlowType> {
        return this.cartSrv.getMyCart().pipe(
            take(1),
            map((cart: EglCartExtended) => eglCart2Products(cart)),
            map((products: Product[]) => {
                const isExtraCommodity = hasOnlyExtraCommodities(products);
                return !isExtraCommodity
                    ? this.troubleshootingResult?.flowType
                    : this.EXTRACOMMODITY_FLOW_TYPE_CONFIG[this.getExtracommodityFlowTypeStrategy(products)](products);
            }),
            tap((flowType) => {
                this.logger.info('calculated flowType: ' + flowType);
            }),
            mergeMap((toBeFlow) =>
                this.store.select(selectFlowType).pipe(map((currentFlow) => ({ currentFlow, toBeFlow }))),
            ),
            take(1),
            mergeMap(({ currentFlow, toBeFlow }) => {
                if (toBeFlow && currentFlow !== toBeFlow) {
                    this.store.dispatch(setFlowType({ flowType: toBeFlow }));
                    return this.eglCartLightService
                        .updateCartSalesProcess(flowTypeToAptSalesProcess(toBeFlow))
                        .pipe(map(() => toBeFlow));
                }
                return of(toBeFlow);
            }),
        );
    }

    private getExtracommodityFlowTypeStrategy(products: Product[]): ExtracommodityFlowTypeStrategy {
        const productLinesStatus = [...new Set(products.map((p) => p.lineItemStatus))];
        if (productLinesStatus.every((ls) => ls === AptLineStatus.New)) {
            return 'ATT_EXTRACOMMODITY';
        }
        if (productLinesStatus.every((ls) => ls === AptLineStatus.Cancelled)) {
            return 'CESS_EXTRACOMMODITY';
        }
        if (productLinesStatus.some((ls) => ls === AptLineStatus.Upgraded)) {
            return 'ATT_EXTRACOMMODITY';
        }
        return 'UNSUPPORTED';
    }

    private EXTRACOMMODITY_FLOW_TYPE_CONFIG: {
        [key in ExtracommodityFlowTypeStrategy]?: (products?: Product[]) => FlowType;
    } = {
        CESS_EXTRACOMMODITY: (products) => {
            if (!this.toggleSrv.isExtraCommodityFlowEnabled) {
                throw Error('FlowType cessazione extracommodity non abilitato');
            }
            return FlowType.CessazioneExtracommodity;
        },
        ATT_EXTRACOMMODITY: (products) =>
            containsProductInsurance(products.map((p) => p.productType)) && this.toggleSrv.isPolizze2023Enabled
                ? FlowType.ExtraCommoditySale
                : this.toggleSrv.isExtraCommodityFlowEnabled
                  ? FlowType.ExtraCommoditySale
                  : FlowType.SwitchIn,
        CP_EXTRACOMMODITY: (products) => {
            // Predispongo questo scenario ma non sappiamo se si realizzerà
            throw Error('FlowType di cambio prodotto Extracommodity non supportato');
        },
        UNSUPPORTED: (products) => {
            throw Error(
                'Impossibile determinare la strategia del flowtype NDS. Scenario configurazione carrello extracommodity non supportato.',
            );
        },
    };
}
type ExtracommodityFlowTypeStrategy =
    | 'ATT_EXTRACOMMODITY'
    | 'CESS_EXTRACOMMODITY'
    | 'CP_EXTRACOMMODITY'
    | 'UNSUPPORTED';
