import { Injectable } from '@angular/core';
import { LoggerService } from '../shared/logger.service';
import { D365Service } from '../d365/d365.service';
import { ApplicationLocationType } from '../../../store/models/app-state';
import { PrivateConfigurationService } from '../shared/private-configuration.service';
import { CookieName, CookieService } from '../shared/cookie.service';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { selectApplicationLocation, selectD365AppUrl } from '../../../store/selectors/app.selectors';
import { EglState } from '../../../store/reducers';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root',
})
export class OAuthService {
    constructor(
        private logger: LoggerService,
        private d365Service: D365Service,
        private configSrv: PrivateConfigurationService,
        private cookieSrv: CookieService,
        private store: Store<EglState>
    ) {}

    /**
     * @description Esegue il login per SalesForce
     * @param appLocation Indica se stiamo usando l'applicazione da Web o Mobile
     * @returns void
     */
    private oauthLogin(appLocation: ApplicationLocationType, d365AppUrl: string) {
        const conf = this.configSrv.config;
        const url = conf.oauthBaseUrl;
        const clientId = conf.clientId;
        const redirectUri = this.getRedirectUrl(appLocation);
        localStorage.setItem('login_app_url', d365AppUrl);
        const urlOAuth = `${url}${clientId}&redirect_uri=${encodeURI(
            location.protocol + redirectUri
        )}%2F&scope=&state=jsforce0.redirect.4sh0nvndizf`;
        this.logger.info('Application Location is ' + appLocation);
        this.logger.info('Authentication URL is ' + urlOAuth);
        return this.d365Service.topNavigationRequest(urlOAuth);
    }

    /**
     * @description Ottiene un token valido per le richieste da effettuare verso Azure
     * @returns Observable<string>
     */
    getAuthToken(): Observable<{ authorization: string; user: string }> {
        const cookieAuth = this.cookieSrv.get(CookieName.OAuth2CookieName);
        if (cookieAuth) {
            const [authorization, user] = cookieAuth.split('|');
            return of({ authorization, user });
        } else {
            return this.d365Service.getOauth2AccessToken().pipe(
                tap((res) =>
                    this.cookieSrv.set(CookieName.OAuth2CookieName, [res.token, res.user].join('|'), {
                        maxAge: res.expires_in - 120,
                    })
                ),
                map(({ token: authorization, user }) => ({ authorization, user }))
            );
        }
    }

    getRedirectUrl(appLocation: ApplicationLocationType): string {
        return appLocation === ApplicationLocationType.Mobile
            ? this.configSrv.config.redirectUri.mobile
            : this.configSrv.config.redirectUri.crm;
    }

    /**
     * @description Invoca il login su SalesForce
     */
    salesforceLogin() {
        return combineLatest([this.store.select(selectApplicationLocation), this.store.select(selectD365AppUrl)]).pipe(
            take(1),
            switchMap(([applicationLocationType, d365AppUrl]) => this.oauthLogin(applicationLocationType, d365AppUrl))
        );
    }
}
