import { Injectable, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WidgetService {
    constructor() {}

    private updateWidgets = new BehaviorSubject(false);
    canUpdate = this.updateWidgets.asObservable();

    update() {
        this.updateWidgets.next(true);
    }
}
