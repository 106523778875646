import { StatusApexResponse } from '../../interfaces/base-apex-api-respose';

export class MailBlacklistRequest {
    Checkmail: {
        Email: string[];
    };

    constructor(mails: string[]) {
        this.Checkmail = {
            Email: [...mails],
        };
    }
}

export class MailBlacklistResponse {
    Status: 'Success' | 'Failure';
}
