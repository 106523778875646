export enum BoDateRangeFilterVal {
    UnaSett = '7d',
    TreMesi = '3m',
    SeiMesi = '6m',
    UnAnno = '12m',
    Tutto = 'all',
    DatepickerRange = 'dp',
}
export enum BoDateTypeFilterVal {
    DataCreazione = 'createDate',
    DataFirma = 'signDate',
}
