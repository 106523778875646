import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { isEqual } from 'lodash';
import { EMPTY, from, fromEvent, merge, Observable, Subscription } from 'rxjs';
import {
    catchError,
    debounceTime,
    filter,
    finalize,
    map,
    mergeMap,
    switchMap,
    tap,
    throwIfEmpty,
} from 'rxjs/operators';
import { EglIcon } from '../enums/shared/icon-types';
import { cleanObj } from '../functions/misc.functions';

export interface THItem<V> {
    label?: string;
    value: V;
    icon?: EglIcon;
}
@Directive({
    selector: 'input[typeahead]',
})
export class TypeAheadDirective<O> implements OnInit, OnDestroy {
    @Input() public typeahead: (text: string, value?: O) => Observable<THItem<O>[]>;
    protected subs: Subscription[] = [];
    private listElement: HTMLElement;

    constructor(
        protected element: ElementRef<HTMLInputElement>,
        protected renderer: Renderer2,
        @Inject(DOCUMENT) protected document: Document,
        protected control: NgControl
    ) {}

    ngOnInit(): void {
        this.createSuggestions();
        this.subs.push(
            merge(
                ...['input', 'paste', 'cut', 'focus'].map((evtName) =>
                    fromEvent<Event & { target: HTMLInputElement }>(this.element?.nativeElement, evtName)
                )
            )
                .pipe(
                    //recupero il valore dall'evento
                    map((event) => event?.target.value),
                    map((text) => ({ text, value: this.control.value as O })),
                    debounceTime(500),
                    tap(() => this.clearSuggestions()),
                    filter(() => this.element?.nativeElement === this.document.activeElement),
                    tap(() => this.element.nativeElement.classList.add('egl-inputfield--loading')),
                    filter(() => typeof this.typeahead === 'function'),
                    switchMap(({ value, text }) =>
                        this.typeahead(text, value).pipe(
                            map((results) => ({
                                value,
                                results,
                            })),
                            throwIfEmpty(),
                            finalize(() => this.element.nativeElement.classList.remove('egl-inputfield--loading')),
                            catchError(() => EMPTY)
                        )
                    ),
                    filter(
                        ({ value, results }) =>
                            results?.length != 1 || !isEqual(cleanObj(value), cleanObj(results[0]?.value))
                    ),
                    map(({ results }) => results),
                    mergeMap((results) => from(results))
                )
                .subscribe((item: THItem<O>) => this.addSuggestion(item))
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => sub.unsubscribe());
        this.destroySuggestions();
    }

    private createSuggestions() {
        const parentNode = this.element.nativeElement.parentElement;
        const nextSibling = this.element.nativeElement.nextElementSibling;
        this.listElement = this.document.createElement('ul');
        this.listElement.classList.add('egl-typeahead__container');
        if (nextSibling) {
            this.renderer.insertBefore(parentNode, this.listElement, nextSibling);
        } else {
            this.renderer.appendChild(parentNode, this.listElement);
        }
    }

    private destroySuggestions() {
        if (this.listElement) {
            this.renderer.removeChild(this.listElement.parentElement, this.listElement);
        }
    }

    private clearSuggestions() {
        Array.from(this.listElement?.children || []).forEach((child) =>
            this.renderer.removeChild(this.listElement, child)
        );
    }

    private addSuggestion(item: THItem<O>) {
        const suggestion = this.document.createElement('li');
        suggestion.classList.add('egl-typeahead__option');
        suggestion.textContent = item.label || item.value + '';
        this.renderer.listen(suggestion, 'click', () => {
            this.control.valueAccessor.writeValue(item.value);
            this.clearSuggestions();
        });
        this.renderer.appendChild(this.listElement, suggestion);
    }
}
