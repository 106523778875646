import { HttpErrorResponse } from '@angular/common/http';

/* 
    In the case of a technical error(i.e. a 5xx response) from the backend, the response will not be in the standard BaseApiResponse format,
    but it will be an HttpErrorResponse. The type BaseApiResponseOrTecErr is useful when that case present itself and has to be handled.

    In the case of a functional error(i.e. there is a problem but the back end responded with 200) BaseApiResponse can be used and 
    errorManagement should have all the info about the error.
*/
export type TecError = { result: string; status: StatusResponse; errorManagement?: ErrorManagement };
export type ApiErrorResponse = Omit<HttpErrorResponse, 'error'> & { error: TecError };
export type ApiRespOrTecErr<T = void> = BaseApiResponse<T> | ApiErrorResponse;
export function isTecError<T>(res: ApiRespOrTecErr<T>): res is ApiErrorResponse {
    return 'error' in res;
}

export class BaseApiResponse<T = void> {
    result: string;
    status: StatusResponse;
    errorManagement?: ErrorManagement;
    response?: T;
}

export enum StatusResponse {
    Success = 'Success',
    Failed = 'Failed',
}

export interface ErrorManagement {
    errorCode: string;
    errorDescription: string;
}

export enum StatusDDLResponse {
    Success = 'OK',
    Failed = 'KO',
}
