import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { RoutesPaths } from '../../../common/config/routes-paths';
import { D365OperationMode } from '../../../common/enums/d365/d365-operation-mode';
import {
    d365AccountMigratedToMastershipType,
    d365AccountMigratedToStrictMastershipType,
    d365OperationModeToFlowType,
    flowTypeToAptSalesProcess,
    flowTypeToMacroFlowType,
    getNumberOrNull,
} from '../../../common/functions/remap.functions';

import {
    D365AccountContactData,
    d365AccountContactDataToDatiAnagraficiMBV2,
} from '../../../common/functions/transformation.functions';

import { CartUtilityService } from '../../../common/services/apttus/tables/cart/cart-utility.service';
import { LoggerService } from '../../../common/services/shared/logger.service';
import {
    setV2AnagraficaMB,
    setV2CartId,
    setV2MainAddress,
    setV2Products,
    setV2SetEmailConfirmed,
} from '../../../store/actions/order-entry-v2.actions';
import { setFlowType } from '../../../store/actions/order-entry.actions';
import { setCartSegment, setCustomerAndContact } from '../../../store/actions/user.actions';
import { Address, PaymentTool, Product } from '../../../store/models/order-entry-state_v2';
import { EglState } from '../../../store/reducers';
import { FlowType, MacroFlowType } from '../../../store/models/flow-type';
import { DeepPartial } from '../../../common/interfaces/deep-partial';
import { DragonRouterService } from '../../../common/services/shared/router/dragon-router.service';
import { D365CustomerSegment } from '../../../common/enums/d365/d365-customer-segment';
import { DataLoaderService } from '../services/data-loader.service';
import { EglCartLightService } from '../../../common/services/apttus/tables/cart/egl-cart-light.service';
import { D365Account } from '../../../common/models/d365/d365-asset.interface';
import { ServiceError } from '../../../common/models/app/service-error';
import { IntestarioConto } from '../../common/order-entry/models/form-bonifico';
import { MastershipType } from '../../../store/models/user-state';

@Injectable({ providedIn: 'root' })
export class AssetLoaderResolver implements Resolve<Observable<void>> {
    constructor(
        private dataLoaderSrv: DataLoaderService,
        private logger: LoggerService,
        private cartUtilSrv: CartUtilityService,
        private store: Store<EglState>,
        private router: Router,
        private routerSrv: DragonRouterService,
        private eglCartLightSrv: EglCartLightService
    ) {}

    private FLOW_TYPE_STATE_STRATEGY_MAP: FlowTypeStateStrategyMap = {
        // mappati soltanto i campi tecnici dall'asset del volturato che serviranno al volturante
        [MacroFlowType.Voltura]: {
            product: (
                {
                    assetId,
                    podPdr,
                    pdf,
                    powerOrGas,
                    deliveryAddress,
                    addressType,
                    family,
                    paymentInfo,
                    technicalDetails,
                },
                account
            ) => ({
                sourceCustomer: {
                    billingPreferenceCode: paymentInfo.paymentTool.billingPreferenceCode,
                    mastership: d365AccountMigratedToStrictMastershipType(account?.egl_migration_mastercode),
                    assetId,
                },
                podPdr,
                pdf,
                powerOrGas,
                deliveryAddress,
                addressType,
                family,
                technicalDetails: {
                    ...technicalDetails,
                    consumption: null,
                    pwrDeclaredConsumption: null,
                    typeOfUsage: null,
                },
            }),
            customer: (account) => ({
                egl_customersegmentcode: account?.egl_customersegmentcode,
            }),
        },
        [MacroFlowType.CambioProdotto]: {
            //Sfilo il productId
            product: ({ productId, ...product }) => product,
        },
        // MacroFlowType.VariazioneParametri => mappato nel default
        DEFAULT: {
            product: (product) => product,
            customer: (account) => account,
        },
    };

    private ACCOUNT_OWNER_MAP: {
        [key in IntestarioConto]: (paymentTool: DeepPartial<PaymentTool>, account: D365AccountContactData) => boolean;
    } = {
        [IntestarioConto.Cliente]: (paymentTool, account) =>
            account?.egl_customersegmentcode === D365CustomerSegment.Residential &&
            account?.egl_taxcode === paymentTool?.holder?.fiscalCode,
        [IntestarioConto.Azienda]: (paymentTool, account) =>
            account?.egl_customersegmentcode === D365CustomerSegment.Microbusiness &&
            account?.egl_vatcode === paymentTool?.holder?.vatCode,
        [IntestarioConto.PersonaFisica]: (paymentTool, account) =>
            account?.egl_customersegmentcode === D365CustomerSegment.Residential &&
            !!paymentTool?.holder?.fiscalCode &&
            account?.egl_taxcode !== paymentTool?.holder?.fiscalCode,
        [IntestarioConto.PersonaGiuridica]: (paymentTool, account) =>
            !!paymentTool?.holder?.fiscalCode &&
            account?.egl_taxcode !== paymentTool?.holder?.fiscalCode &&
            !!paymentTool?.holder?.vatCode &&
            account?.egl_vatcode === paymentTool?.holder?.vatCode &&
            !!paymentTool?.sepaSubscriber?.fiscalCode,
    };

    updateProductAccountContact(product: DeepPartial<Product>, account: D365AccountContactData): DeepPartial<Product> {
        return {
            ...product,
            communicationAddress: account?.addresses?.COMUNICAZIONE,
            paymentInfo: {
                ...(product?.paymentInfo || {}),
                paymentTool: {
                    ...(product?.paymentInfo?.paymentTool || {}),
                    bankAccountOwner: Object.entries(this.ACCOUNT_OWNER_MAP).find(([, check]) =>
                        check(product?.paymentInfo?.paymentTool, account)
                    )?.[0] as IntestarioConto,
                },
            },
        };
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
        // load-asset/operation/:operationMode/asset/:assetId
        // [DEV - ID SALESFORCE] load-asset/operation/100000300/asset/a1k3O000001FsoSQAS?assetId=a1k3O000001G07EQAS
        // [DEV - ID D365] load-asset/operation/100000300/asset/e00b804f-b476-ec11-8d21-0022489caad7?assetId=e468d4c7-fc24-ec11-b6e5-000d3ab6297f
        // VT3:

        // Parto dalla rotta
        console.log('Asset loader: ', route.params, route.queryParams);
        return of(route)
            .pipe(
                // Recupero i pathParams e i queryParams
                map(({ params, queryParams }) => ({
                    operationMode: getNumberOrNull(params?.operationMode) as D365OperationMode,
                    queryParams,
                    // Gathering path assetId, optional assetId and  assetid, removing dupes
                    assetIds: Array.from(
                        new Set<string>(
                            [params?.assetId]
                                .concat(queryParams?.assetid || [], queryParams?.assetId || [])
                                .map((assetId) => (assetId || '').trim())
                        )
                    ).filter(Boolean),
                })),
                // Verifico la presenza dei campi obbligatori e del loro formato
                tap(({ operationMode, assetIds }) => {
                    if (!operationMode || !Object.values(D365OperationMode).includes(operationMode)) {
                        throw new Error(`Invalid operationMode: ${route.params?.operationMode}`);
                    }
                    if (!assetIds?.length) {
                        throw new Error('Missing assetId');
                    }
                    this.logger.info(`Loading asset informations: ${assetIds}`);
                }),
                // Mappo FlowType e MacroFlowType a partire dall'operationMode di D365
                map(({ operationMode, ...data }) => ({
                    ...data,
                    operationMode,
                    flowType: d365OperationModeToFlowType(operationMode),
                    macroFlowType: flowTypeToMacroFlowType(d365OperationModeToFlowType(operationMode)),
                })),
                // Verifico che l'operationMode abbia corrispondenza come FlowType e MacroFlowType
                tap(({ operationMode, flowType, macroFlowType }) => {
                    if (!flowType) {
                        throw new Error(`Missing FlowType for the given D365 OperationMode: ${operationMode}`);
                    }
                    if (!macroFlowType) {
                        throw new Error(`Missing MacroFlowType for the given FlowType: ${flowType}`);
                    }
                })
            )
            .pipe(
                // Recupero le informazioni degli asset
                switchMap(({ assetIds, ...data }) =>
                    this.dataLoaderSrv.getAssets(assetIds).pipe(
                        map(({ products, accountNumber }) => ({
                            ...data,
                            products,
                            accountNumber,
                        }))
                    )
                ),
                // Recupero le informazioni dell'account
                switchMap(({ accountNumber, ...data }) =>
                    this.dataLoaderSrv.getAccountContact(accountNumber).pipe(
                        map((account) => ({
                            ...data,
                            account,
                        }))
                    )
                ),
                // Applichiamo le mappature specifiche per processo ai singoli prodotti
                map(({ products, account, macroFlowType, flowType, ...data }) => ({
                    ...data,
                    flowType,
                    macroFlowType,
                    products: products.map((product) =>
                        (
                            this.FLOW_TYPE_STATE_STRATEGY_MAP[macroFlowType]?.product ||
                            this.FLOW_TYPE_STATE_STRATEGY_MAP[flowType]?.product ||
                            this.FLOW_TYPE_STATE_STRATEGY_MAP.DEFAULT?.product
                        )(
                            // Aggiorno i dati prodotto con i dati dell'account e del contatto
                            this.updateProductAccountContact(product, account),
                            account
                        )
                    ),
                    customer: (
                        this.FLOW_TYPE_STATE_STRATEGY_MAP[macroFlowType]?.customer ||
                        this.FLOW_TYPE_STATE_STRATEGY_MAP[flowType]?.customer ||
                        this.FLOW_TYPE_STATE_STRATEGY_MAP.DEFAULT?.customer
                    )(account),
                })),
                // Salvo nello state i dati necessari per la creazione del carrello (prossimo step)
                tap((data) => {
                    this.store.dispatch(setCartSegment({ payload: data?.customer?.egl_customersegmentcode }));
                    this.store.dispatch(setFlowType({ flowType: data?.flowType }));
                }),
                tap((data) => {
                    if (data.queryParams?.acart) {
                        this.store.dispatch(setV2CartId({ id: data.queryParams?.acart }));
                    }
                }),
                // Creo un nuovo carrello dallo state e restituisco gli stessi parametri ricevuti in ingresso
                switchMap((data: AssetLoaderData) =>
                    !data.queryParams?.acart
                        ? this.cartUtilSrv.newCartFromState(true).pipe(
                              take(1),
                              map(() => data),
                              tap(() => {
                                  this.eglCartLightSrv.updateCartSalesProcess(
                                      flowTypeToAptSalesProcess(data?.flowType)
                                  );
                              })
                          )
                        : of(data)
                ),
                // Salvo nello state informazioni cliente
                tap(({ customer }: AssetLoaderData) => {
                    this.store.dispatch(setCustomerAndContact({ customer }));
                    // se nei dati del cliente esiste la mail setto emailConfirmed in modo che non sia necessario scrivere la mail
                    this.store.dispatch(setV2SetEmailConfirmed({ emailConfirmed: !!customer?.emailaddress1 }));
                    const isMicrobusiness = customer?.egl_customersegmentcode === D365CustomerSegment.Microbusiness;
                    if (isMicrobusiness) {
                        this.store.dispatch(
                            setV2AnagraficaMB({
                                anagraficaMb: d365AccountContactDataToDatiAnagraficiMBV2(customer),
                            })
                        );
                    }
                    this.store.dispatch(
                        setV2MainAddress({
                            address: (isMicrobusiness
                                ? customer?.addresses?.['SEDE LEGALE']
                                : customer?.addresses?.RESIDENZA ||
                                  d365AccountMigratedToMastershipType(customer?.egl_migration_mastercode) ===
                                      MastershipType.Siebel
                                ? customer?.addresses?.COMUNICAZIONE
                                : null) as Address,
                        })
                    );
                }),
                mergeMap(({ products, ...data }) =>
                    combineLatest(
                        (products as Product[]).map((product) =>
                            this.dataLoaderSrv.normalizeAddress(
                                product.deliveryAddress,
                                data.macroFlowType === MacroFlowType.Voltura
                            )
                        )
                    ).pipe(
                        map((results) => ({
                            ...data,
                            products: products.map((product, index) => ({
                                ...product,
                                idx: index,
                                deliveryAddress: {
                                    ...results[index],
                                    isLegitWrongAddress:
                                        !results[index].certified && data.macroFlowType === MacroFlowType.Voltura,
                                },
                            })),
                        }))
                    )
                ),
                tap(({ products, macroFlowType }) => {
                    console.log('[Asset loader] products: ', products);
                    if (
                        products.some(
                            (product) =>
                                product.sourceCustomer?.assetId &&
                                !product.deliveryAddress?.certified &&
                                macroFlowType !== MacroFlowType.Voltura
                        )
                    ) {
                        throw new ServiceError(
                            null,
                            "Non è stato possibile normalizzare l'indirizzo di fornitura per una o più delle forniture selezionate",
                            'LOW'
                        );
                    }
                    this.store.dispatch(setV2Products({ products }));
                }),
                switchMap(() =>
                    from(
                        this.routerSrv.next({
                            ...route?.queryParams,
                        })
                    )
                ),
                catchError((error: ServiceError) => {
                    this.logger.error('Attenzione', error?.message, error, true);
                    if (error?.level === 'LOW') {
                        return from(
                            this.router.navigate([RoutesPaths.KoCreditCheck], {
                                queryParams: { errorCode: 'GENERIC', message: error?.message },
                            })
                        );
                    }
                    return from(this.router.navigate([RoutesPaths.Error500]));
                })
            ) as unknown as Observable<void>;
    }
}

interface AssetLoaderData {
    queryParams: any;
    operationMode: D365OperationMode;
    flowType: FlowType;
    macroFlowType: MacroFlowType;
    products: Product[];
    customer: DeepPartial<D365AccountContactData>;
    account: D365Account;
}

type FlowTypeProductCustomerEnricher = {
    product?: (
        assetProduct: DeepPartial<Product>,
        accountData: DeepPartial<D365AccountContactData>
    ) => DeepPartial<Product>;
    customer?: (customerContactData: D365AccountContactData) => DeepPartial<D365AccountContactData>;
};

type FlowTypeStateStrategyMap = {
    [key in FlowType | MacroFlowType]?: FlowTypeProductCustomerEnricher;
} & {
    DEFAULT: FlowTypeProductCustomerEnricher;
};
