import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'egl-offline',
    templateUrl: './offline.component.html',
    styleUrls: ['./offline.component.scss'],
})
export class OfflineComponent {
    message: string;
    constructor(private activatedRoute: ActivatedRoute) {}
    ngOnInit(): void {
        this.message = this.activatedRoute.snapshot.queryParamMap.get('message');
    }
}
