import { Expose } from 'class-transformer';

export class D365WorkOrderServiceData {
    @Expose({ name: 'msdyn_workorderid' })
    workOrderId: string;

    @Expose({ name: 'NumeroWorkOrder' })
    workOrderNumber: string;

    @Expose({ name: 'WorkOrderTypeName' })
    workOrderType: string;

    @Expose({ name: 'StatoWorkOrder@OData.Community.Display.V1.FormattedValue' })
    workOrderStatus: string;

    @Expose({ name: 'NameSottoStato' })
    workOrderSubStatus: WorkOrderServiceSubStatus;

    @Expose({ name: 'DataInizioAppuntamento' })
    bookingStartDate: string;

    @Expose({ name: 'DataFineUltimoAppuntamento' })
    bookingEndDate: string;

    @Expose({ name: 'IndirizzoServizio' })
    addressService: string;

    /**
     * @description Partner tecnico di riferimento
     */
    @Expose({ name: 'NomeAgenzia' })
    agencyName: string;

    @Expose({ name: 'IndirizzoAgenzia' })
    agencyAddress: string;

    @Expose({ name: 'CodiceAgenzia' })
    agencyCode: string;

    @Expose({ name: 'DataInizioValidita' })
    startExpirationDate: string;

    @Expose({ name: 'DataFineValidita' })
    endExpirationDate: string;

    @Expose({ name: 'DataUltimoAggiornamento' })
    lastUpdateDate: string;

    @Expose({ name: 'DurataGaranzia' })
    warrantyDuration: string;

    @Expose({ name: 'Causale' })
    workorderReason: string;

    @Expose({ name: 'SerialNumber' })
    serialNumber: string;

    @Expose({ name: 'NumeroUscite' })
    numberIncludedAssistance: string;

    @Expose({ name: 'NomeProdotto' })
    productName: string;

    @Expose({ name: 'ProdServiceName' })
    productServiceName: string;

    assetExternalId?: string;
    usualHome: string;
}

export enum WorkOrderServiceSubStatus {
    NOT_WORKABLE = 'Impianto non lavorabile',
    WRONG_ORDER = 'Vendita errata',
    IMPOSSIBLE_TO_DO = 'Intervento non effettuabile',
}
