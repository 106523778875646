export enum MorosityErrorCode {
    Ok = 'APIGTW-AZURE-000000',
    ConnectionError = 'AZURE-APTTUS-999991',
    TimeOut = 'AZURE-APTTUS-999992',
    FunctionalError = 'AZURE-APTTUS-999998',
    InternalServerError = 'AZURE-APTTUS-999999',
}

export enum MorosityErrorName {
    Ok = 'Ok',
    ConnectionError = 'ConnectionError',
    TimeOut = 'TimeOut',
    FunctionalError = 'FunctionalError',
    InternalServerError = 'InternalServerError',
    GenericError = 'GenericError',
}
