import { Component, ElementRef, Renderer2, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'input[eglCnfInput][type=radio], input[eglCnfInput][type=checkbox]',
    template: '',
    styleUrls: ['./cnf-input-directive.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CnfInputDirective {
    constructor(private element: ElementRef<HTMLInputElement>, private render: Renderer2) {
        const inputElement = this.element.nativeElement;
        const parentElement = this.render.parentNode(inputElement);
        const inputContainer = this.render.createElement('label');
        const cosmeticElement = this.render.createElement('span');
        const inputType = inputElement.getAttribute('type').toLowerCase();
        this.render.addClass(cosmeticElement, 'egl-cnf-input-cosmetic');

        this.render.addClass(inputContainer, 'egl-cnf-input-container');
        this.render.addClass(inputContainer, `egl-cnf-input-container-${inputType}`);
        this.render.addClass(cosmeticElement, `egl-cnf-input-${inputType}`);

        this.render.insertBefore(parentElement, inputContainer, inputElement);
        this.render.removeChild(parentElement, inputElement);
        this.render.appendChild(inputContainer, inputElement);
        this.render.appendChild(inputContainer, cosmeticElement);
    }
}
