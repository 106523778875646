import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../models/app-state';

const getSelectFeature = createFeatureSelector<AppState>('app');
export const selectAppState = createSelector(getSelectFeature, (state: AppState) => state);
export const selectHasContactBar = createSelector(getSelectFeature, (state: AppState) => !!state.hasContactBar);
export const selectHasProgressBar = createSelector(getSelectFeature, (state: AppState) => !!state.hasProgressBar);
export const selectHasDevBar = createSelector(getSelectFeature, (state: AppState) => !!state.hasDevBar);
export const selectConnectionStatus = createSelector(getSelectFeature, (state: AppState) => state.connectionStatus);
export const selectApplicationLocation = createSelector(
    getSelectFeature,
    (state: AppState) => state.applicationLocation
);

export const selectBars = createSelector(
    selectHasContactBar,
    selectHasProgressBar,
    selectHasDevBar,
    (hasContactBar: boolean, hasProgressBar: boolean, hasDevBar: boolean) => ({
        hasContactBar,
        hasProgressBar,
        hasDevBar,
    })
);

export const selectD365EntryPoint = createSelector(getSelectFeature, (state: AppState) => state.d365EntryPoint);
export const selectD365AppUrl = createSelector(getSelectFeature, (state: AppState) => state.d365AppUrl);
export const selectShouldOpenTroubleshooting = createSelector(
    getSelectFeature,
    (state: AppState) => state.shouldOpenTroubleShooting
);
