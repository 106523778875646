import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { RoutesPaths } from '../../config/routes-paths';
import { Store } from '@ngrx/store';
import { setDevBarVisibility } from '../../../store/actions/app.actions';
import { Subscription } from 'rxjs';
import { PrivateConfigurationService } from '../../services/shared/private-configuration.service';
import { AptSalesProcess } from '../../../common/enums/apttus/apt-sales-process';
import { setFlowType } from '../../../store/actions/order-entry.actions';
import { setCartSegment, setCustomerMastership } from '../../../store/actions/user.actions';
import { EglModalCustomComponent } from '../modals/egl-modal-custom/egl-modal-custom.component';
import { D365CustomerSegment } from '../../enums/d365/d365-customer-segment';
import { selectFlowType, selectOrderEntryState } from '../../../store/selectors/order-entry.selectors';
import { SegoeMDL2Font } from '../../enums/shared/segoe-mdl2-font';
import { LoadingService } from '../../services/shared/loading.service';
import { isDbarMode, isLocalHost, valueDiff } from '../../functions/misc.functions';
import { distinctUntilChanged, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { FLOW_TYPE_LABELS_MAP } from '../../map/flow-type-labels-map';
import { FlowType } from '../../../store/models/flow-type';
import { MastershipType } from '../../../store/models/user-state';
import { v2SelectMastership } from '../../../store/selectors/order-entry-v2.selectors';
import { OrderEntryState } from '../../../store/models/order-entry-state';
import { anyOrderEntryStateToV1, anyOrderEntryStateToV2 } from '../../functions/transformation.functions';
import { sanitizeLogMessage } from '../../functions/string-format.functions';
import { EglAddProductToCartService } from '../../services/apttus/utility/egl-add-product-to-cart.service';
import { ApttusService } from '../../services/apttus/apttus.service';
import { EglCartExtended } from '../../models/apttus/tables/cart/egl-cart-extended';
import { OAuthService } from '../../services/oauth/oauth-services';
import { TableModel } from '../table/table.component';
import { LoggerService } from '../../services/shared/logger.service';
import { EglSalesupStateService } from '../../services/apttus/tables/egl-salesup-state.service';
import { DragonRouterService } from '../../services/shared/router/dragon-router.service';
import { selectCartSegment } from '../../../store/selectors/user.selectors';
import { aptSalesProcessToFlowType, operationTypeOrFlowTypeToSalesProcess } from '../../functions/remap.functions';
import { CartService } from '@congacommerce/ecommerce';
import { CartToQuoteRequestService } from '../../services/apttus/cart-to-quote/cart-to-quote-request.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
    selector: 'egl-dev-bar',
    templateUrl: './dev-bar.component.html',
    styleUrls: ['./dev-bar.component.scss'],
})
export class DevBarComponent implements OnInit, OnDestroy {
    public readonly SEGMENT = D365CustomerSegment;
    domainEnabled: boolean;
    visible: boolean;
    routes: RoutesPaths[];
    operativeModes: Array<AptSalesProcess>;
    flowTypes: FlowType[] = [];
    // salesProcess: AptSalesProcess;
    flowTypeObj: { label: string; salesProcess: AptSalesProcess };
    url = location.href;
    enabledDomains: string[] = [];
    subs: Subscription[] = [];
    inListDomains: boolean;
    currentFlowType: FlowType;
    readonly ICONS = SegoeMDL2Font;
    localhost: boolean;
    mastership: MastershipType;
    otherInfos: TableModel;
    segment: D365CustomerSegment;
    @ViewChild('moreConfigModal', { static: false }) moreConfigModal: EglModalCustomComponent;
    @ViewChild('otherInfoModal', { static: false }) otherInfoModal: EglModalCustomComponent;

    public route$ = this.router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((event) => event?.url),
        distinctUntilChanged(),
        map((url) =>
            this.routes.find(
                (route) => url.includes(route) || new RegExp(route.replace(/\/\:([^\/]+)\//gm, '[^/]+')).test(url)
            )
        ),
        distinctUntilChanged()
    );

    constructor(
        private router: Router,
        private routerSrv: DragonRouterService,
        private store: Store<OrderEntryState>,
        private configSrv: PrivateConfigurationService,
        private addToCartSrv: EglAddProductToCartService,
        private apttusSrv: ApttusService,
        private authSrv: OAuthService,
        private logger: LoggerService,
        private supStateSrv: EglSalesupStateService,
        private cartSrv: CartService,
        private cartToQuoteRequestSrv: CartToQuoteRequestService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.enabledDomains = this.configSrv.config.devbarDomains;
        this.initComponent();
    }

    ngOnDestroy(): void {
        (this.subs || []).forEach((s) => s.unsubscribe());
    }

    initComponent(): void {
        this.inListDomains = (this.enabledDomains || []).includes(location.origin) || isDbarMode();
        this.localhost = isLocalHost();
        if (this.localhost || this.inListDomains) {
            this.domainEnabled = true;
            this.routes = Object.values(RoutesPaths).sort().reverse();
            this.flowTypes = Object.values(FlowType).sort();
            this.operativeModes = [AptSalesProcess.SwitchIn, AptSalesProcess.Attivazione];
            this.setVisibiltyBar(true);

            this.subs.push(
                this.router.events.subscribe((event) => {
                    if (event instanceof NavigationStart) {
                        this.url = `${location.origin}${event.url}`;
                    }
                })
            );
        }

        this.subs.push(
            this.store.select(selectFlowType).subscribe((flowType) => {
                this.currentFlowType = flowType;
                this.flowTypeObj = {
                    label: this.translate.instant(FLOW_TYPE_LABELS_MAP[this.currentFlowType]),
                    salesProcess: operationTypeOrFlowTypeToSalesProcess(flowType),
                };
            }),
            this.store.select(v2SelectMastership).subscribe(({ customerMastership }) => {
                this.mastership = customerMastership;
            }),
            this.store.select(selectCartSegment).subscribe((segment) => {
                this.segment = segment;
            })
        );
    }

    onChangeRoute(route: string): void {
        this.router.navigate([route]);
    }

    onGoBtn(useLocation: boolean): void {
        if (useLocation) {
            if (this.url !== location.href) {
                const newUrl = new URL(this.url);
                const sanitizedUrl = newUrl.toString().replace(newUrl.origin, location.origin);
                location.href = sanitizedUrl;
            } else {
                location.reload();
            }
        } else {
            if (this.url !== location.href) {
                this.router.navigateByUrl(this.url.replace(location.origin, ''));
            }
        }
    }

    setVisibiltyBar(visible: boolean): void {
        this.visible = visible;
        this.store.dispatch(setDevBarVisibility({ v: this.visible }));
    }

    showMoreConfig(): void {
        this.moreConfigModal.toggle();
        setTimeout(() => {
            if (this.moreConfigModal.isOpen) {
                const modal: any = document.getElementsByClassName('egl-modal-with-footer__container');
                modal[0].style.zIndex = 9999999999999;
            }
        });
    }

    onChangeMastership(customerMastership: MastershipType): void {
        this.mastership = customerMastership;
        this.store.dispatch(setCustomerMastership({ customerMastership }));
    }

    onSegmentChange(segment: string): void {
        this.store.dispatch(
            setCartSegment({
                payload: Number.parseInt(segment),
            })
        );
    }

    onChangeFlowType(flowType: FlowType): void {
        this.store.dispatch(setFlowType({ flowType: flowType }));
    }

    goToHome(): void {
        this.routerSrv.goToDashboard();
    }

    onHideBlockUI(): void {
        LoadingService.toggle();
    }

    logSupState(version: 1 | 2): void {
        if (version === 2) {
            this.store.pipe(take(1)).subscribe((state) => console.log('SUP STATE [V2]', state));
        } else {
            this.store
                .select(selectOrderEntryState)
                .pipe(take(1))
                .subscribe((state) => console.log('SUP STATE [V1]', state));
        }
    }

    multiAdd(): void {
        this.addToCartSrv.eglAddMultiProductToCart().pipe(take(1)).subscribe();
    }

    newCart(): void {
        this.apttusSrv.createNewCart(new EglCartExtended(), null, false, false, false).subscribe();
    }

    stateV2Test(stateV1Json: string): void {
        try {
            const srcStateV1 = JSON.parse(stateV1Json) as OrderEntryState;
            console.info(sanitizeLogMessage('[🐑] [SRC] [OrderEntry] [V1]'), srcStateV1);
            const stateV2 = anyOrderEntryStateToV2(srcStateV1);
            console.info(sanitizeLogMessage('[🐑] [DST] [OrderEntry] [V2]'), stateV2);
            const destStateV1 = anyOrderEntryStateToV1(stateV2);
            console.info(sanitizeLogMessage('[🐑] [DST] [OrderEntry] [V1]'), destStateV1);
            const diff = valueDiff(srcStateV1, destStateV1);
            console.warn(sanitizeLogMessage('[🐑] [DIFF] [OrderEntry] [V1->V2->V1]'), diff || '🎊Tutto OK✨');
            alert(diff ? JSON.stringify(diff, null, 4) : '🎊Tutto OK✨');
        } catch (e) {}
    }

    getAuthTokenByD365() {
        this.authSrv
            .getAuthToken()
            .pipe(
                tap((auth) => {
                    this.logger.debug('Authorization Token', auth);
                    window.alert(auth?.authorization);
                })
            )
            .subscribe();
    }

    resetOEState(): void {
        this.apttusSrv.clearState();
        this.supStateSrv.saveSupState('dbar').toPromise();
    }

    fromCartReq(): void {
        this.cartToQuoteRequestSrv.getCartToQuoteReq().toPromise();
    }

    onActivateCartId(aCartId: string) {
        if (!aCartId) return;

        this.moreConfigModal.hide();
        return this.cartSrv
            .getCartWithId(aCartId)
            .pipe(
                tap((cart) => {
                    if (!cart) {
                        throw new Error(`Carrello ${aCartId} inesistente o fuori matrice di visibilità `);
                    }
                }),
                mergeMap((cart: EglCartExtended) =>
                    this.supStateSrv
                        .restoreSupStateByCartId(aCartId, {
                            cartSegment: cart.egl_customer_type,
                            orderEntry: {
                                flowType: aptSalesProcessToFlowType(cart?.egl_sales_process),
                            },
                        })
                        .pipe(map(() => cart))
                ),
                LoadingService.loaderOperator('Attivazione carrello in corso'),
                tap((cart) => {
                    CartService.setCurrentCartId(cart.Id);
                    this.apttusSrv.setCartId(cart.Id);
                    this.cartSrv.publish(cart);
                })
            )
            .toPromise();
    }

    navigate(type: 'next' | 'back') {
        type === 'next' ? this.routerSrv.next() : this.routerSrv.back();
    }

    openOtherInfoModal() {
        const localstorageData: { key: string; value: string }[] = [];
        for (let i = 0; i < localStorage.length; ++i) {
            const key = localStorage.key(i);
            localstorageData.push({ key, value: localStorage.getItem(key) });
        }
        this.otherInfos = {
            columns: [
                { field: 'key', title: 'Key' },
                { field: 'value', title: 'Value' },
            ],
            options: [...localstorageData],
        };
        this.otherInfoModal.show();
    }

    momentLocale() {
        console.log(moment.locale());
        console.log(moment('2000-11-30').localeData());
    }

    get currentCardId(): string {
        return CartService.getCurrentCartId();
    }

    getAsAny(value: unknown): any {
        return value;
    }
}
