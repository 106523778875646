export enum AtecoClassification6409 {
    AltriUsi = 'ALTRI USI',
    Condominio = 'CONDOMINIO',
    Domestico = 'DOMESTICO',
    GestioneCalore = 'GESTIONE CALORE',
    ServizioPublico = 'SERVIZIO PUBLICO',
}

export const ATECO_CLASSIFICATION6409_MAP: {
    [key in AtecoClassification6409]: number;
} = {
    'ALTRI USI': 685980000,
    CONDOMINIO: 685980001,
    DOMESTICO: 685980002,
    'GESTIONE CALORE': 685980003,
    'SERVIZIO PUBLICO': 685980004,
};
