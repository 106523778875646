export interface IsCustomer {
    isCustomerFlag: boolean;
    internalId: string;
    startActivationDate: Date;
}

export interface IsFormerCustomer {
    isFormerCustomerFlag: boolean;
    ceaseReasonCode: string;
}

export interface AcquisitionAgency {
    codeAgency: string;
    descAgency: string;
}

export interface Municipality {
    name: string;
}

export interface ShippingAddress {
    postCode: string;
    provinceCode: string;
    dug: string;
    street: string;
    number: string;
    municipality: Municipality;
}

export interface CustomerContactInfo {
    email: string;
    phoneNumbers: string[];
    shippingAddress: ShippingAddress;
}

export interface Municipality2 {
    name: string;
}

export interface SupplyAddress {
    postCode: string;
    provinceCode: string;
    dug: string;
    street: string;
    number: string;
    municipality: Municipality2;
}

export interface SupplyPointsInfo {
    type: string;
    usageValue: number;
    supplyAddress: SupplyAddress;
}

export interface PaymentInfo {
    paymentMode: string;
}

export class RetrieveCreditCheckRequest {
    checkTypes: string;
    fiscalCode?: string;
    vatNumber?: string; // se valorizzato 'vatNumber' non deve essere valorizzato e presente il campo 'fiscalCode'. Non accettato valore null/vuoto.
    customerId?: string;
    iban?: string;
    phone?: string;
    email?: string;
    cervedVerification?: CervedVerification;
}

export enum LegalFormType {
    AltreSocieta = 'ALTRE SOCIETA',
    Asl = 'ASL',
    Associazione = 'ASSOCIAZIONE',
    AziendaOspedaliera = 'AZIENDA OSPEDALIERA',
    Condominio = 'CONDOMINIO',
    Consorzio = 'CONSORZIO',
    Cooperativa = 'COOPERATIVA',
    Ente = 'ENTE',
    Fondazione = 'FONDAZIONE',
    GruppoSocietario = 'GRUPPO SOCIETARIO',
    ImpresaIndividuale = 'IMPRESA INDIVIDUALE',
    IstituzioneReligiosa = 'ISTITUZIONE RELIGIOSA',
    Onlus = 'ONLUS',
    PersonaFisicaPrivato = 'PERSONA FISICA (PRIVATO)',
    Sas = 'SAS',
    Snc = 'SNC',
    SpaSapa = 'SPA/SAPA',
    Srl = 'SRL',
    Srls = 'SRLS',
    StudioAssociato = 'STUDIO ASSOCIATO',
}

export interface CervedVerification {
    subjectType: 'PF' | 'PG';
    customerType: LegalFormType;
    scoreCardId: string;
    taxCode: string;
    surname: string;
    name: string;
    Iban: string;
    customerTransactionId: string;
    paymentScore: string;
    isCustomer: IsCustomer;
    isFormerCustomer: IsFormerCustomer;
    acquisitionAgency: AcquisitionAgency;
    aquisitionChannel: string;
    operatingMode: string;
    checkType: 'SCIPAFI' | 'SCORECARD' | 'ANTIFRODE';
    customerContactInfo: CustomerContactInfo;
    supplyPointsInfo: SupplyPointsInfo[];
    paymentInfo: PaymentInfo;
}

export interface InfoLog {
    timestamp: string;
    sourceSystem: string;
    transactionId: string;
    sessionId: string;
    correlationId: string;
    userId: string;
}

export interface RetrieveInsolutoRequest {
    InfoLog?: InfoLog;
    codice_fiscale?: string;
    partita_iva?: string;
    iban?: string;
    telefono?: string;
    email?: string;
}

export interface InfoLog {
    timestamp: string;
    sourceSystem: string;
    transactionId: string;
    sessionId: string;
    correlationId: string;
    userId: string;
}

export interface RetrieveInsolutoRequest {
    InfoLog?: InfoLog;
    codice_fiscale?: string;
    partita_iva?: string;
    iban?: string;
    telefono?: string;
    email?: string;
}
