import { AptProductType } from '../enums/apttus/apt-product-type';
import { D365ProductType } from '../enums/d365/d365-product-type';
import { ProductTypeInput } from '../functions/verifications.functions';

export const APT_D365_PRODUCT_TYPE_MAP: {
    [key in ProductTypeInput]?: D365ProductType;
} = {
    [AptProductType.AssicurazioneAncillare]: D365ProductType.Polizza,
    [AptProductType.AssicurazioneIntermediata]: D365ProductType.Polizza,
    [AptProductType.SmartHome]: D365ProductType.SmartHome,
    [AptProductType.Manutenzione]: D365ProductType.Manutenzione,
    [AptProductType.Complex_pompe_calore]: D365ProductType.PompeDiCalore,
    [AptProductType.Complex_condizionatori]: D365ProductType.Climatizzatore,
    [AptProductType.Complex_caldaia]: D365ProductType.Caldaia,
    [AptProductType.Complex_scaldacqua]: D365ProductType.ScaldabagnoAGas,
    [AptProductType.Complex_sist_ric_elettrica]: D365ProductType.Wallbox,
    [AptProductType.Sopralluogo]: D365ProductType.Sopralluogo,
};
