import { Injectable, Injector } from '@angular/core';
import {
    ACondition,
    AObjectService,
    CacheService,
    ConfigurationService,
    MetadataService,
    PlatformService,
} from '@congacommerce/core';
import { EglTaxDeduction } from '../../../../models/apttus/tables/taxDeduction/egl-tax-deduction';

@Injectable({
    providedIn: 'root',
})
export class EglTaxDeductionService extends AObjectService {
    type = EglTaxDeduction;

    constructor(
        cacheService: CacheService,
        platformService: PlatformService,
        metadataService: MetadataService,
        configurationService: ConfigurationService,
        injector: Injector
    ) {
        super(cacheService, platformService, metadataService, configurationService, injector);
    }

    getTaxDeductionDescription(taxDeductionId: string) {
        const conditions = [new ACondition(this.type, 'egl_tax_detraction__c', 'Includes', taxDeductionId)];
        const searchRecords$ = this.where(conditions);
        return searchRecords$;
    }

    getTaxDeductionList() {
        const conditions = [new ACondition(this.type, 'egl_tax_deduction_id', 'NotNull', null)];
        const searchRecords$ = this.query({
            conditions: conditions,
            cacheStrategy: 'performance',
        });
        return searchRecords$;
    }
}
