import { DoActionBody } from '../../../modules/back-office/models/do-action-body';
import { AptOperationType } from '../../enums/apttus/apt-operation-type';

export class ApttusDoOperationRequest {
    Quote: Quote;
    constructor(id: string, type: AptOperationType | string, params?: any) {
        this.Quote = new Quote(id, type, params);
    }
}

export class ApttusDoOperationResponse {
    ErrorMessage: string;
    Result: '001' | '002';
    Status: string;
}

class Quote {
    QuoteId: string;
    Operation: AptOperationType | string;
    Body?: DoActionBody;
    constructor(id: string, type: AptOperationType | string, body?: DoActionBody) {
        this.QuoteId = id;
        this.Operation =
            type === AptOperationType[AptOperationType.RIFIUTA_DOCUMENTO]
                ? AptOperationType[AptOperationType.RIFIUTA]
                : type;
        this.Body = { ...body, ReasonType: type as string };
    }
}
