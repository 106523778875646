import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ImagePipe } from '@congacommerce/core';
import { CartItem, CartService, StorefrontService } from '@congacommerce/ecommerce';
import {
    ExceptionService,
    MiniCartComponent,
    MiniCartService,
    ProductConfigurationService,
    RevalidateCartService,
} from '@congacommerce/elements';
import { Store } from '@ngrx/store';
import { from, of, Subscription } from 'rxjs';
import { catchError, concatMap, isEmpty, map, mergeMap, take, tap, toArray } from 'rxjs/operators';
import { ESEProvider } from '../../../../modules/common/order-entry/steps/appointment/providers/ese-provider';
import { FlowType } from '../../../../store/models/flow-type';
import { CartInfo } from '../../../../store/models/order-entry-state';
import { EglState } from '../../../../store/reducers';
import { v2SelectExtracommoditiesByFilter } from '../../../../store/selectors/order-entry-v2.selectors';
import { selectCartInfo, selectCpData, selectFlowType } from '../../../../store/selectors/order-entry.selectors';
import { RoutesPaths, urlBuilder } from '../../../config/routes-paths';
import { AptProductFamily } from '../../../enums/apttus/apt-product-family';
import { FieldServiceFamilyCode } from '../../../enums/shared/field-service-family-code.enum';
import { SegoeMDL2Font } from '../../../enums/shared/segoe-mdl2-font';
import { statusCodeEnum } from '../../../enums/shared/status-code.enum';
import { isNotDiscountItem, isNotTechAssetItem, isProductPannello } from '../../../functions/misc.functions';
import { flowTypeUtil, isAdminChangeProduct, isCommodityFamily } from '../../../functions/verifications.functions';
import { EglCartItemExtended } from '../../../models/apttus/tables/cart/egl-cart-item-extended';
import { ApttusService } from '../../../services/apttus/apttus.service';
import { LoadingService } from '../../../services/shared/loading.service';
import { LocalStorageGenericService } from '../../../services/shared/local-storage-generic.service';
import { LoggerService } from '../../../services/shared/logger.service';
import { DragonRouterService } from '../../../services/shared/router/dragon-router.service';
import { MainHeaderService } from '../../header/main/service/main-header.service';
@Component({
    selector: 'egl-mini-cart',
    templateUrl: './egl-mini-cart.component.html',
    styleUrls: ['./egl-mini-cart.component.scss'],
})
export class EglMiniCartComponent extends MiniCartComponent implements OnInit, OnDestroy {
    sub: Subscription[] = [];
    cartIcon = SegoeMDL2Font.ShoppingCart;
    cartInfo: CartInfo;
    cartSrv: CartService;
    itemsCount: number;
    isChangeProduct: boolean;
    cartSubscription: Subscription;
    itemGettingRemoved = false;
    imageCache = {};
    flowType: FlowType;

    constructor(
        cartService: CartService,
        sanitizer: DomSanitizer,
        exceptionService: ExceptionService,
        router: Router,
        storeFrontSrv: StorefrontService,
        productConfigurationSrv: ProductConfigurationService,
        miniCartSrv: MiniCartService,
        revalidateCartSrv: RevalidateCartService,
        private routerSrv: DragonRouterService,
        private apttusService: ApttusService,
        private store: Store<EglState>,
        private logger: LoggerService,
        private apttusSrv: ApttusService,
        private imagePipe: ImagePipe,
        private headerSrv: MainHeaderService,
        private esePrv: ESEProvider,
        private localStorageSrv: LocalStorageGenericService,
    ) {
        super(
            cartService,
            storeFrontSrv,
            sanitizer,
            exceptionService,
            router,
            productConfigurationSrv,
            miniCartSrv,
            revalidateCartSrv,
        );
        this.cartSrv = cartService;
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.cartItems$ = this.cartItems$.pipe(
            map((cartItems: EglCartItemExtended[]) =>
                (cartItems || []).filter(
                    (item) =>
                        isNotTechAssetItem(item) &&
                        item?.LineType === 'Product/Service' &&
                        item?.Product &&
                        !isProductPannello(item),
                ),
            ),
        );
        //Verifico se l'utente può vendere in salesUp, in caso negativo esco senza sottoscrivermi alle altre sottoscrizioni

        this.sub.push(
            this.store.select(selectCpData).subscribe((x) => {
                this.isChangeProduct = x.isChangeProduct;
            }),
        );

        this.sub.push(
            this.store.select(selectFlowType).subscribe((x) => {
                this.flowType = x;
            }),
        );

        this.sub.push(
            this.store.select(selectCartInfo).subscribe((res: CartInfo) => {
                this.cartInfo = res || new CartInfo();
            }),
        );

        this.sub.push(
            this.apttusSrv.currentSubject.subscribe((input: boolean) => {
                this.itemGettingRemoved = input;
            }),
        );
    }

    isItemEditable(item: EglCartItemExtended): boolean {
        return (
            !this.isScontoStandalone &&
            !isAdminChangeProduct(this.flowType) &&
            item?.Id &&
            item?.LineStatus !== 'Cancelled' &&
            isNotDiscountItem(item)
        );
    }

    isItemQuantity(item: EglCartItemExtended): boolean {
        return (
            !isCommodityFamily(<AptProductFamily>item?.Product?.Family) &&
            isNotDiscountItem(item) &&
            !this.isScontoStandalone
        );
    }

    isNotDiscountItem(item: EglCartItemExtended): boolean {
        return isNotDiscountItem(item);
    }

    get isScontoStandalone(): boolean {
        return flowTypeUtil(this.flowType).equalTo(FlowType.ScontoStandalone);
    }

    ngOnDestroy(): void {
        (this.sub || []).forEach((x) => x.unsubscribe());
    }

    /*
     * DELETE PRODUCT FROM CART
     * CHECK IF THE CURRENT IS THE PRODUCT DETAIL PAGE
     * EXAMPLE: https://xxxxxx/products/productId/cartId
     */
    removeCartItem(item: CartItem, evt: any): void {
        if (location.pathname.indexOf('order-entry') !== -1) {
            this.logger.warn('Attenzione! Non è possibile rimuovere prodotti in questo step. Torna al catalogo', true);
            return;
        }
        evt.stopPropagation();
        super.removeCartItem(item, evt);
        if (location.pathname.indexOf(RoutesPaths.AllProducts)) {
            const splittedPathName = location.pathname.split('/');
            if (splittedPathName.length === 4 && splittedPathName[2] === item.Product.Id) {
                splittedPathName.pop();
                const newUrl = splittedPathName.join('/');
                this.routerSrv.navigate([newUrl]);
            }
        }
    }

    onClearCartClick(): void {
        this.store
            .select(v2SelectExtracommoditiesByFilter())
            .pipe(
                take(1),
                map((products) => products.filter((p) => p.booking)),
                mergeMap((products) => from(products)),
                concatMap(({ name, booking: { bookingId } }) =>
                    this.esePrv
                        .setBookableResources(
                            FieldServiceFamilyCode.NDS,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            bookingId,
                            statusCodeEnum.Canceled,
                        )
                        .pipe(
                            catchError((err) => {
                                this.logger.warn(
                                    `Impossibile cancellare la prenotazione per il prodotto ${name}. Segnala questa anomalia`,
                                    true,
                                    { err },
                                );
                                return of(null);
                            }),
                            LoadingService.loaderOperator('Annullamento prenotazione'),
                        ),
                ),
                toArray(),
                isEmpty(),
                mergeMap(() => this.apttusService.clearAndCreateNewCart$([RoutesPaths.Dashboard])),
                tap(() => {
                    this.apttusService.clearState();
                    this.localStorageSrv.productsZip = null;
                }),
                tap(() => {
                    const headerConfig = this.headerSrv.selectedHeaderConfig;
                    if (headerConfig) {
                        this.headerSrv.discardActionDdl(headerConfig);
                    }
                }),
                catchError((err) => {
                    this.logger.error('Svuota carrello', err?.message, true, true);
                    return of(null);
                }),
            )
            .subscribe();
    }

    productDetail(productId: string, lineItemId: string): void {
        if (this.isChangeProduct) {
            this.routerSrv.navigate([urlBuilder(RoutesPaths.CPProductConfiguration, [productId, lineItemId])]);
        } else {
            this.routerSrv.goToProductDetail(productId, lineItemId);
        }
    }

    getImageUrl(imageId: string) {
        if (!this.imageCache[imageId]) {
            this.imagePipe.url = null;
            const imgUrl = this.imagePipe.transform(imageId, undefined, false);
            this.imageCache[imageId] = imgUrl;
        }
        return this.imageCache[imageId];
    }

    getAsAny(value: unknown): any {
        return value;
    }
}
