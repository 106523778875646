import { Expose } from 'class-transformer';

export class D365WorkOrderLogisticData {
    @Expose({ name: 'NumeroWorkOrder' })
    workOrderNumber: string;

    @Expose({ name: 'TipoWorkOrder' })
    workOrderType: string;

    @Expose({ name: 'StatoWorkOrder@OData.Community.Display.V1.FormattedValue' })
    workOrderStatus: string;

    @Expose({ name: 'NomeSottoStato' })
    workOrderSubStatus: string;

    @Expose({ name: 'NomeProdotto' })
    productName: string;

    @Expose({ name: 'DataCreazione' })
    creationDate: string;

    @Expose({ name: 'TrackingLink' })
    trackingLink: string;

    @Expose({ name: 'DataUltimoAggiornamento' })
    lastUpdateDate: string;

    @Expose({ name: 'IndirizzoDiSpedizione@OData.Community.Display.V1.FormattedValue' })
    shippingAddress: string;

    @Expose({ name: 'IndirizzoDiSpedizioneEse@OData.Community.Display.V1.FormattedValue' })
    eseShippingAddress: string;

    @Expose({ name: 'IdProvider@OData.Community.Display.V1.FormattedValue' })
    providerShipment: string;

    @Expose({ name: 'SerialNumber' })
    serialNumber: string;

    @Expose({ name: 'ValiditaGaranzia' })
    validitaGaranzia: string;

    assetExternalId?: string;
}
