import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Product } from '../../../store/models/order-entry-state_v2';
import { AptProductType } from '../../enums/apttus/apt-product-type';
import {
    containsCommodity,
    containsProductMaintenance,
    isHealthInsurance,
    containsProductComplex,
} from '../../functions/verifications.functions';
import { SubProductType } from '../../enums/shared/sub-product-type';

@Injectable({
    providedIn: 'root',
})
export class SetLabelsService {
    private prefixLabelInsurances = 'ORDER_ENTRY.ADDRESS_DATA.';
    private prefixLabel = 'COMMON.FORM.';
    constructor(private translateService: TranslateService) {}

    setPlaceholders(product: Product, addressType?: AddressTypeEnum): string {
        switch (true) {
            case addressType === 'ShippingAddress':
            case product?.productType === AptProductType.SmartHome:
                return this.prefixLabelInsurances + 'TITLE_ADDRESS_SMARTHOME';
            case addressType === 'DomicileAddress':
                return this.prefixLabelInsurances + 'TITLE_ADDRESS_LIVING';
            case addressType === 'ResidentialAddress':
            case product?.subProductType === SubProductType.RCDanni:
                return this.prefixLabelInsurances + 'TITLE_ADDRESS_RESIDENCE';
            case addressType === 'CommunicationAddress':
                return this.prefixLabelInsurances + 'TITLE_ADDRESS_SUPPLY';
            case isHealthInsurance(product):
                return this.prefixLabelInsurances + 'TITLE_ADDRESS_LIVING';
            case product?.subProductType === SubProductType.Implant:
                return this.prefixLabelInsurances + 'TITLE_ADDRESS_INSURANCE_ANC';
            case product?.subProductType === SubProductType.Appliance:
            case containsCommodity(product?.productType):
            case containsProductMaintenance(product?.productType):
            case containsProductComplex(product?.productType):
                return this.prefixLabelInsurances + 'TITLE_ADDRESS_DEFAULT';
            default:
                return this.prefixLabel + 'ADDRESS';
        }
    }

    setLabel(product: Product): string {
        switch (true) {
            case isHealthInsurance(product):
                return this.translateService.instant(this.prefixLabelInsurances + 'LABEL_ADDRESS_LIVING');
            case product?.subProductType === SubProductType.Implant:
                return this.translateService.instant(this.prefixLabelInsurances + 'LABEL_ADDRESS_INSURANCE_ANC');
            case product?.subProductType === SubProductType.RCDanni:
                return this.translateService.instant(this.prefixLabelInsurances + 'LABEL_ADDRESS_RESIDENCE');
            case product?.productType === AptProductType.SmartHome:
                return this.translateService.instant(this.prefixLabelInsurances + 'LABEL_ADDRESS_SMARTHOME');
            case product?.subProductType === SubProductType.Appliance:
            case containsProductMaintenance(product?.productType):
            case containsCommodity(product?.productType):
            case containsProductComplex(product?.productType):
            default:
                return this.translateService.instant(this.prefixLabelInsurances + 'LABEL_ADDRESS_DEFAULT');
        }
    }
}

export type AddressTypeEnum = 'ShippingAddress' | 'ResidentialAddress' | 'CommunicationAddress' | 'DomicileAddress';
