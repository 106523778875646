export enum AptLineStatus {
    Cancelled = 'Cancelled',
    Renewed = 'Renewed',
    Upgraded = 'Upgraded',
    Amended = 'Amended',
    Incremented = 'Incremented',
    New = 'New',
    Suspended = 'Suspended',
    Activated = 'Activated',
}
