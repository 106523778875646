import { AField, AObject, ATable } from '@congacommerce/core';
import { Type } from 'class-transformer';
import { AptLineStatus } from '../../../../enums/apttus/apt-line-status';
import { EglAddress } from '../address/egl-address';
import { EglQuotePAVLight } from '../pav/egl-quote-p-a-v-light';
import { EglProductLight } from '../product/egl-product-light';

@ATable({
    sobjectName: 'Apttus_Proposal__Proposal_Line_Item__c',
    aqlName: null,
})
export class EglQuoteLineItemLight extends AObject {
    constructor() {
        super();
    }

    @AField({ soql: 'IsPrimaryLine' })
    IsPrimaryLine: boolean;

    @AField({ soql: 'Apttus_QPConfig__LocationId__c' })
    Apttus_QPConfig__LocationId: string;

    @AField({ soql: 'egl_digitalcomm' })
    egl_digitalcomm: boolean;

    @AField({ soql: 'egl_immediate_effect' })
    egl_immediate_effect: boolean;

    @AField({ soql: 'egl_iscontextualsale' })
    egl_iscontextualsale: boolean;

    @AField({ soql: 'egl_commodity_typeofsale' })
    egl_commodity_typeofsale: string;

    @AField({ soql: 'AdjustedPrice' })
    AdjustedPrice: number;

    @AField({ soql: 'egl_precheck_description' })
    egl_precheck_description: string;

    @AField({ soql: 'egl_precheck_result' })
    egl_precheck_result: string;

    @AField({
        soql: 'Apttus_QPConfig__AttributeValueId__c',
        expand: 'shallow',
    })
    @Type(() => EglQuotePAVLight)
    AttributeValue: EglQuotePAVLight;

    @AField({
        soql: 'Apttus_Proposal__Product__c',
        expand: 'shallow',
    })
    @Type(() => EglProductLight)
    Product: EglProductLight;

    @AField({ soql: 'ConfigurationId' })
    ConfigurationId: string;

    @AField({ soql: 'egl_precheck_description__c', aql: 'precheckDescription' })
    precheckDescription: string;

    @AField({ soql: 'egl_precheck_result__c', aql: 'precheckResult' })
    precheckResult: string;

    @AField({ soql: 'LineStatus' })
    LineStatus: AptLineStatus;

    @AField({ soql: 'egl_brick_type__c' })
    egl_brick_type: string;

    @AField({ soql: 'egl_invoice_label__c' })
    egl_invoice_label: string;

    @AField({ soql: 'egl_createdon__c' })
    egl_createdon: Date;

    @AField({ soql: 'Apttus_QPConfig__NetPrice__c' })
    NetPrice: number;

    @AField({ soql: 'Apttus_Proposal__Description__c' })
    Description: string;

    @AField({
        soql: 'egl_service_address_id__c',
        expand: 'shallow',
    })
    @Type(() => EglAddress)
    egl_service_address_: EglAddress;

    @AField({
        soql: 'egl_shipment_address_id__c',
        expand: 'shallow',
    })
    @Type(() => EglAddress)
    egl_shipment_address_: EglAddress;
}
