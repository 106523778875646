import { FieldMap } from '../../../utility/functions/map-fields';
import { FetchXmlQuery } from './fetch-xml-manager';

/**
 * @description Recupera i dati legati al serial number
 * @param assetExternalId
 */
export function getSerialNumberXmlQuery(assetExternalId: string): FetchXmlQuery {
    const entity = 'egl_workorderitem';
    return {
        entity,
        query: `
    <?xml version="1.0" encoding="UTF-8"?>
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="${entity}">
    <attribute name="egl_code" />
    <attribute name="egl_workorderlogisticid" />
    <attribute name="egl_warrantyenddate" />
    <attribute name="egl_productid" />
    <attribute name="egl_serialnumber" />
    <attribute name="egl_isreplacedgoods" />
    <attribute name="egl_quantity" />
    <attribute name="egl_workorderitemid" />
    <order attribute="egl_code" descending="false" />
    <filter type="and">
      <condition attribute="egl_isreplacedgoods" operator="eq" value="0" />
      <condition attribute="egl_serialnumber" operator="not-null" />
    </filter>
    <link-entity name="egl_workorderlogistic" from="egl_workorderlogisticid" to="egl_workorderlogisticid" link-type="inner" alias="ak">
      <attribute name="egl_assetexternalid" />
      <filter type="and">
        <condition attribute="egl_assetexternalid" operator="eq" value="${assetExternalId}" />
      </filter>
    </link-entity>
  </entity>
</fetch>
        `,
    };
}

export interface D365SerialNumber {
    egl_serialnumber: string; // Numero seriale
}

export interface SupSerialNumber {
    serialNumber: string; // Original: egl_serialnumber - Numero seriale
}

export const D365ToSupSerialNumberFieldMap: FieldMap<D365SerialNumber, SupSerialNumber>[] = [
    { sourceField: 'egl_serialnumber', resultField: 'serialNumber' },
];
