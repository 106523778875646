import { ProductOptionComponent } from '@congacommerce/ecommerce';
import { Expose, Type } from 'class-transformer';
import { EglProductExtended } from './egl-product-extended';

export class EglProductOptionComponent extends ProductOptionComponent {
    @Expose({ name: 'egl_product_recordtype__c' })
    egl_product_recordtype: AptProductRecordType;

    @Expose({ name: 'Apttus_Config2__ComponentProductId__r' })
    @Type(() => EglProductExtended)
    ComponentProduct: EglProductExtended;
}

export enum AptProductRecordType {
    BeneNds = 'Mattoncino NDS Bene',
}
