import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { LoggerService } from './logger.service';

@Injectable({
    providedIn: 'root',
})
export class CookieService {
    private readonly documentIsAccessible: boolean;
    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId,
        private logger: LoggerService
    ) {
        this.documentIsAccessible = isPlatformBrowser(this.platformId);
    }

    // prettier-ignore
    set(name: string, value: string, expires?: CookieOptions['expires'], path?: CookieOptions['path'], domain?: CookieOptions['domain'], secure?: CookieOptions['secure'], sameSite?: SameSite): void;
    set(name: string, value: string, options?: CookieOptions): void;
    set(
        name: string,
        value: string,
        expiresOrOptions?: CookieOptions['expires'] | CookieOptions,
        path?: CookieOptions['path'],
        domain?: CookieOptions['domain'],
        secure?: CookieOptions['secure'],
        sameSite?: SameSite
    ): void {
        if (!this.documentIsAccessible) {
            this.logger.warn('[COOKIE] document is not accessible');
            return;
        }

        if (
            typeof expiresOrOptions === 'number' ||
            expiresOrOptions instanceof Date ||
            path ||
            domain ||
            secure ||
            sameSite
        ) {
            const optionsBody = {
                expires: expiresOrOptions as CookieOptions['expires'],
                path,
                domain,
                secure,
                sameSite: sameSite ? sameSite : 'Lax',
            };

            this.set(name, value, optionsBody);
            return;
        }

        let cookieString: string = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';';
        const options = expiresOrOptions ? expiresOrOptions : {};

        if (options.expires || options.maxAge) {
            if (options.maxAge) {
                // Internet Explorer (ie6, ie7, and ie8) does not support "max-age", while (mostly) all browsers support expires
                // in this code it used "expires" instead "max-age"
                const dateExpires: Date = new Date(new Date().getTime() + options.maxAge * 1000);
                cookieString += 'expires=' + dateExpires.toUTCString() + ';';
            } else if (typeof options.expires === 'number') {
                const dateExpires: Date = new Date(new Date().getTime() + options.expires * 1000 * 60 * 60 * 24);
                cookieString += 'expires=' + dateExpires.toUTCString() + ';';
            } else {
                cookieString += 'expires=' + options.expires.toUTCString() + ';';
            }
        }
        if (options.path) {
            cookieString += 'path=' + options.path + ';';
        }
        if (options.domain) {
            cookieString += 'domain=' + options.domain + ';';
        }

        if (options.secure === false && options.sameSite === 'None') {
            options.secure = true;
            this.logger.warn(`[COOKIE] Cookie ${name} was forced with secure flag because sameSite=None.`);
        }
        if (!options.sameSite) {
            options.sameSite = 'None'; // Default None;secure;
            options.secure = true;
        }
        if (options.secure) {
            cookieString += 'secure;';
        }
        cookieString += 'sameSite=' + options.sameSite + ';';
        this.document.cookie = cookieString;
    }

    /**
     * Get cookies by name
     *
     * @param name Cookie name
     * @returns property value
     *
     */
    get(name: string): string {
        if (this.documentIsAccessible && this.check(name)) {
            name = encodeURIComponent(name);

            const regExp: RegExp = CookieService.getCookieRegExp(name);
            const result: RegExpExecArray = regExp.exec(this.document.cookie);

            return result[1] ? CookieService.safeDecodeURIComponent(result[1]) : '';
        } else {
            return '';
        }
    }

    /**
     * Get all cookies in JSON format
     *
     * @returns all the cookies in json
     */
    getAll(): { [key: string]: string } {
        if (!this.documentIsAccessible) {
            return {};
        }

        const cookies: { [key: string]: string } = {};
        const document: any = this.document;

        if (document.cookie && document.cookie !== '') {
            document.cookie.split(';').forEach((currentCookie: string) => {
                const [cookieName, cookieValue] = currentCookie.split('=');
                cookies[CookieService.safeDecodeURIComponent(cookieName.replace(/^ /, ''))] =
                    CookieService.safeDecodeURIComponent(cookieValue);
            });
        }

        return cookies;
    }

    check(name: string): boolean {
        if (!this.documentIsAccessible) {
            return false;
        }
        name = encodeURIComponent(name);
        const regExp: RegExp = CookieService.getCookieRegExp(name);
        return regExp.test(this.document.cookie);
    }

    /**
     * Delete cookie by name
     *
     * @param name   Cookie name
     * @param path   Cookie path
     * @param domain Cookie domain
     * @param secure Cookie secure flag
     * @param sameSite Cookie sameSite flag - https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
     *
     */
    delete(
        name: string,
        path?: CookieOptions['path'],
        domain?: CookieOptions['domain'],
        secure?: CookieOptions['secure'],
        sameSite: SameSite = 'Lax'
    ): void {
        if (!this.documentIsAccessible) {
            return;
        }
        const expiresDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');
        this.set(name, '', { expires: expiresDate, path, domain, secure, sameSite });
    }

    /**
     * Delete all cookies
     *
     * @param path   Cookie path
     * @param domain Cookie domain
     * @param secure Is the Cookie secure
     * @param sameSite Is the cookie same site
     *
     */
    deleteAll(
        path?: CookieOptions['path'],
        domain?: CookieOptions['domain'],
        secure?: CookieOptions['secure'],
        sameSite: SameSite = 'Lax'
    ): void {
        if (!this.documentIsAccessible) {
            return;
        }

        const cookies: any = this.getAll();

        for (const cookieName in cookies) {
            if (cookies.hasOwnProperty(cookieName)) {
                this.delete(cookieName, path, domain, secure, sameSite);
            }
        }
    }
    private static getCookieRegExp(name: string): RegExp {
        const escapedName: string = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi, '\\$1');
        return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
    }

    private static safeDecodeURIComponent(encodedURIComponent: string): string {
        try {
            return decodeURIComponent(encodedURIComponent);
        } catch {
            // probably it is not uri encoded. Return as is.
            return encodedURIComponent;
        }
    }
}

export type SameSite = 'Lax' | 'None' | 'Strict';
export interface CookieOptions {
    expires?: number | Date; // days or expiration Date
    maxAge?: number; // validity in seconds
    path?: string;
    domain?: string;
    secure?: boolean;
    sameSite?: SameSite;
}

export enum CookieName {
    OAuth2CookieName = 'salesup_mfs_token',
}
