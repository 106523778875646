export enum D365SignatureType {
    PaperPrintedBySystem = 100000000, //stampa firma carica
    Cartacea = 100000001,
    Grafometrica = 100000002,
    VocalOrder = 100000003,
    Doi = 100000004,
    DoiLight = 100000005,
    NessunaFirma = 100000006,
}

export const D365_SIGNATURE_TYPES = Object.values(D365SignatureType).filter(
    (signatureType): signatureType is D365SignatureType => typeof signatureType !== 'string'
);

//[RV]: DECODE DEI TIPI DI FIRMA PER RIEPILOGO DI VARIAZIONE AUMENTO POTENZA
export const D365_SIGN_TYPE_TO_STRING: {
    [key in D365SignatureType]: string;
} = {
    [D365SignatureType.Cartacea]: 'Cartacea',
    [D365SignatureType.Grafometrica]: 'Grafometrica',
    [D365SignatureType.VocalOrder]: 'Vocal Order',
    [D365SignatureType.Doi]: 'Doi',
    [D365SignatureType.DoiLight]: 'Doi Light',
    [D365SignatureType.NessunaFirma]: 'Nessuna Firma',
    [D365SignatureType.PaperPrintedBySystem]: 'Stampa, Firma, Carica',
};
