import { AttributeValues } from './base-attribute-values';

export class SmartHomeAttributeValues extends AttributeValues {
    attributesMapping(): void {
        super.attributesMapping();

        this.attr['egl_neta_rateizzo_flag__c'] = this.currentProduct?.configurations?.installmentFlag;
        this.attr['egl_neta_cessioneCredito_flag__c'] = this.currentProduct?.configurations?.creditAssignment;
        this.attr['egl_neta_ritenutaDAcconto_flag__c'] = this.currentProduct?.configurations?.withHoldingTax;
        this.attr['egl_neta_productClass__c'] = this.currentProduct?.configurations?.productClass;
    }
}
