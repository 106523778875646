import { AptDeliveryChannelQuote } from './apt-delivery-channel';

export enum AptBillType {
    Cartacea = 'Cartacea',
    Digitale = 'Digitale',
}

export const APT_BILL_TYPE_TO_DELIVERY_CHANNEL_QUOTE_MAP = {
    [AptBillType.Cartacea]: AptDeliveryChannelQuote.Stampa,
    [AptBillType.Digitale]: AptDeliveryChannelQuote.Digitale,
};
