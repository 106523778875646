export class FormBonifico {
    intestatarioConto?: IntestarioConto;
    iban: string;
    isIbanEstero?: boolean;
    titolareConto?: TitolareConto;
    sottoscrittoreSepa?: SottoscrittoreSepa;
    settoreAppartenenza?: string;
    constructor() {
        this.titolareConto = new TitolareConto();
        this.sottoscrittoreSepa = new SottoscrittoreSepa();
    }
}

export class TitolareConto {
    ragioneSociale?: string;
    piva?: string;
    cfAzienda?: string;
    tipologia?: string;

    cf: string;
    nome?: string;
    cognome?: string;
    fullName?: string;
}

export class SottoscrittoreSepa {
    cf: string;
    nome: string;
    cognome: string;
}

export enum IntestarioConto {
    Cliente = 'Cliente',
    Azienda = 'Azienda',
    PersonaGiuridica = 'Persona Giuridica',
    PersonaFisica = 'Persona Fisica',
}
