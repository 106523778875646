import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { delay, map } from 'rxjs/operators';
import { EglState } from '../store/reducers';
import { selectBars } from '../store/selectors/app.selectors';

@Component({
    selector: 'egl-main',
    template: `<main class="egl-main">
        <egl-header></egl-header>
        <div class="order-entry">
            <router-outlet></router-outlet>
        </div>
    </main>`,
    styles: [
        `
            egl-header {
                /* position: fixed; */
                display: 'block';
                width: 100%;
                /* z-index: 4; */
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class OrderEntryOutletComponent {
    //Imposto un delay(0) per evitare che venga scatenato il warn "Expression has changed after it was checked"
    paddingPage$ = this.store.select(selectBars).pipe(
        delay(0),
        map((bars) => {
            const spaces = [44];
            // spaces.push(bars.hasDevBar ? 18 : 0);
            spaces.push(bars.hasContactBar ? 90 : 0);
            spaces.push(bars.hasProgressBar ? 80 : 0);
            return spaces.reduce((a, b) => a + b) + 'px';
        })
    );
    constructor(private store: Store<EglState>) {}
}
