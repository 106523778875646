import { PriceListItem } from '@congacommerce/ecommerce';
import { Expose } from 'class-transformer';

export class EglPriceListItem extends PriceListItem {
    @Expose({ name: 'egl_percent_value__c' })
    egl_percent_value: number;

    @Expose({ name: 'egl_package__c' })
    egl_package;
}
