import { AptSalesProcess } from '../../../common/enums/apttus/apt-sales-process';
import { BoFilterByDate } from './bo-filter-by-date';

export class CarrelliFilterList {
    date?: BoFilterByDate;
    orderBy?: string;
    seeAll?: boolean;
    operativeMode?: AptSalesProcess;
    constructor(applyDefault?: boolean) {
        this.date = new BoFilterByDate(applyDefault);
    }
}
