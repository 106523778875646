import { setConnectionSpeed, setConnectionStatus } from '../../../store/actions/app.actions';
import { AppState } from '../../../store/models/app-state';
import { Injectable } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LoggerService } from '../shared/logger.service';
import { AppInsightsService } from '../shared/app-insights.service';

@Injectable({
    providedIn: 'root',
})
export class InternetConnectionService {
    connection$: Observable<any>;

    constructor(
        private connectionService: ConnectionService,
        private store: Store<{ app: AppState }>,
        private appInsightSrv: AppInsightsService,
        private logger: LoggerService
    ) {
        this.connection$ = new Observable((observer) => {
            if (!navigator['connection']) {
                return;
            }
            navigator['connection'].addEventListener('change', () => {
                const { effectiveType } = navigator['connection'] as any;
                observer.next(effectiveType);
            });
        });
    }

    init(): void {
        this.startConnectionStatus();
        this.startConnectionSpeed();
    }

    private startConnectionStatus(): void {
        this.connectionService.monitor().subscribe((isConnected) => {
            this.store.dispatch(setConnectionStatus({ status: isConnected ? 'ONLINE' : 'OFFLINE' }));
            this.appInsightSrv.logEvent('connection_status', { status: isConnected ? 'ONLINE' : 'OFFLINE' });
        });
    }

    private startConnectionSpeed(): void {
        this.connection$.subscribe((effectiveType: string) => {
            // this.logger.info(`Connection Speed: ${effectiveType}`);
            this.store.dispatch(setConnectionSpeed({ speed: effectiveType }));
            this.appInsightSrv.logEvent('connection_speed', { speed: effectiveType });
        });
    }
}
