export class SelectFullAddressDatiFornitura {
    indirizzoFornitura: string;
    indirizzoComunicazioni: string;
    indirizzoSpedizione: string;
    indirizzoResidenza: string;
    stessoIndirizzoSpedizione: boolean;
    stessoIndirizzoResidenza: boolean;
    stessoIndirizoComunicazioni: boolean;
    isSedeLegale: boolean;

    constructor() {
        this.indirizzoFornitura = '';
        this.indirizzoComunicazioni = '';
        this.indirizzoSpedizione = '';
        this.indirizzoResidenza = '';
        this.isSedeLegale = false;
        this.stessoIndirizzoSpedizione = true;
        this.stessoIndirizzoResidenza = true;
        this.stessoIndirizoComunicazioni = true;
    }
}
