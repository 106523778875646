import { AField, AObject, ATable } from '@congacommerce/core';

@ATable({
    sobjectName: 'User',
    aqlName: 'User',
})
export class EglUserLight extends AObject {
    constructor() {
        super();
        this.FirstName = null;
        this.LastName = null;
    }

    @AField({ soql: 'FirstName' })
    FirstName: string;

    @AField({ soql: 'LastName' })
    LastName: string;

    @AField({ soql: 'Name' })
    Name: string;
}
