import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlowTypeService } from '../services/flow-type.service';

@Injectable()
export class CartFlowTypeResolver implements Resolve<Observable<void>> {
    constructor(private flowTypeSrv: FlowTypeService) {}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
        return this.flowTypeSrv.dispatchFlowType().pipe(map(() => null));
    }
}
