import { AField, AObject, ATable } from '@congacommerce/core';

@ATable({
    sobjectName: 'Contact',
    aqlName: null,
})
export class EglContactLight extends AObject {
    constructor() {
        super();
        this.Name = null;
        this.MobilePhone = null;
        this.Email = null;
    }

    @AField({ soql: 'Name' })
    Name: string;

    @AField({ soql: 'FirstName' })
    FirstName: string;

    @AField({ soql: 'LastName' })
    LastName: string;

    @AField({ soql: 'egl_MailingPostalCode' })
    egl_MailingPostalCode: string = null;

    @AField({ soql: 'egl_MailingStreet' })
    egl_MailingStreet: string = null;

    @AField({ soql: 'egl_MailingCity' })
    egl_MailingCity: string = null;

    @AField({ soql: 'MobilePhone' })
    MobilePhone: string;

    @AField({ soql: 'Email' })
    Email: string;

    @AField({ soql: 'egl_contact_address_line2' })
    egl_contact_address_line2: string = null;

    @AField({ soql: 'egl_contact_address_prefix' })
    egl_contact_address_prefix: string = null;

    @AField({ soql: 'egl_contact_birthplace' })
    egl_contact_birthplace: string = null;

    @AField({ soql: 'egl_contact_certified' })
    egl_contact_certified = false; // TODO: mapping

    @AField({ soql: 'egl_contact_fiscalcode' })
    egl_contact_fiscalcode: string = null;

    @AField({ soql: 'egl_contact_taxcode' })
    egl_contact_taxcode: string = null;

    @AField({ soql: 'egl_contact_telephone2' })
    egl_contact_telephone2: string = null;

    @AField({ soql: 'egl_contact_validated' })
    egl_contact_validated = true;

    @AField({ soql: 'Birthdate' })
    Birthdate: string = null;

    @AField({ soql: 'egl_contact_birthprovince' })
    egl_contact_birthprovince: string = null;

    @AField({ soql: 'egl_contact_birthcountry' })
    egl_contact_birthcountry: string = null;

    @AField({ soql: 'egl_contact_sex' })
    egl_contact_sex: string = null;
}
