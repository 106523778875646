import { ACondition, AFilter, AObjectService } from '@congacommerce/core';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EglQuoteLight } from '../../../../models/apttus/tables/quote/egl-quote-light';

@Injectable({
    providedIn: 'root',
})
export class EglQuoteLightService extends AObjectService {
    type = EglQuoteLight;

    globalFilter(): Observable<AFilter[]> {
        return of([
            new AFilter(this.type, [new ACondition(this.type, 'CreatedDate', 'GreaterThan', new Date('2020-05-18'))]),
        ]);
    }
}
