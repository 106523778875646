export class DataOwnershipChangeRequest {
    taxCode: string;
    vatCode?: string;
    fullName: string;
    contacts: DataOwnershipChangeContacts;
    productCategory: string;
    virtualAgentCode: string;
    virtualAgencyCode: string;
    templateVariables: string;
    expirationDate?: string;
    relatedObjectId?: string;
    relatedObjectType?: string;
    expirationData?: string;
    customerCode?: string;
}

export class DataOwnershipChangeContacts {
    email?: string;
    phone: string;
}

export class DataOwnershipChangeExpirationData {
    days?: number;
    hours?: number;
    minutes?: number;
    canBeInNotWorkingDays?: boolean;

    static default = {
        hours: 2,
        canBeInNotWorkingDays: true,
    } as DataOwnershipChangeExpirationData;
}
