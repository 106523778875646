<div *ngIf="domainEnabled && visible" class="dev-bar" [ngClass]="localhost ? 'bk-blue' : 'bk-red'">
    <button (click)="setVisibiltyBar(false)" [innerHTML]="ICONS.Cancel"></button>
    <button (click)="navigate('back')" style="background-color: indianred">&#60;&#60;</button>
    <button (click)="navigate('next')" style="background-color: indianred">&#62;&#62;</button>
    <button
        class="icon__container"
        (click)="goToHome()"
        style="background-color: indianred"
        [innerHTML]="ICONS.Home"
    ></button>
    <button
        class="icon__container"
        (click)="showMoreConfig()"
        style="background-color: indianred"
        [innerHTML]="ICONS.More"
    ></button>
    &nbsp;DEV BAR&nbsp;
    <input
        type="search"
        [typeahead]="getAsAny(routes)"
        [ngModel]="route$ | async"
        (ngModelChange)="routes.includes($event) && onChangeRoute($event)"
        [typeaheadMinLength]="0"
    />
    &nbsp;&nbsp;
    <input type="text" [(ngModel)]="url" style="width: 100%" />

    <button (click)="onGoBtn(false)">navigate</button>
    <button (click)="onGoBtn(true)" [innerHTML]="ICONS.Refresh"></button>
    <button (click)="openOtherInfoModal()">other info</button>
</div>

<egl-modal-with-footer
    class="dev-bar-modal"
    *ngIf="domainEnabled && visible"
    #moreConfigModal
    title="More options"
    buttonLabel="Close"
    [closeOnConfirm]="true"
    (onConfirm)="moreConfigModal.hide()"
>
    <div class="dev-bar-modal__body">
        <select
            class="dev-bar-modal__select"
            name="mastership"
            [ngModel]="mastership"
            (ngModelChange)="onChangeMastership($event)"
        >
            <option value="SIEBEL">Siebel</option>
            <option value="D365">Dynamics 365</option>
            <option value="NEW">Nuovo cliente</option>
        </select>
        <label for="mastership">&nbsp;Customer Mastership</label>
        <div class="dev-bar-modal__checkbox">
            <select
                class="dev-bar-modal__select"
                name="cartSegment"
                [ngModel]="segment"
                (ngModelChange)="onSegmentChange($event)"
            >
                <option [value]="SEGMENT.Residential">Residenziale</option>
                <option [value]="SEGMENT.Microbusiness">Microbusiness</option>
            </select>
            <label for="cartSegment">&nbsp;Cart Segment</label>
        </div>

        <div class="dev-bar-modal__select-container">
            <!-- <p for="flowTypes">FlowType</p> -->
            <select
                id="flowTypes"
                name="flowTypes"
                (change)="onChangeFlowType($event.target['value'])"
                [(ngModel)]="currentFlowType"
                class="dev-bar-modal__select"
            >
                <option *ngFor="let ft of flowTypes" value="{{ ft }}">
                    {{ ft }}
                </option>
            </select>
            <label for="flowTypes">&nbsp;FlowType </label>
            <code>{{ flowTypeObj | json }}</code>
        </div>

        <input type="button" class="dev-bar-modal__button" (click)="onHideBlockUI()" value="BlockUI" />
        <input type="button" class="dev-bar-modal__button" (click)="logSupState(2)" value="Log SupState" />
        <input type="button" class="dev-bar-modal__button" (click)="newCart()" value="Crea nuovo carrello" />
        <input type="button" class="dev-bar-modal__button" (click)="getAuthTokenByD365()" value="Authorization Token" />
        <input type="button" class="dev-bar-modal__button" (click)="multiAdd()" value="Testi Multi SWIN" />
        <input type="button" class="dev-bar-modal__button" (click)="resetOEState()" value="Reset order-entry state" />
        <input type="button" class="dev-bar-modal__button" (click)="fromCartReq()" value="FromCart Request" />
        <input type="button" class="dev-bar-modal__button" (click)="momentLocale()" value="Log Moment Locale" />

        <egl-new-convert-cart-to-quote-test-page
            class="dev-bar-modal__fieldset"
        ></egl-new-convert-cart-to-quote-test-page>
        <fieldset class="dev-bar-modal__fieldset">
            <legend>OrderEntry State V1->V2->V1 Test</legend>
            <textarea #stateV1 class="dev-bar-modal__textarea"></textarea>
            <div class="dev-bar-modal__button-container">
                <input
                    type="button"
                    class="dev-bar-modal__button"
                    (click)="stateV2Test(stateV1.value)"
                    value="Verifica"
                    [disabled]="!stateV1.value"
                />
                <input type="button" class="dev-bar-modal__button" (click)="logSupState(1)" value="Log State V1" />
            </div>
        </fieldset>

        <fieldset class="dev-bar-modal__fieldset">
            <legend class="dev-bar-modal__legend">Attiva carrello (current: {{ currentCardId }})</legend>
            <input type="text" name="cart-activator" #cartIdToActivate [placeholder]="currentCardId" />
            <input
                type="button"
                (click)="onActivateCartId(cartIdToActivate.value)"
                value="Attiva carrello"
                [disabled]="!cartIdToActivate.value"
                class="dev-bar-modal__button"
            />
        </fieldset>
    </div>
</egl-modal-with-footer>

<egl-modal-with-footer #otherInfoModal [title]="'Other infos'" [hideConfirm]="true">
    <div class="dev-bar-modal__other-infos-table-container">
        <egl-table [dataTable]="otherInfos"></egl-table>
    </div>
</egl-modal-with-footer>
