import {
    AObjectService,
    CacheService,
    ConfigurationService,
    MetadataService,
    PlatformService,
} from '@congacommerce/core';
import { Injectable, Injector } from '@angular/core';
import { EglQuoteLineItemLight } from '../../../../models/apttus/tables/quote/egl-quote-line-item-light';
@Injectable()
export class EglQuoteLineItemService extends AObjectService {
    constructor(
        cacheSrv: CacheService,
        plSrv: PlatformService,
        mtSrv: MetadataService,
        confSrv: ConfigurationService,
        inj: Injector
    ) {
        super(cacheSrv, plSrv, mtSrv, confSrv, inj);
        this.setType(EglQuoteLineItemLight);
    }
}
