import { mergeWith } from 'lodash';
import { AptCommodityType } from '../../common/enums/apttus/apt-commodity-typeof-sale';
import { isAddress } from '../../common/functions/verifications.functions';
import { DeepPartial } from '../../common/interfaces/deep-partial';
import { WinBackType } from '../models/order-entry-state';
import { OrderEntryState_v2, Product } from '../models/order-entry-state_v2';
import { TargetProductIndex } from '../models/utility.types';
import { productFieldSelectorGenerator } from '../selectors/selector-utility.functions';

export const WIN_BACK_MAP = {
    [WinBackType.Both]: [AptCommodityType.Gas, AptCommodityType.Power],
    [WinBackType.Gas]: [AptCommodityType.Gas],
    [WinBackType.Power]: [AptCommodityType.Power],
    [WinBackType.None]: [],
};

export type ProductReducer<T> = (input: {
    product: Product;
    orderStateV2: OrderEntryState_v2;
    payload: T;
}) => DeepPartial<Product>;

export const wrapperUpdateProductByIndex = <T>(
    productReducer: ProductReducer<T>,
    config?: Partial<{ productIdx?: TargetProductIndex; overwrite: boolean }>
) => {
    const productMapper = ({
        overwrite,
        product,
        state,
        payload,
    }: {
        overwrite: boolean;
        product: Product;
        state: OrderEntryState_v2;
        payload: T;
    }) =>
        mergeWith(
            // In base al parametro overwrite parto da un oggetto vuoto
            // o da un oggetto con tutti i campi del prodotto per triggerare il cambiamento
            overwrite ? ({} as Product) : { ...product },
            productReducer({
                product,
                orderStateV2: state,
                payload,
            }),
            (dest, src) => {
                /* Prendo sempre l'oggetto "source" per gli indirizzi per evitare che si creino
                 * degli oggetti mergiati che includono "civic" e "civicSuffix" di due casi separati.
                 * Es: Prima inserisco un indirizzo con civico semplice (2), proseguo nell'order entry e torno indietro.
                 * Poi modifico il civico scegliendo "SNC".
                 * Prendendo l'oggetto mergiato avrei un indirizzo con civico "2 SNC" invece che solo "SNC".
                 */
                if (Array.isArray(dest) || Array.isArray(src) || isAddress(dest) || isAddress(src)) {
                    return src;
                }
            }
        );

    return (state: OrderEntryState_v2, payload: T) => {
        // Creo nuovo array di prodotti per triggerare la variazione in RxJS
        const products = [...(state.products || [])];
        // Utilizzo stessa logica di lettura per identificare i prodotti da modificare
        const productFieldIterator = productFieldSelectorGenerator({
            state,
            productSelector: (product) => product,
            productIdx: config?.productIdx,
        });
        // Modifico i prodotti estratti dall'iteratore e li aggiorno nella lista prodotti
        Array.from(productFieldIterator).forEach((product) => {
            const stateProductIdx = products.indexOf(product);
            if (stateProductIdx < 0) {
                console.error(`Impossibile individuare il prodotto all'interno dello state.`);
                return;
            }
            products[stateProductIdx] = productMapper({
                overwrite: config?.overwrite,
                product,
                state,
                payload,
            });
        });
        return {
            ...state,
            products,
        } as OrderEntryState_v2;
    };
};
