import { ATable, AObject, AField } from '@congacommerce/core';
import { AptPaymentType } from '../../../../enums/apttus/apt-payment-type';
import { AptPaymentInstrument } from '../../../../enums/apttus/apt-payment-instrument';
import { AptProductType } from '../../../../enums/apttus/apt-product-type';

@ATable({
    sobjectName: 'egl_CreditCheck_Rule__mdt',
    aqlName: null,
})
export class EglProductCreditCheckRule extends AObject {
    @AField({ soql: 'egl_Product_Type__c' })
    egl_Product_Type: AptProductType;

    @AField({ soql: 'egl_Payment_Type__c' })
    egl_Payment_Type: AptPaymentType;

    @AField({ soql: 'egl_Payment_Instrument__c' })
    egl_Payment_Instrument: AptPaymentInstrument;

    @AField({ soql: 'egl_Credit_Check_Required__c' })
    egl_Credit_Check_Required: boolean;
}
