import {
    HttpClient,
    HttpContext,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpUrlEncodingCodec,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, delay, finalize, map, retry, shareReplay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Params } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private httpClient: HttpClient) {}

    private pendingCalls: {
        [key: string]: Observable<any>;
    } = {};

    getAsync<T>(url: string, params?: Params): Observable<T>;
    getAsync<T>(url: string, options: Options): Observable<T>;
    getAsync<T>(url: string, paramsOrOptions?: Params | Options): Observable<T> {
        const rawOptions = isOptions(paramsOrOptions)
            ? paramsOrOptions
            : paramsOrOptions && { params: paramsOrOptions };

        const params: HttpParams =
            rawOptions?.params &&
            new HttpParams({
                encoder: new GoblinHttpParameterCodec(),
                fromObject: rawOptions?.params as any,
            });
        const options = rawOptions && { ...rawOptions, params };

        const callId = '[GET]' + url + JSON.stringify(rawOptions || {});
        this.pendingCalls[callId] =
            this.pendingCalls[callId] ||
            this.httpClient.get<T>(url, options).pipe(
                finalize(() => {
                    delete this.pendingCalls[callId];
                }),
                shareReplay(),
            );

        return this.pendingCalls[callId];
    }

    postAsync<T>(url: string, body: any, delaying: number = 0, reqOpts?: any, retryCount: number = 0): Observable<T> {
        const callId = '[POST]' + url + JSON.stringify(body || {}) + JSON.stringify(reqOpts || {});
        this.pendingCalls[callId] =
            this.pendingCalls[callId] ||
            this.httpClient.post<T>(url, body, reqOpts).pipe(
                delay(delaying),
                retry(retryCount),
                catchError((x) => of(x)),
                finalize(() => {
                    delete this.pendingCalls[callId];
                })
            );
        return this.pendingCalls[callId];
    }

    putAsync<T>(url: string, body: any, delaying: number = 0, reqOpts?: any, retryCount: number = 0): Observable<T> {
        const callId = '[PUT]' + url + JSON.stringify(body || {}) + JSON.stringify(reqOpts || {});
        this.pendingCalls[callId] =
            this.pendingCalls[callId] ||
            this.httpClient.put<T>(url, body, reqOpts).pipe(
                delay(delaying),
                retry(retryCount),
                catchError((x) => of(x)),
                finalize(() => {
                    delete this.pendingCalls[callId];
                })
            );
        return this.pendingCalls[callId];
    }

    deleteAsync<T>(url: string, queryParams: Object, delaying: number = 0, retryCount: number = 0): Observable<T> {
        const reqOpts = {
            params: new HttpParams(),
        };
        for (const k in queryParams) {
            reqOpts.params = reqOpts.params.set(k, queryParams[k]);
        }
        const callId = '[DELETE]' + url + JSON.stringify(reqOpts || {});
        this.pendingCalls[callId] =
            this.pendingCalls[callId] ||
            this.httpClient.delete<T>(url, reqOpts).pipe(
                delay(delaying),
                retry(retryCount),
                catchError((x) => of(x)),
                finalize(() => {
                    delete this.pendingCalls[callId];
                })
            );
        return this.pendingCalls[callId];
    }

    getBlobFile<T>(path: string): Observable<any> {
        return this.httpClient.get(path, { responseType: 'blob' });
    }

    /**
     * @description Esegue una chiamata REST di tipo PATCH
     * @returns Observable<HttpResponse<T>>
     */
    patchAsync<T>(
        endpoint: string,
        api: string,
        action: string,
        body: any,
        reqOpts?: any,
        delaying: number = 0,
        retryCount: number = 0
    ): Observable<HttpResponse<T>> {
        const url = `${endpoint}/${api}/${action}`;
        if (!reqOpts) {
            reqOpts = {
                observe: 'response',
            };
        }
        return this.httpClient.patch<T>(url, body, reqOpts).pipe(
            delay(delaying),
            retry(retryCount),
            map((res: HttpResponse<T>) => {
                return res;
            })
        );
    }

    get httpClientInstance(): HttpClient {
        return this.httpClient;
    }
}

class GoblinHttpParameterCodec extends HttpUrlEncodingCodec {
    // encodeKey(key: string): string
    encodeValue(value: unknown): string {
        if (value && value instanceof Date) {
            return value.toISOString();
        }
        return super.encodeValue(value === null || typeof value === undefined ? '' : value + '');
    }
    // decodeKey(key: string): string
    // decodeValue(value: string)
}

interface Options {
    headers?:
        | HttpHeaders
        | {
              [header: string]: string | string[];
          };
    context?: HttpContext;
    observe?: 'body';
    params?:
        | HttpParams
        | {
              [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
          };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

function isOptions(value: unknown): value is Options {
    return (
        typeof value === 'object' &&
        !!value &&
        ['headers', 'context', 'observe', 'params', 'reportProgress', 'responseType', 'withCredentials'].some(
            (key) => key in value
        )
    );
}
