import { Cart } from '@congacommerce/ecommerce';
import { Expose, Type } from 'class-transformer';
import { AptCustomerType } from '../../../../enums/apttus/apt-customer-type';
import { EglCartItemExtended } from './egl-cart-item-extended';
import { EglSalesupState } from '../state/egl-salesup-state';
import { AptSalesProcess } from '../../../../enums/apttus/apt-sales-process';
import { EglAddress } from '../address/egl-address';

export class EglCartExtended extends Cart {
    constructor() {
        super();
        this.egl_customer_type = null;
        this.egl_leadid = null;
        this.egl_agency_code = null;
        this.egl_sales_channel = null;
        this.LineItems = null;
        this.egl_salesup_state_ = null;
        this.egl_sales_process = AptSalesProcess.SwitchIn;
    }

    @Expose({
        name: 'Apttus_Config2__LineItems__r',
    })
    @Type(() => EglCartItemExtended)
    LineItems: Array<EglCartItemExtended>;

    @Expose({ name: 'egl_leadid__c' }) // LEAD ID
    egl_leadid: string;

    @Expose({ name: 'egl_customer_type__c' })
    egl_customer_type: AptCustomerType; // 'Residenziale' |'Microbusiness';

    // //////////////////////////////////////////////////////////// CHANNEL AND AGENCY

    @Expose({ name: 'egl_agency_code__c' }) // Codice Agenzia
    egl_agency_code: string;

    @Expose({ name: 'egl_sales_channel__c' }) // Canale di vendita
    egl_sales_channel: string;

    @Expose({ name: 'egl_salesup_state_id__r' })
    @Type(() => EglSalesupState)
    egl_salesup_state_: EglSalesupState;

    @Expose({ name: 'egl_sales_process__c' })
    egl_sales_process: AptSalesProcess;

    // //////////////////////////////////////////////////////////// BASE LISTA
    @Expose({ name: 'egl_tag__c' }) // Base lista
    egl_tag: string;

    @Expose({ name: 'egl_DAG_code__c' }) // DAG Code
    egl_DAG_code: string;

    @Expose({ name: 'egl_agreement_code__c' }) // Contiene il codice convenzione
    egl_agreement_code: string;

    @Expose({ name: 'egl_signed_date__c' })
    egl_signed_date: string;

    @Expose({ name: 'Apttus_Config2__PricingDate__c' })
    PricingDate: Date;

    @Expose({
        name: 'egl_service_address_id__r',
    })
    @Type(() => EglAddress)
    egl_service_address: EglAddress;
}
