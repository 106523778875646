import { Injectable, OnDestroy } from '@angular/core';
import { get as _get, isEmpty as _isEmpty } from 'lodash';
import { from, fromEvent, interval, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { getRandomHash, writeImportantErrorLog, writeImportantInfoLog } from '../../functions/misc.functions';
import { AppConfig } from '../../models/app/app-config';
import { D365PostMessage } from '../../models/app/d365-postmessage';

@Injectable({
    providedIn: 'root',
})
export class PrivateConfigurationService implements OnDestroy {
    configLoaded = false;

    readonly configObservable$: Observable<AppConfig>;

    private configuration: AppConfig;

    private readonly CACHE_DURATION = 30 * 60000; // 30 min
    private readonly destroy$ = new Subject<void>();

    constructor() {
        this.configObservable$ = interval(this.CACHE_DURATION).pipe(
            startWith(0),
            switchMap(() =>
                this.getSalesUpConfig().pipe(
                    tap(() => {
                        this.configLoaded = true;

                        console.debug(`Private Configuration Loaded at ${new Date().toLocaleTimeString()}`);
                    }),
                ),
            ),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

        this.configObservable$.pipe(takeUntil(this.destroy$)).subscribe((config) => {
            this.configuration = config;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get config(): AppConfig {
        if (_isEmpty(this.configuration)) {
            writeImportantErrorLog('Configuration was not ready. Data could be wrong!');
        }
        return this.configuration;
    }

    get<T>(key: string[] | string, defaultValue?: any): T {
        return _get(this.configuration, key, defaultValue);
    }

    private getSalesUpConfig(): Observable<AppConfig> {
        if (environment.configOverride?.privateConfiguration) {
            return this.getOverridePrivateConfig();
        } else {
            return this.getD365PrivateConfig(<D365PostMessage>{
                type: 'executeaction',
                content: {
                    request: {},
                    requestmetadata: {
                        boundParameter: null,
                        operationType: 0,
                        operationName: 'egl_privateconfiguration_getsalesupconfiguration',
                        parameterTypes: {},
                    },
                },
            });
        }
    }

    private getD365PrivateConfig(message: D365PostMessage): Observable<AppConfig> {
        const type = getRandomHash() + new Date().getTime() + (Math.random() * 10 ** 8).toString(36);
        parent.window.postMessage({ ...message, content: { ...message.content, type } }, '*');

        return fromEvent(window, 'message').pipe(
            filter((evt: MessageEvent) => evt.data.type === type),
            map((evt: MessageEvent) => evt.data || {}),
            map(({ content }) => (content ? <AppConfig>JSON.parse(content.SalesUpConfig) : null)),
        );
    }

    /**
     * Recupera la configurazione dell'app dal file (indicato in environment.configOverride.privateConfiguration)
     */
    private getOverridePrivateConfig(): Observable<AppConfig> {
        return from(fetch(environment.configOverride.privateConfiguration)).pipe(
            map((response) => response.json() as unknown as AppConfig),
            tap((localConfig) => writeImportantInfoLog('LOADED LOCAL CONFIGURATION', localConfig)),
            catchError((error) => {
                console.error(`File '${error.url}' not found`);
                return of(null);
            }),
        );
    }
}
