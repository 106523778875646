import { Injectable } from '@angular/core';
import { PrivateConfigurationService } from './private-configuration.service';
import { Observable, combineLatest, from, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { EglState } from '../../../store/reducers';
import { Store } from '@ngrx/store';
import {
    selectChannelCode,
    selectContactLead,
    selectCurrentProfile,
    selectCurrentVirtualAgent,
    selectDataOwnershipChangeData,
} from '../../../store/selectors/user.selectors';
import { DataOwnershipChangeData } from '../../../store/models/user-state';
import { setDataOwnershipChangeData, setDataOwnershipChangeState } from '../../../store/actions/user.actions';
import { Contact } from '../../models/user/contact';
import { D365Service } from '../d365/d365.service';
import {
    DataOwnershipChangeContacts,
    DataOwnershipChangeRequest,
} from '../../models/d365/data-ownership-change.request';
import { D365DataOwnershipChangeStatuscodes } from '../../enums/d365/d365-data-ownership.change-statuscodes';
import { DataOwnershipChangeRecord } from '../../models/d365/data-ownership-change.record';
import { LoggerService } from './logger.service';
import { D365DataOwnershipChangeOutcome } from '../../enums/d365/d365-data-ownership-change-outcome';
import { DataOwnershipChangeOutcomeRequest } from '../../models/d365/data-ownership-change-outcome.request';
import { UtilityService } from './utility.service';
import { v2HasPowerOrGas } from '../../../store/selectors/order-entry-v2.selectors';
import { AgentInfo } from '../../models/user/agent';
import { CartService } from '@congacommerce/ecommerce';

@Injectable({
    providedIn: 'root',
})
export class DataOwnershipChangeService {
    constructor(
        private configSrv: PrivateConfigurationService,
        private store: Store<EglState>,
        private d365Srv: D365Service,
        private logger: LoggerService,
        private utilitySrv: UtilityService
    ) {
        this.store.select(selectDataOwnershipChangeData).subscribe((res) => {
            this.dataOwnershipChangeData = res;
        });
    }

    private dataOwnershipChangeData: DataOwnershipChangeData;

    async sendDataOwnershipChange(contact: Contact): Promise<DataOwnershipChangeData> {
        const dataOwnershipChangeRequest = await this.getDataOwnershipChangeRequest(contact).toPromise();

        const dataOwnershipChangeResponse = await this.d365Srv
            .sendDataOwnershipChange(dataOwnershipChangeRequest)
            .toPromise();

        if (
            !dataOwnershipChangeResponse ||
            !!dataOwnershipChangeResponse?.ErrorMessage ||
            !!dataOwnershipChangeResponse?.error
        ) {
            this.logger.error(
                'DOI conferma cliente',
                'Si è verificato un errore nella chiamata di creazione Cambio titolarità dati',
                dataOwnershipChangeResponse?.ErrorMessage || '',
                true
            );

            return null;
        }

        const dataOwnershipRecord = (await this.queryDataOwnershipChangeRecord(
            dataOwnershipChangeResponse.DataOwnershipChangeId
        ).toPromise()) as DataOwnershipChangeRecord;

        let dataOwnershipChangeData = new DataOwnershipChangeData();

        dataOwnershipChangeData = {
            ...dataOwnershipChangeData,
            ...dataOwnershipChangeRequest,
            ...{ ...dataOwnershipChangeResponse, code: dataOwnershipRecord.egl_code },
        };

        this.store.dispatch(setDataOwnershipChangeData({ data: dataOwnershipChangeData }));
        return dataOwnershipChangeData;
    }

    private getDataOwnershipChangeRequest(contact: Contact): Observable<DataOwnershipChangeRequest> {
        return combineLatest([this.store.select(selectCurrentProfile), this.store.select(v2HasPowerOrGas)]).pipe(
            take(1),
            map(([currentAgentInfo, hasPowerOrGas]) => {
                let dataOwnershipChangeRequest = new DataOwnershipChangeRequest();

                let doiContacts = new DataOwnershipChangeContacts();
                doiContacts.email = contact?.emailaddress1;
                doiContacts.phone = `${contact?.prefixMobilephone || ''}${contact?.mobilephone}`;

                dataOwnershipChangeRequest.customerCode = contact?.egl_customercode;
                dataOwnershipChangeRequest.fullName = this.utilitySrv.getContactFullName(contact);
                dataOwnershipChangeRequest.taxCode = contact.egl_taxcode;
                dataOwnershipChangeRequest.vatCode = contact.egl_vatcode;

                dataOwnershipChangeRequest.virtualAgentCode = currentAgentInfo?.selectedVirtualAg?.AgentCode || '';
                dataOwnershipChangeRequest.virtualAgencyCode =
                    currentAgentInfo?.selectedVirtualAg?.VirtualAgency?.Code || '';
                dataOwnershipChangeRequest.productCategory = hasPowerOrGas ? 'COMMODITY' : 'EXTRACOMMODITY';
                dataOwnershipChangeRequest.relatedObjectId = CartService.getCurrentCartId();
                dataOwnershipChangeRequest.relatedObjectType = 'Apttus_Config2__ProductConfiguration__c';
                dataOwnershipChangeRequest.contacts = doiContacts;
                dataOwnershipChangeRequest.templateVariables = this.generateTemplateArguments(
                    currentAgentInfo?.currentAgentInfo
                );

                return dataOwnershipChangeRequest;
            })
        );
    }

    private generateTemplateArguments(agentinfo: AgentInfo): any {
        return { agency_name: agentinfo?.Agency?.Name };
    }

    async resendDoi(
        id: string,
        outcomeType: D365DataOwnershipChangeOutcome = D365DataOwnershipChangeOutcome.DoiPendingSend
    ): Promise<boolean> {
        const dataOwnershipChangeOutcomeRequest = {
            quoteId: id,
            status: outcomeType,
        } as DataOwnershipChangeOutcomeRequest;

        const createOutcomeResponse = await this.d365Srv
            .createDataOwnershipChangeOutcome(dataOwnershipChangeOutcomeRequest)
            .toPromise();

        return createOutcomeResponse?.Result === 'OK';
    }

    checkDataOwnershipChangeDataConsinstency(): Observable<boolean> {
        return combineLatest([
            this.store.select(selectDataOwnershipChangeData),
            this.store.select(selectContactLead),
            this.skipDataOwnershipChangePage(),
        ]).pipe(
            take(1),
            mergeMap(([dataOwnershipChangeData, contactOrLead, skipDataOwnershipChangePage]) => {
                if (dataOwnershipChangeData?.status === D365DataOwnershipChangeStatuscodes.DoiSigned) {
                    return of(true);
                }

                if (skipDataOwnershipChangePage) {
                    return of(true);
                }

                const contact = contactOrLead.contact || contactOrLead.lead || new Contact();
                const fullName = this.utilitySrv.getContactFullName(contact);

                if (!dataOwnershipChangeData?.contacts?.phone) {
                    return of(true);
                }

                let resetDoiStatus = false;

                const mobilePhone = `${contact?.prefixMobilephone || ''}${contact?.mobilephone}`;

                if (dataOwnershipChangeData?.contacts?.phone != mobilePhone) {
                    resetDoiStatus = true;
                }

                if (dataOwnershipChangeData?.fullName != fullName) {
                    resetDoiStatus = true;
                }

                if (!!dataOwnershipChangeData?.taxCode && dataOwnershipChangeData?.taxCode != contact.egl_taxcode) {
                    resetDoiStatus = true;
                }

                if (!!dataOwnershipChangeData?.vatCode && dataOwnershipChangeData?.vatCode != contact.egl_vatcode) {
                    resetDoiStatus = true;
                }

                if (
                    !!dataOwnershipChangeData?.contacts?.email &&
                    dataOwnershipChangeData?.contacts?.email != contact.emailaddress1
                ) {
                    resetDoiStatus = true;
                }

                if (!resetDoiStatus) {
                    return of(true);
                }

                this.store.dispatch(setDataOwnershipChangeData({ data: new DataOwnershipChangeData() }));
                this.store.dispatch(setDataOwnershipChangeState({ newState: undefined }));

                return of(true);
            })
        );
    }

    skipDataOwnershipChangePage(): Observable<boolean> {
        return combineLatest([
            of(this.configSrv.get<boolean>('dataOwnershipChangeEnabled', false)),
            of(this.configSrv.get<string[]>('channelsWithDataOwnershipChange', [])),
            this.store.select(selectChannelCode),
            of(this.configSrv.get<string[]>('dataOwnershipChangeAgencyBlackList', [])),
            this.store.select(selectCurrentVirtualAgent),
        ]).pipe(
            take(1),
            map(
                ([doiConfermaClienteEnabled, channelsEnabled, currentChannel, agencyBlackList, currentVA]) =>
                    !doiConfermaClienteEnabled ||
                    agencyBlackList.includes(currentVA?.VirtualAgency?.Code) ||
                    (doiConfermaClienteEnabled && !channelsEnabled.includes(currentChannel))
            )
        );
    }

    queryDataOwnershipChangeRecord(id: string): Observable<DataOwnershipChangeRecord> {
        return from(
            this.d365Srv.retrieveRecordAsync(
                DataOwnershipChangeRecord.entityName,
                id,
                DataOwnershipChangeRecord.recordFields()
            )
        ).pipe(
            (take(1),
            catchError((err) => {
                this.logger.error('Errore', 'errore nel recupero', err);
                if (err === null) {
                    return of(null);
                }
                throw err;
            }),
            mergeMap((res) => {
                return of(res.queryresult as DataOwnershipChangeRecord);
            }))
        );
    }

    disableRegistryFields(): boolean {
        return this.dataOwnershipChangeData?.status === D365DataOwnershipChangeStatuscodes.DoiSigned;
    }

    shouldRemoveSaveCart(): Observable<boolean> {
        return this.skipDataOwnershipChangePage().pipe(
            take(1),
            map((dataOwnershipChangeDisabled) => !dataOwnershipChangeDisabled)
        );
    }

    isUpsellingCWEEnabled(): Observable<boolean> {
        return combineLatest([
            this.store.select(selectDataOwnershipChangeData),
            of(this.configSrv.get<boolean>('isCWEUpsellingEnabled', false)),
            this.skipDataOwnershipChangePage(),
        ]).pipe(
            take(1),
            mergeMap(([dataOwnershipChangeData, isUpsellingEnabled, skipDataOwnershipChangePage]) => {
                const upsellingEnabled =
                    !!dataOwnershipChangeData.code && isUpsellingEnabled && !skipDataOwnershipChangePage;
                return of(upsellingEnabled);
            })
        );
    }

    canDeleteCartWithoutProducts(): Observable<boolean> {
        return combineLatest([
            this.shouldRemoveSaveCart(),
            of(this.configSrv.get<boolean>('doiCWEUpsellingCanDeleteEmptyCart', false)),
        ]).pipe(
            take(1),
            mergeMap(([shouldRemoveSaveCart, canDeleteEmptyCart]) => {
                const canDeleteCart = shouldRemoveSaveCart && canDeleteEmptyCart;
                return of(canDeleteCart);
            })
        );
    }

    skipCheckUniqueRegistrationMailForDataOwneshipChange(): Observable<boolean> {
        return combineLatest([
            this.skipDataOwnershipChangePage(),
            this.store.select(selectDataOwnershipChangeData),
        ]).pipe(
            take(1),
            mergeMap(([skipDataOwnershipChangePage, dataOwnershipChangeData]) => {
                const isDataOwnershipChangeSigned =
                    dataOwnershipChangeData.status === D365DataOwnershipChangeStatuscodes.DoiSigned;
                const skipCheckUniqueRegistration = !skipDataOwnershipChangePage && isDataOwnershipChangeSigned;
                return of(skipCheckUniqueRegistration);
            })
        );
    }

    get emailFromdataOwnershipChangeData(): string {
        return this.dataOwnershipChangeData?.contacts?.email || null;
    }
}
