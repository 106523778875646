import { FormBonifico } from './form-bonifico';
import { AptPaymentInstrument } from '../../../../common/enums/apttus/apt-payment-instrument';

export class FormDatiPagamento {
    id?: string; // valorizzato in caso di payment tool esistente e selezionato
    oldId?: string;
    tipoPagamento: AptPaymentInstrument;
    formBonifico?: FormBonifico;
    /**
     * @description IBAN o Numero Carta di Credito
     * Se IBAN selezionato attraverso payment-tools pre-esistenti.
     * In caso di censimento nuovo IBAN (non in payment-tools) i dati raccolti sono in state.datiPagamento.formBonifico.
     * In caso di REVOCA DOMICILIAZIONE = null
     */
    existingPaymentTool?: string;
    creditCardExpirationDate?: string;
    deactivateOldPaymentTool?: boolean;
    constructor() {
        this.formBonifico = new FormBonifico();
    }
}
