import { singleLineString } from '../../../functions/misc.functions';

export interface FetchXmlQuery {
    entity: string;
    query: string;
}
export interface FetchXmlParams {
    [key: string]: string;
}

export const getFetchXmlQuery = (query: FetchXmlQuery, params: FetchXmlParams = {}) => {
    const composedQuery = singleLineString(
        `${query.query}`.replace(/##(\w+)##/g, (match, name) =>
            typeof params[name] !== 'undefined' ? params[name] : match
        )
    );
    return {
        entity: query.entity,
        fetchxml: composedQuery,
    };
};
