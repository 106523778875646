import { Injectable } from '@angular/core';
import {
    ClientConstraintRuleService,
    ConstraintActionsIntent,
    ConstraintActionsType,
    ConstraintRule,
    ConstraintRuleService,
    Product,
    ProductOptionComponent,
    ProductOptionService,
} from '@congacommerce/ecommerce';
import { filter, get, uniqBy, map as _map, isNil, concat, compact, isEmpty, forEach, intersection, set } from 'lodash';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AptProductFamily } from '../../../enums/apttus/apt-product-family';
import { EglProductExtended } from '../../../models/apttus/tables/product/egl-product-extended';
import { PrivateConfigurationService } from '../../shared/private-configuration.service';
@Injectable({ providedIn: 'root' })
export class EglClientConstraintRuleService extends ClientConstraintRuleService {
    eglProductOptionService: ProductOptionService;
    eglCrService: ConstraintRuleService;
    constructor(
        productOptionService: ProductOptionService,
        crService: ConstraintRuleService,
        private configSrv: PrivateConfigurationService
    ) {
        super(productOptionService, crService);
        this.eglProductOptionService = productOptionService;
        this.eglCrService = crService;
    }

    getConstraintRulesForProducts(bundleProduct: EglProductExtended, cartItem) {
        this.loadAlertMessages([]);
        this.appliedRuleActionInfoRec = [];
        if (!isNil(get(cartItem, 'AssetLineItem'))) return of([]);
        let _productList: Product[] = [bundleProduct];
        if (bundleProduct.HasOptions) {
            const options = _map(this.eglProductOptionService.getProductOptions(bundleProduct), (c) =>
                get(c, 'ComponentProduct')
            );
            _productList = concat(bundleProduct, options);
        }
        const loadPrimaryLinesRules =
            this.configSrv
                .get<AptProductFamily>('primaryLinesRulesProductFamilies', [])
                .includes(bundleProduct?.Family) ||
            this.configSrv
                .get<AptProductFamily>('primaryLinesRulesProductTypes', [])
                .includes(bundleProduct?.ProductType);
        const rules$ = this.eglCrService.getConstraintRulesForProducts(
            uniqBy(_productList, 'Id'),
            loadPrimaryLinesRules,
            true
        );
        return rules$.pipe(
            map((rules) => {
                return filter(compact(uniqBy(rules, 'Id')), (rule) => {
                    if (isEmpty(get(rule, 'ConstraintRuleActions')) || isNil(get(rule, 'ConstraintRuleActions')))
                        return false;
                    else {
                        const conditions = rule?.ConstraintRuleConditions || [];
                        const actions = rule?.ConstraintRuleActions || [];
                        let valid = false;
                        _productList.forEach((product) => {
                            const productValid =
                                conditions.filter(
                                    (condition) =>
                                        (condition.ProductScope === 'Product' && product.Id === condition.ProductId) ||
                                        (condition.ProductScope === 'Product Family' &&
                                            product.Family === condition.ProductFamily) ||
                                        (condition.ProductScope === 'Product Group' &&
                                            filter(
                                                get(product, 'ProductGroups', []),
                                                (groupMember) => groupMember.ProductGroupId === condition.ProductGroupId
                                            ).length > 0)
                                ).length > 0;
                            if (productValid) {
                                valid = true;
                                const ruleProducts = get(rule, '_metadata.productList', []);
                                ruleProducts.push(product);
                                rule.set('productList', ruleProducts);
                            }
                            const actionProductValid =
                                actions.filter(
                                    (action) =>
                                        (action.ProductScope === 'Product' && product.Id === action.ProductId) ||
                                        (action.ProductScope === 'Product Family' &&
                                            product.Family === action.ProductFamily) ||
                                        (action.ProductScope === 'Product Group' &&
                                            filter(
                                                get(product, 'ProductGroups', []),
                                                (groupMember) => groupMember.ProductGroupId === action.ProductGroupId
                                            ).length > 0)
                                ).length === actions.length;
                            if (actionProductValid) {
                                valid = true;
                                const ruleProducts = get(rule, '_metadata.actionProductList', []);
                                ruleProducts.push(product);
                                rule.set('actionProductList', ruleProducts);
                            }
                        });

                        return valid;
                    }
                });
            }),
            map((rules) => filter(rules, (r) => r.get('actionProductList')))
        );
    }

    validateRules(ruleList: ConstraintRule[], product, arg) {
        const actionToPerform = { addOption: [], removeOption: [], disableOrHideOption: [] };
        const loadRuleMessages = [];
        forEach(ruleList, (rule) => {
            // applyAction return boolean value which states the condition is satisfied or not.
            const applyAction =
                rule.ConstraintRuleConditions.filter((condition) =>
                    // checks for condition is selected ad it satisfies the rule.
                    this.validateCRCondition(condition, product, arg.selectedComponents)
                ).length === rule.ConstraintRuleConditions.length;
            // List of rule action with autoInclude as actionIntent.
            const autoInclusionRules = uniqBy(
                rule.ConstraintRuleActions.filter(
                    (r) =>
                        get(r, 'ActionType') === ConstraintActionsType.INCLUSION &&
                        get(r, 'ActionIntent') === ConstraintActionsIntent.AUTO_INCLUDE
                ),
                'Id'
            );
            // List of rule action other than autoInclude and disable selection.
            const genricRules = uniqBy(
                rule.ConstraintRuleActions.filter(
                    (r) =>
                        get(r, 'ActionIntent') !== ConstraintActionsIntent.DISABLE_SELECTION &&
                        get(r, 'ActionIntent') !== ConstraintActionsIntent.AUTO_INCLUDE &&
                        get(r, 'ActionIntent') !== ConstraintActionsIntent.HIDE
                ),
                'Id'
            );
            // List of rule action with disable selection as actionIntent.
            const disableSelectionRules = uniqBy(
                rule.ConstraintRuleActions.filter(
                    (r) =>
                        get(r, 'ActionType') === ConstraintActionsType.EXCLUSION &&
                        get(r, 'ActionIntent') === ConstraintActionsIntent.DISABLE_SELECTION
                ),
                'Id'
            );
            // List of rule action with hide selection as actionIntent.
            const hideExclusionRules = uniqBy(
                rule.ConstraintRuleActions.filter(
                    (r) =>
                        get(r, 'ActionType') === ConstraintActionsType.EXCLUSION &&
                        get(r, 'ActionIntent') === ConstraintActionsIntent.HIDE
                ),
                'Id'
            );
            let actionProdList = [];
            if (!rule.get('actionProductList')) {
                actionProdList = actionProdList.concat(
                    rule.ConstraintRuleActions.filter((p) => p.Product != null && p.Product.Id !== null).map(
                        (p) => p.Product.Id
                    )
                );
            } else {
                actionProdList = rule.get('actionProductList');
            }
            const comps: ProductOptionComponent[] = filter(
                arg.allComponents,
                (op) => actionProdList.filter((actionProd) => op.ComponentProduct.Id === actionProd.Id).length > 0
            );
            // applyAction when flag is set to true.
            if (applyAction) {
                // disable selection / hide exclusion rule
                if (get(disableSelectionRules, 'length') > 0 || get(hideExclusionRules, 'length') > 0) {
                    // condition option of DisabelExclusionRules is selected then disable the action option and remove the selection of that option. Also, display the error message
                    if (!isNil(comps)) {
                        forEach(comps, (c) => {
                            if (
                                get(disableSelectionRules, 'length') > 0 &&
                                !get(c, 'ComponentProduct.ProductOptionGroup.IsHidden')
                            ) {
                                c.set('disabled', true);
                                actionToPerform.removeOption.push(c.ComponentProduct);
                                actionToPerform.disableOrHideOption.push(c);
                            }
                            //Lorenzo Fadda: Rispetto alla versione SDK ho tolto il controllo sul numero di prodotti selezionati che doveva essere uguale a zero.
                            //Questo perchè ho bisogno che venga eseguita la regola di esclusione anche se ho prodotti opzione selezionati
                            if (get(hideExclusionRules, 'length') > 0) {
                                c.set('hidden', true);
                                actionToPerform.disableOrHideOption.push(c);
                                actionToPerform.removeOption.push(c.ComponentProduct);
                            }
                            c.set('selected', false);
                        });
                        if (
                            (get(disableSelectionRules, 'length') > 0 &&
                                intersection(
                                    actionToPerform.removeOption.map((p) => p.Id),
                                    comps.map((c) => c.ComponentProduct.Id)
                                ).length > 0) ||
                            (get(hideExclusionRules, 'length') > 0 &&
                                intersection(comps, arg.selectedComponents).length > 0)
                        )
                            loadRuleMessages.push({ rule: rule, pending: true });
                    } else {
                        // condition option of DisableExclusionRules is not seelcted then enable the option and remove the error message if displayed.
                        forEach(comps, (comp: ProductOptionComponent) => {
                            comp.set('disabled', false);
                            actionToPerform.disableOrHideOption.push(comp);
                            comp.set('ruleAction', null);
                        });
                        if (get(disableSelectionRules, 'length') > 0)
                            loadRuleMessages.push({ rule: rule, pending: false });
                    }
                }
                // auto include inclusion rule
                if (autoInclusionRules.length > 0) {
                    if (!isNil(comps)) {
                        forEach(comps, (comp) => {
                            if (!this.isProductSelected(comp.ComponentProduct.Id)) {
                                const group = get(comp, 'ProductOptionGroup');
                                if (isEmpty(get(group, 'Options')))
                                    set(
                                        group,
                                        'Options',
                                        this.eglProductOptionService.getProductOptionsForGroup(product, group)
                                    );
                                actionToPerform.addOption.push(comp.ComponentProduct);
                            }
                        });
                        loadRuleMessages.push({ rule: rule, pending: true });
                    }
                }
                // show or remove messages when actions is not satisfied for prompt and show message actionIntent.
                if (genricRules.length > 0) {
                    if (actionProdList?.length) {
                        const errormsg = !this.validateCRActionForPrompt([rule], null);
                        if (
                            genricRules.find((r) =>
                                [
                                    ConstraintActionsType.INCLUSION,
                                    ConstraintActionsType.EXCLUSION,
                                    'Validation',
                                ].includes(r.ActionType)
                            )
                        ) {
                            loadRuleMessages.push({ rule, pending: !!errormsg });
                        }
                    }
                }
            } else {
                let allowAction = true;
                if (rule.ConstraintRuleConditions.filter((r) => r.MatchInPrimaryLines === true).length > 0)
                    allowAction = this.checkMatchInPrimaryLine(rule, product);
                // when apply rule apply action flag is false
                // removes the alert messages if condition is unchecked.
                if (allowAction) {
                    forEach(comps, (comp) => {
                        if (disableSelectionRules.length > 0) {
                            comp.set('disabled', false);
                            actionToPerform.disableOrHideOption.push(comp);
                        }
                        if (hideExclusionRules.length > 0) {
                            comp.set('hidden', false);
                            actionToPerform.disableOrHideOption.push(comp);
                        }
                    });
                    if (
                        disableSelectionRules.length > 0 ||
                        autoInclusionRules.length > 0 ||
                        hideExclusionRules.length > 0
                    )
                        loadRuleMessages.push({ rule: rule, pending: false });
                    if (genricRules.length > 0) {
                        if (
                            genricRules.find((r) =>
                                [
                                    ConstraintActionsType.INCLUSION,
                                    ConstraintActionsType.EXCLUSION,
                                    'Validation',
                                ].includes(r.ActionType)
                            )
                        ) {
                            loadRuleMessages.push({ rule, pending: false });
                        }
                    }
                }
            }
        });
        this.appliedRuleActionInfoRec = this.loadAlertMessages(loadRuleMessages, product);
        return actionToPerform;
    }
}
